import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  trendingApps: any;
  allCategories: any;
}

const initialState: InitialState = {
  trendingApps: null,
  allCategories: null,
};

const trendingAppSlice = createSlice({
  name: "trendingapp",
  initialState: { ...initialState },
  reducers: {
    setTrendingAppList: (state, action) => {
      const trendingApps = action?.payload;
      state.trendingApps = trendingApps;
    },
    setAllCategories: (state, action) => {
      const allCategories = action?.payload;
      state.allCategories = allCategories;
    },
  },
});

export const { setTrendingAppList, setAllCategories } =
  trendingAppSlice.actions;

export default trendingAppSlice.reducer;

export const selectTrendingAppslist = (state: any) =>
  state?.trending?.trendingApps;
export const selectAllCategories = (state: any) =>
  state?.trending?.allCategories;
