import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface NetReachRequest {
  primaryAppId: number;
  categoryIncludeList: any;
  categoryExcludeList: any;
  appExcludeList: any;
  appIncludeList: any;
  targetUniqueUserPercentage: any;
  fromDate: any;
  toDate: any;
}

const getTotalUserCountForApp: any = gql`
  query getTotalUserCountForApp($netReachRequest: NetReachRequest) {
    getTotalUserCountForApp(netReachRequest: $netReachRequest)
  }
`;

const getAppsForCategory: any = gql`
  query getAppsForCategory($category: String) {
    getAppsForCategory(category: $category) {
      appName
      appIcon
      appId
    }
  }
`;

const getNetReachReport = gql`
  query getNetReachReport($netReachRequest: NetReachRequest) {
    getNetReachReport(netReachRequest: $netReachRequest) {
      netReachData {
        appId
        uniqueUserCount
      }
    }
  }
`;

export const netReachApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppsForCategory: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getAppsForCategory,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getTotalUserCountForApp: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getTotalUserCountForApp,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getNetReachReport: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getNetReachReport,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
  }),
});
export const {
  useGetAppsForCategoryQuery,
  useLazyGetAppsForCategoryQuery,
  useGetTotalUserCountForAppQuery,
  useLazyGetTotalUserCountForAppQuery,
  useGetNetReachReportQuery,
  useLazyGetNetReachReportQuery,
} = netReachApiSlice;
