import { useState, useEffect } from "react";
import {
  Card,
  Grid,
  SimpleGrid,
  Text,
  Flex,
  Select,
  Tooltip,
  Image,
} from "@mantine/core";
import UserActivitySkeleton from "../../../containers/SkeletonLoader/UserActivitySkeleton";
import LineGredientChart from "./LineGredientChart/LineGredientChart";
import MultiProgressBar from "./MultiProgressBar/MultiProgressBar";
import SessionCard from "./SessionCard/SessionCard";
import { selectInitialAppList } from "../../../features/services/search/searchAppSlice";
import {
  selectComprativeTwoJsonModel,
  selectComprativeJsonModel,
  selectPrimaryJsonModel,
  selectDate,
} from "../../../features/services/overView/overViewSlice";
import {
  selectUsePrimaryChartData,
  selectUserEngagementData,
  selectUserEngagementOneData,
  selectUseComprativeChartDataOne,
  selectUseComprativeChartDataTwo,
  selectUserEngagementTwoData,
  selectUserActivityLoading,
  setComprativeAppLineChartOneData,
  setPrimaryAppLineChartData,
  setComprativeAppLineChartTwoData,
  setUserEngageMentPrimaryData,
  setUserEngageMentComprativeOneData,
  setUserEngageMentComprativeTwoData,
  setUserActivityLoader,
  setUserEngangementLoader,
  selectUserEngagementLoader,
  selectEngagementRateData,
  selectEngagementRateOneData,
  selectEngagementRateTwoData,
  setByDownloads,
  setByEvents,
  selectEngagementRateLineData,
  selectEngagementRateLineOneData,
  selectEngagementRateLineTwoData,
} from "../../../features/services/userActivity/userActivitySlice";
import { useSelector, useDispatch } from "react-redux";
import UserEngagementSkeleton from "../../../containers/SkeletonLoader/UserEngagementSkeleton";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import { ChartBarSquareIcon } from "@heroicons/react/24/outline";
import BarChartIcon from "src/assets/icons/bar-chart-icon.svg";
import LineChartIcon from "src/assets/icons/line-chart-icon.svg";
import LineChart from "@/containers/engagementRateReport/LineChart";

import "./InsightsUserActivity.scss";
import {
  useLazyGetUserEngagementQuery,
  useLazyGetUserTrendsQuery,
} from "../../../features/services/userActivity/userActivityApiSlice";
import EngagementRateReport from "@/containers/engagementRateReport/EngagementRateReport";
import AppCard from "./AppCard/AppCard";

import {
  selectDateRange,
  selectLabel,
} from "@/features/services/dateRange/dateRangeSlice";

import moment from "moment";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const renderLabel = () => {
  return (
    <>
      <Text fz="sm">
        <b>Low Usage -</b>Lorem ipsum dolor sit amet consectetur. Posuere quis
        molestie
      </Text>
      <Text fz="sm">
        <b>Very Low Usage -</b>Lorem ipsum dolor sit amet consectetur. Posuere
        quis molestie
      </Text>
      <Text fz="sm">
        <b>Medium -</b>Lorem ipsum dolor sit amet consectetur. Posuere quis
        molestie
      </Text>
      <Text fz="sm">
        <b>Low Usage -</b>Lorem ipsum dolor sit amet consectetur. Posuere quis
        molestie
      </Text>
      <Text fz="sm">
        <b>Very Low Usage -</b>Lorem ipsum dolor sit amet consectetur. Posuere
        quis molestie
      </Text>
    </>
  );
};

export default function InsightsUserActivity() {
  const dispatch = useDispatch();
  const dates = useSelector(selectLabel);
  const dateRange = useSelector(selectDateRange);
  const [showBarChart, setShowBarChart] = useState(true);

  const [fetchUserActivity] = useLazyGetUserTrendsQuery();
  const [fetUserEngagement] = useLazyGetUserEngagementQuery();

  const loader = useSelector(selectUserActivityLoading);
  /* Line chart data */
  const Appdata = useSelector(selectUsePrimaryChartData);
  const comprativeAppDataOne = useSelector(selectUseComprativeChartDataOne);
  const comprativeAppdataTwo = useSelector(selectUseComprativeChartDataTwo);
  /* progress bar chart data */
  const userEngagement = useSelector(selectUserEngagementData);
  const userEngagementComprativeOne = useSelector(selectUserEngagementOneData);
  const userEngagementComprativeTwo = useSelector(selectUserEngagementTwoData);

  /* engagament rate report data */
  const engagementRatePrimaryData = useSelector(selectEngagementRateData);
  const engagementRateOneData = useSelector(selectEngagementRateOneData);
  const engagementRateTwoData = useSelector(selectEngagementRateTwoData);

  const engagementRateData = [
    engagementRatePrimaryData,
    engagementRateOneData,
    engagementRateTwoData,
  ];

  const engagementRatePrimaryLineData = useSelector(
    selectEngagementRateLineData
  );
  const engagementRateOneLineData = useSelector(
    selectEngagementRateLineOneData
  );
  const engagementRateTwoLineData = useSelector(
    selectEngagementRateLineTwoData
  );
  const engagementRateLineData = [
    engagementRatePrimaryLineData,
    engagementRateOneLineData,
    engagementRateTwoLineData,
  ];
  /* get app name */
  const primaryAppJsonModel = useSelector(selectPrimaryJsonModel);
  const comprativeAppOneJsonModel = useSelector(selectComprativeJsonModel);
  const comprativeAppTwoJsonModel = useSelector(selectComprativeTwoJsonModel);
  const userEngagementLoader = useSelector(selectUserEngagementLoader);
  const sessionData = [
    { label: "By Session", value: "num_sessions" },
    { label: "By Events", value: "num_events" },
  ];
  const [value, setValue] = useState<string | null>(null);
  const downloadAndUpload = [
    { label: "By Downloads", value: "download_data_usage" },
    { label: "By Uploads", value: "upload_data_usage" },
  ];
  // const lineChartData = [
  //   {
  //     appId: 1010,
  //     appName: "Whatsapp",
  //     data: [
  //       {
  //         retentionCount: 5224905,
  //         retentionPercentage: 64.78,
  //       },
  //       {
  //         retentionCount: 2258859,
  //         retentionPercentage: 35.15,
  //       },
  //       {
  //         retentionCount: 3967208,
  //         retentionPercentage: 34.34,
  //       },
  //       {
  //         retentionCount: 3053909,
  //         retentionPercentage: 28.82,
  //       },
  //     ],
  //   },
  // ];
  const handleClick = (chartType: string) => {
    if (chartType === "barChart") {
      setShowBarChart(true);
    } else {
      setShowBarChart(false);
    }
  };

  const [downloadupload, setDownloadUploads] = useState<string | null>(null);
  const [primaryImage, setprimaryImage] = useState("");
  const [comprativeAppOneImage, setComprativeAppOneImage] = useState("");
  const [comprativeAppTwoImage, setComprativeAppTwoImage] = useState("");
  const selectInitialApp = useSelector(selectInitialAppList);

  useEffect(() => {
    setValue(sessionData[0].value);
    dispatch(setByEvents(sessionData[0].value));
    dispatch(setByDownloads(downloadAndUpload[0].value));
    setDownloadUploads(downloadAndUpload[0].value);
  }, []);

  useEffect(() => {
    if (primaryAppJsonModel !== null || primaryAppJsonModel !== undefined) {
      const primaryImage = selectInitialApp.filter(
        (item: any) => item.id === primaryAppJsonModel?.appId
      );
      setprimaryImage(primaryImage[0]?.appicon);
    }
    if (
      comprativeAppOneJsonModel !== null &&
      comprativeAppOneJsonModel !== undefined
    ) {
      const comprativeAppOneImage = selectInitialApp.filter(
        (item: any) => item.id === comprativeAppOneJsonModel?.appId
      );
      setComprativeAppOneImage(comprativeAppOneImage[0]?.appicon);
    }
    if (
      comprativeAppTwoJsonModel !== null &&
      comprativeAppTwoJsonModel !== undefined
    ) {
      const comprativeAppTwoImage = selectInitialApp.filter(
        (item: any) => item.id === comprativeAppTwoJsonModel?.appId
      );
      setComprativeAppTwoImage(comprativeAppTwoImage[0]?.appicon);
    }
  }, [
    primaryAppJsonModel,
    comprativeAppOneJsonModel,
    comprativeAppTwoJsonModel,
  ]);

  const handleSessionOrEventData = (e: any) => {
    setValue(e);
    dispatch(setUserActivityLoader(true));
    dispatch(setByEvents(e));
    if (primaryAppJsonModel !== null || primaryAppJsonModel !== undefined) {
      const { appName, appId } = primaryAppJsonModel;
      fetchUserActivity({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: e,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setPrimaryAppLineChartData(res.data));
          dispatch(setUserActivityLoader(false));
        }
      });
    }
    if (
      comprativeAppOneJsonModel !== null &&
      comprativeAppOneJsonModel !== undefined
    ) {
      const { appName, appId } = comprativeAppOneJsonModel;
      fetchUserActivity({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: e,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setComprativeAppLineChartOneData(res.data));
          dispatch(setUserActivityLoader(false));
        }
      });
    }
    if (
      comprativeAppTwoJsonModel !== null &&
      comprativeAppTwoJsonModel !== undefined
    ) {
      const { appName, appId } = comprativeAppTwoJsonModel;
      fetchUserActivity({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: e,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setComprativeAppLineChartTwoData(res.data));
          dispatch(setUserActivityLoader(false));
        }
      });
    }
  };

  const handleDownloadsAndUploads = (e: any) => {
    setDownloadUploads(e);
    dispatch(setUserEngangementLoader(true));
    dispatch(setByDownloads(e));
    if (primaryAppJsonModel !== null || primaryAppJsonModel !== undefined) {
      const { appName, appId } = primaryAppJsonModel;
      fetUserEngagement({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: e,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      }).then((res: any) => {
        dispatch(setUserEngangementLoader(false));
        dispatch(setUserEngageMentPrimaryData(res));
      });
    }
    if (
      comprativeAppOneJsonModel !== null &&
      comprativeAppOneJsonModel !== undefined
    ) {
      const { appName, appId } = comprativeAppOneJsonModel;

      fetUserEngagement({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: e,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      }).then((res: any) => {
        dispatch(setUserEngangementLoader(false));
        dispatch(setUserEngageMentComprativeOneData(res));
      });
    }
    if (
      comprativeAppTwoJsonModel !== null &&
      comprativeAppTwoJsonModel !== undefined
    ) {
      const { appName, appId } = comprativeAppTwoJsonModel;

      fetUserEngagement({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: e,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      }).then((res: any) => {
        dispatch(setUserEngangementLoader(false));
        dispatch(setUserEngageMentComprativeTwoData(res));
      });
    }
  };
  let dateOne = moment(dateRange.fromDate);
  let dateTwo = moment(dateRange.toDate);
  let dateDifference = dateTwo.diff(dateOne, "days");
  return (
    <SimpleGrid className="ma-user-activity">
      <Grid align={"center"}>
        {!loader ? (
          <Grid.Col span={12}>
            <Grid>
              <Grid.Col lg={12} md={12} sm={12}>
                <Card withBorder>
                  <Grid>
                    <Grid.Col span={6}>
                      <div className="title mx-4 mb-4">
                        <Flex align={"center"}>
                          <Text fw={700} fz={"md"} className={"title"}>
                            User Activity Trends
                          </Text>
                          <Tooltip
                            label={`Average number of
                          ${
                            value === "num_sessions" ? " sessions " : " events "
                          }
                          active during
                          ${
                            dates === "lastWeek"
                              ? " a day"
                              : dates === "LastMonth"
                              ? "a month"
                              : "an hour"
                          }`}
                            withArrow
                            openDelay={500}
                            closeDelay={100}
                          >
                            <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                          </Tooltip>
                        </Flex>
                      </div>
                    </Grid.Col>
                    <Grid.Col span={2} offset={4}>
                      <SimpleGrid style={{ marginLeft: "10px" }}>
                        <Select
                          value={value}
                          data={sessionData}
                          onChange={(e: any) => handleSessionOrEventData(e)}
                        />
                      </SimpleGrid>
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={9}>
                      {Appdata !== undefined &&
                        Appdata?.length > 0 &&
                        Appdata?.map((item: any) => {
                          return (
                            <LineGredientChart
                              item={{ ...item, iconUrl: primaryImage }}
                              key={item?.trendMapper?.value + Math.random()}
                            />
                          );
                        })}
                      {comprativeAppDataOne !== undefined &&
                        comprativeAppDataOne?.length > 0 &&
                        comprativeAppDataOne?.map((item: any) => {
                          return (
                            <LineGredientChart
                              item={{
                                ...item,
                                iconUrl: comprativeAppOneImage,
                              }}
                              key={item?.trendMapper?.value}
                            />
                          );
                        })}
                      {comprativeAppdataTwo !== undefined &&
                        comprativeAppdataTwo?.length > 0 &&
                        comprativeAppdataTwo?.map((item: any) => {
                          return (
                            <LineGredientChart
                              item={{
                                ...item,
                                iconUrl: comprativeAppTwoImage,
                              }}
                              key={item?.trendMapper?.value}
                            />
                          );
                        })}
                    </Grid.Col>
                    <Grid.Col lg={3} md={4} sm={5}>
                      <Flex
                        direction={"column"}
                        justify={"center"}
                        align={"center"}
                        className="h-full"
                      >
                        {Appdata !== undefined && Appdata?.length > 0 && (
                          <AppCard
                            appName={Appdata[0].appName}
                            cardColor={"#F4F8F8"}
                            borderColor={"#2C7A7B"}
                            textColor={"#2C7A7B"}
                            trendMapper={Appdata[0].trendMapper}
                            value={value}
                            iconUrl={primaryImage}
                          />
                        )}
                        {comprativeAppDataOne !== undefined &&
                          comprativeAppDataOne?.length > 0 && (
                            <AppCard
                              appName={comprativeAppDataOne[0].appName}
                              cardColor={"#F7F6FC"}
                              borderColor={"#6B46C1"}
                              textColor={"#6B46C1"}
                              trendMapper={comprativeAppDataOne[0].trendMapper}
                              value={value}
                              iconUrl={comprativeAppOneImage}
                            />
                          )}
                        {comprativeAppdataTwo !== undefined &&
                          comprativeAppdataTwo?.length > 0 && (
                            <AppCard
                              appName={comprativeAppdataTwo[0].appName}
                              cardColor={"#FCF6F4"}
                              borderColor={"#C05621"}
                              textColor={"#C05621"}
                              trendMapper={comprativeAppdataTwo[0].trendMapper}
                              value={value}
                              iconUrl={comprativeAppTwoImage}
                            />
                          )}
                      </Flex>
                    </Grid.Col>
                  </Grid>
                </Card>
              </Grid.Col>
            </Grid>
          </Grid.Col>
        ) : (
          <Grid.Col span={12}>
            <UserActivitySkeleton />
          </Grid.Col>
        )}
      </Grid>
      <Grid>
        <Grid.Col span={12}>
          <Grid>
            {userEngagementLoader === false ? (
              <Grid.Col span={12}>
                {userEngagement !== undefined && userEngagement.length > 0 && (
                  <Card withBorder style={{ zIndex: 0, overflow: "unset" }}>
                    <Card.Section>
                      <Grid className="mt-5 mb-2 mx-4">
                        <Grid.Col span={6}>
                          <div className="title-text">
                            <Flex align={"center"}>
                              <Text fw={700} fz={"md"} className={"title-text"}>
                                User Engagement
                              </Text>
                              <Tooltip
                                zIndex={1}
                                label={
                                  "User data usage on an app, split into upload and download, measured in megabytes. It categorizes app users by activity levels (low, very low, medium, high, very high) through daily/weekly/monthly sessions selected in a dropdown, helping identify top apps for advertisers."
                                }
                                openDelay={500}
                                closeDelay={100}
                                multiline
                                width={800}
                                withArrow
                                transitionProps={{
                                  duration: 100,
                                  transition: "scale-y",
                                }}
                              >
                                <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                              </Tooltip>
                            </Flex>
                          </div>
                        </Grid.Col>
                        <Grid.Col span={2} offset={4}>
                          <SimpleGrid style={{ marginLeft: "10px" }}>
                            <Select
                              value={downloadupload}
                              data={downloadAndUpload}
                              onChange={(e: any) =>
                                handleDownloadsAndUploads(e)
                              }
                            />
                          </SimpleGrid>
                        </Grid.Col>
                      </Grid>
                    </Card.Section>
                    <Card.Section className="mx-4">
                      <MultiProgressBar
                        appName={primaryAppJsonModel?.appName}
                        appIcon={primaryImage}
                        section={userEngagement}
                      />
                    </Card.Section>
                    {userEngagementComprativeOne !== undefined &&
                      userEngagementComprativeOne.length > 0 && (
                        <Card.Section className="mx-4">
                          <MultiProgressBar
                            appName={comprativeAppOneJsonModel?.appName}
                            appIcon={comprativeAppOneImage}
                            section={userEngagementComprativeOne}
                          />
                        </Card.Section>
                      )}
                    {userEngagementComprativeTwo !== undefined &&
                      userEngagementComprativeTwo.length > 0 && (
                        <Card.Section className="mx-4">
                          <MultiProgressBar
                            appName={comprativeAppTwoJsonModel?.appName}
                            appIcon={comprativeAppTwoImage}
                            section={userEngagementComprativeTwo}
                          />
                        </Card.Section>
                      )}
                    <Flex
                      justify={"space-between"}
                      align={"center"}
                      className="ma-session-card"
                    >
                      {userEngagement.map((item: any, index: any) => {
                        return (
                          <SessionCard
                            appColor={item.color}
                            appUses={item.tooltip}
                            appCount={item.value}
                            key={item.value + index}
                          />
                        );
                      })}
                    </Flex>
                  </Card>
                )}
              </Grid.Col>
            ) : (
              <Grid.Col span={12}>
                <UserEngagementSkeleton />
              </Grid.Col>
            )}
          </Grid>
        </Grid.Col>
        <Grid.Col span={12}>
          {dateDifference >= 6 && (
            <>
              <EngagementRateReport
                engagementRateData={engagementRateData}
                selectedPrimaryApp={""}
                comparativeAppOne={""}
                comparativeAppTwo={""}
              ></EngagementRateReport>
              {/* {dates === "LastMonth" && (
              <LineChart
                engagementRateData={engagementRateLineData}
                selectedPrimaryApp={""}
                comparativeAppOne={""}
                comparativeAppTwo={""}
              ></LineChart>
            )} */}
            </>
          )}
        </Grid.Col>
      </Grid>
    </SimpleGrid>
  );
}
