import { Card, Skeleton } from "@mantine/core";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  selectedPrimaryApp,
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
} from "../../features/services/overView/overViewSlice";

export default function UserActivitySkeleton() {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);
  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(4);
    } else if (primaryApp !== null && comprativeAppOne !== null) {
      setColumn(6);
    } else if (primaryApp !== null && comprativeAppTwo !== null) {
      setColumn(6);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(12);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <>
      <Card withBorder>
        <Card.Section className="p-4">
          {column === 4 && (
            <>
              <Skeleton height={180} my={10} />
              <Skeleton height={180} my={10} />
              <Skeleton height={180} my={10} />
            </>
          )}
          {column === 6 && (
            <>
              <Skeleton height={180} my={10} />
              <Skeleton height={180} my={10} />
            </>
          )}
          {column === 12 && (
            <>
              <Skeleton height={180} my={10} />
            </>
          )}
        </Card.Section>
      </Card>
    </>
  );
}
