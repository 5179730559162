import {
  createApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { setLogin, logOut } from "../authSlice";
import { Navigate } from "react-router-dom";

const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service/graphql"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service/graphql"
  : "https://gateway.dev.ma2.telekom.com/insights-service/graphql";

const baseQuery: any = graphqlRequestBaseQuery({
  url: BASE_URL,
  prepareHeaders: (headers) => {
    headers.set("Access-Control-Allow-Methods", "GET,PUT,POST,DELETE");
    headers.set("Access-Control-Allow-Origin", "*");
    headers.set("Access-Control-Allow-Headers", "Content-Type");
    headers.set("X-Frame-Options", "SAMEORIGIN");
    const token: any = sessionStorage.getItem("accesstoken");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args: any, api: any, extraOptions) => {
  let result: any = await baseQuery(args, api, extraOptions);
  if (result?.meta?.response?.status === 401) {
    window.location.href = "/login";

    const refreshTokenResult: any = await baseQuery(
      "/login",
      api,
      extraOptions
    );
    if (refreshTokenResult?.data) {
      const userName: any = api.getState().auth.userName;
      api.dispatch(setLogin({ userName }));
      //retry the original query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: "api",
  tagTypes: ["GET_APP_DATA"],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
