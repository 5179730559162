import { useState, useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Group,
  Button,
  Grid,
  Stack,
  Switch,
  Text,
  Select,
  ActionIcon,
} from "@mantine/core";
import _ from "lodash";
import { PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { ClockIcon, CursorArrowRaysIcon } from "@heroicons/react/24/outline";
import SelectSectionOneKeywordDrawer from "./SelectSectionOneKeywordDrawer";
import SelectAppsForAudience from "./SelectAppsForAudience";
import {
  setAudienceSectionOneObj,
  setAudienceSectionTwoObj,
  setAudienceSectionThreeObj,
  selectAudienceSectionOneObj,
  selectAudienceSectionTwoObj,
  selectAudienceSectionThreeObj,
  selectSectionOneData,
  selectSectionTwoData,
  selectSectionThreeData,
  selectAudienceRouteFlag,
  setAudienceRouteFlag,
  setSectionOnePayload,
  setSectionTwoPayload,
  setSectionThreePayload,
} from "@/features/services/audienceBuilder/audienceBuilderSlice";
import { useSelector, useDispatch } from "react-redux";
import SelectCategoriesForAudience from "./SelectCategoriesForAudience";
import "./CreateAudienceStyles.scss";

const AudienceSection = (props: any) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [keywordsList, setKeywordsList] = useState<any>([]);
  const [initialkeywordsList, setInitialkeywordsList] = useState<any>({});
  const [sectionOneKeywordsList, setSectionOneKeywordsList] = useState<any>([]);
  const [selectedKeywords, setSelectedKeywords] = useState<any>([]);
  const [selectedOneKeywords, setSelectedOneKeywords] = useState<any>([]);
  const [appliedFilters, setAppliedFilters] = useState<any>({});
  const [sectionOneOpened, { open: sectionOneOpen, close: sectionOneClose }] =
    useDisclosure(false);
  const [
    appSelectionDrawerOpened,
    { open: appSelectionDrawerOpen, close: appSelectionDrawerClose },
  ] = useDisclosure(false);
  const [
    categorySelectionDrawerOpened,
    { open: categorySelectionDrawerOpen, close: categorySelectionDrawerClose },
  ] = useDisclosure(false);
  const [audienceSelectionCriteria, setAudienceSelectionCriteria] = useState<
    string | null
  >("");
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [selectedmApps, setSelectedmApps] = useState<string[]>([]);
  const [selectedDayPhase, setSelectedDayPhase] = useState<string[]>([]);
  const [selectedUsage, setSelectedUsage] = useState<string[]>([]);
  const routeBackFlag = useSelector(selectAudienceRouteFlag);

  const [selectedDayPhaseList, setSelectedDayPhaseList] = useState<string[]>(
    []
  );
  const [selectedUsageList, setSelectedUsageList] = useState<string[]>([]);
  const sectionOneFilters = useSelector(selectSectionOneData);
  const sectionTwoFilters = useSelector(selectSectionTwoData);
  const sectionThreeFilters = useSelector(selectSectionThreeData);

  const initialDayPhaseList: any = [
    { id: 41, name: "Early Morning", isChecked: false },
    { id: 42, name: "Morning", isChecked: false },
    { id: 43, name: "Afternoon", isChecked: false },
    { id: 44, name: "Evening", isChecked: false },
    { id: 45, name: "Night", isChecked: false },
  ];
  const initialUsageList: any = [
    { id: 51, name: "Very High", isChecked: false },
    { id: 52, name: "High", isChecked: false },
    { id: 53, name: "Medium", isChecked: false },
    { id: 54, name: "Low", isChecked: false },
    { id: 55, name: "Very Low", isChecked: false },
  ];

  useEffect(() => {
    setInitialkeywordsList(props.initialkeywordsList);
    setKeywordsList(props.keywordsList);
  }, []);

  useEffect(() => {
    if (
      props.existingSectionData !== null &&
      !_.isEmpty(props.existingSectionData)
    ) {
      if (props.existingSectionData?.apps?.length > 0) {
        updateAppDrawerData(props.existingSectionData);
      } else if (props?.existingSectionData?.appCategories?.length > 0) {
        updateCategoryDrawerData(props.existingSectionData);
      } else if (props?.existingSectionData?.keywords?.length > 0) {
        updateKeywordsDrawerData(props.existingSectionData.keywords);
      }
      setChecked(props.existingSectionData.include);
    }
  }, [props.existingSectionData]);

  useEffect(() => {
    if (sectionOneKeywordsList.length > 0) {
      setKeywordsList(sectionOneKeywordsList);
    }
  }, [sectionOneKeywordsList]);

  const updateAppDrawerData = (inputObj: any) => {
    setAudienceSelectionCriteria("Apps");
    setSelectedUsage(inputObj.usages);
    setSelectedDayPhase(inputObj.dayPhases);
    setSelectedApps(inputObj.apps);
    setSelectedmApps(inputObj.mApps);
    setAppliedFilters({
      apps: inputObj.apps,
      dayPhase: inputObj.dayPhases,
      mApps: inputObj.mApps,
      usage: inputObj.usages,
    });
    let dayPhaseList = JSON.parse(JSON.stringify(initialDayPhaseList));
    dayPhaseList.map((item: any) => {
      inputObj.dayPhases?.map((dItem: any) => {
        if (item.name === dItem) {
          item.isChecked = true;
        }
      });
    });
    setSelectedDayPhaseList(dayPhaseList);
    let usageList = JSON.parse(JSON.stringify(initialUsageList));
    usageList.map((item: any) => {
      inputObj.usages?.map((uItem: any) => {
        if (item.name === uItem) {
          item.isChecked = true;
        }
      });
    });
    setSelectedUsageList(usageList);
  };

  const updateCategoryDrawerData = (inputObj: any) => {
    setAudienceSelectionCriteria("Category");
    setSelectedCategories(inputObj?.appCategories);
    setAppliedFilters({
      categories: inputObj?.appCategories,
    });
  };

  const updateKeywordsDrawerData = (inputObj: any) => {
    setSelectedKeywords(inputObj);
    setSelectedOneKeywords(inputObj);
    let newObj = JSON.parse(JSON.stringify(props.initialkeywordsList));
    newObj?.map((item: any) => {
      item.tabContent?.map((tItem: any) => {
        inputObj.map((kItem: any) => {
          if (tItem.name === kItem) {
            tItem.isChecked = true;
          }
        });
      });
    });
    setSectionOneKeywordsList(newObj);
    setAudienceSelectionCriteria("Interests");
  };

  const removeSectionOneKeyword = (itemToRemove: any) => {
    let keywordsArray;
    keywordsArray = selectedKeywords.filter(function (item: any) {
      return item !== itemToRemove;
    });
    setSelectedKeywords(keywordsArray);
    setSelectedOneKeywords(keywordsArray);
    let newObj = JSON.parse(JSON.stringify(sectionOneKeywordsList));
    newObj?.map((item: any) => {
      item.tabContent?.map((tItem: any) => {
        if (tItem.name === itemToRemove) {
          tItem.isChecked = false;
        }
      });
    });
    setSectionOneKeywordsList(newObj);
    //setKeywordsList(newObj);
    mapAppCateIntrest(props.handleSelectedSection, keywordsArray, newObj);
    if (keywordsArray.length === 0) {
      resetAllSelectedData();
    }
  };

  const removeApp = (itemToRemove: any, itemMicaName: any) => {
    let keywordsArray;
    keywordsArray = selectedApps.filter(function (item: any) {
      return item !== itemToRemove;
    });
    let mAppsArray = selectedmApps.filter(function (item: any) {
      return item !== itemMicaName;
    });
    setSelectedApps(keywordsArray);
    setSelectedmApps(mAppsArray);
    setAppliedFilters({
      apps: keywordsArray,
      dayPhase: selectedDayPhase,
      mApps: mAppsArray,
      usage: selectedUsage,
    });
    const data = {
      apps: keywordsArray,
      dayPhase: selectedDayPhase,
      mApps: mAppsArray,
      usage: selectedUsage,
      dayPhaseList: selectedDayPhaseList,
      usageList: selectedUsageList,
      categories: [],
      keywords: [],
    };
    mapAppCateIntrest(props.handleSelectedSection, null, data);
    if (keywordsArray.length === 0) {
      resetAllSelectedData();
    }
  };

  const removeDayPhase = (itemToRemove: any) => {
    let keywordsArray;
    keywordsArray = selectedDayPhase.filter(function (item: any) {
      return item !== itemToRemove;
    });
    setSelectedDayPhase(keywordsArray);
    let newObj = JSON.parse(JSON.stringify(selectedDayPhaseList));
    newObj.map((item: any) => {
      if (item.name === itemToRemove) {
        item.isChecked = false;
      }
    });
    setSelectedDayPhaseList(newObj);
    setAppliedFilters({
      apps: selectedApps,
      dayPhase: keywordsArray,
      mApps: selectedmApps,
      usage: selectedUsage,
    });
    const data = {
      apps: selectedApps,
      dayPhase: keywordsArray,
      mApps: selectedmApps,
      usage: selectedUsage,
      dayPhaseList: newObj,
      usageList: selectedUsageList,
      categories: [],
      keywords: [],
    };
    mapAppCateIntrest(props.handleSelectedSection, null, data);
  };

  const removeUsage = (itemToRemove: any) => {
    let keywordsArray;
    keywordsArray = selectedUsage.filter(function (item: any) {
      return item !== itemToRemove;
    });

    setSelectedUsage(keywordsArray);
    let newObj = JSON.parse(JSON.stringify(selectedUsageList));
    newObj.map((item: any) => {
      if (item.name === itemToRemove) {
        item.isChecked = false;
      }
    });
    setSelectedUsageList(newObj);
    setAppliedFilters({
      apps: selectedApps,
      dayPhase: selectedDayPhase,
      mApps: selectedmApps,
      usage: keywordsArray,
    });
    const data = {
      apps: selectedApps,
      dayPhase: selectedDayPhase,
      mApps: selectedmApps,
      usage: keywordsArray,
      dayPhaseList: selectedDayPhaseList,
      usageList: newObj,
      categories: [],
      keywords: [],
    };
    mapAppCateIntrest(props.handleSelectedSection, null, data);
  };

  const removeCategory = (itemToRemove: any) => {
    let keywordsArray;
    keywordsArray = selectedCategories.filter(function (item: any) {
      return item !== itemToRemove;
    });

    setSelectedCategories(keywordsArray);
    setAppliedFilters({
      categories: keywordsArray,
    });
    const data = {
      apps: [],
      dayPhase: [],
      mApps: [],
      usage: [],
      dayPhaseList: [],
      usageList: [],
      categories: keywordsArray,
      keywords: [],
    };
    mapAppCateIntrest(props.handleSelectedSection, null, data);
    if (keywordsArray.length === 0) {
      resetAllSelectedData();
    }
  };

  const editAudienceSection = () => {
    if (selectedKeywords.length > 0) {
      sectionOneOpen();
    } else if (
      Object.keys(appliedFilters).length > 0 &&
      selectedCategories.length === 0
    ) {
      appSelectionDrawerOpen();
    } else {
      categorySelectionDrawerOpen();
    }
  };

  const handleAudienceCriteriaChange = (data: any) => {
    if (data === "Interests") {
      sectionOneOpen();
    } else if (data === "Apps") {
      appSelectionDrawerOpen();
    } else {
      categorySelectionDrawerOpen();
    }
  };

  const sectionOneObj = useSelector(selectAudienceSectionOneObj);
  const sectionTwoObj = useSelector(selectAudienceSectionTwoObj);
  const sectionThreeObj = useSelector(selectAudienceSectionThreeObj);
  const [sectionOneCheck, setSectionOneCheck] = useState<any>(false);
  const [sectionTwoCheck, setSectionTwoCheck] = useState<any>(false);
  const [sectionThreeCheck, setSectionThreeCheck] = useState<any>(false);

  const mapAppCateIntrest = (
    handleSelectedSection: any,
    keywords: any,
    data: any
  ) => {
    if (handleSelectedSection === "section-one") {
      const obj1 = {
        keywords:
          keywords === undefined || keywords === null
            ? data.keywords === undefined
              ? []
              : data.keywords
            : keywords,
        appCategories:
          data.categories !== undefined || data.categories !== null
            ? data.categories
            : [],
        dayPhases: data.dayPhase !== undefined ? data.dayPhase : [],
        apps: data.apps !== undefined ? data.apps : [],
        mApps: data.mApps !== undefined ? data.mApps : [],
        usages: data.usage !== undefined ? data.usage : [],
        dayPhaseList: data.dayPhaseList !== undefined ? data.dayPhaseList : [],
        usageList: data.usageList !== undefined ? data.usageList : [],
        include: sectionOneCheck,
      };
      dispatch(setAudienceSectionOneObj(obj1));
    } else if (handleSelectedSection === "section-two") {
      const obj2 = {
        keywords:
          keywords === undefined || keywords === null
            ? data.keywords === undefined
              ? []
              : data.keywords
            : keywords,
        appCategories: data.categories !== undefined ? data.categories : [],
        apps: data.apps !== undefined ? data.apps : [],
        dayPhases: data.dayPhase !== undefined ? data.dayPhase : [],
        mApps: data.mApps !== undefined ? data.mApps : [],
        usages: data.usage !== undefined ? data.usage : [],
        dayPhaseList: data.dayPhaseList !== undefined ? data.dayPhaseList : [],
        usageList: data.usageList !== undefined ? data.usageList : [],
        include: sectionTwoCheck,
      };
      dispatch(setAudienceSectionTwoObj(obj2));
    } else if (handleSelectedSection === "section-three") {
      const obj3 = {
        keywords:
          keywords === undefined || keywords === null
            ? data.keywords === undefined
              ? []
              : data.keywords
            : keywords,
        appCategories:
          data.categories !== undefined || data.categories == null
            ? data.categories
            : [],
        apps: data.apps !== undefined ? data.apps : [],
        dayPhases: data.dayPhase !== undefined ? data.dayPhase : [],
        mApps: data.mApps !== undefined ? data.mApps : [],
        usages: data.usage !== undefined ? data.usage : [],
        dayPhaseList: data.dayPhaseList !== undefined ? data.dayPhaseList : [],
        usageList: data.usageList !== undefined ? data.usageList : [],
        include:
          data?.sectionThreeChecked === undefined
            ? sectionThreeCheck
            : data?.sectionThreeChecked,
      };
      dispatch(setAudienceSectionThreeObj(obj3));
    }
  };

  const setSectionOneKeywords = (data: any, keywords: any) => {
    setSelectedOneKeywords(keywords);
    setSelectedKeywords(keywords);
    setKeywordsList(data);
    sectionOneClose();
    updateFilterData({
      categories: [],
      apps: [],
      dayPhase: [],
      mApps: [],
      usage: [],
      dayPhaseList: [],
      usageList: [],
    });
    resetFilterData();
    setAudienceSelectionCriteria("Interests");
    mapAppCateIntrest(props.handleSelectedSection, keywords, data);
  };

  const handleSectionOneClose = () => {
    sectionOneClose();
  };

  const handleAppSelectionDrawerClose = () => {
    appSelectionDrawerClose();
  };

  const handleCategorySelectionDrawerClose = () => {
    categorySelectionDrawerClose();
  };

  const updateFilterData = (data: any) => {
    setAppliedFilters(data);
    if (data.dayPhaseList && data.dayPhaseList.length === 0) {
      setSelectedDayPhaseList(initialDayPhaseList);
    } else {
      setSelectedDayPhaseList(data.dayPhaseList);
    }
    if (data.usageList && data.usageList.length === 0) {
      setSelectedUsageList(initialUsageList);
    } else {
      setSelectedUsageList(data.usageList);
    }

    setSelectedDayPhase(data.dayPhase);
    setSelectedApps(data.apps);
    setSelectedmApps(data.mApps);
    setSelectedUsage(data.usage);

    setSelectedCategories(data.categories);
    props.updateSectionData(props.sectionName, {
      keywords: selectedKeywords,
      filters: data,
    });
  };

  const applyCategoryFilters = (data: any) => {
    mapAppCateIntrest(props.handleSelectedSection, null, data);
    setSelectedKeywords([]);
    setSelectedOneKeywords([]);
    setSectionOneKeywordsList(props.initialkeywordsList);
    resetFilterData();
    handleCategorySelectionDrawerClose();
    updateFilterData(data);
    setAudienceSelectionCriteria("Category");
  };

  const applyAppFilters = (data: any) => {
    mapAppCateIntrest(props.handleSelectedSection, null, data);
    setSelectedKeywords([]);
    setSelectedOneKeywords([]);
    setSectionOneKeywordsList(props.initialkeywordsList);
    appSelectionDrawerClose();
    updateFilterData(data);
    setAudienceSelectionCriteria("Apps");
  };

  const resetFilterData = () => {
    setSelectedDayPhaseList(initialDayPhaseList);
    setSelectedUsageList(initialUsageList);
  };

  const resetAllSelectedData = () => {
    setAppliedFilters({});
    setSelectedOneKeywords([]);
    setSectionOneKeywordsList([]);
    setSelectedDayPhaseList(initialDayPhaseList);
    setSelectedUsageList(initialUsageList);
    if (props.handleSelectedSection === "section-one") {
      dispatch(setAudienceSectionOneObj(null));
    }
    if (props.handleSelectedSection === "section-two") {
      dispatch(setAudienceSectionTwoObj(null));
    }
    if (props.handleSelectedSection === "section-three") {
      dispatch(setAudienceSectionThreeObj(null));
    }
  };

  useEffect(() => {
    if (routeBackFlag == true) {
      if (props?.handleSelectedSection == "section-one") {
        setSectionOneCheck(sectionOneObj?.include);
      } else if (props?.handleSelectedSection === "section-two") {
        setSectionTwoCheck(sectionTwoObj?.include);
      } else if (props?.handleSelectedSection === "section-three") {
        setSectionThreeCheck(sectionThreeObj?.include);
      }
    }
  }, []);
  return (
    <>
      <Grid>
        <Grid.Col p={24}>
          <Stack spacing={24}>
            <Group sx={{ justifyContent: "space-between" }}>
              <Group>
                <Switch
                  size="lg"
                  checked={checked}
                  onChange={(e: any) => {
                    setChecked(e.currentTarget.checked);
                    if (props?.handleSelectedSection == "section-one") {
                      setSectionOneCheck(e.currentTarget.checked);
                      const localObj = {
                        ...sectionOneObj,
                        include: e.currentTarget.checked,
                      };
                      dispatch(setAudienceSectionOneObj(localObj));
                    } else if (props?.handleSelectedSection === "section-two") {
                      setSectionTwoCheck(e.currentTarget.checked);
                      const localObj = {
                        ...sectionTwoObj,
                        include: e.currentTarget.checked,
                      };
                      dispatch(setAudienceSectionTwoObj(localObj));
                    } else if (
                      props?.handleSelectedSection === "section-three"
                    ) {
                      setSectionThreeCheck(e.currentTarget.checked);
                      const localObj = {
                        ...sectionThreeObj,
                        include: e.currentTarget.checked,
                      };
                      dispatch(setAudienceSectionThreeObj(localObj));
                    }
                  }}
                  onLabel="Include"
                  offLabel="Exclude"
                  className="section-include-exclude-criteria"
                  color="green"
                ></Switch>
                <Text className="display-text">Audience with</Text>
              </Group>
              {props.sectionCount > 1 && (
                <ActionIcon
                  variant="transparent"
                  onClick={() => props.removeSection(props.sectionId)}
                >
                  <TrashIcon height={20} width={20} />{" "}
                </ActionIcon>
              )}
            </Group>
            {sectionOneKeywordsList.length === 0 &&
              Object.keys(appliedFilters).length === 0 && (
                <Group>
                  <Button
                    onClick={() => {
                      sectionOneOpen();
                    }}
                    className="cancel-btn select-criteria-btn"
                  >
                    Interests
                  </Button>
                  <Button
                    onClick={appSelectionDrawerOpen}
                    className="cancel-btn select-criteria-btn"
                  >
                    Apps
                  </Button>
                  <Button
                    onClick={categorySelectionDrawerOpen}
                    className="cancel-btn select-criteria-btn"
                  >
                    App Category
                  </Button>
                </Group>
              )}
          </Stack>
        </Grid.Col>

        {(sectionOneKeywordsList.length > 0 ||
          Object.keys(appliedFilters).length > 0) && (
          <Grid.Col p={24}>
            <Grid align="center">
              <Grid.Col md={2}>
                <Select
                  data={["Interests", "Apps", "Category"]}
                  value={audienceSelectionCriteria}
                  onChange={handleAudienceCriteriaChange}
                  size="sm"
                  className="criteria-dropdown"
                ></Select>
              </Grid.Col>
              <Grid.Col span={"content"}>
                <Text>are</Text>
              </Grid.Col>
              <Grid.Col md={8}>
                {selectedKeywords?.length > 0 && (
                  <Group>
                    <>
                      {selectedKeywords.map((item: any) => {
                        return (
                          <Group
                            spacing={16}
                            pl={12}
                            pr={8}
                            sx={{
                              border: "1px solid #C6D0E0",
                              background: "#fff",
                            }}
                            className="selected-data"
                            key={item}
                          >
                            <Text className="keyword-text" fz="sm">
                              {item}
                            </Text>
                            <Button
                              p={0}
                              className="remove-keyword-btn"
                              onClick={() => removeSectionOneKeyword(item)}
                            >
                              <XMarkIcon
                                fill="#C6D0E0"
                                height={20}
                                width={20}
                              />
                            </Button>
                          </Group>
                        );
                      })}
                    </>
                  </Group>
                )}
                {Object.keys(appliedFilters).length > 0 && (
                  <Group>
                    <>
                      {appliedFilters?.apps?.map((item: any, index: any) => {
                        return (
                          <Group
                            spacing={16}
                            pl={12}
                            pr={8}
                            sx={{
                              border: "1px solid #C6D0E0",
                              background: "#fff",
                            }}
                            className="selected-data"
                            key={item}
                          >
                            <Text className="keyword-text" fz="sm">
                              {item}
                            </Text>
                            <Button
                              p={0}
                              className="remove-keyword-btn"
                              onClick={() =>
                                removeApp(item, selectedmApps[index])
                              }
                            >
                              <XMarkIcon
                                fill="#C6D0E0"
                                height={20}
                                width={20}
                              />
                            </Button>
                          </Group>
                        );
                      })}
                      {appliedFilters?.categories?.map(
                        (item: any, index: any) => {
                          return (
                            <Group
                              spacing={16}
                              pl={12}
                              pr={8}
                              sx={{
                                border: "1px solid #C6D0E0",
                                background: "#fff",
                              }}
                              className="selected-data"
                              key={JSON.stringify(item)}
                            >
                              <Text className="keyword-text" fz="sm">
                                {item}
                              </Text>
                              <Button
                                p={0}
                                className="remove-keyword-btn"
                                onClick={() => removeCategory(item)}
                              >
                                <XMarkIcon
                                  fill="#C6D0E0"
                                  height={20}
                                  width={20}
                                />
                              </Button>
                            </Group>
                          );
                        }
                      )}
                    </>
                  </Group>
                )}
              </Grid.Col>
              <Grid.Col span={"auto"} sx={{ textAlign: "right" }}>
                <Button
                  className="edit-audience-criteria-btn"
                  onClick={editAudienceSection}
                  leftIcon={
                    <PencilIcon
                      fontSize={14}
                      height={14}
                      width={14}
                      color="#e20074"
                    />
                  }
                >
                  Edit
                </Button>
              </Grid.Col>
              {Object.keys(appliedFilters).length > 0 && (
                <>
                  {appliedFilters?.dayPhase?.length > 0 && (
                    <>
                      <Grid.Col md={2}>
                        <Group className="selected-criteria-value">
                          <ClockIcon height={20} width={20} fontSize={20} />
                          <Text>DayPhase</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={"content"} className="supportive-text">
                        <Text>:-</Text>
                      </Grid.Col>
                      <Grid.Col md={8}>
                        <Group>
                          {appliedFilters?.dayPhase?.map(
                            (item: any, index: any) => {
                              return (
                                <Group
                                  spacing={16}
                                  pl={12}
                                  pr={8}
                                  sx={{
                                    border: "1px solid #C6D0E0",
                                    background: "#fff",
                                  }}
                                  className="selected-data"
                                  key={JSON.stringify(item)}
                                >
                                  <Text className="keyword-text">{item}</Text>
                                  <Button
                                    p={0}
                                    className="remove-keyword-btn"
                                    onClick={() => removeDayPhase(item)}
                                  >
                                    <XMarkIcon
                                      fill="#C6D0E0"
                                      height={20}
                                      width={20}
                                    />
                                  </Button>
                                </Group>
                              );
                            }
                          )}
                        </Group>
                      </Grid.Col>
                    </>
                  )}
                  {appliedFilters?.usage?.length > 0 && (
                    <>
                      <Grid.Col md={2}>
                        <Group className="selected-criteria-value">
                          <CursorArrowRaysIcon
                            height={20}
                            width={20}
                            fontSize={20}
                          />
                          <Text>Usage</Text>
                        </Group>
                      </Grid.Col>
                      <Grid.Col span={"content"} className="supportive-text">
                        <Text>:-</Text>
                      </Grid.Col>
                      <Grid.Col md={8}>
                        <Group>
                          {appliedFilters?.usage?.map(
                            (item: any, index: any) => {
                              return (
                                <Group
                                  spacing={16}
                                  pl={12}
                                  pr={8}
                                  sx={{
                                    border: "1px solid #C6D0E0",
                                    background: "#fff",
                                  }}
                                  className="selected-data"
                                  key={JSON.stringify(item)}
                                >
                                  <Text className="keyword-text">{item}</Text>
                                  <Button
                                    p={0}
                                    className="remove-keyword-btn"
                                    onClick={() => removeUsage(item)}
                                  >
                                    <XMarkIcon
                                      fill="#C6D0E0"
                                      height={20}
                                      width={20}
                                    />
                                  </Button>
                                </Group>
                              );
                            }
                          )}
                        </Group>
                      </Grid.Col>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Grid.Col>
        )}
      </Grid>

      <SelectSectionOneKeywordDrawer
        sectionOneOpened={sectionOneOpened}
        handleSectionOneClose={handleSectionOneClose}
        keywordsList={keywordsList}
        setKeywordsList={setSectionOneKeywords}
        selectedOneKeywords={selectedOneKeywords}
        initialkeywordsList={initialkeywordsList}
      />

      <SelectAppsForAudience
        filterSectionOpened={appSelectionDrawerOpened}
        handleFilterSectionClose={handleAppSelectionDrawerClose}
        applyFilters={applyAppFilters}
        selectedDayPhase={selectedDayPhase}
        selectedCategories={selectedCategories}
        selectedApps={selectedApps}
        selectedmApps={selectedmApps}
        selectedUsage={selectedUsage}
        selectedUsageList={selectedUsageList}
        selectedDayPhaseList={selectedDayPhaseList}
      />
      <SelectCategoriesForAudience
        filterSectionOpened={categorySelectionDrawerOpened}
        handleFilterSectionClose={handleCategorySelectionDrawerClose}
        applyFilters={applyCategoryFilters}
        selectedCategories={selectedCategories}
      />
    </>
  );
};

export default AudienceSection;
