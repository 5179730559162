const TooltipLabel = () => {
  return (
    <>
      <div>
        <b>High Active:</b> Number of user who have used the app more than 5
        days in a week
      </div>
      <div>
        <b>Medium Active:</b> Number of user who have used the app more than 3
        days and less than 5 days in a week
      </div>
      <div>
        <b>Low Active:</b> Number of user who have used the app less than 3 days
        in a week
      </div>
    </>
  );
};

export default TooltipLabel;
