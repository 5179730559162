import { Modal, Button, Grid, Divider, Text, Radio } from "@mantine/core";
import _ from "lodash";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import React, { useEffect, useState } from "react";
import { setSelectedDataSource } from "@/features/services/audienceBuilder/audienceBuilderSlice";
import { useDispatch, useSelector } from "react-redux";

interface RadioInputProps {
  dataSource: any;
  selectedValue: any;
  onChange: (value: number) => void;
}

const RadioInput: React.FC<RadioInputProps> = ({
  dataSource,
  selectedValue,
  onChange,
}) => {
  return (
    <>
      {dataSource.length > 0 &&
        dataSource?.map((item: any) => {
          return (
            <div className="py-2" key={item}>
              <Radio
                type="radio"
                value={item}
                label={item}
                checked={selectedValue === item}
                onChange={() => onChange(item)}
                disabled={item === "EMETRIQ"}
              />
            </div>
          );
        })}
    </>
  );
};

const SelectDataSourcePopUp = (props: any) => {
  const theme = useSelector(selectLightTheme);
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const dispatch = useDispatch();

  const [dataSource, setDataSource] = useState<any>([]);

  useEffect(() => {
    const dataSource: any = sessionStorage?.getItem("data-source")?.split(",");
    const arr: any = dataSource?.filter((item: any) => {
      return item === "EMETRIQ" || item === "MICA";
    });
    if (arr.includes("EMETRIQ") && arr.includes("MICA")) {
      setDataSource(["MICA", "EMETRIQ"]);
    } else if (
      arr.includes("EMETRIQ") === false &&
      arr.includes("MICA") == true
    ) {
      setDataSource(["MICA"]);
    } else if (
      arr.includes("EMETRIQ") == true &&
      arr.includes("MICA") == false
    ) {
      setDataSource(["EMETRIQ"]);
    }
  }, [sessionStorage]);

  const onCancelClick = () => {
    props.onCancel();
    dispatch(setSelectedDataSource(null));
  };

  const handleValueChange = (value: any) => {
    setSelectedValue(value);
    dispatch(setSelectedDataSource(value));
  };

  return (
    <>
      <Modal.Root
        opened={props.opened}
        onClose={props.onClose}
        centered
        size="lg"
        className="ma-modal"
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Create your Audience</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Divider size="xs" className="divider-color" />
            <Grid className="modal-row" m={0}>
              <Grid.Col pl={24} pt={24} pb={20} pr={24}>
                <Text
                  fz="md"
                  style={{ color: theme === "dark" ? "#C1C2C5" : "#000" }}
                >
                  Select Data-Source from which you want to create audience
                </Text>
              </Grid.Col>
              <Grid.Col pl={24} pt={12} pb={12} pr={24}>
                {dataSource?.length > 0 && (
                  <RadioInput
                    dataSource={dataSource}
                    selectedValue={selectedValue}
                    onChange={handleValueChange}
                    // disabled={dataSource.item}
                  />
                )}
              </Grid.Col>
            </Grid>
            <Divider size="xs" className="divider-color" />
          </Modal.Body>
          <Grid>
            <Grid.Col
              span={12}
              className={
                theme === "dark"
                  ? "dark-theme-button btn-container"
                  : "btn-container"
              }
            >
              <Button
                className={"cancel-btn secondary-button btn"}
                onClick={onCancelClick}
              >
                Cancel
              </Button>
              <Button
                className={
                  selectedValue ? "primary-button" : "primary-button disabled"
                }
                disabled={selectedValue ? false : true}
                onClick={props.onContine}
              >
                Start Creating Audience
              </Button>
            </Grid.Col>
          </Grid>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default SelectDataSourcePopUp;
