import { Footer, Grid, Text, Image, Flex, Anchor } from "@mantine/core";
import FooterLogo from "src/assets/images/footer-logo.png";
import "./AppFooter.scss";

export const AppFooter = () => {
  return (
    <Footer height={60} p="md" className="app-footer">
      <Grid>
        <Grid.Col span={4}>
          <Flex
            justify="flex-start"
            align="flex-start"
            direction="row"
            wrap="wrap"
          >
            <Image
              width={20}
              src={FooterLogo}
              className="mr-4"
              alt="footer logo"
            />
            <Text>Life Is For Sharing</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={4} className="text-center">
          <Text>© {new Date().getFullYear()} Deutsche Telekom AG</Text>
        </Grid.Col>
        <Grid.Col span={4} className="text-right -mt-1">
          <Flex justify="flex-end" align="flex-end" direction="row">
            <Anchor variant="text" className="mr-4">
              Contact
            </Anchor>
            <Anchor variant="text">Terms & Conditions</Anchor>
          </Flex>
        </Grid.Col>
      </Grid>
    </Footer>
  );
};

export default AppFooter;
