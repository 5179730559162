import Hoc from "@/Hoc/Hoc";
import { useLocation, useNavigate } from "react-router-dom";
import TooltipLabel from "../../containers/TootipLabel/TooltipLabel";
import "./AppProfileDashboard.scss";
import {
  Box,
  Flex,
  Grid,
  Image,
  Title,
  Text,
  Card,
  Group,
  Rating,
  Stack,
  Chip,
  Button,
  Modal,
  Tooltip,
  MediaQuery,
  SimpleGrid,
  Divider,
} from "@mantine/core";
import {
  InformationCircleIcon,
  CalendarIcon,
  ArrowsRightLeftIcon,
} from "@heroicons/react/24/outline";
import { useDisclosure } from "@mantine/hooks";
import PieChartComponent from "../../features/common/PieChartComponent/PieChartComponent";
import ProgressBarComponent from "../../features/common/ProgressBarComponent/ProgressBarComponent";
import { useLazyGetAppProfileQuery } from "../../features/services/overView/overViewApiSlice";
import {
  getAppList,
  getInitialAppList,
} from "@/features/services/search/searchAppSlice";
import SkeletonLoader from "@/containers/SkeletonLoader/SkeletonLoader";
import { Interweave } from "interweave";
import {
  setAppPrimaryData,
  setComprativeJsonModel,
  setComprativeTwoData,
  setPrimaryJsonModel,
  setComprativeOneData,
  setComprativeTwoJsonModel,
  setRouteFlag,
  selectInitialApp,
} from "../../features/services/overView/overViewSlice";
import { setCrossBrowsingData } from "../../features/services/crossBrowsing/crossBrowsingSlice";
import {
  useLazyGetOverIndexQuery,
  useLazyGetTwinAppQuery,
} from "../../features/services/crossBrowsing/crossBrowsingApiSlice";
import { useDispatch, useSelector } from "react-redux";
import { numberFormatter, generateErrorMessage } from "../../Util/utils";
import {
  setPrimaryAppLineChartData,
  setUserActivityLoader,
  setUserEngageMentPrimaryData,
  setUserEngangementLoader,
  setPrimaryAppEngagementRateData,
  setEngagementRateLoader,
  setPrimaryAppEngagementRateLineData,
} from "../../features/services/userActivity/userActivitySlice";
import {
  useLazyGetEngagementRateBarChartQuery,
  useLazyGetEngagementRateLineChartQuery,
  useLazyGetUserEngagementQuery,
  useLazyGetUserTrendsQuery,
} from "../../features/services/userActivity/userActivityApiSlice";
import { UrlMatcher, HashtagMatcher } from "interweave-autolink";
import { setTwinAppData } from "@/features/services/crossBrowsing/twinAppSlice";
import { useEffect, useState } from "react";
import { setError } from "@/features/services/errorSlice";
import { useLazyGetSearchedAppQuery } from "@/features/services/search/serachAppApiSlice";
import {
  useLazyGetAllCategoriesQuery,
  useLazyGetMinMaxDateQuery,
} from "@/features/services/trendingApps/trendingAppApiSlice";
import { setAllCategories } from "@/features/services/trendingApps/trendingAppSlice";
import {
  selectDateRange,
  setDateRange,
  setMaxMinDate,
  selectAppDashboardDateLabel,
} from "@/features/services/dateRange/dateRangeSlice";
import moment from "moment";

const AppProfileDashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [fetchUserActivity] = useLazyGetUserTrendsQuery();
  const [fetUserEngagement] = useLazyGetUserEngagementQuery();
  const [fetchEngagementRate] = useLazyGetEngagementRateBarChartQuery();
  const [fetchEngagementRateLineChart] =
    useLazyGetEngagementRateLineChartQuery();
  const appDateLabel = useSelector(selectAppDashboardDateLabel);

  const app = useSelector(selectInitialApp);
  const [fetchMinMaxDate, { data: startAndEndDateInEs }] =
    useLazyGetMinMaxDateQuery<any>();
  //const dateRange = useSelector(selectDateRange);
  const [totalUserCount, setTotalUserCount] = useState<any>(0);
  const [dateArr, setDateArr] = useState([]);

  useEffect(() => {
    const getMinMaxDate = async () => {
      const { data } = await fetchMinMaxDate("");
      dispatch(setMaxMinDate(data?.startAndEndDateInEs));
    };
    getMinMaxDate();
  }, [startAndEndDateInEs]);

  useEffect(() => {
    const dateArr = appDateLabel.split("_");
    setDateArr(dateArr);
  }, [appDateLabel]);

  const [
    fetchGetAppProfile,
    {
      data: appProfileData,
      isLoading,
      error: appProfileErrorMessage,
      isError: appProfileError,
    },
  ] = useLazyGetAppProfileQuery<any>();
  const [fetchSearchOption] = useLazyGetSearchedAppQuery<any>();
  const [fetchCrossBrowsingData] = useLazyGetOverIndexQuery();
  const [
    fetchTwinAppData,
    { isError: twinappDatayErr, error: twinAppDataErrMsg },
  ] = useLazyGetTwinAppQuery();
  const [fetchCategory] = useLazyGetAllCategoriesQuery<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      startAndEndDateInEs !== undefined &&
      startAndEndDateInEs?.startAndEndDateInEs !== undefined
    ) {
      const { endDateInEs } = startAndEndDateInEs?.startAndEndDateInEs;
      const dateRange = {
        dateLabel: "lastWeek",
        calFromDates: moment(endDateInEs)
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        calToDates: endDateInEs,
        fromDate: moment(endDateInEs).subtract(6, "days").format("YYYY-MM-DD"),
        toDate: endDateInEs,
      };
      dispatch(setDateRange(dateRange));
      if (app !== null || app !== undefined) {
        const payload: any = {
          appProfileRequest: {
            appId: app?.appId,
            appName: app?.appName,
            isDemographic: false,
            fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
            toDate: dateRange?.toDate, //selectedDateRange.toDate,
          },
        };
        fetchGetAppProfile(payload);
      }
    }
  }, [app, startAndEndDateInEs]);

  useEffect(() => {
    if (
      startAndEndDateInEs !== undefined &&
      startAndEndDateInEs?.startAndEndDateInEs !== undefined
    ) {
      const { endDateInEs } = startAndEndDateInEs?.startAndEndDateInEs;
      const dateRange = {
        dateLabel: "lastWeek",
        calFromDates: moment(endDateInEs)
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        calToDates: endDateInEs,
        fromDate: moment(endDateInEs).subtract(6, "days").format("YYYY-MM-DD"),
        toDate: endDateInEs,
      };
      const appDateLabel: any = `Last Week_(${moment(endDateInEs)
        .subtract(6, "days")
        .format("DD MMM YYYY")} - ${moment(endDateInEs).format(
        "DD MMM YYYY"
      )})`;
      const arr = appDateLabel.split("_");
      setDateArr(arr);
      if (app === undefined || app === null) {
        const payload = {
          appProfileRequest: {
            appId: location?.state?.appId,
            appName: location?.state?.appName,
            isDemographic: false,
            fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
            toDate: dateRange?.toDate, //selectedDateRange.toDate,
          },
        };
        fetchGetAppProfile(payload);
      }
    }
  }, [location?.state?.appId, startAndEndDateInEs]);

  let deviceBrandDistributionData =
    appProfileData?.insights?.deviceBrand.length === 0
      ? [
          { percent: "20", name: "Samsung" },
          { percent: "40", name: "Iphone" },
          { percent: "30", name: "Nokia" },
          { percent: "5", name: "Xiaomi" },
          { percent: "5", name: "huawei" },
        ]
      : appProfileData?.insights?.deviceBrand;
  deviceBrandDistributionData = deviceBrandDistributionData?.map(
    (item: any, index: any) => ({ ...item, id: index + 1 })
  );

  let ageDistributionData =
    appProfileData?.insights?.age === null || undefined
      ? [
          { percent: "20", name: "<25" },
          { percent: "40", name: "25-34" },
          { percent: "30", name: "35-54" },
          { percent: "10", name: "55+" },
        ]
      : appProfileData?.insights?.age;
  ageDistributionData = ageDistributionData?.map((item: any, index: any) => ({
    ...item,
    id: index + 1,
  }));

  const genderDistributionSeriesData =
    appProfileData?.insights?.gender === null || undefined
      ? [
          { value: 40, name: "Male" },
          { value: 60, name: "Female" },
        ]
      : appProfileData?.insights?.gender;

  const totalUserSeriesData =
    appProfileData?.insights?.activeInactiveUsers === null || undefined
      ? [
          { value: 30, name: "Active Users" },
          { value: 70, name: "Inactive Users" },
        ]
      : appProfileData?.insights?.activeInactiveUsers.map((item: any) => {
          return {
            name: item.name?.replace("Users", ""),
            percent: item.percent,
            value: item.value,
          };
        });

  const deviceDistributionSeriesData =
    appProfileData?.insights?.os === null || undefined
      ? [
          { value: 40, name: "IOS" },
          { value: 60, name: "Android" },
        ]
      : appProfileData?.insights?.os;
  const networkDistributionSeriesData =
    appProfileData?.insights?.connectionType === null || undefined
      ? [
          { value: 40, name: "3G" },
          { value: 20, name: "4G" },
          { value: 10, name: "2G" },
          { value: 30, name: "5G" },
        ]
      : appProfileData?.insights?.connectionType;
  const primaryAppColors: string[] = ["#2C7A7B", "#81E6D9"];

  useEffect(() => {
    if (twinAppDataErrMsg) {
      const message = generateErrorMessage(twinAppDataErrMsg);
      dispatch(setError(message));
    }

    if (appProfileErrorMessage) {
      const message = generateErrorMessage(appProfileErrorMessage);
      dispatch(setError(message));
    }
  }, [twinappDatayErr, appProfileError]);

  const handleExploreNow = async () => {
    setLoading(true);
    if (
      startAndEndDateInEs !== undefined &&
      startAndEndDateInEs?.startAndEndDateInEs !== undefined
    ) {
      const { endDateInEs } = startAndEndDateInEs?.startAndEndDateInEs;
      const dateRange = {
        dateLabel: "lastWeek",
        calFromDates: moment(endDateInEs)
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        calToDates: endDateInEs,
        fromDate: moment(endDateInEs).subtract(6, "days").format("YYYY-MM-DD"),
        toDate: endDateInEs,
      };
      const { data } = await fetchSearchOption("");
      const appList: any = data;
      const response = await fetchCategory("");
      const category = response?.data;
      let appName: any = "";
      let appId: any = "";
      if (location.state === null) {
        appId = app.appId;
        appName = app.appName;
      } else {
        appName = location?.state?.appName;
        appId = location?.state?.appId;
      }

      dispatch(setUserEngangementLoader(true));
      dispatch(setRouteFlag(true));
      dispatch(setAppPrimaryData(appName));
      dispatch(
        setPrimaryJsonModel({
          ...appProfileData,
          appId: appId,
        })
      );
      dispatch(setComprativeJsonModel(null));
      dispatch(setComprativeOneData(null));
      dispatch(setComprativeTwoData(null));
      dispatch(setComprativeTwoJsonModel(null));
      dispatch(setUserActivityLoader(false));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: appId,
          competitiveAppOneId: 0,
          competitiveAppTwoId: 0,
          fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
          toDate: dateRange?.toDate, //selectedDateRange.toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      })
        .then((res: any) => {
          if (res?.data !== undefined) {
            dispatch(setCrossBrowsingData(res.data));
          }
        })
        .catch((e: any) => console.log(e));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: appId,
          competitiveAppOneId: 0,
          competitiveAppTwoId: 0,
          fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
          toDate: dateRange?.toDate, //selectedDateRange.toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      })
        .then((res: any) => {
          if (res?.data !== undefined) {
            dispatch(setTwinAppData(res.data));
          }
        })
        .catch((e: any) => console.log(e));
      fetchUserActivity({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: "num_sessions",
          fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
          toDate: dateRange?.toDate, //selectedDateRange.toDate,
        },
      })
        .then((res: any) => {
          if (res !== undefined) {
            dispatch(setPrimaryAppLineChartData(res.data));
            dispatch(setUserActivityLoader(false));
          }
        })
        .catch((e: any) => console.log(e));

      fetchEngagementRate({
        engagementRateRequest: {
          appId: appId,
          appName: appName,
          fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
          toDate: dateRange?.toDate, //selectedDateRange.toDate,
        },
      })
        .then((res: any) => {
          if (res !== undefined) {
            dispatch(setPrimaryAppEngagementRateData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        })
        .catch((e: any) => console.log(e));
      fetchEngagementRateLineChart({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
          toDate: dateRange?.toDate, //selectedDateRange.toDate,
        },
      })
        .then((res: any) => {
          if (res !== undefined) {
            dispatch(setPrimaryAppEngagementRateLineData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        })
        .catch((e: any) => console.log(e));

      dispatch(getAppList(appList));
      dispatch(getInitialAppList(appList));
      dispatch(setAllCategories(category));
      fetUserEngagement({
        userActivitiesRequest: {
          appId: appId,
          appName: appName,
          activityBy: "download_data_usage",
          fromDate: dateRange?.fromDate, //selectedDateRange.fromDate,
          toDate: dateRange?.toDate, //selectedDateRange.toDate,
        },
      })
        .then((res: any) => {
          dispatch(setUserEngangementLoader(false));
          dispatch(setUserEngageMentPrimaryData(res));
        })
        .catch((e: any) => console.log(e));
      navigate("/insights/competitiveResearch");
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  useEffect(() => {
    if (appProfileData?.insights?.activeInactiveUsers !== null || undefined) {
      const initialValue = 0;
      const sumWithInitial =
        appProfileData?.insights?.activeInactiveUsers.reduce(
          (accumulator: any, currentValue: any) => {
            return accumulator + parseInt(currentValue?.value);
          },
          initialValue
        );
      setTotalUserCount(sumWithInitial);
    }
  }, [appProfileData?.insights?.activeInactiveUsers]);
  return (
    <>
      {!loading ? (
        <>
          {isLoading ? (
            <Grid>
              <SkeletonLoader />
            </Grid>
          ) : (
            <>
              <Modal
                opened={opened}
                onClose={close}
                title="Description"
                centered
                size="50%"
                className="ma-insight-overview"
              >
                <Text style={{ margin: "16px" }}>
                  <Interweave
                    content={
                      appProfileData?.description !== undefined
                        ? JSON?.parse(appProfileData?.description)
                        : appProfileData?.description?.slice(1, -1)
                    }
                    matchers={[
                      new UrlMatcher("url"),
                      new HashtagMatcher("hashtag"),
                    ]}
                  />
                </Text>
              </Modal>
              {sessionStorage.getItem("insightTab") == "true" && (
                <Button
                  className="explore-more-btn"
                  onClick={handleExploreNow}
                  data-testid="compare"
                >
                  <ArrowsRightLeftIcon className="h-5 w-5 mr-1" />
                  <b>Compare</b>
                </Button>
              )}
              <Grid
                gutter={20}
                className="ma-app-dashboard"
                data-testid="ma-app-dashboard"
              >
                <Grid.Col md={12} lg={12} sm={12}>
                  <div className="app-basic-info-area">
                    <Grid align="center">
                      <Grid.Col lg={6} md={4} sm={12} className="my-3">
                        <Group className="app-name">
                          <Image
                            src={appProfileData?.iconUrl.slice(1, -1)}
                            width={63}
                          />
                          <Box component="div">
                            <Title order={1}>{appProfileData?.appName}</Title>
                            {appProfileData?.appCategory && (
                              <Text className="app-sub-text">
                                {appProfileData?.appCategory?.toString()}
                              </Text>
                            )}
                          </Box>
                        </Group>
                      </Grid.Col>
                      <Grid.Col lg={3} md={4} sm={12}>
                        <Grid>
                          <Grid.Col
                            span={11}
                            className="flex -mt-5 justify-end"
                          >
                            <CalendarIcon className="h-5 w-5 mr-2 neutral-rank" />
                            <Text fz="xs">
                              <span className="bold">{dateArr[0]}</span>
                              <span className="pl-2 avg-rating-text ">
                                {dateArr[1]}
                              </span>
                            </Text>
                          </Grid.Col>
                          <Grid.Col span={1} className="flex">
                            <Divider
                              size="lg"
                              style={{
                                display: "inline",
                                marginTop: "-40px",
                                marginBottom: "-20px",
                                borderWidth: "1px",
                              }}
                              orientation="vertical"
                            />
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                      <Grid.Col lg={3} md={4} sm={12}>
                        <Grid>
                          <Grid.Col span={7}>
                            <Group
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "4px",
                              }}
                            >
                              <Rating defaultValue={1} size="sm" count={1} />
                              <Text className="app-rating">
                                {Math.round(
                                  appProfileData?.rating?.avgRating * 10
                                ) / 10}
                              </Text>

                              <Text className="avg-rating-text">
                                Average Rating
                              </Text>
                            </Group>
                            <Group className="block">
                              {appProfileData?.rating?.source && (
                                <Text className="info-text text-right">
                                  (Data Source :{" "}
                                  {appProfileData?.rating?.source.slice(1, -1)})
                                </Text>
                              )}
                            </Group>
                          </Grid.Col>
                          <Grid.Col span={1} className="flex -mt-5">
                            <Divider
                              size="lg"
                              style={{ display: "inline", borderWidth: "1px" }}
                              orientation="vertical"
                            />
                          </Grid.Col>
                          <Grid.Col span={4} className="-mt-5">
                            #{appProfileData?.rank}
                            <Text className="avg-rating-text" fz="xs">
                              Rank by No. of events
                            </Text>
                            {/* <Chip
                              color="black"
                              variant="filled"
                              style={{ color: "black" }}
                            ></Chip> */}
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                    </Grid>
                    <Divider my="sm" style={{ marginTop: "0px" }} />
                    <Grid gutter={20}>
                      <MediaQuery largerThan="md" styles={{ display: "none" }}>
                        <Grid.Col span={12}>
                          {deviceDistributionSeriesData && (
                            <ProgressBarComponent
                              colorArr={primaryAppColors}
                              barType={"barWithtoolTip"}
                              valueArr={[
                                deviceDistributionSeriesData[0]?.percent,
                                deviceDistributionSeriesData[1]?.percent,
                              ]}
                              tooltipArr={[
                                deviceDistributionSeriesData[0]?.percent +
                                  "%" +
                                  " Absolute " +
                                  deviceDistributionSeriesData[0].value,
                                deviceDistributionSeriesData[1]?.percent +
                                  "%" +
                                  " Absolute " +
                                  deviceDistributionSeriesData[1].value,
                              ]}
                              value={undefined}
                              color={""}
                              Barsize={undefined}
                              sections={undefined}
                              isPadding={true}
                            />
                          )}
                          <Flex className="p-2" justify={"space-between"}>
                            <SimpleGrid>
                              <Text size="xs" className="break-words w-40">
                                Event wise Device Distribution :
                                {numberFormatter(
                                  appProfileData?.insights?.osCount
                                ) || ""}
                              </Text>
                            </SimpleGrid>
                            <Flex>
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: 15,
                                    height: 15,
                                    background: "#2C7A7B",
                                    borderRadius: "4px",
                                    marginRight: "10px",
                                  }}
                                ></span>
                                {deviceDistributionSeriesData && (
                                  <span style={{ fontSize: "12px" }}>
                                    {`${deviceDistributionSeriesData[0].name} ${deviceDistributionSeriesData[0].percent} %`}
                                  </span>
                                )}
                              </div>
                              <div>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: 15,
                                    height: 15,
                                    background: "#2C7A7B",
                                    borderRadius: "4px",
                                    marginLeft: 20,
                                    marginRight: "10px",
                                  }}
                                ></span>
                                {deviceDistributionSeriesData && (
                                  <span style={{ fontSize: "12px" }}>
                                    {`${deviceDistributionSeriesData[1].name} ${deviceDistributionSeriesData[1].percent} %`}
                                  </span>
                                )}
                              </div>
                            </Flex>
                          </Flex>
                        </Grid.Col>
                      </MediaQuery>
                      <Grid.Col
                        md={12}
                        lg={12}
                        sm={12}
                        className="app-description-area"
                        pl={10}
                      >
                        <Stack spacing={0}>
                          <Title
                            order={2}
                            style={{ fontSize: "16px", fontWeight: "500" }}
                          >
                            Description
                          </Title>
                          <Text
                            size="sm"
                            lineClamp={6}
                            className="description-text"
                          >
                            <Interweave
                              content={
                                appProfileData?.description !== undefined
                                  ? JSON?.parse(appProfileData?.description)
                                  : appProfileData?.description?.slice(1, -1)
                              }
                              matchers={[
                                new UrlMatcher("url"),
                                new HashtagMatcher("hashtag"),
                              ]}
                            />
                            \{" "}
                          </Text>
                          {appProfileData &&
                            JSON?.parse(appProfileData?.description).length >
                              700 && (
                              <Text
                                component="button"
                                type="button"
                                align="left"
                                p={0}
                                c="blue"
                                onClick={open}
                                className="read-more-link"
                              >
                                Read More
                              </Text>
                            )}
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  </div>
                </Grid.Col>
                <Grid.Col md={12} lg={12} sm={12}>
                  <Divider
                    label="User Insights"
                    size="sm"
                    labelPosition="center"
                  />
                </Grid.Col>
                <Grid.Col md={4} lg={4} sm={4}>
                  <Card
                    withBorder
                    shadow="sm"
                    radius="md"
                    className={
                      appProfileData?.insights?.activeInactiveUsers === null
                        ? "chart-card disabled"
                        : "chart-card"
                    }
                  >
                    <Card.Section inheritPadding className="card-header">
                      <Group position="left">
                        <Text fz="sm">
                          Total Users
                          <span style={{ color: "#6E7B91" }}>
                            (Userwise)&nbsp;
                          </span>
                        </Text>
                        <Tooltip multiline label={<TooltipLabel />}>
                          <InformationCircleIcon className="h-6 w-6 -ml-4 z-1 cursor-pointer" />
                        </Tooltip>
                      </Group>
                    </Card.Section>
                    <Card.Section mt={0} ml={10} mr={10} className="mt-0">
                      {appProfileData?.insights?.activeInactiveUsers ===
                        null && (
                        <Text className="no-data-text">No Data Available</Text>
                      )}
                      <PieChartComponent
                        seriesData={totalUserSeriesData}
                        chartName={
                          `${
                            totalUserCount !== "" &&
                            numberFormatter(totalUserCount)
                          } \n Users` || "users"
                        }
                        showLabelLine={false}
                        chartRadius={
                          window.innerWidth < 821 ? [40, 20] : [60, 50]
                        }
                        legendOrientation={["horizontal", "center", "bottom"]}
                        height={"180px"}
                        showLabelinMiddle={true}
                        chartAlign={["50%", "50%"]}
                        hideLabel={false}
                        showLegend={true}
                        titleAlign={"50%"}
                        colors={["#2C7A7B", "#38B2AC", "#81E6D9"]}
                        width={undefined}
                        className={"app-profile-totalUserSeriesData"}
                        itemGap={50}
                        alignLegend={"horizontal"}
                      />
                    </Card.Section>
                  </Card>
                </Grid.Col>
                <Grid.Col md={4} lg={4} sm={4}>
                  <Card
                    withBorder
                    shadow="sm"
                    radius="md"
                    className={
                      appProfileData?.insights?.age === null
                        ? "chart-card disabled"
                        : "chart-card"
                    }
                  >
                    <Card.Section
                      inheritPadding
                      py="xs"
                      className="card-header"
                    >
                      <Group position="apart">
                        <Text fz="sm">
                          Age Distribution{" "}
                          <span style={{ color: "#6E7B91" }}>(Userwise)</span>
                        </Text>
                      </Group>
                    </Card.Section>
                    <Card.Section ml={0} mr={0} mt={12}>
                      {appProfileData?.insights?.age === null && (
                        <Text className="no-data-text">No Data Available</Text>
                      )}
                      {ageDistributionData?.length > 0 &&
                        ageDistributionData.map((item: any) => {
                          return (
                            <Grid pl={8} pr={8} pt={8} key={item.id} gutter={4}>
                              <Grid.Col span="auto">
                                <Text>{item.name}</Text>
                              </Grid.Col>
                              <Grid.Col md={6} lg={9} sm={5}>
                                <ProgressBarComponent
                                  Barsize="lg"
                                  color="#2C7A7B"
                                  value={Math.round(item?.percent)}
                                  barType={undefined}
                                  colorArr={[]}
                                  valueArr={[]}
                                  tooltipArr={[]}
                                  sections={undefined}
                                  isPadding={true}
                                />
                              </Grid.Col>
                              <Grid.Col span="auto">
                                <Text>{item.percent} %</Text>
                              </Grid.Col>
                            </Grid>
                          );
                        })}
                    </Card.Section>
                  </Card>
                </Grid.Col>
                <Grid.Col md={4} lg={4} sm={4}>
                  <Card
                    withBorder
                    shadow="sm"
                    radius="md"
                    className={
                      appProfileData?.insights?.gender === null
                        ? "chart-card disabled"
                        : "chart-card"
                    }
                  >
                    <Card.Section
                      inheritPadding
                      py="xs"
                      className="card-header"
                    >
                      <Group position="apart">
                        <Text fz="sm">
                          Gender Distribution{" "}
                          <span style={{ color: "#6E7B91" }}>(Userwise)</span>
                        </Text>
                      </Group>
                    </Card.Section>
                    <Card.Section mt={10}>
                      {appProfileData?.insights?.gender === null && (
                        <Text className="no-data-text">No Data Available</Text>
                      )}
                      <PieChartComponent
                        seriesData={genderDistributionSeriesData}
                        chartName={
                          appProfileData?.insights?.gender?.total || ""
                        }
                        showLabelLine={false}
                        chartRadius={
                          window.innerWidth < 821 ? [40, 20] : [60, 45]
                        }
                        legendOrientation={["horizontal", "center", "bottom"]}
                        height={"150px"}
                        showLabelinMiddle={true}
                        chartAlign={["50%", "50%"]}
                        hideLabel={false}
                        showLegend={true}
                        titleAlign={"80%"}
                        colors={["#2C7A7B", "#81E6D9", "#2C7A7B", "#234E52"]}
                        width={undefined}
                        className={"app-profile-genderDistributionSeriesData"}
                        alignLegend={"center"}
                        itemGap={50}
                      />
                    </Card.Section>
                  </Card>
                </Grid.Col>
                <Grid.Col md={12} lg={12} sm={12}>
                  <Divider
                    label="Device & Network Insights"
                    size="sm"
                    labelPosition="center"
                  />
                </Grid.Col>
                <Grid.Col md={3} lg={3} sm={4}>
                  <Card
                    withBorder
                    shadow="sm"
                    radius="md"
                    className="chart-card"
                  >
                    <Card.Section
                      inheritPadding
                      py="xs"
                      className="card-header "
                    >
                      <Text fz="sm">
                        Device Distribution{" "}
                        <span style={{ color: "#6E7B91" }}>(Userwise)</span>
                      </Text>
                    </Card.Section>
                    <Card.Section ml={0} mr={0} mt={12}>
                      <PieChartComponent
                        className={"app-profile-dashboard"}
                        seriesData={deviceDistributionSeriesData}
                        chartName={
                          `${numberFormatter(
                            appProfileData?.insights?.osCount
                          )} \n Users` || ""
                        }
                        showLabelLine={false}
                        chartRadius={[90, 75]}
                        legendOrientation={["horizontal", "center", "bottom"]}
                        height={"260px"}
                        showLabelinMiddle={true}
                        chartAlign={["50%", "50%"]}
                        hideLabel={false}
                        showLegend={true}
                        titleAlign={"50%"}
                        colors={["#2C7A7B", "#81E6D9", "#2C7A7B", "#234E52"]}
                        width={null}
                        itemGap={100}
                        alignLegend={"horizontal"}
                      />
                    </Card.Section>
                  </Card>
                </Grid.Col>
                <Grid.Col md={5} lg={5} sm={4}>
                  <Card
                    withBorder
                    shadow="sm"
                    radius="md"
                    className={
                      appProfileData?.insights?.deviceBrand === null ||
                      appProfileData?.insights?.deviceBrand.length === 0
                        ? "chart-card disabled"
                        : "chart-card"
                    }
                  >
                    <Card.Section
                      inheritPadding
                      py="xs"
                      className="card-header"
                    >
                      <Group position="apart">
                        <Text fz="sm">
                          Device Brand Distribution{" "}
                          <span style={{ color: "#6E7B91" }}>(Userwise)</span>
                        </Text>
                      </Group>
                    </Card.Section>
                    <Card.Section ml={0} mr={0} mt={12}>
                      {appProfileData?.insights?.deviceBrand === null ||
                        (appProfileData?.insights?.deviceBrand.length === 0 && (
                          <Text className="no-data-text">
                            No Data Available
                          </Text>
                        ))}
                      {deviceBrandDistributionData?.length > 0 &&
                        deviceBrandDistributionData?.map((item: any) => {
                          return (
                            <Grid
                              pl={8}
                              pr={8}
                              pt={8}
                              key={item.id}
                              justify="space-between"
                              align="center"
                              className="pt-4"
                            >
                              <Grid.Col span={2}>
                                <Text>{item.name}</Text>
                              </Grid.Col>
                              <Grid.Col md={8} lg={8} sm={5}>
                                <Tooltip.Floating
                                  label={`${item.value
                                    ?.toString()
                                    .replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )} users`}
                                  color={"#2C7A7B"}
                                  offset={10}
                                  key={item.appId}
                                >
                                  <div>
                                    <ProgressBarComponent
                                      Barsize="lg"
                                      color="#2C7A7B"
                                      value={Math.round(item?.percent)}
                                      barType={undefined}
                                      colorArr={[]}
                                      valueArr={[]}
                                      tooltipArr={[]}
                                      sections={undefined}
                                      isPadding={true}
                                    />
                                  </div>
                                </Tooltip.Floating>
                              </Grid.Col>
                              <Grid.Col span="auto">
                                <Text>{item.percent} %</Text>
                              </Grid.Col>
                            </Grid>
                          );
                        })}
                    </Card.Section>
                  </Card>
                </Grid.Col>
                <Grid.Col md={4} lg={4} sm={4}>
                  <Card
                    withBorder
                    shadow="sm"
                    radius="md"
                    className="chart-card"
                  >
                    <Card.Section
                      inheritPadding
                      py="xs"
                      className="card-header"
                    >
                      <Group position="apart">
                        <Text fz="sm">
                          Network Distribution{" "}
                          <span style={{ color: "#6E7B91" }}>(Userwise)</span>
                        </Text>
                      </Group>
                    </Card.Section>
                    <Card.Section ml={0} mr={0} mt={0}>
                      <PieChartComponent
                        seriesData={networkDistributionSeriesData}
                        chartName={
                          `${numberFormatter(
                            appProfileData?.insights?.connectionCount
                          )} \n Users` || ""
                        }
                        showLabelLine={false}
                        chartRadius={[90, 75]}
                        legendOrientation={["horizontal", "center", "bottom"]}
                        height={"280px"}
                        showLabelinMiddle={true}
                        chartAlign={["50%", "50%"]}
                        hideLabel={false}
                        showLegend={true}
                        titleAlign={"50%"}
                        colors={["#81E6D9", "#38B2AC", "#2C7A7B", "#234E52"]}
                        width={undefined}
                        className={"app-profile-networkDistributionSeriesData"}
                        alignLegend={"horizontal"}
                        itemGap={30}
                      />
                    </Card.Section>
                  </Card>
                </Grid.Col>
              </Grid>
            </>
          )}
        </>
      ) : (
        <Grid>
          <SkeletonLoader />
        </Grid>
      )}
    </>
  );
};

export default Hoc(AppProfileDashboard);
