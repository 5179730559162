import { FC, useEffect, useRef } from "react";
import {
  Popover,
  Text,
  Select,
  Flex,
  Skeleton,
  SimpleGrid,
} from "@mantine/core";
import {
  MagnifyingGlassIcon,
  PencilIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

interface Data {
  label: string;
  value: string;
  group: string;
}

interface InputProps {
  flag: string | null;
  opened: boolean;
  open: (e: any) => void;
  showTaggedInputLoader: any;
  src: string;
  value: string | null | undefined;
  setCloseMark: any;
  openSection: any;
  onChangeHandler: (e: any) => void;
  data: Data[];
  iconsOption: boolean;
  openOverlay: boolean;
  handleOverLayClick: (e: any) => void;
  nothingFound: string;
  showLoaderOption: boolean;
  limit: number;
  itemComponent: any;
  clearable: any;
  bgColor: any;
  borderColor: any;
}

const TaggedInput: FC<InputProps> = ({
  showTaggedInputLoader,
  opened,
  src,
  value,
  setCloseMark,
  openSection,
  onChangeHandler,
  data,
  iconsOption,
  openOverlay,
  handleOverLayClick,
  nothingFound,
  limit,
  itemComponent,
  clearable,
  bgColor,
  borderColor,
}) => {
  const ref: any = useRef<HTMLInputElement>(null);

  useEffect((): any => {
    if (openOverlay) {
      document.body.style.overflow = "hidden";
    }
    return () => (document.body.style.overflow = "unset");
  }, [openOverlay]);

  useEffect(() => {
    if (ref !== null) {
      ref?.current?.focus();
    }
  }, [ref?.current]);

  return (
    <div
      className="ma-tag-input"
      style={{
        width: value !== null ? "auto" : 0,
        height: value !== null ? "32px" : 0,
      }}
    >
      {openOverlay && (
        <div className="ma-overlay" onClick={handleOverLayClick}></div>
      )}
      <Popover
        width={280}
        position="bottom"
        shadow="md"
        opened={opened}
        closeOnClickOutside={true}
      >
        <Popover.Target>
          <Text
            style={{
              width: value !== null ? "100%" : 0,
            }}
            className="ml-3 input-tag-text"
          >
            <Flex
              justify={"space-between"}
              align={"center"}
              className="input-tag-flex"
              style={{
                display: value !== null ? "flex" : "none",
                background: bgColor,
                border: `1px solid ${borderColor}`,
              }}
            >
              {(value !== null || value !== undefined || value !== "") && (
                <>
                  <img width={20} height={20} src={src} alt="sky" />
                  <Text size="sm">{value}</Text>
                  {iconsOption ? (
                    <XMarkIcon
                      className="h-4 w-4 close-mark"
                      onClick={setCloseMark}
                    />
                  ) : (
                    <PencilIcon
                      className="h-4 w-4 edit-mark"
                      onClick={openSection}
                    />
                  )}
                </>
              )}
            </Flex>
          </Text>
        </Popover.Target>
        <Popover.Dropdown
          className="px-0 rounded-none"
          style={{
            height:
              showTaggedInputLoader || (data !== undefined && data !== null)
                ? "280px"
                : 0,
          }}
        >
          {data !== null && data !== undefined && data.length > 0 && (
            <div>
              <Select
                ref={ref}
                className="mx-3"
                value={value}
                onChange={(e) => onChangeHandler(e)}
                searchable
                initiallyOpened={true}
                clearable={clearable}
                itemComponent={itemComponent}
                limit={limit}
                selectOnBlur
                nothingFound={nothingFound}
                hoverOnSearchChange
                maxDropdownHeight={221}
                icon={<MagnifyingGlassIcon className="h-4 w-4" />}
                styles={() => ({
                  rightSection: { pointerEvents: "none", paddingLeft: 10 },
                })}
                data={data}
              />
            </div>
          )}

          {data == null && data == undefined && (
            <SimpleGrid
              className="p-4"
              style={{ height: "280px", background: "#fff" }}
            >
              <Skeleton height={30} />
              <Skeleton height={15} />
              <Skeleton height={15} mt={2} />
              <Skeleton height={15} mt={2} />
              <Skeleton height={15} mt={2} />
              <Skeleton height={15} mt={2} />
              <Skeleton height={15} mt={2} />
            </SimpleGrid>
          )}
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default TaggedInput;
