import ProgressBarComponent from "@/features/common/ProgressBarComponent/ProgressBarComponent";
import {
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectedPrimaryApp,
} from "@/features/services/overView/overViewSlice";
import { selectLabel } from "@/features/services/dateRange/dateRangeSlice";
import { Card, Grid, Group, Text, Tooltip } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function DeviceBrandDistribution(props: any) {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);
  const [smColumnSize, setSmColumnSize] = useState(0);
  const [mdColumnSize, setMdColumnSize] = useState(0);
  const [appCount, setAppCount] = useState(1);
  const label = useSelector(selectLabel);
  const subHeader =
    label === "lastWeek" || label === "LastMonth" ? "Userwise" : "Eventwise";
  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
      setAppCount(3);
    } else if (
      primaryApp !== null &&
      (comprativeAppOne !== null || comprativeAppTwo !== null)
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
      setAppCount(2);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(4);
      setSmColumnSize(4);
      setMdColumnSize(4);
      setAppCount(1);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <Grid.Col md={mdColumnSize} lg={column} sm={smColumnSize}>
      <Card
        withBorder
        radius="md"
        shadow="sm"
        className={
          props?.appProfileData?.insights?.deviceBrand === null ||
          props?.appProfileData?.insights?.deviceBrand.length === 0
            ? "chart-card disabled h-full"
            : "chart-card h-full"
        }
        style={{ borderRadius: "4px" }}
      >
        <Card.Section inheritPadding py="xs" className="card-header">
          <Group position="apart">
            <Text size="sm" style={{ fontSize: "14px", fontWeight: "500" }}>
              Device Brand Distribution{" "}
              <span style={{ color: "#6E7B91" }}> ({subHeader}) </span>
            </Text>
          </Group>
        </Card.Section>
        <Card.Section ml={0} mr={0} mt={10}>
          {props?.appProfileData?.insights?.deviceBrand === null ||
            (props?.appProfileData?.insights?.deviceBrand.length === 0 && (
              <Text className="no-data-text">No Data Available</Text>
            ))}
          {props?.deviceBrandDistributionData?.length > 0 &&
            props?.deviceBrandDistributionData.map(
              (item: any, index: number) => {
                return (
                  <Grid
                    pl={8}
                    pr={8}
                    pt={8}
                    key={index}
                    gutterSm={10}
                    align="center"
                  >
                    <Grid.Col
                      md={appCount === 1 || appCount === 2 ? 3 : 4}
                      lg={3}
                      sm={appCount === 1 || appCount === 2 ? 4 : 12}
                    >
                      <Text fz="sm">{item.name}</Text>
                    </Grid.Col>
                    <Grid.Col
                      md={appCount === 1 || appCount === 2 ? 6 : 5}
                      lg={7}
                      sm={appCount === 1 || appCount === 2 ? 4 : 7}
                    >
                      <Tooltip.Floating
                        label={`${item?.value
                          ?.toString()
                          ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${
                          label === "Yesterday" ? "Events" : "Users"
                        }`}
                        color={props.color}
                        offset={10}
                        key={item.appId}
                      >
                        <div>
                          <ProgressBarComponent
                            Barsize="lg"
                            color={props.color}
                            value={Math.round(item?.percent)}
                            barType={undefined}
                            colorArr={[]}
                            valueArr={[]}
                            tooltipArr={[]}
                            sections={undefined}
                            isPadding={false}
                          />
                        </div>
                      </Tooltip.Floating>
                    </Grid.Col>
                    <Grid.Col
                      md={appCount === 1 || appCount === 2 ? 3 : "content"}
                      lg={2}
                      sm={appCount === 1 || appCount === 2 ? 4 : "content"}
                    >
                      <Text fz="sm">{item.percent} %</Text>
                    </Grid.Col>
                  </Grid>
                );
              },
            )}
        </Card.Section>
      </Card>
    </Grid.Col>
  );
}
