import PieChartComponent from "@/features/common/PieChartComponent/PieChartComponent";
import TooltipLabel from "@/containers/TootipLabel/TooltipLabel";
import {
  selectedPrimaryApp,
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectDate,
} from "@/features/services/overView/overViewSlice";
import { Card, Grid, Group, Text, Tooltip } from "@mantine/core";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import "./TotalUserCard.scss";
import { selectLabel } from "@/features/services/dateRange/dateRangeSlice";
import { numberFormatter } from "@/Util/utils";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

export default function TotalUserCard(props: any) {
  const [column, setColumn] = useState(0);
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [smColumnSize, setSmColumnSize] = useState(0);
  const [mdColumnSize, setMdColumnSize] = useState(0);
  const label = useSelector(selectLabel);

  const [totalUserCount, setTotalUserCount] = useState<any>(0);
  const subHeader =
    label === "lastWeek" || label === "LastMonth" ? "Userwise" : "Eventwise";
  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setSmColumnSize(12);
      setMdColumnSize(12);
    } else if (
      primaryApp !== null &&
      (comprativeAppOne !== null || comprativeAppTwo !== null)
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(4);
      setSmColumnSize(4);
      setMdColumnSize(4);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);

  const [totaluserData, setTotalUserData] = useState<any>([]);

  useEffect(() => {
    if (
      props?.totalUserSeriesData !== null ||
      props?.totalUserSeriesData !== undefined
    ) {
      const initialValue = 0;
      const sumWithInitial = props?.totalUserSeriesData?.reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + parseInt(currentValue?.value);
        },
        initialValue
      );
      setTotalUserCount(sumWithInitial);
      const data: any = props?.totalUserSeriesData?.map((item: any) => {
        return {
          name: item?.name?.replace("Users", ""),
          percent: item?.percent,
          value: item?.value,
        };
      });
      setTotalUserData(data);
    }
    const data: any = props?.totalUserSeriesData?.map((item: any) => {
      return {
        name: item.name.replace("Users", ""),
        percent: item.percent,
        value: item.value,
      };
    });
    setTotalUserData(data);
  }, [props?.totalUserSeriesData]);

  const getPieChartWidth = () => {
    if (column === 12) {
      return "100%";
    }
    return column === 4 ? 200 : 330;
  };
  return (
    <>
      {props?.totalUserSeriesData !== undefined && (
        <Grid.Col
          md={mdColumnSize}
          lg={column}
          sm={smColumnSize}
          className="total-user-card"
        >
          <Card
            withBorder
            shadow="sm"
            radius="md"
            className={
              props.appProfileData?.insights?.activeInactiveUsers === null
                ? "chart-card disabled"
                : "chart-card h-full"
            }
          >
            <Card.Section className="card-header">
              <Group position="left">
                <Text size="sm" style={{ fontSize: "14px", fontWeight: "500" }}>
                  Total Users
                  <span style={{ color: "#6E7B91" }}> ({subHeader}) </span>
                </Text>
                {(label === "lastWeek" || label === "LastMonth") && (
                  <Tooltip multiline label={<TooltipLabel />}>
                    <InformationCircleIcon className="h-6 w-6 -ml-4 z-1 cursor-pointer" />
                  </Tooltip>
                )}
              </Group>
            </Card.Section>
            <Card.Section>
              {props.appProfileData?.insights?.activeInactiveUsers === null && (
                <Text className="no-data-text">No Data Available</Text>
              )}
              <Text>
                {props.appProfileData?.insights?.totalUsers?.total || ""}
              </Text>
              <PieChartComponent
                seriesData={totaluserData}
                chartName={
                  `${
                    totalUserCount !== "" && numberFormatter(totalUserCount)
                  } \n Users` || "Users"
                }
                showLabelLine={true}
                chartRadius={["60%", "50%"]}
                legendOrientation={["horizontal", "center", "bottom"]}
                height={180}
                showLabelinMiddle={true}
                chartAlign={["50%", "50%"]}
                hideLabel={false}
                showLegend={true}
                titleAlign={"50%"}
                colors={props.color}
                width={getPieChartWidth()}
                className="insight-overview-totalUser"
                itemGap={30}
                alignLegend={"horizontal"}
              />
              {/* <PieChartComponent
                seriesData={props?.totalUserSeriesData}
                chartName={
                  `${
                    totalUserCount !== "" && numberFormatter(totalUserCount)
                  } \n Users` || "Users"
                }
                showLabelLine={false}
                chartRadius={window.innerWidth < 821 ? [40, 20] : [60, 50]}
                legendOrientation={["horizontal", "center", "bottom"]}
                height={"180px"}
                showLabelinMiddle={true}
                chartAlign={["50%", "50%"]}
                hideLabel={false}
                showLegend={true}
                titleAlign={"50%"}
                colors={["#2C7A7B", "#81E6D9"]}
                width={undefined}
                className={"app-profile-totalUserSeriesData"}
                itemGap={50}
                alignLegend={"horizontal"}
              /> */}
            </Card.Section>
          </Card>
        </Grid.Col>
      )}
    </>
  );
}
