import { createSlice } from "@reduxjs/toolkit";

interface userActivityInitialState {
  usePrimaryChartData: any;
  useComprativeChartDataOne: any;
  useComprativeChartDataTwo: any;
  userEngagementData: any;
  userEngagementOneData: any;
  userEngagementTwoData: any;
  engagementRateData: any;
  engagementRateOneData: any;
  engagementRateTwoData: any;
  engagementRateLineData: any;
  engagementRateLineOneData: any;
  engagementRateLineTwoData: any;
  loading: any;
  userEngagementLoader: any;
  engagementRateLoader: any;
  byEvent: any;
  byDownload: any;
}
const initialState: userActivityInitialState = {
  usePrimaryChartData: [],
  useComprativeChartDataOne: [],
  useComprativeChartDataTwo: [],
  userEngagementData: [],
  userEngagementOneData: [],
  userEngagementTwoData: [],
  engagementRateData: [],
  engagementRateOneData: [],
  engagementRateTwoData: [],
  engagementRateLineData: [],
  engagementRateLineOneData: [],
  engagementRateLineTwoData: [],
  loading: null,
  userEngagementLoader: null,
  engagementRateLoader: null,
  byEvent: "num_sessions",
  byDownload: "download_data_usage",
};

const userEngagementColor = [
  "#FDD835",
  "#FFF59D",
  "#F5F7FA",
  "#FEB2B2",
  "#C53030",
];

const colors: string[] = [
  "rgba(244, 248, 248, 0.1)",
  "rgb(244, 248, 248, 0.7)",
  "rgba(0, 163, 196, 0)",
];
const colors1: string[] = [
  "rgba(0, 0, 0, 0.1)",
  "rgba(159, 122, 234, 0.7)",
  "rgba(159, 122, 234, 0)",
];
const colors2: string[] = [
  "rgba(0, 0, 0, 0.1)",
  "rgba(237, 137, 54, 0.7)",
  "rgba(237, 137, 54, 0)",
];

const userActivitySlice = createSlice({
  name: "useractivity",
  initialState: { ...initialState },
  reducers: {
    setUserActivityLoader: (state, action) => {
      state.loading = action.payload;
    },
    setUserEngangementLoader: (state, action) => {
      state.userEngagementLoader = action.payload;
    },
    setEngagementRateLoader: (state, action) => {
      state.userEngagementLoader = action.payload;
    },
    setPrimaryAppLineChartData: (state, action) => {
      const data = [];
      const primaryAppLineChartData = action.payload;
      data.push({
        ...primaryAppLineChartData,
        ...{ colors: colors },
        axisLabel: { show: true },
      });
      state.usePrimaryChartData = [...data];
    },
    setComprativeAppLineChartOneData: (state, action) => {
      const data = [];
      const comprativeAppLineChartOneData = action.payload;
      data.push({
        ...comprativeAppLineChartOneData,
        ...{ colors: colors1 },
        axisLabel: { show: true },
      });
      state.useComprativeChartDataOne = [...data];
    },
    setComprativeAppLineChartTwoData: (state, action) => {
      const data = [];
      const comprativeAppLineChartTwoData = action.payload;
      data.push({
        ...comprativeAppLineChartTwoData,
        ...{ colors: colors2 },
        axisLabel: { show: true },
      });
      state.useComprativeChartDataTwo = [...data];
    },
    setUserEngageMentPrimaryData: (state, action) => {
      const sections = [];
      const primaryEngagementData = action?.payload?.data;
      for (let i = 0; i < primaryEngagementData?.length; i++) {
        sections.push({
          value: parseFloat(primaryEngagementData[i].value),
          color: userEngagementColor[i],
          label: primaryEngagementData[i].value + "%",
          tooltip:
            primaryEngagementData[i].name +
            ": " +
            primaryEngagementData[i].value +
            "%",
        });
      }
      state.userEngagementData = sections;
    },
    setUserEngageMentComprativeOneData: (state, action) => {
      const sections = [];
      const ComprativeAppData = action?.payload?.data;
      for (let i = 0; i < ComprativeAppData?.length; i++) {
        sections.push({
          value: parseFloat(ComprativeAppData[i].value),
          color: userEngagementColor[i],
          label: ComprativeAppData[i].value + "%",
          tooltip:
            ComprativeAppData[i].name + ": " + ComprativeAppData[i].value + "%",
        });
      }
      state.userEngagementOneData = sections;
    },
    setUserEngageMentComprativeTwoData: (state, action) => {
      const sections = [];
      const ComprativeAppData = action?.payload?.data;
      for (let i = 0; i < ComprativeAppData?.length; i++) {
        sections.push({
          value: parseFloat(ComprativeAppData[i].value),
          color: userEngagementColor[i],
          label: ComprativeAppData[i].value + "%",
          tooltip:
            ComprativeAppData[i].name + ": " + ComprativeAppData[i].value + "%",
        });
      }
      state.userEngagementTwoData = sections;
    },

    setPrimaryAppEngagementRateData: (state, action) => {
      const primaryAppEngagementRateData = action.payload;
      const data = {
        ...primaryAppEngagementRateData,
      };
      state.engagementRateData = data;
    },
    setComparativeAppOneEngagementRateData: (state, action) => {
      const comparativeAppOneEngagementRateData = action.payload;
      const data = {
        ...comparativeAppOneEngagementRateData,
      };
      state.engagementRateOneData = data;
    },
    setComparativeAppTwoEngagementRateData: (state, action) => {
      const comparativeAppTwoEngagementRateData = action.payload;
      const data = {
        ...comparativeAppTwoEngagementRateData,
      };
      state.engagementRateTwoData = data;
    },
    setPrimaryAppEngagementRateLineData: (state, action) => {
      const primaryAppEngagementRateData = action.payload;
      const data = {
        ...primaryAppEngagementRateData,
      };
      state.engagementRateLineData = data;
    },
    setComparativeAppOneEngagementRateLineData: (state, action) => {
      const comparativeAppOneEngagementRateData = action.payload;
      const data = {
        ...comparativeAppOneEngagementRateData,
      };
      state.engagementRateLineOneData = data;
    },
    setComparativeAppTwoEngagementRateLineData: (state, action) => {
      const comparativeAppTwoEngagementRateData = action.payload;
      const data = {
        ...comparativeAppTwoEngagementRateData,
      };
      state.engagementRateLineTwoData = data;
    },
    setByEvents: (state, action) => {
      state.byEvent = action.payload;
    },
    setByDownloads: (state, action) => {
      state.byDownload = action.payload;
    },
  },
});

export const {
  setPrimaryAppLineChartData,
  setComprativeAppLineChartOneData,
  setComprativeAppLineChartTwoData,
  setUserEngageMentPrimaryData,
  setUserEngageMentComprativeOneData,
  setUserEngageMentComprativeTwoData,
  setPrimaryAppEngagementRateData,
  setComparativeAppOneEngagementRateData,
  setComparativeAppTwoEngagementRateData,
  setPrimaryAppEngagementRateLineData,
  setComparativeAppOneEngagementRateLineData,
  setComparativeAppTwoEngagementRateLineData,
  setUserActivityLoader,
  setUserEngangementLoader,
  setEngagementRateLoader,
  setByDownloads,
  setByEvents,
} = userActivitySlice.actions;

export default userActivitySlice.reducer;

export const selectUsePrimaryChartData = (state: any) => {
  return state.useractivity.usePrimaryChartData;
};

export const selectUseComprativeChartDataOne = (state: any) => {
  return state.useractivity.useComprativeChartDataOne;
};

export const selectUseComprativeChartDataTwo = (state: any) => {
  return state.useractivity.useComprativeChartDataTwo;
};

export const selectUserEngagementData = (state: any) => {
  return state.useractivity.userEngagementData;
};

export const selectUserEngagementOneData = (state: any) => {
  return state.useractivity.userEngagementOneData;
};

export const selectUserEngagementTwoData = (state: any) => {
  return state.useractivity.userEngagementTwoData;
};

export const selectEngagementRateData = (state: any) => {
  return state.useractivity.engagementRateData;
};

export const selectEngagementRateOneData = (state: any) => {
  return state.useractivity.engagementRateOneData;
};

export const selectEngagementRateTwoData = (state: any) => {
  return state.useractivity.engagementRateTwoData;
};
export const selectEngagementRateLineData = (state: any) => {
  return state.useractivity.engagementRateLineData;
};

export const selectEngagementRateLineOneData = (state: any) => {
  return state.useractivity.engagementRateLineOneData;
};

export const selectEngagementRateLineTwoData = (state: any) => {
  return state.useractivity.engagementRateLineTwoData;
};

export const selectUserActivityLoading = (state: any) =>
  state.useractivity.loading;

export const selectUserEngagementLoader = (state: any) =>
  state.useractivity.userEngagementLoader;

export const selectEngagementRateLoader = (state: any) =>
  state.useractivity.engagementRateLoader;

export const selectByEvents = (state: any) => state.useractivity.byEvent;

export const selectByDownload = (state: any) => state.useractivity.byDownload;
