import { Grid, Text, Button, Flex } from "@mantine/core";
import { PlusIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { selectSuggestionAppList } from "@/features/services/search/searchAppSlice";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { selectedPrimaryApp } from "@/features/services/overView/overViewSlice";

import "./SuggestedApps.scss";

const SuggestedApps = (props: any) => {
  const suggestionData = useSelector(selectSuggestionAppList);
  const [data, setData] = useState<any>([]);
  const primaryApp = useSelector(selectedPrimaryApp);
  const [showSuggestion, setShowSuggestion] = useState<any>(null);

  useEffect(() => {
    if (primaryApp) {
      setData(suggestionData?.slice(0, 5));
      if (suggestionData?.length === 0) {
        setShowSuggestion(true);
      } else {
        setShowSuggestion(false);
      }
    }
  }, [suggestionData, props, primaryApp, setShowSuggestion]);

  const handleClick = (item: any) => {
    props.onclick(item);
  };

  return (
    <>
      <Grid className="suggested-component">
        <Grid.Col className={data !== null && data?.length ? "pb-10" : "pb-10"}>
          {data !== null && data?.length > 0 && (
            <Text size="xs" className="mt-2 text-secondary">
              Suggested Apps Based on Primary App Category:
              {props.primaryCategoryList.map((item: any, index: any) => {
                return (
                  <>
                    <b>
                      &nbsp;{item}
                      {props.primaryCategoryList.length - 1 !== index
                        ? ", "
                        : ""}
                    </b>
                  </>
                );
              })}
            </Text>
          )}
          <Flex align={"center"}>
            {data?.length > 0 &&
              data?.map((item: any) => {
                return (
                  <div
                    style={{
                      cursor:
                        props.primaryApp && props.app2 && props.app3
                          ? "no-drop"
                          : "pointer",
                    }}
                    key={item.value}
                  >
                    <Button
                      className={
                        props.primaryApp && props.app2 && props.app3
                          ? "disabled primary-button-outline my-3 outline mr-2 cursor-disable"
                          : "primary-button-outline my-3 outline mr-2"
                      }
                      onClick={() => handleClick(item)}
                      key={item.value}
                      disabled={props.primaryApp && props.app2 && props.app3}
                    >
                      <img
                        src={item?.appicon}
                        className="img-fluid"
                        role={item?.appicon}
                      />
                      <Text className="suggestion-text">{item?.value}</Text>
                      {props.primaryApp && props.app2 && props.app3 ? (
                        ""
                      ) : (
                        <PlusIcon className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                );
              })}
            {showSuggestion == true && (
              <>
                <ExclamationTriangleIcon className="w-4 h-4 ml-3 mr-1 mt-3 text-muted" />
                <Text className="mt-3 text-muted" fz="xs">
                  No Similar Apps found.
                </Text>
              </>
            )}
          </Flex>
        </Grid.Col>
      </Grid>
    </>
  );
};

export default SuggestedApps;
