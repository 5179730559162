import { useState, useEffect } from "react";
import { useDisclosure } from "@mantine/hooks";
import {
  Text,
  Button,
  Flex,
  Grid,
  Image,
  SimpleGrid,
  Title,
} from "@mantine/core";
import Frame from "../../assets/images/Frame.png";
import TaggedInput from "./TaggedInput";
import { useDispatch, useSelector } from "react-redux";
import SuggestedApps from "./SuggestedApps/SuggestedApps";
import {
  setAppPrimaryData,
  setPrimaryAppId,
  setComparativeAppOneId,
  setComparativeAppTwoId,
  setPrimaryJsonModel,
  setLoader,
  setComprativeOneData,
  setComprativeTwoJsonModel,
  setComprativeTwoData,
  setComprativeJsonModel,
  selectedPrimaryApp,
  selectPrimaryJsonModel,
  selectComprativeJsonModel,
  selectComprativeTwoJsonModel,
  selectRouteFlag,
  setRouteFlag,
} from "../../features/services/overView/overViewSlice";
import {
  filterAppandReturnList,
  filterAppandReturnListWithCategory1,
  filterOnEdit,
} from "@/Util/utils";
import {
  setPrimaryAppLineChartData,
  setComprativeAppLineChartOneData,
  setComprativeAppLineChartTwoData,
  setUserEngageMentPrimaryData,
  setUserEngageMentComprativeOneData,
  setUserEngageMentComprativeTwoData,
  setPrimaryAppEngagementRateData,
  setComparativeAppOneEngagementRateData,
  setComparativeAppTwoEngagementRateData,
  setUserActivityLoader,
  setUserEngangementLoader,
  setEngagementRateLoader,
  selectByEvents,
  selectByDownload,
  setPrimaryAppEngagementRateLineData,
  setComparativeAppOneEngagementRateLineData,
  setComparativeAppTwoEngagementRateLineData,
} from "../../features/services/userActivity/userActivitySlice";
import {
  setCrossBrowsingData,
  setCrossBrowsingAppFilter,
  setCrossBrowsingCategoryFilter,
  setOverIndexLoader,
} from "../../features/services/crossBrowsing/crossBrowsingSlice";
import {
  useLazyGetUserTrendsQuery,
  useLazyGetUserEngagementQuery,
  useLazyGetEngagementRateBarChartQuery,
  useLazyGetEngagementRateLineChartQuery,
} from "../../features/services/userActivity/userActivityApiSlice";
import { useLazyGetAppProfileQuery } from "../../features/services/overView/overViewApiSlice";
import {
  useLazyGetOverIndexQuery,
  useLazyGetTwinAppQuery,
} from "../../features/services/crossBrowsing/crossBrowsingApiSlice";
import {
  useLazyGetSearchedAppQuery,
  useLazyGetAppsForSelectedCategoryDataQuery,
} from "../../features/services/search/serachAppApiSlice";
import {
  getAppList,
  selectAppList,
  getComporarativeAppTwoList,
  getComporarativeAppOneList,
  selectedComprativeAppOneOptions,
  selecedComprativeAppTwoOptions,
  getInitialAppList,
  setSuggestionArray,
  selectSuggestionAppList,
} from "../../features/services/search/searchAppSlice";
import "./TaggedSearch.scss";
import {
  setTwinAppData,
  setTwinApploader,
} from "@/features/services/crossBrowsing/twinAppSlice";
import { setError } from "../../features/services/errorSlice";
import { generateErrorMessage } from "../../Util/utils";
import { SelectItem } from "./SelectItems";
import {
  useLazyGetMinMaxDateQuery,
  useLazyGetTrendingAppsQuery,
} from "../../features/services/trendingApps/trendingAppApiSlice";
import {
  setMaxMinDate,
  selectedcalenderList,
  setCalenderList,
  selectDateRange,
  setDateRange,
} from "../../features/services/dateRange/dateRangeSlice";
import MaCustomSelect from "../../containers/MaCustomSelect/MaCustomSelect";
import { PlusIcon } from "@heroicons/react/24/solid";

export default function TaggedSearch() {
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);
  const listItems = useSelector(selectedcalenderList);
  const dateRange = useSelector(selectDateRange);
  const [suggestedApp1, setSuggestedApp1] = useState(false);
  const [suggestedApp2, setSuggestedApp2] = useState(false);
  const suggestionList = useSelector(selectSuggestionAppList);

  const [primaryAppImage, setPrimaryAppImage] = useState(Frame);
  const [comprativeAppOneImage, setcomprativeAppOneImage] = useState(Frame);
  const [comprativeAppTwoImage, setcomprativeAppTwoImage] = useState(Frame);
  const appName = useSelector(selectedPrimaryApp);
  const [primaryApp, setValue] = useState<string | null>(null),
    [comprativeAppOne, setValue1] = useState<string | null>(null),
    [comprativeAppTwo, setValue2] = useState<string | null>(null);
  const [comprativeApp1Flag, setComprativeAppOneFlag] =
    useState<boolean>(false);
  const [opened, { close, open }] = useDisclosure(false);
  const [openedComp, { close: closedApp, open: openedApp }] =
    useDisclosure(false);
  const [comprativeApp2Flag, setcomprativeApp2Flag] = useState(true);
  const [selectApp, setPrimaryApp] = useState(false);
  const [openedComp2, { close: closedApp2, open: openedApp2 }] =
    useDisclosure(false);
  const [hideButton, setHideButton] = useState(true);
  const [overlayFlag, setOverLayFlag] = useState(false);
  const [overlayFlag1, setOverLayFlag1] = useState(false);
  const [overlayFlag2, setOverLayFlag2] = useState(false);
  const [skeletonLoader, setSkeletonLoadingOption] = useState<any | null>(null),
    [dates, setDates] = useState<string | null>(null);
  const byEvents = useSelector(selectByEvents);
  const byDownload = useSelector(selectByDownload);
  const [fetchSuggestedApp] = useLazyGetAppsForSelectedCategoryDataQuery();
  const [primaryAppLimit] = useState(100);
  const [comprativeC1Limit] = useState(100);
  const [comprativeC2Limit] = useState(100);
  const [primaryFlag, setPrimaryFlag] = useState(false);
  const [
    fetchSearchOption,
    { data: initialAppList, isError: searchAppErr, error: searchAppErrMsg },
  ] = useLazyGetSearchedAppQuery<any>("");

  const [
    fetchUserActivity,
    { isError: userActivityErr, error: userActivityErrMsg },
  ] = useLazyGetUserTrendsQuery();
  const [
    fetchEngagementRate,
    { isError: engagementRateErr, error: engagementRateErrMsg },
  ] = useLazyGetEngagementRateBarChartQuery();
  const [
    fetchEngagementRateLineChart,
    { isError: engagementRateLineErr, error: engagementRateLineErrMsg },
  ] = useLazyGetEngagementRateLineChartQuery();

  const [
    fetchCrossBrowsingData,
    { isError: crossBrowsingErr, error: crossBrowsingErrMsg },
  ] = useLazyGetOverIndexQuery();
  const [
    fetchTwinAppData,
    { isError: twinappDatayErr, error: twinAppDataErrMsg },
  ] = useLazyGetTwinAppQuery();
  const [
    fetUserEngagement,
    { isError: userEngagementErr, error: userEngagementErrMsg },
  ] = useLazyGetUserEngagementQuery();
  const [
    getAllProfile,
    { isError: getAllProfileErr, error: getAllProfilErrMsg },
  ] = useLazyGetAppProfileQuery();
  const allAppLists = useSelector(selectAppList);
  const comprativeAppOneOptions = useSelector(selectedComprativeAppOneOptions);
  const comprativeAppTwoOptions = useSelector(selecedComprativeAppTwoOptions);
  const routeFlag = useSelector(selectRouteFlag);
  const primaryAppJsonModel = useSelector(selectPrimaryJsonModel);
  const comprativeAppJsonModel = useSelector(selectComprativeJsonModel);
  const comprativeAppTwoJsonModel = useSelector(selectComprativeTwoJsonModel);
  const [fetchTrendingAppsData] = useLazyGetTrendingAppsQuery();
  const [fetchMinMax] = useLazyGetMinMaxDateQuery();
  const [primaryCategoryList, setPrimaryCategoryList] = useState("");
  const rankFilter = "num_sessions",
    timeFilter = "all_time";
  const [showTaggedInputLoader, setShowTaggedInputLoader] = useState(false);

  useEffect(() => {
    if (initialAppList === undefined) {
      fetchTrendingAppsData({
        trendingAppQueryParam: {
          appCategory: ["all_categories"],
          intervalType: timeFilter,
          offset: 10,
          rankBy: rankFilter,
        },
      }).then(async (res: any) => {
        if (res?.data !== undefined) {
          setShowTaggedInputLoader(false);
          const trendingAppsData = res?.data?.trendingApps?.map((item: any) => {
            return {
              value: item?.appName,
              label: item.appName,
              appicon: item?.iconUrl,
              id: item?.appId,
              categoryList: item?.appCategory,
              group: "Trending Apps",
            };
          });
          const appData: any = await fetchSearchOption("");
          const appList = appData?.data;
          const appList1 = [...trendingAppsData, ...appList];
          const data = appList1?.filter(
            (value: any, index: any, self: any) =>
              index === self.findIndex((t: any) => t.id === value.id)
          );
          dispatch(getAppList(data));
          dispatch(getInitialAppList(data));
          setSkeletonLoadingOption(false);
          // setShowTaggedInputLoader(false);
        }
      });
    }
  }, [initialAppList, fetchSearchOption]);

  useEffect(() => {
    setSkeletonLoadingOption(false);
    if (appName !== null && routeFlag === true) {
      setPrimaryFlag(true);
      setValue(appName);
      setPrimaryApp(true);
      setComprativeAppOneFlag(true);
      setPrimaryCategoryList(primaryAppJsonModel?.appCategory);
      setPrimaryAppImage(primaryAppJsonModel?.iconUrl.slice(1, -1));
      const payload = {
        trendingAppForSelectedCategory: {
          appCategory: primaryAppJsonModel?.appCategory,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      };
      fetchSuggestedApp({
        ...payload,
      }).then((res: any) => {
        if (res?.data) {
          const suggestion = filterAppandReturnListWithCategory1(
            res?.data,
            appName,
            null,
            null
          );
          dispatch(setSuggestionArray(suggestion));
        }
      });
      setShowTaggedInputLoader(false);
    }
  }, [setValue, appName, primaryApp, dates, fetchSuggestedApp]);

  useEffect(() => {
    if (userActivityErrMsg) {
      const message = generateErrorMessage(userActivityErrMsg);
      dispatch(setError(message));
    }
    if (engagementRateErrMsg && dates === "lastWeek") {
      const message = generateErrorMessage(engagementRateErrMsg);
      dispatch(setError(message));
    }
    if (engagementRateLineErrMsg && dates === "lastWeek") {
      const message = generateErrorMessage(engagementRateLineErrMsg);
      dispatch(setError(message));
    }
    if (crossBrowsingErrMsg) {
      const message = generateErrorMessage(crossBrowsingErrMsg);
      dispatch(setError(message));
    }
    if (twinAppDataErrMsg) {
      const message = generateErrorMessage(twinAppDataErrMsg);
      dispatch(setError(message));
    }
    if (userEngagementErrMsg) {
      const message = generateErrorMessage(userEngagementErrMsg);
      dispatch(setError(message));
    }
    if (getAllProfilErrMsg) {
      const message = generateErrorMessage(getAllProfilErrMsg);
      dispatch(setError(message));
    }
    if (searchAppErrMsg) {
      const message = generateErrorMessage(searchAppErrMsg);
      dispatch(setError(message));
    }
  }, [
    userActivityErr,
    crossBrowsingErr,
    engagementRateErr,
    engagementRateLineErr,
    twinappDatayErr,
    userEngagementErr,
    getAllProfileErr,
    searchAppErr,
  ]);

  useEffect(() => {
    if (
      comprativeAppJsonModel !== null &&
      comprativeAppJsonModel?.length === 0
    ) {
      dispatch(setCrossBrowsingAppFilter(""));
      dispatch(setCrossBrowsingCategoryFilter(""));
      dispatch(setTwinApploader(true));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setTwinApploader(false));
        if (res?.data !== undefined) {
          dispatch(setTwinAppData(res.data));
        }
      });
      dispatch(setOverIndexLoader(true));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setOverIndexLoader(false));
        if (res?.data !== undefined) {
          dispatch(setCrossBrowsingData(res.data));
        }
      });
    }
  }, [comprativeAppJsonModel, dates]);

  useEffect(() => {
    if (
      comprativeAppTwoJsonModel !== null &&
      comprativeAppTwoJsonModel?.length === 0
    ) {
      dispatch(setCrossBrowsingAppFilter(""));
      dispatch(setCrossBrowsingCategoryFilter(""));
      dispatch(setTwinApploader(true));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setTwinApploader(false));
        if (res?.data !== undefined) {
          dispatch(setTwinAppData(res.data));
        }
      });
      dispatch(setOverIndexLoader(true));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setOverIndexLoader(false));
        if (res?.data !== undefined) {
          dispatch(setCrossBrowsingData(res.data));
        }
      });
    }
  }, [comprativeAppTwoJsonModel, dates]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: startAndEndDateInEs, isSuccess } = await fetchMinMax("");
        setIsSuccess(isSuccess);
        dispatch(setCalenderList(startAndEndDateInEs));
        dispatch(setMaxMinDate(startAndEndDateInEs));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const callPrimaryAppChange = async (
    e: any,
    dateRange: any,
    appChangeFlag: boolean
  ): Promise<void> => {
    const fromDate = dateRange?.fromDate;
    const toDate = dateRange?.toDate;
    dispatch(setRouteFlag(false));
    dispatch(setUserEngangementLoader(true));
    dispatch(setUserActivityLoader(true));
    setSuggestedApp1(true);

    if (e !== null) {
      const matchObj = initialAppList.filter((item: any) => item.value === e);
      setPrimaryAppImage(matchObj[0].appicon);
      setPrimaryCategoryList(matchObj[0].categoryList);
      if (appChangeFlag === true) {
        const payload = {
          trendingAppForSelectedCategory: {
            appCategory: matchObj[0].categoryList,
            fromDate: fromDate,
            toDate: toDate,
          },
        };
        const suggestedApp: any = await fetchSuggestedApp({
          ...payload,
        }).unwrap();

        const data1 = filterAppandReturnListWithCategory1(
          suggestedApp,
          e,
          comprativeAppOne,
          comprativeAppTwo
        );

        dispatch(setSuggestionArray(data1));
        setAppData(data1);
      }
      setOverLayFlag(false);
      dispatch(setAppPrimaryData(e));
      close();
      dispatch(setLoader());
      dispatch(setPrimaryAppId(matchObj[0].id));
      dispatch(setCrossBrowsingAppFilter(""));
      dispatch(setCrossBrowsingCategoryFilter(""));
      const gatAppProfilePayload = {
        appProfileRequest: {
          appId: matchObj[0].id,
          appName: e,
          isDemographic: false,
          fromDate,
          toDate,
        },
      };
      fetchUserActivity({
        userActivitiesRequest: {
          appId: matchObj[0].id,
          appName: e,
          activityBy: byEvents,
          fromDate,
          toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setPrimaryAppLineChartData(res.data));
          dispatch(setUserActivityLoader(false));
        }
      });
      if (fromDate.localeCompare(toDate) === -1) {
        fetchEngagementRate({
          engagementRateRequest: {
            appId: matchObj[0].id,
            appName: e,
            fromDate,
            toDate,
          },
        }).then((res: any) => {
          if (res !== undefined) {
            dispatch(setPrimaryAppEngagementRateData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        });
        fetchEngagementRateLineChart({
          userActivitiesRequest: {
            appId: matchObj[0].id,
            appName: e,
            fromDate,
            toDate,
          },
        }).then((res: any) => {
          if (res !== undefined) {
            dispatch(setPrimaryAppEngagementRateLineData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        });
      }
      dispatch(setTwinApploader(true));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: matchObj[0].id,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate,
          toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setTwinApploader(false));
        if (res?.data !== undefined) {
          dispatch(setTwinAppData(res.data));
        }
      });
      dispatch(setOverIndexLoader(true));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: matchObj[0].id,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate,
          toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setOverIndexLoader(false));
        if (res?.data !== undefined) {
          dispatch(setCrossBrowsingData(res.data));
        }
      });
      fetUserEngagement({
        userActivitiesRequest: {
          appId: matchObj[0].id,
          appName: e,
          activityBy: byDownload,
          fromDate,
          toDate,
        },
      }).then((res: any) => {
        if (res.data !== undefined) {
          dispatch(setUserEngangementLoader(false));
          dispatch(setUserEngageMentPrimaryData(res));
        }
      });
      const data: any = await getAllProfile(gatAppProfilePayload).unwrap();
      dispatch(setPrimaryJsonModel({ ...data, appId: matchObj[0].id }));
    }
  };

  const callComprativeAppOneChnage = async (
    d: any,
    dateRange: any,
    appChangeFlag: boolean
  ): Promise<void> => {
    dispatch(setUserEngangementLoader(true));
    const fromDate = dateRange?.fromDate;
    const toDate = dateRange?.toDate;
    let matchObj: any = {};
    let e = typeof d == "string" ? d : d.value;

    if (comprativeAppOneOptions !== null && typeof d == "string") {
      matchObj = comprativeAppOneOptions?.filter(
        (item: any) => item.value === e
      )[0];
    } else if (d?.id !== undefined) {
      matchObj.appicon = d?.appicon;
      matchObj.value = d?.value;
      matchObj.id = d?.id;
      matchObj.categoryList = primaryCategoryList;
    } else {
      matchObj = allAppLists?.filter((item: any) => item?.value === d)[0];
    }
    if (e !== null) {
      const payload = {
        trendingAppForSelectedCategory: {
          appCategory: primaryCategoryList,
          fromDate: fromDate,
          toDate: toDate,
        },
      };

      if (
        (appChangeFlag && JSON.stringify(matchObj.categoryList)) ||
        JSON.stringify(primaryCategoryList)
      ) {
        const suggestedApp: any = await fetchSuggestedApp({
          ...payload,
        }).unwrap();
        const data1: any = filterAppandReturnListWithCategory1(
          suggestedApp,
          primaryApp,
          e,
          comprativeAppTwo
        );
        dispatch(setSuggestionArray(data1));
        setAppData(data1);
      }
      setcomprativeAppOneImage(matchObj.appicon);
      dispatch(setComparativeAppOneId(matchObj.id));
      dispatch(setComprativeOneData(e));
      closedApp();
      setOverLayFlag1(false);
      dispatch(setLoader());
      dispatch(setUserActivityLoader(true));
      setHideButton(true);
      dispatch(setCrossBrowsingAppFilter(""));
      dispatch(setCrossBrowsingCategoryFilter(""));
      const gatAppProfilePayloadComprativeApp = {
        appProfileRequest: {
          appId: matchObj.id,
          appName: e,
          isDemographic: false,
          fromDate,
          toDate,
        },
      };
      fetchUserActivity({
        userActivitiesRequest: {
          appId: matchObj.id,
          appName: e,
          activityBy: byEvents,
          fromDate,
          toDate,
        },
      }).then((res: any) => {
        dispatch(setUserActivityLoader(false));
        if (res !== undefined) {
          dispatch(setComprativeAppLineChartOneData(res.data));
          dispatch(setUserActivityLoader(false));
        }
      });
      if (fromDate.localeCompare(toDate) === -1) {
        fetchEngagementRate({
          engagementRateRequest: {
            appId: matchObj.id,
            appName: e,
            fromDate,
            toDate,
          },
        }).then((res: any) => {
          dispatch(setEngagementRateLoader(false));
          if (res !== undefined) {
            dispatch(setComparativeAppOneEngagementRateData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        });
        fetchEngagementRateLineChart({
          userActivitiesRequest: {
            appId: matchObj.id,
            appName: e,
            fromDate,
            toDate,
          },
        }).then((res: any) => {
          dispatch(setEngagementRateLoader(false));
          if (res !== undefined) {
            dispatch(setComparativeAppOneEngagementRateLineData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        });
      }
      dispatch(setTwinApploader(true));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: matchObj.id,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate,
          toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setTwinApploader(false));
        if (res?.data !== undefined) {
          dispatch(setTwinAppData(res.data));
        }
      });
      dispatch(setOverIndexLoader(true));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: matchObj.id,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate,
          toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setOverIndexLoader(false));
        if (res?.data !== undefined) {
          dispatch(setCrossBrowsingData(res.data));
        }
      });
      fetUserEngagement({
        userActivitiesRequest: {
          appId: matchObj.id,
          appName: e,
          activityBy: byDownload,
          fromDate,
          toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setUserEngangementLoader(false));
          dispatch(setUserEngageMentComprativeOneData(res));
        }
      });
      const data: any = await getAllProfile(
        gatAppProfilePayloadComprativeApp
      ).unwrap();
      dispatch(setComprativeJsonModel({ ...data, appId: matchObj.id }));
    }
  };

  const callComprativeAppTwoChnage = async (
    d: any,
    dateRange: any,
    appChangeFlag: boolean
  ): Promise<void> => {
    dispatch(setUserEngangementLoader(true));
    dispatch(setLoader());
    const fromDate = dateRange?.fromDate;
    const toDate = dateRange?.toDate;
    let matchObj: any = {};
    let e = typeof d == "string" ? d : d.value;
    if (comprativeAppTwoOptions !== null && typeof d == "string") {
      matchObj = comprativeAppTwoOptions?.filter(
        (item: any) => item.value === e
      )[0];
    } else if (d?.id !== undefined) {
      matchObj.appicon = d?.appicon;
      matchObj.value = d?.value;
      matchObj.id = d?.id;
      matchObj.categoryList = primaryCategoryList;
    } else {
      matchObj = allAppLists?.filter((item: any) => item?.value === d)[0];
    }
    if (e !== null) {
      const payload = {
        trendingAppForSelectedCategory: {
          appCategory: primaryCategoryList,
          fromDate: fromDate,
          toDate: toDate,
        },
      };
      if (
        (appChangeFlag && JSON.stringify(matchObj.categoryList)) ||
        JSON.stringify(primaryCategoryList)
      ) {
        const suggestedApp: any = await fetchSuggestedApp({
          ...payload,
        }).unwrap();
        const data1: any = filterAppandReturnListWithCategory1(
          suggestedApp,
          primaryApp,
          comprativeAppOne,
          e
        );
        dispatch(setSuggestionArray(data1));
        setAppData(data1);
      }
      setcomprativeAppTwoImage(matchObj.appicon);
      dispatch(setComprativeTwoData(e));
      closedApp2();
      dispatch(setUserActivityLoader(true));
      dispatch(setComparativeAppTwoId(matchObj.id));
      dispatch(setCrossBrowsingAppFilter(""));
      dispatch(setCrossBrowsingCategoryFilter(""));

      const gatAppProfilePayloadComprativeAppTwo = {
        appProfileRequest: {
          appId: matchObj.id,
          appName: e,
          isDemographic: false,
          fromDate,
          toDate,
        },
      };
      fetchUserActivity({
        userActivitiesRequest: {
          appId: matchObj.id,
          appName: e,
          activityBy: byEvents,
          fromDate,
          toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setLoader());
          dispatch(setComprativeAppLineChartTwoData(res.data));
          dispatch(setUserActivityLoader(false));
        }
      });
      if (fromDate.localeCompare(toDate) === -1) {
        fetchEngagementRate({
          engagementRateRequest: {
            appId: matchObj.id,
            appName: e,
            fromDate,
            toDate,
          },
        }).then((res: any) => {
          if (res !== undefined) {
            dispatch(setComparativeAppTwoEngagementRateData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        });
        fetchEngagementRateLineChart({
          userActivitiesRequest: {
            appId: matchObj.id,
            appName: e,
            fromDate,
            toDate,
          },
        }).then((res: any) => {
          if (res !== undefined) {
            dispatch(setComparativeAppTwoEngagementRateLineData(res.data));
            dispatch(setEngagementRateLoader(false));
          }
        });
      }
      dispatch(setTwinApploader(true));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: matchObj.id,
          fromDate,
          toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setTwinApploader(false));
        if (res?.data !== undefined) {
          dispatch(setTwinAppData(res.data));
        }
      });
      dispatch(setOverIndexLoader(true));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: matchObj.id,
          fromDate,
          toDate,
          isCategory: false,
          searchableAppName: "",
          categoryName: "",
          searchableAppId: -1,
        },
      }).then((res: any) => {
        dispatch(setOverIndexLoader(false));
        if (res?.data !== undefined) {
          dispatch(setCrossBrowsingData(res.data));
        }
      });
      fetUserEngagement({
        userActivitiesRequest: {
          appId: matchObj.id,
          appName: e,
          activityBy: byDownload,
          fromDate,
          toDate,
        },
      }).then((res: any) => {
        if (res !== undefined) {
          dispatch(setUserEngangementLoader(false));
          dispatch(setUserEngageMentComprativeTwoData(res));
        }
      });
      const data: any = await getAllProfile(
        gatAppProfilePayloadComprativeAppTwo
      ).unwrap();
      dispatch(setComprativeTwoJsonModel({ ...data, appId: matchObj.id }));
      setOverLayFlag2(false);
    }
  };
  const [data, setAppData] = useState([]);

  const handlePrimaryApp = async (e: any): Promise<void> => {
    setValue(e);
    callPrimaryAppChange(e, dateRange, true);
    setPrimaryFlag(true);
    if (comprativeAppOne === null || comprativeAppOne === undefined) {
      setComprativeAppOneFlag(true);
    } else {
      setComprativeAppOneFlag(false);
    }
  };

  const [closeSuggestionOne, setCloseSuggestionOne] = useState(false);
  const [closeSuggestionTwo, setCloseSuggestionTwo] = useState(false);

  const handleComprativeOne = async (e: any): Promise<void> => {
    const e1 = typeof e == "string" ? e : e.value;
    setValue1(e1);
    await callComprativeAppOneChnage(e, dateRange, true);
  };

  const handleComprativeTwo = async (e: any): Promise<void> => {
    const e1 = typeof e == "string" ? e : e.value;
    setValue2(e1);
    await callComprativeAppTwoChnage(e, dateRange, true);
  };

  useEffect(() => {
    if (comprativeAppTwo === "" && comprativeAppOne === "") {
      setHideButton(false);
    }
    if (comprativeAppOne === "" && comprativeAppTwo === null) {
      setHideButton(false);
    }
  }, [comprativeAppOne, comprativeAppTwo]);

  const handleComprativeAppOneCompare = async (): Promise<void> => {
    if (routeFlag) {
      const payload = {
        trendingAppForSelectedCategory: {
          appCategory: primaryCategoryList,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      };
      const suggestedApp: any = await fetchSuggestedApp({
        ...payload,
      }).unwrap();
      if (suggestedApp) {
        const suggestion = filterAppandReturnListWithCategory1(
          suggestedApp,
          primaryApp,
          null,
          null
        );
        setOverLayFlag(false);
        dispatch(setSuggestionArray(suggestion));
        const appList1 = [...suggestion, ...initialAppList];
        const data = appList1?.filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.id === value.id)
        );
        const appList = await filterAppandReturnList(
          data,
          primaryApp,
          comprativeAppOne,
          comprativeAppTwo
        );

        dispatch(getComporarativeAppOneList({ appList }));
        setComprativeAppOneFlag(false);
        setOverLayFlag1(true);
        openedApp();
        setSuggestedApp1(true);
      }
    }
    if (allAppLists !== undefined && primaryApp !== null) {
      const payload = {
        trendingAppForSelectedCategory: {
          appCategory: primaryCategoryList,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      };

      const suggestedApp: any = await fetchSuggestedApp({
        ...payload,
      }).unwrap();
      if (suggestedApp) {
        const suggestion = filterAppandReturnListWithCategory1(
          suggestedApp,
          primaryApp,
          null,
          null
        );
        dispatch(setSuggestionArray(suggestion));
        setOverLayFlag(false);
        setComprativeAppOneFlag(false);
        dispatch(setSuggestionArray(suggestion));
        const appList1 = [...suggestion, ...initialAppList];
        const data = appList1?.filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t.id === value.id)
        );

        const appList = filterAppandReturnList(
          data,
          primaryApp,
          comprativeAppOne,
          comprativeAppTwo
        );
        dispatch(getComporarativeAppOneList({ appList }));

        setOverLayFlag1(true);
        setSuggestedApp1(true);
        openedApp();
      }
    } else {
      setComprativeAppOneFlag(false);
      setOverLayFlag1(true);
    }
  };

  const handleComprativeAppTwoCompare = async (): Promise<void> => {
    setSuggestedApp2(true);
    if (
      allAppLists !== undefined &&
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppOne !== undefined
    ) {
      const payload = {
        trendingAppForSelectedCategory: {
          appCategory: primaryCategoryList,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
        },
      };
      const suggestion: any = await fetchSuggestedApp({ ...payload }).unwrap();
      const appList1 = [...suggestion, ...initialAppList];
      const data = appList1?.filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t.id === value.id)
      );
      const appList = filterAppandReturnList(
        data,
        primaryApp,
        comprativeAppOne,
        comprativeAppTwo
      );
      dispatch(getComporarativeAppTwoList({ appList }));
      openedApp2();
      setSkeletonLoadingOption(true);
      setcomprativeApp2Flag(false);
      setOverLayFlag2(true);
    }
  };
  const callApi = (dateRange: any) => {
    dispatch(setDateRange(dateRange));
    if (
      appName &&
      (comprativeAppOne == null ||
        comprativeAppOne == undefined ||
        comprativeAppOne == "") &&
      (comprativeAppTwo == null ||
        comprativeAppTwo == undefined ||
        comprativeAppTwo === "")
    ) {
      callPrimaryAppChange(appName, dateRange, true);
    }
    if (
      appName !== null &&
      comprativeAppOne !== null &&
      (comprativeAppTwo == null ||
        comprativeAppTwo == undefined ||
        comprativeAppTwo === "")
    ) {
      callPrimaryAppChange(appName, dateRange, true);
      callComprativeAppOneChnage(comprativeAppOne, dateRange, true);
    }
    if (
      appName !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      callPrimaryAppChange(appName, dateRange, true);
      callComprativeAppOneChnage(comprativeAppOne, dateRange, true);
      callComprativeAppTwoChnage(comprativeAppTwo, dateRange, true);
    }
  };

  const handleSuggestion = (item: any): void => {
    if (
      comprativeAppOne === null ||
      comprativeAppOne == undefined ||
      comprativeAppOne === ""
    ) {
      setSuggestedApp1(true);
      setComprativeAppOneFlag(false);
      handleComprativeOne(item);
      setOverLayFlag1(false);
    } else if (
      comprativeAppOne !== null ||
      comprativeAppOne !== undefined ||
      comprativeAppOne !== ""
    ) {
      setSuggestedApp2(true);
      setComprativeAppOneFlag(false);
      handleComprativeTwo(item);
      setcomprativeAppTwoImage(item.appicon);
      setcomprativeApp2Flag(false);
    }
  };
  return (
    <SimpleGrid className="tagged-searchCard">
      <Grid className="ma-multiselect-tag">
        <Grid.Col span={12}>
          <Title size="lg" className="pt-4">
            Competitive Research
          </Title>
        </Grid.Col>
        <Grid.Col lg={7} md={7} sm={12}>
          <Text size="xs" className="mb-1 text-secondary">
            Brand
          </Text>
          <div style={{ height: "54px" }} className="main-Grid">
            <Flex align="center" justify="flex-start" className="h-full">
              {selectApp ? (
                <>
                  {allAppLists !== undefined && allAppLists !== null && (
                    <TaggedInput
                      showTaggedInputLoader={showTaggedInputLoader}
                      handleOverLayClick={(e: any) => {
                        e.stopPropagation();
                        close();
                        setOverLayFlag(false);
                        if (primaryApp === null) {
                          setValue(null);
                          setPrimaryApp(false);
                        }
                      }}
                      limit={primaryAppLimit}
                      opened={opened}
                      data={
                        allAppLists.length > 0
                          ? allAppLists
                          : [{ value: "test", label: "label" }]
                      }
                      bgColor={"rgba(44, 122, 123, 0.05)"}
                      borderColor={"#2C7A7B"}
                      src={primaryAppImage}
                      flag={primaryApp || appName}
                      value={primaryApp}
                      iconsOption={false}
                      openSection={() => {
                        fetchTrendingAppsData({
                          trendingAppQueryParam: {
                            appCategory: ["all_categories"],
                            intervalType: timeFilter,
                            offset: 10,
                            rankBy: rankFilter,
                          },
                        }).then((res: any) => {
                          if (res?.data !== undefined) {
                            setShowTaggedInputLoader(false);
                            const trendingAppsData =
                              res?.data?.trendingApps?.map((item: any) => {
                                return {
                                  value: item?.appName,
                                  label: item.appName,
                                  appicon: item?.iconUrl,
                                  id: item?.appId,
                                  categoryList: item?.appCategory,
                                  group: "Trending Apps",
                                };
                              });
                            const appList1 = [
                              ...trendingAppsData,
                              ...initialAppList,
                            ];
                            const data = appList1?.filter(
                              (value: any, index: any, self: any) =>
                                index ===
                                self.findIndex((t: any) => t.id === value.id)
                            );
                            const appList = filterOnEdit(
                              data,
                              comprativeAppOne,
                              comprativeAppTwo
                            );
                            dispatch(getAppList(appList));
                            setSkeletonLoadingOption(true);
                            setOverLayFlag(true);
                            open();
                            setOverLayFlag(true);
                          }
                        });
                      }}
                      open={(e: any) => {
                        e.preventDefault();
                        open();
                      }}
                      clearable={false}
                      itemComponent={SelectItem}
                      onChangeHandler={handlePrimaryApp}
                      setCloseMark={undefined}
                      openOverlay={overlayFlag}
                      nothingFound={"No results found"}
                      showLoaderOption={skeletonLoader}
                    />
                  )}

                  {primaryApp && <Text size="sm">VS</Text>}
                  {primaryApp !== null && comprativeApp1Flag ? (
                    <Button
                      size="sm"
                      radius="md"
                      className="primary-button ml-3"
                      onClick={handleComprativeAppOneCompare}
                    >
                      <PlusIcon className="h-4 w-4" />
                      Add
                    </Button>
                  ) : (
                    <>
                      {((comprativeAppOneOptions !== null &&
                        comprativeAppOneOptions == undefined) ||
                        suggestedApp1) && (
                        <TaggedInput
                          showTaggedInputLoader={showTaggedInputLoader}
                          clearable={true}
                          handleOverLayClick={() => {
                            closedApp();
                            setComprativeAppOneFlag(true);
                            setValue1(null);
                          }}
                          bgColor={"rgba(107, 70, 193, 0.05)"}
                          borderColor={"#6B46C1"}
                          limit={comprativeC1Limit}
                          iconsOption={true}
                          opened={openedComp}
                          data={comprativeAppOneOptions}
                          flag={comprativeAppOne}
                          src={comprativeAppOneImage}
                          value={comprativeAppOne}
                          setCloseMark={async () => {
                            setCloseSuggestionOne(true);
                            const payload = {
                              trendingAppForSelectedCategory: {
                                appCategory: primaryCategoryList,
                                fromDate: dateRange?.fromDate,
                                toDate: dateRange?.toDate,
                              },
                            };
                            const suggestedApp: any = await fetchSuggestedApp({
                              ...payload,
                            }).unwrap();
                            const data1 = filterAppandReturnListWithCategory1(
                              suggestedApp,
                              primaryApp,
                              null,
                              comprativeAppTwo
                            );
                            dispatch(setSuggestionArray(data1));
                            setAppData(data1);

                            dispatch(setComprativeJsonModel([]));
                            setComprativeAppOneFlag(true);
                            setValue1(null);
                            dispatch(setComprativeOneData(null));
                            dispatch(setComprativeJsonModel([]));
                            dispatch(setComprativeAppLineChartOneData([]));
                            dispatch(setUserEngageMentComprativeOneData([]));
                            dispatch(
                              setComparativeAppOneEngagementRateData([])
                            );
                          }}
                          open={(e) => {
                            e.preventDefault();
                            openedApp();
                          }}
                          onChangeHandler={(e) => {
                            handleComprativeOne(e);
                          }}
                          openSection={undefined}
                          openOverlay={overlayFlag1}
                          nothingFound={"No results found"}
                          showLoaderOption={true}
                          itemComponent={SelectItem}
                        />
                      )}
                    </>
                  )}
                  {hideButton ? (
                    <>
                      {primaryApp && comprativeAppOne && comprativeApp2Flag ? (
                        <Button
                          size="sm"
                          radius="md"
                          className="ml-4 primary-button"
                          onClick={handleComprativeAppTwoCompare}
                        >
                          <PlusIcon className="h-4 w-4" />
                          Add
                        </Button>
                      ) : (
                        <>
                          {suggestedApp2 == true && (
                            <TaggedInput
                              showTaggedInputLoader={showTaggedInputLoader}
                              clearable={true}
                              limit={comprativeC2Limit}
                              handleOverLayClick={() => {
                                closedApp2();
                                setcomprativeApp2Flag(true);
                                setValue2(null);
                              }}
                              bgColor={"rgba(192, 86, 33, 0.05)"}
                              borderColor={"#C05621"}
                              iconsOption={true}
                              opened={openedComp2}
                              data={comprativeAppTwoOptions}
                              src={comprativeAppTwoImage}
                              flag={comprativeAppTwo}
                              value={comprativeAppTwo}
                              setCloseMark={async () => {
                                setCloseSuggestionTwo(true);
                                const payload = {
                                  trendingAppForSelectedCategory: {
                                    appCategory: primaryCategoryList,
                                    fromDate: dateRange?.fromDate,
                                    toDate: dateRange?.toDate,
                                  },
                                };
                                const suggestedApp: any =
                                  await fetchSuggestedApp({
                                    ...payload,
                                  }).unwrap();
                                const data1 =
                                  filterAppandReturnListWithCategory1(
                                    suggestedApp,
                                    primaryApp,
                                    comprativeAppOne,
                                    null
                                  );
                                dispatch(setSuggestionArray(data1));
                                setAppData(data1);

                                dispatch(setComprativeTwoJsonModel([]));
                                setValue2(null);
                                setcomprativeApp2Flag(true);
                                dispatch(setComprativeTwoData(null));
                                dispatch(setComprativeAppLineChartTwoData([]));
                                dispatch(
                                  setUserEngageMentComprativeTwoData([])
                                );
                                dispatch(
                                  setComparativeAppTwoEngagementRateData([])
                                );
                              }}
                              open={() => openedApp2()}
                              onChangeHandler={handleComprativeTwo}
                              openSection={undefined}
                              openOverlay={overlayFlag2}
                              nothingFound={"No results found "}
                              showLoaderOption={true}
                              itemComponent={SelectItem}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <Text
                  className="ml-3 text-button w-full"
                  onClick={() => {
                    if (allAppLists !== null || initialAppList !== undefined) {
                      open();
                      setPrimaryApp(true);
                      setOverLayFlag(true);
                      setSkeletonLoadingOption(true);
                      setShowTaggedInputLoader(false);
                      dispatch(setUserActivityLoader(true));
                    } else {
                      open();
                      setPrimaryApp(true);
                      setOverLayFlag(true);
                      setShowTaggedInputLoader(true);
                    }
                  }}
                >
                  <Image width={30} height={30} src={Frame} alt={"frame"} />
                  <Text style={{ color: "#B4BFCC" }} className="pl-2">
                    Let’s start by selecting an app to generate data insights
                  </Text>
                </Text>
              )}
            </Flex>
          </div>
          {/* need to suggested app*/}

          <SuggestedApps
            primaryApp={primaryApp}
            app2={comprativeAppOne}
            app3={comprativeAppTwo}
            closeSuggestionOne={closeSuggestionOne}
            closeSuggestionTwo={closeSuggestionTwo}
            primaryCategoryList={primaryCategoryList}
            data={data}
            onclick={handleSuggestion}
          />
        </Grid.Col>
        <Grid.Col lg={5} md={5} sm={8} className="calender-label">
          <Text size="xs" className="mb-1 ml-4 text-secondary">
            Date Range
          </Text>
          <div>
            {listItems !== undefined && listItems?.length > 0 && (
              <MaCustomSelect listItems={listItems} handelApiCall={callApi} />
            )}
          </div>
        </Grid.Col>
      </Grid>
    </SimpleGrid>
  );
}
