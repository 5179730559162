import { Container, Grid, Flex, Button, Text } from "@mantine/core";

export default function MaContainer(props: any) {
  return (
    <Container fluid>
      <Grid>
        <Grid.Col span={5} offset={7} className="search-row">
          <Flex justify={"flex-end"}>
            <Button
              size="sm"
              radius="sm"
              className="primary-button"
              onClick={props.handleRoute}
            >
              + Create New Audience
            </Button>
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid
        justify={"center"}
        align="center"
        className="ma-insight-blankstate no-bg full-width"
      >
        <Grid.Col span={12} className="ma-placeholder-image text-center">
          <Flex justify={"center"} direction="column" align={"center"}>
            <img src={props.overViewImage} alt="" />
            <Text size="md" className="text-secondary mt-2">
              No Audiences
            </Text>
            <Text size="sm" className="text-secondary mt-2">
              Start creating audiences as per your requirements.
            </Text>
          </Flex>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
