import "./login.scss";
import Email from "../../assets/images/Email.svg";
import Tlogo from "../../assets/images/Tlogo.svg";
import { Link } from "react-router-dom";

export const ResetEmailSuccess = () => {
  return (
    <div className="login-background">
      <img src={Tlogo}></img>
      <div className="center-content email-reset">
        <div className="form-container">
          <span>
            Password reset sucessful.Please sign in with new password.
          </span>
        </div>

        <div className="sign-in">
          <p>
            Back to{" "}
            <Link to={"/login"} className="forgot-password">
              Sign In
            </Link>{" "}
            Page
          </p>
        </div>
      </div>
    </div>
  );
};
