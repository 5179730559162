import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

interface DateRange {
  dateLabel: string | any;
  fromDate: string | any;
  toDate: string | any;
  calFromDates: any;
  calToDates: any;
}

interface InitialState {
  dateRange: DateRange;
  startAndEndDateInEs: any;
  calenderList: any;
  label: string | any;
  appDashboardLabel: any;
}
const initialState: InitialState = {
  dateRange: {
    dateLabel: "",
    fromDate: "",
    toDate: "",
    calFromDates: "",
    calToDates: "",
  },
  startAndEndDateInEs: {},
  calenderList: [],
  label: "",
  appDashboardLabel: "",
};

const dateRangeSlice = createSlice({
  name: "daterange",
  initialState: { ...initialState },
  reducers: {
    setMaxMinDate: (state, action) => {
      state.startAndEndDateInEs = action?.payload;
    },
    setCalenderList: (state, action) => {
      const { startDateInEs, endDateInEs } =
        action?.payload?.startAndEndDateInEs;
      const startMonthDate = moment(endDateInEs)
        .subtract(27, "days")
        .format("DD-MM-YYYY");
      const endMonthDate = moment(endDateInEs).format("DD-MM-YYYY");
      const startWeekDate = moment(endDateInEs)
        .subtract(6, "days")
        .format("DD-MM-YYYY");
      const endDateWeekDate = moment(endDateInEs).format("DD-MM-YYYY");
      if (startDateInEs !== undefined && endDateInEs !== undefined) {
        const calenderList: any = [
          {
            label: `Last Week (${startWeekDate} - ${endDateWeekDate})`,
            value: "lastWeek",
          },
          {
            label: `Yesterday (${endDateWeekDate})`,
            value: `Yesterday`,
          },
          // {
          //   label: `Last Month (${startMonthDate} - ${endMonthDate})`,
          //   value: "LastMonth",
          // },
        ];
        state.calenderList = calenderList;
      }
    },
    setDateRange: (state, action) => {
      state.dateRange = action.payload;
    },
    setDateLabel: (state, action) => {
      state.label = action.payload;
    },
    setAppDashBoardLabel: (state, action) => {
      state.appDashboardLabel = action.payload;
    },
  },
});

export const {
  setMaxMinDate,
  setCalenderList,
  setDateRange,
  setDateLabel,
  setAppDashBoardLabel,
} = dateRangeSlice.actions;

export default dateRangeSlice.reducer;

export const selectMinMaxDate = (state: any) => state.date.startAndEndDateInEs;

export const selectedcalenderList = (state: any) => state?.date?.calenderList;

export const selectDateRange = (state: any) => state.date.dateRange;

export const selectLabel = (state: any) => state.date.label;

export const selectAppDashboardDateLabel = (state: any) =>
  state.date.appDashboardLabel;
