import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface TrendingAppQueryParam {
  appCategory: string[];
  intervalType: string;
  offset: number;
  rankBy: string;
  appName: string;
}

export interface AllCategoriesQueryParam {
  appCategory: string[];
}

const getTrendingAppsQuery = gql`
  query getTrendingApps($trendingAppQueryParam: TrendingAppQueryParam) {
    getTrendingApps(trendingAppQueryParam: $trendingAppQueryParam) {
      trendingApps {
        appId
        appName
        appCategory
        rank
        iconUrl
        rankChange
        count
        rankChangeDifferenceCount
      }
    }
  }
`;

const getAllCategoriesQuery = gql`
  query {
    getAllCategories {
      categories
    }
  }
`;

const getMinMaxDates = gql`
  query {
    startAndEndDateInEs {
      startDateInEs
      endDateInEs
    }
  }
`;

export const trendingAppApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTrendingApps: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getTrendingAppsQuery,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getTrendingApps;
      },
    }),
    getMinMaxDate: builder.query<any, any>({
      query: (): any => ({
        document: getMinMaxDates,
      }),
    }),
  }),
});

export const categoryApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategories: builder.query<any, any>({
      query: (): any => ({
        document: getAllCategoriesQuery,
      }),
      transformResponse: (response: any): any => {
        return response?.getAllCategories?.categories;
      },
    }),
  }),
});

export const {
  useGetTrendingAppsQuery,
  useLazyGetTrendingAppsQuery,
  useGetMinMaxDateQuery,
  useLazyGetMinMaxDateQuery,
} = trendingAppApiSlice;

export const { useGetAllCategoriesQuery, useLazyGetAllCategoriesQuery } =
  categoryApiSlice;
