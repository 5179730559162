import { useEffect, useState } from "react";
import {
  Table,
  Checkbox,
  Button,
  Pagination,
  Card,
  Title,
  Flex,
  Input,
  Text,
  Grid,
  MultiSelect,
  Modal,
  Divider,
} from "@mantine/core";
import getUsers from "./GetAllUsers.generated";
import GrantAcccess from "./GrantAccess.generated";
import AudienceDashboardSkeleton from "@/containers/SkeletonLoader/AudienceDashboardSkeleton";
import { notifications } from "@mantine/notifications";
import Hoc from "@/Hoc/Hoc";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { selectRealms } from "../services/realmRoles/realmRoles";
import { useNavigate } from "react-router-dom";

import "./login.scss";
import { useDisclosure } from "@mantine/hooks";

const AccessManagemnt = () => {
  const realms = useSelector(selectRealms);
  const navigate = useNavigate();
  const [data1, setData1] = useState([]);

  const [insightChecked, setinsightChecked] = useState(false);
  const [audienceChecked, setaudienceChecked] = useState(false);
  const [userData, setUserData] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [apiErr, setApiErr] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const itemsPerPage = 10;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const [currentData, setCurrentData] = useState([]);

  useEffect(() => {
    const filteredData =
      searchTerm.length > 0
        ? userData.filter(
            (item: any) =>
              item?.username?.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : userData;
    if (filteredData.length > 0) {
      const slicedData = filteredData.slice(startIndex, endIndex);
      setCurrentData(slicedData);
    }
  }, [userData, startIndex, endIndex, searchTerm, audienceChecked]);

  const totalPageCount = Math.ceil(userData.length / itemsPerPage);
  const [changedAccessData, setChangedAccessData] = useState<any>([]);

  useEffect(() => {
    if (sessionStorage.getItem("data-source")) {
      const data: any = sessionStorage.getItem("data-source")?.split(",");

      const data1: any = data.filter((item: any) => {
        if (item === "EMETRIQ") {
          return { value: item, label: item };
        } else if (item == "MICA") {
          return { value: item, label: item };
        }
      });
      setData1(data1);
    }
  }, [sessionStorage]);

  useEffect(() => {
    getUsers(
      (res: any) => {
        const data: any = sessionStorage.getItem("data-source")?.split(",");
        const data1: any = data.filter((item: any) => {
          if (item == "EMETRIQ") {
            return { value: item, label: item };
          } else if (item == "MICA") {
            return { value: item, label: item };
          }
        });

        const arr: any = res.map((item: any) => {
          let data = [];
          if (
            item?.dataSource.includes("MICA") &&
            item?.dataSource.includes("EMETRIQ")
          ) {
            data = ["MICA", "EMETRIQ"];
          } else if (
            item?.dataSource.includes("MICA") === true &&
            item?.dataSource.includes("EMETRIQ") == false
          ) {
            data = ["MICA"];
          } else if (
            item?.dataSource.includes("MICA") === false &&
            item?.dataSource.includes("EMETRIQ") == true
          ) {
            data = ["EMETRIQ"];
          } else {
            data = [data1[1]];
          }
          return {
            ...item,
            dataSource: data,
          };
        });
        setUserData(arr);
      },
      (err: any) => {
        setApiErr(true);
      }
    );
  }, [sessionStorage]);
  const [openedModal, { open: sectionOpen, close: sectionClose }] =
    useDisclosure(false);
  const [modalData, setModalData] = useState<any>({});
  const [modalSelection, setModalSelection] = useState<any>([]);
  const [dataSourceLabel, setDataSourceLabel] = useState("");

  const updateDataSourceValue = (e: any, el: any) => {
    if (e.length === 0) {
      sectionOpen();
      setModalData(el);
      setModalSelection(e);
      setDataSourceLabel(el.username);
      return false;
    } else {
      const newData: any = currentData.map((obj: any) =>
        obj.username === el.username ? { ...obj, dataSource: e } : obj
      );
      setCurrentData(newData);
    }

    const data: any = userData;
    const changedData: any = changedAccessData;
    for (let val of data) {
      if (val.username === el.username) {
        val.audience = !val.audience;
      }
    }
    let userFound = false;
    if (changedData.length === 0) {
      changedData.push({
        username: el.username,
        insight: el.insight,
        audience: el.audience,
        admin: el.admin,
        dataSource: e,
      });
    } else {
      for (let val of changedData) {
        if (val.username === el.username) {
          userFound = true;
          val.insight = el.insight;
          val.audience = el.audience;
          val.dataSource = e;
        }
      }
      userFound === false
        ? changedData.push({
            username: el.username,
            insight: el.insight,
            audience: el.audience,
            admin: el.admin,
            dataSource: e,
          })
        : "";
    }
    setChangedAccessData(changedData);
    setUserData(data);
  };

  const handleInsightChecked = (e: any, el: any) => {
    const newData: any = currentData.map((obj: any) =>
      obj.username === el.username ? { ...obj, insight: e.target.checked } : obj
    );
    setCurrentData(newData);
    const data: any = userData;
    const changedData: any = changedAccessData;
    for (let val of data) {
      if (val.username === el.username) {
        val.insight = !val.insight;
      }
    }
    let userFound = false;
    if (changedData.length === 0) {
      changedData.push({
        username: el.username,
        insight: e.target.checked,
        audience: el.audience,
        admin: el.admin,
        dataSource: el.dataSource,
      });
    } else {
      for (let val of changedData) {
        if (val.username === el.username) {
          userFound = true;

          val.insight = e.target.checked;
          val.audience = el.audience;
          val.dataSource = el.dataSource;
        }
      }
      userFound === false
        ? changedData.push({
            username: el.username,
            insight: e.target.checked,
            audience: el.audience,
            admin: el.admin,
            dataSource: el.dataSource,
          })
        : "";
    }
    setChangedAccessData(changedData);
    setUserData(data);
  };

  const handleAudienceChecked = (e: any, el: any) => {
    const data2: any = sessionStorage.getItem("data-source")?.split(",");
    const data1: any = data2.filter((item: any) => {
      if (item == "EMETRIQ") {
        return { value: item, label: "item" };
      } else if (item == "MICA") {
        return { value: item, label: "item" };
      }
    });
    setData1(data1);
    const newData: any = currentData.map((obj: any) => {
      if (obj.username === el.username) {
        if (
          e.target.checked === true &&
          (el.dataSource.includes("MICA") == false ||
            el.dataSource.includes("EMETRIQ") == false)
        ) {
          return { ...obj, audience: e.target.checked, dataSource: [data1[1]] };
        } else {
          return { ...obj, audience: e.target.checked, dataSource: [] };
        }
      }
      return obj;
    });

    if (e.target.checked == false) {
      setCurrentData(newData);
      const data: any = userData;
      const changedData: any = changedAccessData;
      for (let val of data) {
        if (val.username === el.username) {
          val.audience = !val.audience;
        }
      }
      let userFound = false;
      if (changedData.length === 0) {
        changedData.push({
          username: el.username,
          insight: el.insight,
          audience: el.audience || e.target.checked,
          admin: el.admin,
          dataSource: [],
        });
      } else {
        for (let val of changedData) {
          if (val.username === el.username) {
            userFound = true;

            val.insight = el.insight;
            val.audience = el.audience || e.target.checked;
            val.dataSource = [];
          }
        }
        userFound === false
          ? changedData.push({
              username: el.username,
              insight: el.insight,
              audience: e.target.checked,
              admin: el.admin,
              dataSource: [],
            })
          : "";
      }

      setChangedAccessData(changedData);
      setUserData(data);
    } else {
      setCurrentData(newData);
      const data: any = userData;
      const changedData: any = changedAccessData;
      for (let val of data) {
        if (val.username === el.username) {
          val.audience = !val.audience;
        }
      }
      let userFound = false;
      if (changedData.length === 0) {
        changedData.push({
          username: el.username,
          insight: el.insight,
          audience: el.audience || e.target.checked,
          admin: el.admin,
          dataSource: [data1[1]],
        });
      } else {
        for (let val of changedData) {
          if (val.username === el.username) {
            userFound = true;

            val.insight = el.insight;
            val.audience = el.audience || e.target.checked;
            val.dataSource = [data1[1]];
          }
        }
        userFound === false
          ? changedData.push({
              username: el.username,
              insight: el.insight,
              audience: true,
              admin: el.admin,
              dataSource: [data1[1]],
            })
          : "";
      }
      setChangedAccessData(changedData);
      setUserData(data);
    }
  };

  const handleDeselectAll = () => {
    const el = modalData;
    const e = modalSelection;
    const newData: any = currentData.map((obj: any) =>
      obj.username === el.username
        ? { ...obj, dataSource: [], audience: false }
        : obj
    );
    setCurrentData(newData);
    const data: any = userData;
    const changedData: any = changedAccessData;
    for (let val of data) {
      if (val.username === el.username) {
        val.audience = !val.audience;
      }
    }
    let userFound = false;
    if (changedData.length === 0) {
      changedData.push({
        username: el.username,
        insight: el.insight,
        audience: false,
        admin: el.admin,
        dataSource: [],
      });
    } else {
      for (let val of changedData) {
        if (val.username === el.username) {
          userFound = true;

          val.insight = el.insight;
          val.audience = false;
          val.dataSource = [];
        }
      }
      userFound === false
        ? changedData.push({
            username: el.username,
            insight: el.insight,
            audience: false,
            admin: el.admin,
            dataSource: [],
          })
        : "";
    }
    setChangedAccessData(changedData);
    setUserData(data);
    sectionClose();
  };

  const grantAccess = () => {
    const accessModifiedUser = [];
    const dataForAccess: any = changedAccessData;
    for (let val of dataForAccess) {
      const obj: any = { username: "", realmRoles: [] };
      (obj.username = val.username),
        val.insight === true ? obj.realmRoles.push("INSIGHTS") : "";
      val.audience === true ? obj.realmRoles.push("AUDIENCE") : "";
      val.admin === true ? obj.realmRoles.push("MA2-ADMIN") : "";
      val?.dataSource?.length > 0 ? obj.realmRoles.push(val.dataSource) : "";

      const flatternObj = {
        ...obj,
        realmRoles: obj.realmRoles?.flat(1),
      };
      accessModifiedUser.push(flatternObj);
    }
    GrantAcccess(
      accessModifiedUser,
      (res: any) => {
        notifications.show({
          id: "login-sucess",
          withCloseButton: true,
          onClose: () => {},
          onOpen: () => {},
          autoClose: 10000,
          title: "SUCCESS",
          message: "Role assigned sucessfully  for the selected users.",
          color: "green",
          className: "my-notification-class",
          style: { backgroundColor: "white" },
          sx: { backgroundColor: "green" },
          loading: false,
        });
      },
      (err: any) => {
        notifications.show({
          id: "login-fail",
          withCloseButton: true,
          onClose: () => {},
          onOpen: () => {},
          autoClose: 5000,
          title: "FAIL",
          message: "Please try again after some time.",
          color: "red",
          className: "my-notification-class",
          style: { backgroundColor: "white" },
          sx: { backgroundColor: "red" },
          loading: false,
        });
      }
    );
  };

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page when the search term changes
  };

  const rows = currentData?.map((element: any) => (
    <>
      <tr
        key={element.username}
        style={{ margin: "4px" }}
        className={element.admin ? "disabledRow" : ""}
      >
        <td style={{ border: "none", padding: "10px", width: "600px" }}>
          {element.username}
        </td>
        <td style={{ paddingLeft: "22px", border: "none" }}>
          <Checkbox
            checked={element.insight}
            onChange={(e: any) => {
              handleInsightChecked(e, element);
            }}
          />
        </td>
        <td style={{ paddingLeft: "40px", border: "none" }}>
          <Checkbox
            checked={element.audience}
            onChange={(event) => handleAudienceChecked(event, element)}
          />
        </td>
        {element.audience && element.dataSource.length > 0 && (
          <td style={{ border: "none", width: "320px" }}>
            <MultiSelect
              withAsterisk
              data={data1}
              value={element.dataSource}
              clearable={element.dataSource.length === 1 ? false : true}
              onChange={(e: any) => {
                updateDataSourceValue(e, element);
              }}
            />
          </td>
        )}
      </tr>
    </>
  ));

  return (
    <>
      <Modal
        opened={openedModal}
        onClose={sectionClose}
        title={`No Data-Source :- ${dataSourceLabel}`}
        centered
        size={"auto"}
      >
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            padding: 20,
          }}
        >
          <Text
            fz="md"
            className="pb-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {`You have deselected all the data sources for ${dataSourceLabel}`}.
            <br />
            {`Hence access of Audience Builder will be removed for this user.`}
          </Text>
        </div>
        <div className="py-5">
          <Divider />
          <Flex justify={"flex-end"} className="mt-4">
            <Button
              className="secondary-button mr-4"
              id="back-to-home-button"
              onClick={() => sectionClose()}
            >
              Cancel
            </Button>
            <Button
              onClick={handleDeselectAll}
              size="sm"
              radius="sm"
              className="primary-button mr-4"
            >
              Deselect all
            </Button>
          </Flex>
        </div>
      </Modal>
      {userData.length === 0 && !apiErr && <AudienceDashboardSkeleton />}
      {apiErr && <> Api failed . Please try again after some time.</>}
      {userData.length > 0 && !apiErr && (
        <>
          <Flex justify="space-between" align="center" className="mb-4">
            <Title size={"md"}>USER ACCESS MANAGEMENT</Title>
            <div>
              <Button
                className="secondary-button mr-4"
                id="back-to-home-button"
                onClick={() => navigate("/trending")}
              >
                Back To Home
              </Button>
              <Button className="primary-button" onClick={grantAccess}>
                Apply & Grant Access
              </Button>
            </div>
          </Flex>
          <Card className="mb-5 p-10">
            <Card.Section>
              <Grid>
                <Grid.Col lg={4} md={4}>
                  <Input
                    className="mb-3"
                    icon={
                      <MagnifyingGlassIcon
                        height={20}
                        width={20}
                        color="#6E7B91"
                      />
                    }
                    placeholder="Search by email ID"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    width="100px"
                  />
                </Grid.Col>
              </Grid>

              {rows.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th>Email ID</th>
                      <th>Insights</th>
                      <th>Audience Builder</th>
                      <th>Data Source</th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              ) : (
                <Text>No Data Found with search criteria.</Text>
              )}
            </Card.Section>

            <Flex justify={"flex-end"}>
              {rows.length > 0 && (
                <Pagination
                  total={totalPageCount}
                  itemsPerPage={itemsPerPage}
                  page={currentPage}
                  onChange={(newPage: any) => handleChangePage(newPage)}
                  style={{ paddingTop: "12px" }}
                />
              )}
            </Flex>
          </Card>
        </>
      )}
    </>
  );
};

export default Hoc(AccessManagemnt);
