import { createSlice } from "@reduxjs/toolkit";

const theme = sessionStorage.getItem("theme");

const initialState: any = {
  themeName: theme ?? "light",
};

const themeSlice = createSlice({
  name: "useractivity",
  initialState: { ...initialState },
  reducers: {
    setTheme: (state, action) => {
      state.themeName = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;

export const selectLightTheme = (state: any) => state.theme.themeName;
