import { useEffect, useState } from "react";
import { Grid, Tabs } from "@mantine/core";
import InsightsOverView from "../InsightsOverView/InsightsOverView";
import InsightsRecommendation from "@/Pages/Insights/InsightsRecommendations/InsightsRecommendations";
import {
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectedPrimaryApp,
  selectPrimaryJsonModel,
  selectComprativeJsonModel,
  selectComprativeTwoJsonModel,
  selectLoading,
} from "@/features/services/overView/overViewSlice";
import { useSelector } from "react-redux";
import SkeletonLoader from "../../../containers/SkeletonLoader/SkeletonLoader";
import SkeletonTwoColumnLoader from "../../../containers/SkeletonLoader/SkeletonTwoColumnLoader";
import InsightsUserActivity from "../InsightsUserActivity/InsightsUserActivity";
import CrossBrowsing from "@/Pages/Insights/CrossBrowsing/CrossBrowsing";
import "./InsightTabs.scss";

export default function InsightTabs() {
  const [column, setColumn] = useState(0);
  const [isTabDisabled, setIsTabDisabled] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>("Overview");
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const primaryAppJsonModel = useSelector(selectPrimaryJsonModel);
  const comprativeAppJsonModel = useSelector(selectComprativeJsonModel);
  const comprativeAppTwoJsonModel = useSelector(selectComprativeTwoJsonModel);
  const loading = useSelector(selectLoading);

  const primaryAppColors: string[] = ["#2C7A7B", "#38B2AC", "#81E6D9"];
  const primaryAppPiChartColors: string[] = [
    "#81E6D9",
    "#38B2AC",
    "#2C7A7B",
    "#234E52",
  ];
  const comprativeAppOneColors = ["#6B46C1", "#BA9CFF", "#44337A"];
  const comprativeAppOnePiChartColors = [
    "#44337A",
    "#6B46C1",
    "#805AD5",
    "#BA9CFF",
  ];
  const comprativeAppTwoColors = ["#C05621", "#DD6B20", "#FFE7D1"];
  const comprativeAppTwoPiChartColors = [
    "#7B341E",
    "#C05621",
    "#DD6B20",
    "#F5A663",
  ];

  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(4);
    } else if (primaryApp !== null && comprativeAppOne !== null) {
      setColumn(6);
    } else if (primaryApp !== null && comprativeAppTwo !== null) {
      setColumn(6);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(12);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);

  useEffect(() => {
    if (
      (comprativeAppOne === null || comprativeAppOne === "") &&
      (comprativeAppTwo === null || comprativeAppTwo === "")
    ) {
      setIsTabDisabled(true);
      if (activeTab === "Recommendation") {
        setActiveTab("Overview");
      }
    } else if (comprativeAppOne !== null || comprativeAppOne !== "") {
      setIsTabDisabled(false);
    } else if (comprativeAppTwo !== null || comprativeAppTwo !== "") {
      setIsTabDisabled(false);
    }
  }, [comprativeAppOne, comprativeAppTwo, activeTab]);

  return (
    <Grid className="full-width ma-insights-tabs">
      <Tabs
        className="w-full mx-10"
        color="#131B2A"
        value={activeTab}
        onTabChange={setActiveTab}
      >
        <Tabs.List>
          <Tabs.Tab value="Overview">Overview</Tabs.Tab>
          <Tabs.Tab value="UserActivities">User Activities</Tabs.Tab>
          <Tabs.Tab value="CrossBrowsing">Cross Browsing</Tabs.Tab>
          <Tabs.Tab value="Recommendation" disabled={isTabDisabled}>
            Recommendation
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="Overview" pt="xs">
          <Grid>
            {primaryApp !== null &&
              comprativeAppOne === null &&
              comprativeAppTwo === null &&
              loading === true && <SkeletonLoader />}
            {primaryApp !== null &&
              comprativeAppOne !== null &&
              comprativeAppTwo === null &&
              loading === true && (
                <>
                  <Grid.Col span={6}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                </>
              )}
            {primaryApp !== null &&
              comprativeAppOne === null &&
              comprativeAppTwo !== null &&
              loading === true && (
                <>
                  <Grid.Col span={6}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                  <Grid.Col span={6}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                </>
              )}
            {primaryApp !== null &&
              comprativeAppOne !== null &&
              comprativeAppTwo !== null &&
              loading === true && (
                <>
                  <Grid.Col span={4}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Grid>
                      <SkeletonTwoColumnLoader />
                    </Grid>
                  </Grid.Col>
                </>
              )}
          </Grid>
          <Grid gutter={20} className="my-6">
            <Grid.Col lg={column} md={column} sm={column}>
              <Grid gutter={20}>
                {primaryAppJsonModel && (
                  <InsightsOverView
                    appName={primaryAppJsonModel?.appName}
                    iconUrl={primaryAppJsonModel?.iconUrl?.slice(1, -1)}
                    appCategory={primaryAppJsonModel?.appCategory?.toString()}
                    appProfileData={primaryAppJsonModel}
                    colors={primaryAppColors}
                    piChartColors={primaryAppPiChartColors}
                    isCOl={column == 12 ? 1 : 0}
                  />
                )}
              </Grid>
            </Grid.Col>
            {comprativeAppOne && (
              <Grid.Col lg={column} md={column} sm={column}>
                <Grid gutter={20}>
                  {comprativeAppJsonModel && (
                    <InsightsOverView
                      appName={comprativeAppJsonModel?.appName}
                      iconUrl={comprativeAppJsonModel?.iconUrl?.slice(1, -1)}
                      appCategory={comprativeAppJsonModel?.appCategory?.toString()}
                      appProfileData={comprativeAppJsonModel}
                      colors={comprativeAppOneColors}
                      piChartColors={comprativeAppOnePiChartColors}
                    />
                  )}
                </Grid>
              </Grid.Col>
            )}
            {comprativeAppTwo && (
              <Grid.Col lg={column} md={column} sm={column}>
                <Grid gutter={20}>
                  {comprativeAppTwoJsonModel && (
                    <InsightsOverView
                      appName={comprativeAppTwoJsonModel?.appName}
                      iconUrl={comprativeAppTwoJsonModel?.iconUrl?.slice(1, -1)}
                      appCategory={comprativeAppTwoJsonModel?.appCategory?.toString()}
                      appProfileData={comprativeAppTwoJsonModel}
                      colors={comprativeAppTwoColors}
                      piChartColors={comprativeAppTwoPiChartColors}
                    />
                  )}
                </Grid>
              </Grid.Col>
            )}
          </Grid>
        </Tabs.Panel>
        <Tabs.Panel value="UserActivities" pt="xs">
          <InsightsUserActivity />
        </Tabs.Panel>
        <Tabs.Panel value="CrossBrowsing" pt="xs">
          {/* {primaryAppJsonModel !== null || comprativeAppJsonModel !== null && comprativeAppTwoJsonModel !== null && */}
          <CrossBrowsing />
          {/* } */}
        </Tabs.Panel>
        <Tabs.Panel value="Recommendation" pt="xs">
          <InsightsRecommendation
            displayInsightContent={false}
            src={""}
            recommendationsData={undefined}
          ></InsightsRecommendation>
        </Tabs.Panel>
      </Tabs>
    </Grid>
  );
}
