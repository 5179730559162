import { Grid, Flex, Checkbox, Text, List, Skeleton } from "@mantine/core";
export default function InsightsRecommendationSkeleton() {
  return (
    <>
      <Flex justify="space-between" align="center" direction="row">
        <Skeleton height={40} mt={6} width="20%" />
        <Skeleton height={50} width="20%" />
      </Flex>
      <Grid className="insights-recommendation mt-0">
        <Grid.Col span={12}>
          <Text fz="xl" className="mb-3"></Text>
          <List>
            <Checkbox.Group className="checkbox-group">
              <List.Item>
                <Flex align="center" gap="sm" className="px-5 py-3">
                  <Text></Text>
                  <Text className="description"></Text>
                </Flex>
                <Text className="indication"></Text>
              </List.Item>
            </Checkbox.Group>
          </List>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text fz="xl" className="mb-3"></Text>
          <List>
            <Checkbox.Group className="checkbox-group">
              <List.Item>
                <Flex align="center" gap="sm" className="px-5 py-3">
                  <Text></Text>
                  <Text className="description"></Text>
                </Flex>
                <Text className="indication"></Text>
              </List.Item>
            </Checkbox.Group>
          </List>
        </Grid.Col>
        <Grid.Col span={12}>
          <Text fz="xl" className="mb-3"></Text>
          <List>
            <Checkbox.Group className="checkbox-group">
              <List.Item>
                <Flex align="center" gap="sm" className="px-5 py-3">
                  <Text></Text>
                  <Text className="description"></Text>
                </Flex>
                <Text className="indication"></Text>
              </List.Item>
            </Checkbox.Group>
          </List>
        </Grid.Col>
      </Grid>
    </>
  );
}
