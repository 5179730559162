import "./login.scss";
import CompareAppImg from "../../assets/images/CompareAppImg.svg";
import RecommendationImg from "../../assets/images/RecommendationImg.svg";
import Tlogo from "../../assets/images/Tlogo.svg";
import { useNavigate } from "react-router-dom";
import { Grid, Container } from "@mantine/core";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export const LoginLanding = () => {
  const navigate = useNavigate();
  return (
    <Container fluid className="login-background p-0" p={0}>
      <Grid>
        <Grid.Col span={8}>
          <img src={Tlogo}></img>
        </Grid.Col>
      </Grid>
      <Grid justify="center" align="center">
        <Grid.Col md={8} lg={6} xs={8} sm={12}>
          <div className="center-content">
            <div className="header">Data-Driven Advantage</div>
            <div className="subheader">
              Unlock Your Potential with our Cutting Edge Insights
            </div>
            <button className="start-btn" onClick={() => navigate("/login")}>
              Start Now
            </button>
          </div>
        </Grid.Col>
      </Grid>
      <Grid justify={"center"}>
        <Grid.Col span={9}>
          <Carousel
            autoPlay
            showArrows={false}
            infiniteLoop={true}
            dynamicHeight={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
          >
            <img className="img-slide" src={CompareAppImg} />
            <img className="img-slide" src={RecommendationImg} />
          </Carousel>
        </Grid.Col>
      </Grid>
    </Container>
  );
};
