import { Grid, Select, Text } from "@mantine/core";
import { CalendarIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import MaDateRange from "../../../containers/taggedSearch/MaDateRange";
import { useState } from "react";
export default function DatePicker(props: any) {
  const [opened, setOpened] = useState(false);
  const handleClick = (e: any) => {
    setOpened((o) => !o);
  };
  return (
    <Grid className="pt-0 pl-8">
      <Grid.Col span={12} className="pl-0">
        <Text fz="md" className="text-secondary">
          Date Range<sup style={{ color: "#ff0000" }}>*</sup>
        </Text>
      </Grid.Col>
      <Grid.Col
        span={11}
        className="pt-0 pl-0"
        style={{ position: "relative" }}
      >
        <Select
          placeholder=" Please Select a Date"
          data={props.dateRange}
          icon={
            <CalendarIcon
              className="h-5 w-5 ml-4 mr-1"
              style={{ color: "rgba(0,0,0,0)" }}
            />
          }
          rightSection={
            <ChevronDownIcon className="h-4 w-4 mr-3 text-secondary" />
          }
          value={props.dates}
          onChange={props.handleDateChange}
        />
        <MaDateRange
          opened={opened}
          onChange={setOpened}
          defaultValue={props.defaultValue}
          numberOfColumns={1}
        >
          <span>
            <CalendarIcon
              className="h-5 w-5 mr-4"
              style={{
                position: "absolute",
                top: "15px",
                left: "5px",
                color: "#adb5bd",
              }}
              onClick={handleClick}
            />
          </span>
        </MaDateRange>
      </Grid.Col>
    </Grid>
  );
}
