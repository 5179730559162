import axios from "axios";
import CryptoJS from "crypto-js";
const RERIRECT_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service/keycloak"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service/keycloak"
  : "https://gateway.dev.ma2.telekom.com/insights-service/keycloak";

const ResetLoginPassword = (
  password: string,
  otp: string,
  callback: any,
  errCallback: any,
) => {
  const cryticPassword = CryptoJS.SHA256(password).toString();
  axios
    .put(
      `${RERIRECT_URL}/resetUserPassword`,

      {
        username: sessionStorage.getItem("userName"),
        otp,
        credentials: [
          {
            type: "password",
            value: cryticPassword,
          },
        ],
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      errCallback(err);
    });
};
export default ResetLoginPassword;
