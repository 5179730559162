import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface AppQueryParam {
  primaryAppId: number;
  competitiveAppOneId: number;
  competitiveAppTwoId: number;
  fromDate: string;
  toDate: string;
  isCategory: boolean;
  searchableAppId: number;
}

const getRecommendationQuery = gql`
  query getAllRecommendations($appQueryParam: AppQueryParam) {
    getRecommendations(appQueryParam: $appQueryParam) {
      high {
        id
        impact
        description
        indicator
      }
      medium {
        id
        impact
        description
        indicator
      }
      low {
        id
        impact
        description
        indicator
      }
    }
  }
`;

export const recommendationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecommendationsData: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getRecommendationQuery,
        variables,
      }),
    }),
  }),
});

export const {
  useGetRecommendationsDataQuery,
  useLazyGetRecommendationsDataQuery,
} = recommendationApiSlice;
