import { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import { Card, Text, Flex } from "@mantine/core";
import { numberFormatter } from "../../../../Util/utils";
import * as echarts from "echarts/core";
import { useSelector } from "react-redux";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { selectLabel } from "@/features/services/dateRange/dateRangeSlice";
import moment from "moment";

export default function LineGredientChart(props: any) {
  const label = useSelector(selectLabel);

  const { item } = props;
  const themeProvider = useSelector(selectLightTheme);

  const [time, setTime] = useState([]);
  useEffect(() => {
    if (label === "LastMonth") {
      if (
        item?.trendMapper !== undefined ||
        (null && item?.trendMapper?.time !== null) ||
        undefined
      ) {
        const time = item?.trendMapper?.time.map((item: any, index: any) => {
          return item.replace("_", " ").split(" ");
        });
        const arr: any = time.flat();
        const dateArray = arr.map((item: any) => {
          return moment(item, "YYYY-MM-DD").format("DD MMM YY");
        });

        const MonthData: any = [
          "Week-1" + "\n\n" + dateArray[7] + "-" + dateArray[6],
          "Week-2" + "\n\n" + dateArray[5] + "-" + dateArray[4],
          "Week-3" + "\n\n" + dateArray[3] + "-" + dateArray[2],
          "Week-4" + "\n\n" + dateArray[1] + "-" + dateArray[0],
        ];
        setTime(MonthData);
      }
    }
  }, [item?.trendMapper?.time]);
  let option = {
    grid: {
      top: 40,
      left: 60,
      right: 60,
      bottom: 40,
      containLabel: true,
    },
    tooltip: {
      trigger: "item",
      formatter: function (params: any) {
        if (params.seriesName !== null) {
          return `
                ${params.name} </br>
                ${params.seriesName}:- ${numberFormatter(
                  params.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                )} <br />`;
        }
      },
    },
    xAxis: {
      type: "category",
      onZero: false,
      axisLabel: item?.axisLabel,
      boundaryGap: false,
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          width: 1,
        },
      },
      data: label === "LastMonth" ? time : item?.trendMapper?.time,
    },
    yAxis: [
      {
        name: "Absolute Count",
        type: "value",
        position: "left",
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: (num: any) => {
            return numberFormatter(num);
          },
        },
        nameLocation: "middle",
        nameGap: 50,
      },
    ],
    series: [
      {
        name: "",
        data: [],
        type: "line",
      },
      {
        name: item?.appName,
        data: item?.trendMapper?.value,
        type: "line",
        showSymbol: true,
        symbolSize: [15, 15],
        itemStyle: {
          color: "#e5e5e5",
        },
        markPoint: {
          data: [{ type: "max", name: "max" }],
          symbol: "circle",
          symbolSize: [10, 10],
          itemStyle: {
            color: item?.colors[1],
          },
          label: {
            show: true,
            position: "top",
            color: themeProvider === "light" ? "#00022d" : "#ffffff",
            formatter: (num: any) => {
              return `${numberFormatter(num?.data?.value)}\n Max`;
            },
          },
        },
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: item?.colors[1],
            },
            {
              offset: 1,
              color: item?.colors[2],
            },
          ]),
        },
      },
    ],
  };

  return (
    <>
      {item?.trendMapper?.value !== undefined && (
        <Card.Section style={{ position: "relative" }}>
          <Flex className="ml-8">
            <img src={item?.iconUrl} style={{ height: 20, width: 20 }} />
            <Text fz="xs" className="text-secondary ml-3">
              {item?.appName}
            </Text>
          </Flex>
          <ReactEcharts
            option={option}
            opts={{ renderer: "svg" }}
            style={{
              height: 250,
              width: "100%",
            }}
          ></ReactEcharts>
        </Card.Section>
      )}
    </>
  );
}
