import { Grid, Text, Container, Flex, Skeleton } from "@mantine/core";

export default function AudienceDashboardSkeleton() {
  return (
    <Container fluid className="audience-dashboard">
      <Grid>
        <Grid.Col span={5} offset={7} className="search-row mb-10">
          <Flex>
            <Skeleton height={40} radius="xs" className="mr-3" />
            <Skeleton height={40} width={100} radius="xs" className="mr-3" />
            <Skeleton height={40} radius="xs" />
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={3}>
          <Skeleton height={100} radius="xs" className="mb-3" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton height={100} radius="xs" className="mb-3" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton height={100} radius="xs" className="mb-3" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton height={100} radius="xs" className="mb-3" />
        </Grid.Col>
      </Grid>
      <Grid>
        <Grid.Col span={3}>
          <Skeleton height={300} radius="xs" className="mb-3" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton height={300} radius="xs" className="mb-3" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton height={300} radius="xs" className="mb-3" />
        </Grid.Col>
        <Grid.Col span={3}>
          <Skeleton height={300} radius="xs" className="mb-3" />
        </Grid.Col>
      </Grid>
    </Container>
  );
}
