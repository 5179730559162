import { useEffect } from "react";

import Hoc from "@/Hoc/Hoc";
import TaggedSearch from "../../containers/taggedSearch/TaggedSearch";
import InsightBlankState from "../../containers/InsightBlankState/InsightBlankState";
import InsightTabs from "./insightTabs/InsightTabs";
import { selectedPrimaryApp } from "../../features/services/overView/overViewSlice";
import { useSelector, useDispatch } from "react-redux";
import { useGetAllCategoriesQuery } from "@/features/services/trendingApps/trendingAppApiSlice";
import { setAllCategories } from "@/features/services/trendingApps/trendingAppSlice";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";

const Insights = () => {
  const flag = useSelector(selectedPrimaryApp);
  const { data: categories } = useGetAllCategoriesQuery("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (categories !== undefined) {
      dispatch(setAllCategories(categories));
    }
  }, [categories]);

  return (
    <>
      {sessionStorage.getItem("insightTab") == "true" ? (
        <>
          <TaggedSearch />
          {flag ? (
            <>
              <InsightTabs />
            </>
          ) : (
            <InsightBlankState />
          )}
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

export default Hoc(Insights);
