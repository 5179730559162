import { Group, Text } from "@mantine/core";
import ReactEcharts from "echarts-for-react";
import "echarts-wordcloud";
import { useEffect, useState } from "react";
import "../PersonaBuilder/AudienceBuilder.scss";

const WordCloud = (props: any) => {
  const [cloudData, setCloudData] = useState<any>(props.data);
  useEffect(() => {
    if (props.displayCount !== "") {
      // setCloudData(props.data.slice(0, parseInt(props.displayCount)));
    }
  }, [props.displayCount]);
  useEffect(() => {
    let datatoSave = props.data;

    setCloudData(datatoSave);
  }, [props.data]);

  var options = {
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "wordCloud",
        gridSize: 15,
        sizeRange: [15, 50],
        rotationRange: [0, 0],
        shape: "circle",
        width: "100%",
        height: "100%",
        drawOutOfBound: false,
        shrinkToFit: true,
        layoutAnimation: false,
        textStyle: {
          color: "#2c7a7b",
        },
        emphasis: {
          textStyle: {
            shadowBlur: 10,
            shadowColor: "#333",
          },
        },
        data: cloudData,
      },
    ],
  };

  return (
    <div className="px-2">
      <ReactEcharts
        option={options}
        style={{
          width: "auto",
          height: "400px",
          margin: "15px 0 10px",
        }}
      ></ReactEcharts>
      {props.showKeywords && (
        <Group className="word-cloud-legends" position={"center"}>
          <Text>
            Keywords as per Criteria -&nbsp;
            {cloudData.length -
              cloudData.filter((obj: any) => "textStyle" in obj).length}
          </Text>
          <Text className="newly-keyword">
            Newly Added -&nbsp;
            {cloudData.filter((obj: any) => "textStyle" in obj).length}
          </Text>
        </Group>
      )}
    </div>
  );
};

export default WordCloud;
