import { useState, useEffect } from "react";
import Hoc from "@/Hoc/Hoc";
import {
  Grid,
  Text,
  Accordion,
  AccordionControlProps,
  Box,
  Container,
  Flex,
  Button,
  Image,
  Divider,
  Drawer,
  Stack,
  Tabs,
  Title,
  Tooltip,
  Chip,
  Card,
  Group,
} from "@mantine/core";
import ReactEcharts from "echarts-for-react";
import {
  InformationCircleIcon,
  ChevronRightIcon,
  UserGroupIcon,
  SignalIcon,
  SparklesIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { UserPlusIcon, UsersIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useLazyGetSavedAudiencesQuery } from "../../features/services/audienceBuilder/audienceBuilderApiSlice";
import LiveIcon from "@/assets/icons/live-icon.svg";
import TargetingIcon from "@/assets/icons/targeting-icon.svg";
import CalculatingIcon from "@/assets/icons/calculating-icon.svg";
import AudienceDashboardSkeleton from "@/containers/SkeletonLoader/AudienceDashboardSkeleton";
import { numberFormatter } from "@/Util/utils";
import { selectRealms } from "@/features/services/realmRoles/realmRoles";
import WordCloud from "./WordCloud";

import {
  setAppPrimaryData,
  setPrimaryAppId,
  setComparativeAppOneId,
  setComparativeAppTwoId,
  setComprativeOneData,
  setComprativeTwoData,
  setPrimaryJsonModel,
  setComprativeJsonModel,
  setComprativeTwoJsonModel,
} from "@/features/services/overView/overViewSlice";
import { useDispatch, useSelector } from "react-redux";
import overViewImage from "../../assets/images/SearchIllustration.png";
import "./AudienceDashboard.scss";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useDisclosure } from "@mantine/hooks";
import MaContainer from "./MaContainer";
import MaCard from "./MaCard";
import AudienceStatusCard from "./AudienceStatusCard";
import { chartOptions } from "./ChartOptions";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";
import SelectDataSourcePopUp from "./createAudience/SelectDataSourcePopUp";
import CreateAudience from "./createAudience/CreateAudience";

function AccordionControl(props: AccordionControlProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Accordion.Control {...props} />
    </Box>
  );
}
const LabledButton = (data: any) => {
  const response = data.data;
  const [opened, setOpened] = useState(false);
  return (
    <Flex align="center" wrap="wrap" gap="xs">
      {response?.sectionOneFilters !== null && (
        <Chip
          className={
            response?.sectionOneFilters?.include
              ? "selected--keywords-section include"
              : "selected--keywords-section exclude"
          }
          defaultChecked={false}
          checked={false}
          size="sm"
          style={{
            width: "auto",
            fontSize: "10px",
          }}
        >
          <Flex>
            <Text
              className={
                response?.sectionOneFilters?.include
                  ? "include-exclude-section include"
                  : "include-exclude-section exclude"
              }
            >
              {response?.sectionOneFilters?.include ? "Include" : "Exclude"}
            </Text>
            <Text className="names">
              <>
                {response?.sectionOneFilters?.appCategories?.length > 0 && (
                  <>
                    <span className="text-secondary">App Categories:-</span>
                    <Tooltip
                      label={response?.sectionOneFilters?.appCategories?.map(
                        (element: any, index: any) => {
                          return (
                            <span className="element-name">
                              &nbsp;{element}
                              {response?.sectionOneFilters?.appCategories
                                ?.length -
                                1 !==
                              index
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                      )}
                    >
                      <span>
                        {response?.sectionOneFilters?.appCategories?.map(
                          (element: any, index: any) => {
                            return (
                              <span className="element-name">
                                &nbsp;{element}
                                {response?.sectionOneFilters?.appCategories
                                  ?.length -
                                  1 !==
                                index
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      </span>
                    </Tooltip>
                  </>
                )}
                {response?.sectionOneFilters?.apps?.length > 0 && (
                  <>
                    <div>
                      <span className="text-secondary">Apps:-</span>
                      <Tooltip
                        label={response?.sectionOneFilters?.apps?.map(
                          (element: any, index: any) => {
                            return (
                              <span className="element-name">
                                &nbsp;{element}
                                {response?.sectionOneFilters?.apps?.length -
                                  1 !==
                                index
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      >
                        <span>
                          {response?.sectionOneFilters?.apps?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name apps">
                                  &nbsp;{element}
                                  {response?.sectionOneFilters?.apps?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </Tooltip>
                      <Tooltip
                        color="dark"
                        opened={opened}
                        label={
                          <>
                            <Text color="#ffffff">
                              <span className="color-magenta">Apps:-</span>
                              {response?.sectionOneFilters?.apps?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionOneFilters?.apps
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </Text>
                            <Text color="#ffffff">
                              <span className="color-magenta">Usage:-</span>
                              {response?.sectionOneFilters?.usages?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionOneFilters?.usages
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </Text>
                            <Text color="#ffffff">
                              <span className="color-magenta">Day Phase:-</span>
                              {response?.sectionOneFilters?.dayPhases?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionOneFilters?.dayPhases
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </Text>
                          </>
                        }
                        position="bottom"
                        withArrow
                      >
                        <span
                          className="show-more"
                          onClick={() => setOpened((o) => !o)}
                        >
                          More
                        </span>
                      </Tooltip>
                    </div>
                  </>
                )}
                {response?.sectionOneFilters?.keywords?.length > 0 && (
                  <>
                    <span className="text-secondary">Interests:-</span>
                    <Tooltip
                      label={response?.sectionOneFilters?.keywords?.map(
                        (element: any, index: any) => {
                          return (
                            <span className="element-name">
                              &nbsp;{element}
                              {response?.sectionOneFilters?.keywords?.length -
                                1 !==
                              index
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                      )}
                    >
                      <span>
                        {response?.sectionOneFilters?.keywords?.map(
                          (element: any, index: any) => {
                            return (
                              <span className="element-name">
                                &nbsp;{element}
                                {response?.sectionOneFilters?.keywords?.length -
                                  1 !==
                                index
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      </span>
                    </Tooltip>
                  </>
                )}
              </>
            </Text>
          </Flex>
        </Chip>
      )}
      {response?.sectionOneCondition !== "" && (
        <Text className="color-magenta">-{response?.sectionOneCondition}-</Text>
      )}
      {response?.sectionOneCondition !== "" && (
        <>
          {response?.sectionTwoFilters !== null && (
            <Chip
              className={
                response?.sectionTwoFilters?.include
                  ? "selected--keywords-section include"
                  : "selected--keywords-section exclude"
              }
              defaultChecked={false}
              checked={false}
              size="sm"
              style={{
                width: "auto",
                fontSize: "10px",
              }}
            >
              <Flex>
                <Text
                  className={
                    response?.sectionTwoFilters?.include
                      ? "include-exclude-section include"
                      : "include-exclude-section exclude"
                  }
                >
                  {/* {data?.data?.sectionOneKeywords[0]?.toUpperCase()} */}
                  {response?.sectionTwoFilters?.include ? "Include" : "Exclude"}
                </Text>
                <Text className="names">
                  <>
                    {response?.sectionTwoFilters?.appCategories?.length > 0 && (
                      <>
                        <span className="text-secondary">App Categories:-</span>
                        <Tooltip
                          label={response?.sectionTwoFilters?.appCategories?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name">
                                  &nbsp;{element}
                                  {response?.sectionTwoFilters?.appCategories
                                    ?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        >
                          <span>
                            {response?.sectionTwoFilters?.appCategories?.map(
                              (element: any, index: any) => {
                                return (
                                  <span className="element-name">
                                    &nbsp;{element}
                                    {response?.sectionTwoFilters?.appCategories
                                      ?.length -
                                      1 !==
                                    index
                                      ? ", "
                                      : ""}
                                  </span>
                                );
                              }
                            )}
                          </span>
                        </Tooltip>
                      </>
                    )}
                    {response?.sectionTwoFilters?.apps?.length > 0 && (
                      <>
                        <span className="text-secondary">Apps:-</span>
                        <Tooltip
                          label={response?.sectionTwoFilters?.apps?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name">
                                  &nbsp;{element}
                                  {response?.sectionTwoFilters?.apps?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        >
                          <span>
                            <div className="app-block">
                              {response?.sectionTwoFilters?.apps?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionTwoFilters?.apps
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          </span>
                        </Tooltip>
                        <Tooltip
                          color="dark"
                          opened={opened}
                          label={
                            <>
                              <Text color="#ffffff">
                                <span className="color-magenta">Apps:-</span>
                                {response?.sectionTwoFilters?.apps?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionTwoFilters?.apps
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </Text>
                              <Text color="#ffffff">
                                <span className="color-magenta">Usage:-</span>
                                {response?.sectionTwoFilters?.usages?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionTwoFilters?.usages
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </Text>
                              <Text color="#ffffff">
                                <span className="color-magenta">
                                  Day Phase:-
                                </span>
                                {response?.sectionTwoFilters?.dayPhases?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionTwoFilters?.dayPhases
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </Text>
                            </>
                          }
                          position="bottom"
                          withArrow
                        >
                          <span
                            className="show-more"
                            onClick={() => setOpened((o) => !o)}
                          >
                            More
                          </span>
                        </Tooltip>
                      </>
                    )}

                    {response?.sectionTwoFilters?.keywords?.length > 0 && (
                      <>
                        <span className="text-secondary">Interests:-</span>
                        <Tooltip
                          label={response?.sectionTwoFilters?.keywords?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name">
                                  &nbsp;{element}
                                  {response?.sectionTwoFilters?.keywords
                                    ?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        >
                          <span>
                            {response?.sectionTwoFilters?.keywords?.map(
                              (element: any, index: any) => {
                                return (
                                  <span className="element-name">
                                    &nbsp;{element}
                                    {response?.sectionTwoFilters?.keywords
                                      ?.length -
                                      1 !==
                                    index
                                      ? ", "
                                      : ""}
                                  </span>
                                );
                              }
                            )}
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </>
                </Text>
              </Flex>
            </Chip>
          )}
          {response?.sectionTwoCondition !== "" && (
            <Text className="color-magenta">
              -{response?.sectionTwoCondition}-
            </Text>
          )}
          {response?.sectionTwoCondition !== "" && (
            <>
              {response?.sectionThreeFilters !== null && (
                <Chip
                  className={
                    response?.sectionThreeFilters?.include
                      ? "selected--keywords-section include"
                      : "selected--keywords-section exclude"
                  }
                  defaultChecked={false}
                  checked={false}
                  size="sm"
                  style={{
                    width: "auto",
                    fontSize: "10px",
                  }}
                >
                  <Flex>
                    <Text
                      className={
                        response?.sectionThreeFilters?.include
                          ? "include-exclude-section include"
                          : "include-exclude-section exclude"
                      }
                    >
                      {response?.sectionThreeFilters?.include
                        ? "Include"
                        : "Exclude"}
                    </Text>
                    <Text className="names">
                      <>
                        {response?.sectionThreeFilters?.appCategories?.length >
                          0 && (
                          <>
                            <span className="text-secondary">
                              App Categories:-
                            </span>
                            <Tooltip
                              label={response?.sectionThreeFilters?.appCategories?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name">
                                      &nbsp;{element}
                                      {response?.sectionThreeFilters
                                        ?.appCategories?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            >
                              <span>
                                {response?.sectionThreeFilters?.appCategories?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name">
                                        &nbsp;{element}
                                        {response?.sectionThreeFilters
                                          ?.appCategories?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {response?.sectionThreeFilters?.apps?.length > 0 && (
                          <>
                            <span className="text-secondary">Apps:-</span>
                            <Tooltip
                              label={response?.sectionThreeFilters?.apps?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionThreeFilters?.apps
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            >
                              <span>
                                {response?.sectionThreeFilters?.apps?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionThreeFilters?.apps
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </Tooltip>
                            <Tooltip
                              color="dark"
                              opened={opened}
                              label={
                                <>
                                  <Text color="#ffffff">
                                    <span className="color-magenta">
                                      Apps:-
                                    </span>
                                    {response?.sectionThreeFilters?.apps?.map(
                                      (element: any, index: any) => {
                                        return (
                                          <span className="element-name apps">
                                            &nbsp;{element}
                                            {response?.sectionThreeFilters?.apps
                                              ?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      }
                                    )}
                                  </Text>
                                  <Text color="#ffffff">
                                    <span className="color-magenta">
                                      Usage:-
                                    </span>
                                    {response?.sectionThreeFilters?.usages?.map(
                                      (element: any, index: any) => {
                                        return (
                                          <span className="element-name apps">
                                            &nbsp;{element}
                                            {response?.sectionThreeFilters
                                              ?.usages?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      }
                                    )}
                                  </Text>
                                  <Text color="#ffffff">
                                    <span className="color-magenta">
                                      Day Phase:-
                                    </span>
                                    {response?.sectionThreeFilters?.dayPhases?.map(
                                      (element: any, index: any) => {
                                        return (
                                          <span className="element-name apps">
                                            &nbsp;{element}
                                            {response?.sectionThreeFilters
                                              ?.dayPhases?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      }
                                    )}{" "}
                                  </Text>
                                </>
                              }
                              position="bottom"
                              withArrow
                            >
                              <span
                                className="show-more"
                                onClick={() => setOpened((o) => !o)}
                              >
                                More
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {response?.sectionThreeFilters?.keywords?.length >
                          0 && (
                          <>
                            <span className="text-secondary">Interests:-</span>
                            <Tooltip
                              label={response?.sectionThreeFilters?.keywords?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name">
                                      &nbsp;{element}
                                      {response?.sectionThreeFilters?.keywords
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            >
                              <span>
                                {response?.sectionThreeFilters?.keywords?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name">
                                        &nbsp;{element}
                                        {response?.sectionThreeFilters?.keywords
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </Tooltip>
                          </>
                        )}
                      </>
                    </Text>
                  </Flex>
                </Chip>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  );
};

const AudienceDashboard = () => {
  const dispatch = useDispatch();
  const [opened, handlers] = useDisclosure(false);
  const [openedShowMore, setOpenedShowMore] = useState(false);
  const [selectedAudienceInModal, setSelectedAudienceInModal] = useState<any>(
    {}
  );

  const [openedPopover, setOpenedPopover] = useState(false);

  const [audienceData, setAudienceData] = useState<any>([]);
  const [inCreationsAudienceData, setInCreationsAudienceData] = useState<any>(
    []
  );
  const [errorAudienceData, setErrorAudienceData] = useState<any>([]);

  const [liveAudienceData, setLiveAudienceData] = useState<any>([]);
  const [originalaudienceData, setOriginalAudienceData] = useState<any>([]);
  const [value, setValue] = useState<any>("");
  const [error, setError] = useState<any>(false);
  const [loading, setLoading] = useState<any>(true);
  const [showLiveAudience, setShowLiveAudience] = useState<any>(true);
  const [showInCreationAudience, setShowInCreationAudience] =
    useState<any>(true);
  const [showErrorAudience, setShowErrorAudience] = useState<any>(true);
  const [openpopUp, { open: setOpenPopUp, close: closeOpenPopUp }] =
    useDisclosure(false);

  useEffect(() => {}, []);
  const navigate = useNavigate();
  const [fetchSavedAudiences] = useLazyGetSavedAudiencesQuery();
  const themeProvider = useSelector(selectLightTheme);
  useEffect(() => {
    loadAudiences();
  }, []);

  const [baseReactCount, setBaseReactCount] = useState("");
  const loadAudiences = () => {
    fetchSavedAudiences({ userName: sessionStorage.getItem("username") }).then(
      (res: any) => {
        if (res?.data !== undefined) {
          setOriginalAudienceData(res.data);
          const sum = res.data.reduce(
            (accumulator: any, currentValue: any) =>
              accumulator +
              parseInt(
                currentValue?.baseReach === null ? 0 : currentValue?.baseReach
              ),
            0
          );
          setBaseReactCount(numberFormatter(sum));
          setAudienceData(res.data);
          setInCreationsAudienceData(
            res.data.filter(
              (item: any) =>
                item.status !== "Live" &&
                item.platforms.some((platform: any) => platform.error === null)
            )
          );
          setLiveAudienceData(
            res.data.filter((item: any) => item.status === "Live")
          );
          setErrorAudienceData(
            res.data.filter((item: any) =>
              item.platforms.some((platform: any) => platform.error !== null)
            )
          );
        } else {
          setError(true);
        }
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const updatedResults = audienceData?.filter((element: any) => {
      if (
        element?.audienceName
          ?.toLowerCase()
          .trim()
          .startsWith(value.toLowerCase().trim())
      ) {
        return element;
      } else {
        return;
      }
    });
    setAudienceData(updatedResults);
  }, [value]);

  useEffect(() => {
    dispatch(setAppPrimaryData(null));
    dispatch(setPrimaryAppId(null));
    dispatch(setComparativeAppOneId(null));
    dispatch(setComparativeAppTwoId(null));
    dispatch(setComparativeAppTwoId(null));
    dispatch(setComprativeOneData(null));
    dispatch(setComprativeTwoData(null));
    dispatch(setPrimaryJsonModel(null));
    dispatch(setComprativeJsonModel(null));
    dispatch(setComprativeTwoJsonModel(null));
  }, []);

  const handlePopoverClick = (e: any, item: any) => {
    handlers.open();
    if (e === "edit" || e === "rename") {
      setOpenedPopover(false);
    } else {
      setOpenedShowMore(true);
      setSelectedAudienceInModal(item);
    }
  };

  const audChipChange = (label: any, checked: boolean) => {
    setShowInCreationAudience(true);
    setShowErrorAudience(true);
    setShowLiveAudience(true);
    if (checked) {
      if (label === "Live Audience") {
        //setShowLiveAudience(true);
        setShowErrorAudience(false);
        setShowInCreationAudience(false);
      }
      if (label === "In Creation Audience") {
        //setShowInCreationAudience(true);
        setShowErrorAudience(false);
        setShowLiveAudience(false);
      }
      if (label === "Error Audience") {
        //setShowErrorAudience(true);
        setShowLiveAudience(false);
        setShowInCreationAudience(false);
      }
    }
  };
  const createAudienceHandler = () => {
    setOpenPopUp();
  };

  const closeAudiencePopUp = () => {
    closeOpenPopUp();
  };

  const continuePopUp = () => {
    navigate("/audiences/create");
  };
  return (
    <>
      {sessionStorage.getItem("audienceBuilderTab") == "true" ? (
        <>
          {loading && <AudienceDashboardSkeleton />}
          {!loading && (
            <>
              {openpopUp && (
                <SelectDataSourcePopUp
                  opened={openpopUp}
                  onClose={closeAudiencePopUp}
                  onCancel={closeAudiencePopUp}
                  onContine={continuePopUp}
                />
              )}
              {error ? (
                <Container fluid>
                  <Grid>
                    <Grid.Col>
                      <Text>
                        Oops! Something went wrong. Please try after some time.
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Container>
              ) : originalaudienceData.length === 0 ? (
                <MaContainer
                  handleRoute={() => setOpenPopUp()}
                  overViewImage={overViewImage}
                />
              ) : (
                <>
                  <Drawer
                    size="lg"
                    position="right"
                    opened={openedShowMore}
                    onClose={() => setOpenedShowMore(false)}
                    title={selectedAudienceInModal.audienceName}
                    overlayProps={{ opacity: 0.5, blur: 4 }}
                    className={
                      themeProvider === "light"
                        ? "selected-audience-detail"
                        : "selected-audience-detail dark-theme"
                    }
                  >
                    <Container fluid className="pb-4">
                      <Grid>
                        <Grid.Col className="pb-4">
                          <Flex align="center" className="mt-4">
                            <Text
                              className={
                                selectedAudienceInModal.status === "Live"
                                  ? "pr-4 status-block live"
                                  : "pr-4 status-block calculating"
                              }
                            >
                              <Flex align={"center"}>
                                {selectedAudienceInModal.status === "Live" ? (
                                  <Image
                                    width={20}
                                    src={LiveIcon}
                                    className="mr-1"
                                  />
                                ) : selectedAudienceInModal.status ===
                                  "Calculating" ? (
                                  <Image
                                    width={20}
                                    src={CalculatingIcon}
                                    className="mr-1"
                                  />
                                ) : (
                                  <Image
                                    width={20}
                                    src={TargetingIcon}
                                    className="mr-1"
                                  />
                                )}

                                {selectedAudienceInModal.status}
                              </Flex>
                            </Text>

                            <Divider orientation="vertical" />
                            <Text className="pl-4 creation-date">
                              {selectedAudienceInModal.creationDate}
                            </Text>
                          </Flex>
                        </Grid.Col>
                      </Grid>
                      <Grid className="base-reach-background">
                        {selectedAudienceInModal.filters?.keywords && (
                          <>
                            <Grid.Col className="bg mt-4">
                              <Text className="heading">Keywords</Text>
                            </Grid.Col>
                            <Grid.Col className="bg pt-0 pb-4">
                              <Flex gap="md">
                                {selectedAudienceInModal.filters?.keywords.map(
                                  (keyword: any) => {
                                    return (
                                      <Text
                                        key={keyword.audienceId}
                                        className="tag-text"
                                      >
                                        {keyword}
                                      </Text>
                                    );
                                  }
                                )}
                              </Flex>
                            </Grid.Col>
                          </>
                        )}

                        {(selectedAudienceInModal.filters?.appList.length > 0 ||
                          selectedAudienceInModal.filters?.categoryList.length >
                            0 ||
                          selectedAudienceInModal.filters?.dayPhases.length >
                            0) && (
                          <>
                            <Grid.Col className="bg pb-0 mt-4">
                              <Text className="heading">Filters</Text>
                            </Grid.Col>
                            <Grid.Col className="bg pb-4">
                              <Flex
                                gap="md"
                                justify="flex-start"
                                align="flex-start"
                              >
                                {selectedAudienceInModal.filters?.appList
                                  ?.length > 0 && (
                                  <Text className="tag-text">
                                    <span className="heading">App - </span>
                                    {selectedAudienceInModal.filters?.appList?.join(
                                      ", "
                                    )}
                                  </Text>
                                )}

                                {selectedAudienceInModal.filters?.categoryList
                                  ?.length > 0 && (
                                  <Text className="tag-text">
                                    <span className="heading">Category - </span>
                                    {selectedAudienceInModal.filters?.categoryList?.join(
                                      ", "
                                    )}
                                  </Text>
                                )}
                              </Flex>
                              <Flex
                                gap="md"
                                justify="flex-start"
                                align="flex-start"
                                sx={{ marginTop: "15px" }}
                              >
                                {selectedAudienceInModal.filters?.dayPhases
                                  ?.length > 0 && (
                                  <Text className="tag-text">
                                    <span className="heading">DayPhase - </span>
                                    {selectedAudienceInModal.filters?.dayPhases?.join(
                                      ", "
                                    )}
                                  </Text>
                                )}
                              </Flex>
                            </Grid.Col>
                          </>
                        )}

                        <Grid.Col className="pt-4 pl-0">
                          <Card>
                            <Text className="text-secondary mb-2">
                              Top 5 apps used
                            </Text>
                            <Text className="capitalize mb-4 top-five-data-block">
                              {selectedAudienceInModal?.topAppsMetrics?.map(
                                (element: any) => {
                                  return (
                                    <Chip
                                      defaultChecked={false}
                                      checked={false}
                                      size="sm"
                                      className="chip-top"
                                    >
                                      {element.name}
                                    </Chip>
                                  );
                                }
                              )}
                            </Text>
                          </Card>
                        </Grid.Col>
                        <Grid.Col className="pt-4 pl-0">
                          <Card>
                            <Text className="text-secondary mb-2">
                              Top 5 Category used
                            </Text>
                            <Text className="capitalize top-five-data-block">
                              {selectedAudienceInModal?.topCategoryMetrics?.map(
                                (element: any) => {
                                  return (
                                    <Chip
                                      defaultChecked={false}
                                      checked={false}
                                      size="sm"
                                      className="mb-2"
                                    >
                                      {element.name}
                                    </Chip>
                                  );
                                }
                              )}
                            </Text>
                          </Card>
                        </Grid.Col>
                        {selectedAudienceInModal.topKeywordsMetrics && (
                          <Grid.Col className="pt-4 pl-0">
                            <Card shadow="sm" padding="lg">
                              <Flex style={{ justifyContent: "space-between" }}>
                                <Group spacing={5}>
                                  <Text className="heading-text">Keywords</Text>
                                  <Tooltip
                                    label="Keywords and weightage"
                                    withArrow
                                    openDelay={500}
                                    closeDelay={100}
                                  >
                                    <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
                                  </Tooltip>
                                </Group>

                                {selectedAudienceInModal &&
                                  selectedAudienceInModal?.topKeywordsMetrics && (
                                    <Text>
                                      {
                                        selectedAudienceInModal
                                          ?.topKeywordsMetrics.length
                                      }
                                    </Text>
                                  )}
                              </Flex>
                              {selectedAudienceInModal.topKeywordsMetrics && (
                                <WordCloud
                                  data={
                                    selectedAudienceInModal?.topKeywordsMetrics
                                  }
                                  showKeywords={false}
                                />
                              )}
                            </Card>
                          </Grid.Col>
                        )}

                        <Grid.Col className="pt-4 pl-0">
                          <Text className="mb-3">
                            Selected Criteria with specific reach:-
                          </Text>
                          <LabledButton data={selectedAudienceInModal} />
                        </Grid.Col>
                      </Grid>
                      <Grid>
                        <Grid.Col className="pt-4 pl-0">
                          <Tabs color="#E20074" defaultValue="gallery">
                            <Tabs.List>
                              <Tabs.Tab value="gallery" className="pl-0">
                                Final Reach Per Platform
                              </Tabs.Tab>
                              <Tabs.Tab value="messages" disabled>
                                Audience Insights
                              </Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value="gallery" className="p-4">
                              <Stack className="platform-list">
                                {selectedAudienceInModal?.platforms?.map(
                                  (platform: any) => {
                                    return (
                                      <Flex
                                        justify={"space-between"}
                                        key={platform.platformId}
                                      >
                                        <Text className="pr-1 pb-3 platform-name">
                                          {platform.platformName}
                                        </Text>
                                        {platform.reachForPlatform ? (
                                          <Text className="pr-1">
                                            {platform.reachForPlatform}
                                          </Text>
                                        ) : (
                                          <Text className="pr-1">
                                            Calculating...
                                          </Text>
                                        )}
                                      </Flex>
                                    );
                                  }
                                )}
                              </Stack>
                            </Tabs.Panel>
                            <Tabs.Panel value="messages" className="p-4">
                              Audience Insights
                            </Tabs.Panel>
                          </Tabs>
                        </Grid.Col>
                      </Grid>
                    </Container>
                  </Drawer>
                  <Container fluid className="audience-dashboard">
                    <Grid>
                      <Grid.Col span={12} className="search-row">
                        <Flex justify={"space-between"}>
                          <Flex align={"center"}>
                            <Title order={3}>Audience Dashboard</Title>
                            <Tooltip
                              label="Displays all active audience"
                              withArrow
                              openDelay={500}
                              closeDelay={100}
                            >
                              <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                            </Tooltip>
                          </Flex>
                          <Flex>
                            {/* <AdjustmentsHorizontalIcon className="h-8 w-8 mx-4 filter-icon" /> */}
                            <Button
                              size="sm"
                              radius="sm"
                              className="primary-button"
                              onClick={createAudienceHandler}
                            >
                              <UserPlusIcon className="w-4 h-4 mr-2" />
                              Create New Audience
                            </Button>
                          </Flex>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col>
                        <Grid>
                          <Grid.Col span={3}>
                            <AudienceStatusCard
                              label="Total Users Reached (Base Reach)"
                              value={baseReactCount}
                              disabled
                            >
                              <UserGroupIcon className="h-7 w-7 ml-1" />
                            </AudienceStatusCard>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <AudienceStatusCard
                              label="Live Audience"
                              value={liveAudienceData?.length}
                              chipChange={audChipChange}
                              checked={
                                showErrorAudience || showInCreationAudience
                              }
                            >
                              <SignalIcon
                                className="h-7 w-7 ml-1"
                                color={"#25855A"}
                              />
                            </AudienceStatusCard>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <AudienceStatusCard
                              label="In Creation Audience"
                              value={inCreationsAudienceData?.length}
                              chipChange={audChipChange}
                              checked={showErrorAudience || showLiveAudience}
                            >
                              <SparklesIcon
                                className="h-7 w-7 ml-1"
                                color={"#999999"}
                              />
                            </AudienceStatusCard>
                          </Grid.Col>
                          <Grid.Col span={3}>
                            <AudienceStatusCard
                              label="Error Audience"
                              value={errorAudienceData?.length}
                              chipChange={audChipChange}
                              checked={
                                showLiveAudience || showInCreationAudience
                              }
                            >
                              <ExclamationTriangleIcon
                                className="h-6 w-6 mx-2"
                                color="#e20074"
                              />
                            </AudienceStatusCard>
                          </Grid.Col>
                        </Grid>
                      </Grid.Col>
                    </Grid>
                    {audienceData.length > 0 && (
                      <>
                        <Accordion
                          chevronPosition="left"
                          chevron={<ChevronRightIcon className="h-8 w-8" />}
                          defaultValue={
                            liveAudienceData.length === 0
                              ? "in-creation"
                              : "live"
                          }
                          styles={{
                            chevron: {
                              "&[data-rotate]": {
                                transform: "rotate(90deg)",
                                color: "#e20074",
                              },
                            },
                          }}
                        >
                          {showLiveAudience && liveAudienceData.length > 0 && (
                            <>
                              <Accordion.Item value="live">
                                <AccordionControl className="p-0">
                                  <Grid>
                                    <Grid.Col span={3}>
                                      Live Audience ({liveAudienceData.length})
                                    </Grid.Col>
                                  </Grid>
                                </AccordionControl>
                                <Accordion.Panel>
                                  <Grid>
                                    {liveAudienceData.map((element: any) => {
                                      const chartData = element?.platforms?.map(
                                        (item: any) => {
                                          return item.reachForPlatform;
                                        }
                                      );
                                      const chartLabel =
                                        element?.platforms?.map((item: any) => {
                                          return item.platformName;
                                        });
                                      return (
                                        <Grid.Col
                                          span={4}
                                          key={element.audienceId}
                                        >
                                          <MaCard
                                            handleClick={() =>
                                              handlePopoverClick(
                                                "showMore",
                                                element
                                              )
                                            }
                                            element={element}
                                            reloadAudience={loadAudiences}
                                          >
                                            <ReactEcharts
                                              option={chartOptions(
                                                chartData,
                                                chartLabel
                                              )}
                                              opts={{ renderer: "svg" }}
                                              style={{
                                                width: "auto",
                                                height: "200px",
                                                // background: "#fff",
                                              }}
                                            ></ReactEcharts>
                                          </MaCard>
                                        </Grid.Col>
                                      );
                                    })}
                                  </Grid>
                                </Accordion.Panel>
                              </Accordion.Item>
                            </>
                          )}
                          {showInCreationAudience &&
                            inCreationsAudienceData.length > 0 && (
                              <>
                                <Accordion.Item value="in-creation">
                                  <AccordionControl className="p-0">
                                    <Grid>
                                      <Grid.Col span={3}>
                                        In creation Audience (
                                        {inCreationsAudienceData.length})
                                      </Grid.Col>
                                    </Grid>
                                  </AccordionControl>
                                  <Accordion.Panel>
                                    <Grid>
                                      {inCreationsAudienceData.map(
                                        (element: any) => {
                                          const chartData =
                                            element?.platforms?.map(
                                              (item: any) => {
                                                return item.reachForPlatform;
                                              }
                                            );
                                          const chartLabel =
                                            element?.platforms?.map(
                                              (item: any) => {
                                                return item.platformName;
                                              }
                                            );
                                          return (
                                            <Grid.Col
                                              span={4}
                                              key={element.audienceId}
                                            >
                                              <MaCard
                                                handleClick={() =>
                                                  handlePopoverClick(
                                                    "showMore",
                                                    element
                                                  )
                                                }
                                                element={element}
                                                reloadAudience={loadAudiences}
                                              >
                                                <Stack className="platform-list">
                                                  {element?.platforms?.map(
                                                    (platform: any) => {
                                                      return (
                                                        <Flex
                                                          justify={
                                                            "space-between"
                                                          }
                                                          key={
                                                            platform.platformId
                                                          }
                                                        >
                                                          <Text className="pr-1 pb-3 platform-name">
                                                            {
                                                              platform.platformName
                                                            }
                                                          </Text>

                                                          <Text className="pr-1">
                                                            Calculating...
                                                          </Text>
                                                        </Flex>
                                                      );
                                                    }
                                                  )}
                                                </Stack>
                                              </MaCard>
                                            </Grid.Col>
                                          );
                                        }
                                      )}
                                    </Grid>
                                  </Accordion.Panel>
                                </Accordion.Item>
                              </>
                            )}
                          {showErrorAudience &&
                            errorAudienceData.length > 0 && (
                              <>
                                <Accordion.Item value="error">
                                  <AccordionControl className="p-0">
                                    <Grid>
                                      <Grid.Col span={3}>
                                        Error Audience (
                                        {errorAudienceData.length})
                                      </Grid.Col>
                                    </Grid>
                                  </AccordionControl>
                                  <Accordion.Panel>
                                    <Grid>
                                      {errorAudienceData.map((element: any) => {
                                        const chartData =
                                          element?.platforms?.map(
                                            (item: any) => {
                                              return item.reachForPlatform;
                                            }
                                          );
                                        const chartLabel =
                                          element?.platforms?.map(
                                            (item: any) => {
                                              return item.platformName;
                                            }
                                          );
                                        return (
                                          <Grid.Col
                                            span={4}
                                            key={element.audienceId}
                                          >
                                            <MaCard
                                              handleClick={() =>
                                                handlePopoverClick(
                                                  "showMore",
                                                  element
                                                )
                                              }
                                              element={element}
                                              reloadAudience={loadAudiences}
                                            >
                                              <Stack className="platform-list">
                                                {element?.platforms?.map(
                                                  (platform: any) => {
                                                    return (
                                                      <Flex
                                                        justify={
                                                          "space-between"
                                                        }
                                                        key={
                                                          platform.platformId
                                                        }
                                                      >
                                                        <Text className="pr-1 pb-3 platform-name">
                                                          {
                                                            platform.platformName
                                                          }
                                                        </Text>

                                                        <Text className="pr-1">
                                                          Calculating...
                                                        </Text>
                                                      </Flex>
                                                    );
                                                  }
                                                )}
                                              </Stack>
                                            </MaCard>
                                          </Grid.Col>
                                        );
                                      })}
                                    </Grid>
                                  </Accordion.Panel>
                                </Accordion.Item>
                              </>
                            )}
                        </Accordion>
                      </>
                    )}
                    {audienceData.length === 0 && (
                      <Grid>
                        <Grid.Col>
                          <Text>
                            No Records found. Please try with different
                            keywords.
                          </Text>
                        </Grid.Col>
                      </Grid>
                    )}
                  </Container>
                </>
              )}
            </>
          )}
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

export default Hoc(AudienceDashboard);
