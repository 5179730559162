import { FC, useEffect, useState } from "react";
import { Grid, Text, Tooltip, Flex, SimpleGrid } from "@mantine/core";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ReactEcharts from "echarts-for-react";
import { TwinAppProps } from "../../types/twinAppReport.types";
import { selectInitialAppList } from "@/features/services/search/searchAppSlice";
import { useSelector } from "react-redux";
import "./TwinAppReport.scss";

const TwinAppReport: FC<TwinAppProps> = ({
  twinAppsData,
  selectedPrimaryApp,
  comparativeAppOne,
  comparativeAppTwo,
  selectedFilter,
}) => {
  const initialAppList = useSelector(selectInitialAppList);
  const [twinApp, setTwinApp] = useState<any>([]);
  const [xAxisLabel, setLabel] = useState<string[] | undefined>();
  const reportData = (): any => {
    const twinApp: any[] = [];
    const xAxisLabels: string[] = [];
    twinApp.push([
      "",
      selectedPrimaryApp,
      comparativeAppOne,
      comparativeAppTwo,
    ]);
    for (let x in twinAppsData) {
      let xAxisLabel = twinAppsData[x].appName;
      let primaryApp = twinAppsData[x].appPercent;
      let competitiveApp1Percent = twinAppsData[x].competitiveApp1Percent;
      let competitiveApp2Percent = twinAppsData[x].competitiveApp2Percent;
      let primaryAppValue = twinAppsData[x].appOneAbsoluteValue;
      let comparativeAppOneValue = twinAppsData[x].competitiveApp1AbsoluteValue;
      let comparativeAppTwoValue = twinAppsData[x].competitiveApp2AbsoluteValue;
      xAxisLabels.push(xAxisLabel);
      twinApp.push([
        xAxisLabel,
        primaryApp,
        competitiveApp1Percent,
        competitiveApp2Percent,
        { primaryAppValue, comparativeAppOneValue, comparativeAppTwoValue },
      ]);
    }
    setLabel(xAxisLabels);
    return twinApp;
  };

  useEffect(() => {
    setTwinApp(reportData());
  }, [setTwinApp, twinAppsData]);

  let appData: any[] = [];
  xAxisLabel?.forEach((item) => {
    appData.push({
      name: item,
    });
  });

  const options = {
    legend: {
      show: false,
      orient: "horizontal",
      right: "1%",
      top: "0",
    },
    grid: {
      top: 40,
      left: 0,
      bottom: 10,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisLabel !== undefined ? [...xAxisLabel] : [],
      axisLabel: {
        interval: 0,
        formatter: function (value: any) {
          return (
            "{" +
            value?.replace(/ +/g, "") +
            "| }{value|" +
            value?.replace(/ +/g, "") +
            "}"
          );
        },
        rich: {
          value: { lineHeight: 30, padding: 10, align: "center" },
          [appData[0]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[0]?.name;
              })?.appicon,
            },
          },
          [appData[1]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[1]?.name;
              })?.appicon,
            },
          },
          [appData[2]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[2]?.name;
              })?.appicon,
            },
          },
          [appData[3]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[3]?.name;
              })?.appicon,
            },
          },
          [appData[4]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[4]?.name;
              })?.appicon,
            },
          },
          [appData[5]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[5]?.name;
              })?.appicon,
            },
          },
          [appData[6]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[6]?.name;
              })?.appicon,
            },
          },
          [appData[7]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[7]?.name;
              })?.appicon,
            },
          },
          [appData[8]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[8]?.name;
              })?.appicon,
            },
          },
          [appData[9]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[9]?.name;
              })?.appicon,
            },
          },
        },
        fontSize: "small",
      },
    },
    yAxis: {
      type: "value",
    },
    dataset: {
      source: [...twinApp],
    },
    series: [
      {
        type: "bar",
        color: ["#2C7A7B"],
        barWidth: 15,
      },
      {
        type: "bar",
        color: ["#6B46C1"],
        barWidth: 15,
      },
      {
        type: "bar",
        color: ["#C05621"],
        barWidth: 15,
      },
    ],
    tooltip: {
      show: true,
      trigger: "axis",
      formatter: (params: any) => {
        if (
          params[0].data[1] > 0 &&
          params[0].data[2] > 0 &&
          params[0].data[3] > 0
        ) {
          return `<div style="width: auto; height: auto; background:#fff; ">
              <div style="display: flex; justify-content: space-between; align-items:center">
                <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
                <span style="width : 120px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
                <style="width : 50px;display:inline-block;text-align:right">(${
                  Math.round(params[0].data[1]) + "%"
                })</span>
              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#6B46C1;"></span>
                <span style="width : 120px;display:inline-block">${comparativeAppOne}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppOneValue
                }</span>
                  <style="width : 50px;display:inline-block;text-align:right">(${
                    Math.round(params[0].data[2]) + "%"
                  })</span>

              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#C05621;"></span>
                <span style="width : 120px;display:inline-block">${comparativeAppTwo}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppTwoValue
                }</span>
                  <style="width : 50px;display:inline-block;text-align:right">(${
                    Math.round(params[0].data[3]) + "%"
                  })</span>
              </div>
            </div>`;
        } else if (params[0].data[1] > 0 && params[0].data[2] > 0) {
          return `
            <div style="width: auto; height: auto; background:#fff; ">
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
                <span style="width : 80px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
                <style="width : 50px;display:inline-block;text-align:left">(${
                  Math.round(params[0].data[1]) + "%"
                })</span>

              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#6B46C1;"></span>
                <span style="width : 80px;display:inline-block">${comparativeAppOne}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppOneValue
                }</span>
                <style="width : 50px;display:inline-block;text-align:left">(${
                  Math.round(params[0].data[2]) + "%"
                })</span>

              </div>
            </div>`;
        } else if (
          params[0].data[1] > 0 &&
          params[0].data[2] === 0 &&
          params[0].data[3] > 0
        ) {
          return `
            <div class="twin-app-tooltip" style="width: auto; height: auto; background:#fff; ">
              <div style="display: flex; justify-content: space-between; align-items:center">
                  <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
                <span style="width : 80px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
                <span style="width : 50px;display:inline-block;text-align:left">(${
                  Math.round(params[0].data[1]) + "%"
                })</span>

              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#FDAD65;"></span>
                <span style="width : 80px;display:inline-block">${comparativeAppTwo}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppTwoValue
                }</span>
                  <span style="width : 50px;display:inline-block;text-align:left">(${
                    Math.round(params[0].data[3]) + "%"
                  })</span>
              </div>
            </div>`;
        } else if (params[0].data[1] > 0) {
          return `<div style="width: auto; height: auto; background:#fff; display: flex; justify-content: space-between; align-items: center">
             <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
            <span style="width : 80px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
            <style="width : 50px;display:inline-block;text-align:left">(${
              Math.round(params[0].data[1]) + "%"
            })</span>

          </div>`;
        }
      },
    },
  };
  const categoryOptions = {
    legend: {
      show: false,
    },
    grid: {
      top: 10,
      left: 0,
      bottom: 10,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisLabel !== undefined ? [...xAxisLabel] : [],
      axisLabel: {
        interval: 0,
        formatter: function (value: any) {
          return value;
        },
        fontSize: "small",
        width: 100,
        overflow: "break",
      },
    },
    yAxis: {
      type: "value",
    },
    dataset: {
      source: [...twinApp],
    },
    series: [
      {
        type: "bar",
        color: ["#2C7A7B"],
        barWidth: 15,
      },
      {
        type: "bar",
        color: ["#6B46C1"],
        barWidth: 15,
      },
      {
        type: "bar",
        color: ["#C05621"],
        barWidth: 15,
      },
    ],
    tooltip: {
      show: true,
      trigger: "axis",
      formatter: (params: any) => {
        if (
          params[0].data[1] > 0 &&
          params[0].data[2] > 0 &&
          params[0].data[3] > 0
        ) {
          return `<div style="width: auto; height: auto; background:#fff; ">
              <div style="display: flex; justify-content: space-between; align-items:center">
                <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
                <span style="width : 120px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
                <style="width : 50px;display:inline-block;text-align:right">(${
                  Math.round(params[0].data[1]) + "%"
                })</span>
              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#6B46C1;"></span>
                <span style="width : 120px;display:inline-block">${comparativeAppOne}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppOneValue
                }</span>
                  <style="width : 50px;display:inline-block;text-align:right">(${
                    Math.round(params[0].data[2]) + "%"
                  })</span>

              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#C05621;"></span>
                <span style="width : 120px;display:inline-block">${comparativeAppTwo}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppTwoValue
                }</span>
                  <style="width : 50px;display:inline-block;text-align:right">(${
                    Math.round(params[0].data[3]) + "%"
                  })</span>
              </div>
            </div>`;
        } else if (params[0].data[1] > 0 && params[0].data[2] > 0) {
          return `
            <div style="width: auto; height: auto; background:#fff; ">
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
                <span style="width : 80px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
                <style="width : 50px;display:inline-block;text-align:left">(${
                  Math.round(params[0].data[1]) + "%"
                })</span>

              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                <div style="margin-right : 15px"> <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#6B46C1;"></span>
                <span style="width : 80px;display:inline-block">${comparativeAppOne}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppOneValue
                }</span>
                <style="width : 50px;display:inline-block;text-align:left">(${
                  Math.round(params[0].data[2]) + "%"
                })</span>

              </div>
            </div>`;
        } else if (
          params[0].data[1] > 0 &&
          params[0].data[2] === 0 &&
          params[0].data[3] > 0
        ) {
          return `
            <div class="twin-app-tooltip" style="width: auto; height: auto; background:#fff; ">
              <div style="display: flex; justify-content: space-between; align-items:center">
                  <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
                <span style="width : 80px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
                <span style="width : 50px;display:inline-block;text-align:left">(${
                  Math.round(params[0].data[1]) + "%"
                })</span>

              </div>
              <div style="display: flex; justify-content: space-between; align-items:center">
                 <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#FDAD65;"></span>
                <span style="width : 80px;display:inline-block">${comparativeAppTwo}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].comparativeAppTwoValue
                }</span>
                  <span style="width : 50px;display:inline-block;text-align:left">(${
                    Math.round(params[0].data[3]) + "%"
                  })</span>
              </div>
            </div>`;
        } else if (params[0].data[1] > 0) {
          return `<div style="width: auto; height: auto; background:#fff; display: flex; justify-content: space-between; align-items: center">
             <div style="margin-right : 15px"><span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:#2C7A7B;"></span>
            <span style="width : 80px;display:inline-block">${selectedPrimaryApp}</span></div>
                <span style="margin-right : 5px;text-align:right;display:inline-block;width:60px">${
                  params[0].data[4].primaryAppValue
                }</span>
            <style="width : 50px;display:inline-block;text-align:left">(${
              Math.round(params[0].data[1]) + "%"
            })</span>

          </div>`;
        }
      },
    },
  };

  const [primaryAppColor, setPrimaryAppColor] = useState("white");
  const [comprativeAppOneColor, setcomprativeAppOneColor] = useState("white");
  const [comprativeAppTwoColor, setcomprativeAppTwoColor] = useState("white");

  useEffect(() => {
    if (selectedPrimaryApp) {
      setPrimaryAppColor("#2C7A7B");
    }
    if (comparativeAppOne) {
      setcomprativeAppOneColor("#6B46C1");
    }
    if (comparativeAppTwo) {
      setcomprativeAppTwoColor("#C05621");
    }
  }, [selectedPrimaryApp, comparativeAppOne, comparativeAppTwo]);
  return (
    <Grid className="ma-twin-app p-4">
      <Grid.Col span={12}>
        <Flex
          justify="space-between"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          <SimpleGrid spacing="xs" cols={2}>
            <Text fz="sm" fw={500}>
              Twin App - Report
            </Text>
            <Tooltip
              label="Shows the percentage of overlapping of users from primary app, that are using other apps"
              withArrow
              openDelay={500}
              closeDelay={100}
            >
              <InformationCircleIcon className="h-6 w-6 cursor-pointer" />
            </Tooltip>
          </SimpleGrid>
        </Flex>
      </Grid.Col>
      <Grid.Col span={12} pt={0}>
        <Text fz="xs" color="dimmed">
          Showing results for top 10 apps used by {selectedPrimaryApp} users
        </Text>
      </Grid.Col>
      <Grid.Col span={12}>
        {twinAppsData !== undefined && twinAppsData.length > 0 ? (
          <>
            <Flex justify={"flex-end"} align={"center"}>
              {selectedPrimaryApp && (
                <Text
                  fz="xs"
                  style={{ display: "flex", alignItems: "center" }}
                  className="mr-3"
                >
                  <span
                    style={{
                      width: "15px",
                      height: "15px",
                      background: primaryAppColor,
                      display: "inline-block",
                    }}
                  ></span>
                  &nbsp;
                  {selectedPrimaryApp}
                </Text>
              )}
              {comparativeAppOne && (
                <Text
                  fz="xs"
                  style={{ display: "flex", alignItems: "center" }}
                  className="mr-3"
                >
                  <span
                    style={{
                      width: "15px",
                      height: "15px",
                      background: comprativeAppOneColor,
                      display: "inline-block",
                    }}
                  ></span>
                  &nbsp;
                  {comparativeAppOne}
                </Text>
              )}
              {comparativeAppTwo && (
                <Text fz="xs" style={{ display: "flex", alignItems: "center" }}>
                  <span
                    style={{
                      width: "15px",
                      height: "15px",
                      background: comprativeAppTwoColor,
                      display: "inline-block",
                    }}
                  ></span>
                  &nbsp;
                  {comparativeAppTwo}
                </Text>
              )}
            </Flex>
            {selectedFilter === "apps" && (
              <ReactEcharts
                option={options}
                opts={{ renderer: "svg" }}
                style={{
                  width: "auto",
                  height: "350px",
                  // background: "#fff",
                  paddingTop: "20px",
                  margin: "0 16px 16px 16px",
                }}
              ></ReactEcharts>
            )}
            {selectedFilter !== "apps" && (
              <ReactEcharts
                option={categoryOptions}
                opts={{ renderer: "svg" }}
                style={{
                  width: "auto",
                  height: "350px",
                  // background: "#fff",
                  paddingTop: "20px",
                  margin: "0 16px 16px 16px",
                }}
              ></ReactEcharts>
            )}
          </>
        ) : (
          <Text ta="center" fz="sm">
            No data found for selected Dates
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};

export default TwinAppReport;
