import { Card, Grid, Group, Text } from "@mantine/core";
import ProgressBarComponent from "@/features/common/ProgressBarComponent/ProgressBarComponent";
import {
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectedPrimaryApp,
} from "@/features/services/overView/overViewSlice";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { selectLabel } from "@/features/services/dateRange/dateRangeSlice";

export default function AgeDistributionCard(props: any) {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);
  const [smColumnSize, setSmColumnSize] = useState(0);
  const [mdColumnSize, setMdColumnSize] = useState(0);
  const [appCount, setAppCount] = useState(1);
  const label = useSelector(selectLabel);
  const subHeader =
    label === "lastWeek" || label === "LastMonth" ? "Userwise" : "Eventwise";

  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
      setAppCount(3);
    } else if (
      primaryApp !== null &&
      (comprativeAppOne !== null || comprativeAppTwo !== null)
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
      setAppCount(2);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(4);
      setSmColumnSize(4);
      setMdColumnSize(4);
      setAppCount(1);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <Grid.Col md={mdColumnSize} lg={column} sm={smColumnSize}>
      <Card
        withBorder
        shadow="sm"
        radius="md"
        className={
          props.appProfileData?.insights?.age === null
            ? "chart-card disabled h-full"
            : "chart-card h-full "
        }
        style={{ minHeight: "240px" }}
      >
        <Card.Section inheritPadding py="xs" className="card-header">
          <Group position="apart">
            <Text size="sm" style={{ fontSize: "14px", fontWeight: "500" }}>
              Age Distribution{" "}
              <span style={{ color: "#6E7B91" }}> ({subHeader}) </span>
            </Text>
          </Group>
        </Card.Section>
        <Card.Section ml={0} mr={0} mt={10}>
          {props.appProfileData?.insights?.age === null && (
            <Text className="no-data-text">No Data Available</Text>
          )}
          {props.ageDistributionData?.length > 0 &&
            props.ageDistributionData.map((item: any, index: number) => {
              return (
                <Grid key={index} gutterSm={10} align="center">
                  <Grid.Col
                    md={appCount === 1 || appCount === 2 ? 3 : 4}
                    lg={3}
                    sm={appCount === 1 || appCount === 2 ? 4 : 12}
                  >
                    <Text>{item.name}</Text>
                  </Grid.Col>
                  <Grid.Col
                    md={appCount === 1 || appCount === 2 ? 6 : 5}
                    lg={7}
                    sm={appCount === 1 || appCount === 2 ? 4 : 7}
                  >
                    <ProgressBarComponent
                      Barsize="lg"
                      color={props.color}
                      value={Math.round(item?.percent)}
                      barType={undefined}
                      colorArr={[]}
                      valueArr={[]}
                      tooltipArr={[]}
                      sections={undefined}
                      isPadding={false}
                    />
                  </Grid.Col>
                  <Grid.Col
                    md={appCount === 1 || appCount === 2 ? 3 : "content"}
                    lg={2}
                    sm={appCount === 1 || appCount === 2 ? 4 : "content"}
                  >
                    <Text>{item.percent} %</Text>
                  </Grid.Col>
                </Grid>
              );
            })}
        </Card.Section>
      </Card>
    </Grid.Col>
  );
}
