import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Flex, Text, Select, SimpleGrid } from "@mantine/core";
import "./CrossBrowsing.scss";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  selectPrimaryJsonModel,
  selectComprativeJsonModel,
  selectComprativeTwoJsonModel,
  selectDate,
} from "@/features/services/overView/overViewSlice";
import {
  crossBrowsingData,
  setCrossBrowsingData,
  setCrossBrowsingAppFilter,
  setCrossBrowsingCategoryFilter,
  setIsSelectedCategory,
} from "@/features/services/crossBrowsing/crossBrowsingSlice";
import TwinAppReport from "@/containers/twinAppReport/TwinAppReport";
import OverIndexingReport from "./OverIndexing/OverIndexingReport";
import { selectInitialAppList } from "@/features/services/search/searchAppSlice";
import {
  twinAppData,
  setTwinAppData,
} from "@/features/services/crossBrowsing/twinAppSlice";
import {
  useLazyGetOverIndexQuery,
  useLazyGetTwinAppQuery,
} from "@/features/services/crossBrowsing/crossBrowsingApiSlice";
import { setError } from "../../../features/services/errorSlice";
import { selectAllCategories } from "@/features/services/trendingApps/trendingAppSlice";
import { generateErrorMessage } from "../../../Util/utils";
import {
  selectDateRange,
  selectLabel,
} from "@/features/services/dateRange/dateRangeSlice";

const CrossBrowsing = () => {
  const [showByFilter, setShowByFilter] = useState<string | null>(null),
    [appFilterApp, setAppFilterApp] = useState<string | null>(null),
    [appfilterCategory, setAppFilterCategory] = useState<string | null>(null);
  const primaryAppJsonModel = useSelector(selectPrimaryJsonModel);
  const comprativeAppJsonModel = useSelector(selectComprativeJsonModel);
  const comprativeAppTwoJsonModel = useSelector(selectComprativeTwoJsonModel);
  const crossBrowsingInsightData = useSelector(crossBrowsingData);
  const twinAppInsightData = useSelector(twinAppData);
  const categories = useSelector(selectAllCategories);
  const allAppLists = useSelector(selectInitialAppList);
  const [selectUpload, setSelectUpload] = useState("Overall");
  const [searchableAppId, setSearchableAppId] = useState<any | number>(-1);
  const [fetchCrossBrowsingData] = useLazyGetOverIndexQuery();
  const [appList, setAppList] = useState([]);
  const dates = useSelector(selectLabel);
  const dateRange = useSelector(selectDateRange);
  const [
    fetchTwinAppData,
    { isError: twinappDatayErr, error: twinAppDataErrMsg },
  ] = useLazyGetTwinAppQuery();
  const dispatch = useDispatch();
  const handleClick = (filter: any, filterType: any, element: any) => {
    setShowByFilter(filter);
    if (filter === "apps") {
      const appId = allAppLists?.filter((item: any) => item.value === element);
      if (filterType === "app") {
        setAppFilterApp(element);
        setAppFilterCategory("");
        dispatch(setCrossBrowsingCategoryFilter(""));
        dispatch(setCrossBrowsingAppFilter(element));
        dispatch(setIsSelectedCategory(false));
      } else {
        setAppFilterApp("");
        setAppFilterCategory(element);
        dispatch(setCrossBrowsingCategoryFilter(element));
        dispatch(setCrossBrowsingAppFilter(""));
        dispatch(setIsSelectedCategory(false));
      }
      setSearchableAppId(
        filterType === "app" ? (appId[0]?.id ? appId[0]?.id : -1) : -1,
      );
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: false,
          searchableAppName:
            filterType === "app" ? (element ? element : "") : "",
          categoryName:
            filterType === "category" ? (element ? element : "") : "",
          searchableAppId:
            filterType === "app" ? (appId[0]?.id ? appId[0]?.id : -1) : -1,
        },
      })
        .then((res: any) => {
          if (res?.data !== undefined) {
            dispatch(
              setCrossBrowsingData({
                overIndexPriAppsData: [],
                overIndexComOneAppsData: [],
                overIndexComTwoAppsData: [],
              }),
            );
            dispatch(setCrossBrowsingData(res.data));
          }
        })
        .catch((e: any) => console.log(e));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: false,
          searchableAppName:
            filterType === "app" ? (element ? element : "") : "",
          categoryName:
            filterType === "category" ? (element ? element : "") : "",
          searchableAppId:
            filterType === "app" ? (appId[0]?.id ? appId[0]?.id : -1) : -1,
        },
      })
        .then((res: any) => {
          if (res?.data !== undefined) {
            dispatch(setTwinAppData(res.data));
          }
        })
        .catch((e: any) => console.log(e));
    } else {
      setSearchableAppId(-1);
      dispatch(setCrossBrowsingAppFilter(""));
      dispatch(setIsSelectedCategory(true));
      fetchCrossBrowsingData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: true,
          searchableAppName: "",
          categoryName:
            filterType == "category" ? (element ? element : "") : "",
          searchableAppId: -1,
        },
      })
        .then((res: any) => {
          if (res?.data !== undefined) {
            dispatch(
              setCrossBrowsingData({
                overIndexPriAppsData: [],
                overIndexComOneAppsData: [],
                overIndexComTwoAppsData: [],
              }),
            );
            dispatch(setCrossBrowsingData(res.data));
          }
        })
        .catch((e: any) => console.log(e));
      fetchTwinAppData({
        appQueryParam: {
          primaryAppId: primaryAppJsonModel?.appId,
          competitiveAppOneId: comprativeAppJsonModel?.appId
            ? comprativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comprativeAppTwoJsonModel?.appId
            ? comprativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          isCategory: true,
          searchableAppName: "",
          categoryName:
            filterType == "category" ? (element ? element : "") : "",
          searchableAppId: -1,
        },
      })
        .then((res: any) => {
          if (res?.data !== undefined) {
            dispatch(setTwinAppData(res.data));
          }
        })
        .catch((e: any) => console.log(e));
      dispatch(setCrossBrowsingCategoryFilter(element));
    }
  };
  useEffect(() => {
    setAppFilterApp(crossBrowsingInsightData.selectedAppInFilter);
    setAppFilterCategory(crossBrowsingInsightData.selectedCategoryInFilter);
  }, [crossBrowsingInsightData]);
  useEffect(() => {
    setShowByFilter("apps");
  }, []);

  useEffect(() => {
    const newList = allAppLists.filter((item: any) => {
      if (
        comprativeAppJsonModel &&
        comprativeAppTwoJsonModel &&
        primaryAppJsonModel
      ) {
        return (
          item.value !== primaryAppJsonModel?.appName &&
          item.value !== comprativeAppJsonModel?.appName &&
          item.value !== comprativeAppTwoJsonModel?.appName
        );
      } else if (primaryAppJsonModel && comprativeAppJsonModel) {
        return (
          item.value !== primaryAppJsonModel?.appName &&
          item.value !== comprativeAppJsonModel?.appName
        );
      } else if (primaryAppJsonModel && comprativeAppTwoJsonModel) {
        return (
          item.value !== primaryAppJsonModel?.appName &&
          item.value !== comprativeAppTwoJsonModel?.appName
        );
      } else if (primaryAppJsonModel) {
        return item.value !== primaryAppJsonModel?.appName;
      }
    });
    setAppList(newList);
  }, [
    primaryAppJsonModel,
    comprativeAppJsonModel,
    comprativeAppTwoJsonModel,
    allAppLists,
  ]);

  useEffect(() => {
    if (twinAppDataErrMsg) {
      const message = generateErrorMessage(twinAppDataErrMsg);
      dispatch(setError(message));
    }
  }, [twinappDatayErr]);

  return (
    <Grid className="cross-browsing">
      <Grid.Col span={4}>
        <Text fz="md">Audience Interest</Text>
        <Text fz="xs" className="text-secondary">
          Explore your target audience’s interests based on their browsing
          activities on different platforms.
        </Text>
      </Grid.Col>
      <Grid.Col span={8} className="text-right">
        <Flex align="center" justify="flex-end" className="show-by">
          <Text className="mr-0 text-secondary" fz="sm">
            Show by :
          </Text>
          <Select
            rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
            rightSectionWidth={60}
            styles={{ rightSection: { pointerEvents: "none" } }}
            data={[
              { value: "apps", label: "Apps" },
              { value: "category", label: "Category" },
            ]}
            value={showByFilter}
            onChange={(e) => handleClick(e, "", "")}
            className={categories ? "mx-2 select-width" : "mx-0 select-width"}
          />
          {showByFilter === "apps" ? (
            <SimpleGrid cols={2} className="ml-2">
              {appList && appList.length > 0 && (
                <Select
                  placeholder="Select App"
                  rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={appList}
                  onChange={(e) => handleClick("apps", "app", e)}
                  searchable
                  value={appFilterApp}
                  allowDeselect
                  disabled={appfilterCategory ? true : false}
                />
              )}
              {categories && (
                <Select
                  placeholder="Select Category"
                  rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
                  rightSectionWidth={30}
                  styles={{ rightSection: { pointerEvents: "none" } }}
                  data={categories}
                  onChange={(e) => handleClick("apps", "category", e)}
                  searchable
                  value={appfilterCategory}
                  allowDeselect
                  disabled={appFilterApp ? true : false}
                  className={
                    !categories ? "mx-2 select-width" : "mx-0 select-width"
                  }
                  clearable={false}
                />
              )}
            </SimpleGrid>
          ) : (
            <Select
              placeholder="Select Category"
              rightSection={<ChevronDownIcon className="h-4 w-4 ml-2" />}
              rightSectionWidth={30}
              styles={{ rightSection: { pointerEvents: "none" } }}
              data={categories}
              onChange={(e) => handleClick("category", "category", e)}
              searchable
              value={appfilterCategory}
              allowDeselect
            />
          )}
        </Flex>
      </Grid.Col>
      <Grid.Col span={12}>
        <TwinAppReport
          twinAppsData={twinAppInsightData?.twinAppData}
          selectedPrimaryApp={primaryAppJsonModel?.appName}
          comparativeAppOne={comprativeAppJsonModel?.appName}
          comparativeAppTwo={comprativeAppTwoJsonModel?.appName}
          selectedFilter={showByFilter}
        ></TwinAppReport>
      </Grid.Col>
      <Grid.Col span={12}>
        <OverIndexingReport
          crossBrowsingData={crossBrowsingInsightData}
          selectedPrimaryApp={primaryAppJsonModel}
          comparativeAppOne={comprativeAppJsonModel}
          comparativeAppTwo={comprativeAppTwoJsonModel}
          onchangeHandle={(e: string) => setSelectUpload(e)}
          selectUpload={selectUpload}
          searchableAppId={searchableAppId}
        ></OverIndexingReport>
      </Grid.Col>
    </Grid>
  );
};
export default CrossBrowsing;
