import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { apiSlice } from "../services/api/apiSlice";
import { personaApiSlice } from "../services/api/personaApiSlice";
import authReducer from "../services/authSlice";
import overViewSliceReducer from "../services/overView/overViewSlice";
import searchAppSliceReducer from "../services/search/searchAppSlice";
import trendingAppReducer from "../services/trendingApps/trendingAppSlice";
import userActivityReducer from "../services/userActivity/userActivitySlice";
import crossBrowsingReducer from "../services/crossBrowsing/crossBrowsingSlice";
import notificatonsReducer from "../services/errorSlice";
import twinAppReducer from "../services/crossBrowsing/twinAppSlice";
import dateRangeSlice from "../services/dateRange/dateRangeSlice";
import themeReducer from "../services/themes/themeSlice";
import audienceBuilderReducer from "../services/audienceBuilder/audienceBuilderSlice";
import realmRolesAppReducer from '../services/realmRoles/realmRoles';

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined,
) =>
  configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      [personaApiSlice.reducerPath]: personaApiSlice.reducer,
      auth: authReducer,
      overview: overViewSliceReducer,
      search: searchAppSliceReducer,
      trending: trendingAppReducer,
      useractivity: userActivityReducer,
      audienceBuilder: audienceBuilderReducer,
      crossBrowsingData: crossBrowsingReducer,
      notification: notificatonsReducer,
      twinAppData: twinAppReducer,
      theme: themeReducer,
      date: dateRangeSlice,
      realms: realmRolesAppReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      })
        .concat(apiSlice.middleware)
        .concat(personaApiSlice.middleware),
    devTools: process.env.NODE_ENV === "development" ? true : false,
    ...options,
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
