import { Card, Grid, Text, Chip, Group, Box } from "@mantine/core";

export default function AudienceStatusCard(props: any) {
  return (
    <Card shadow={"none"} p={0} sx={{ border: "1px solid #ccc" }}>
      <Chip
        radius={0}
        className={
          props.disabled
            ? "audience-info-chip defaultCard"
            : "audience-info-chip"
        }
        onChange={(changed) =>
          props.value !== 0 && props.chipChange(props.label, changed)
        }
        checked={!props.checked}
      >
        <Group>
          <Box>{props.children}</Box>
          <Box>
            <Text size="sm" className="text-secondary">
              {props.label}
            </Text>
            <Text size="xl">{props.value}</Text>
          </Box>
        </Group>
        {/*  <Grid align="center">
          <Grid.Col span={2}>
            {props.children}
            {/* <UserGroupIcon className="h-7 w-7 ml-1" /> 
          </Grid.Col>
          <Grid.Col span={10}>
            <Text size="sm" className="text-secondary">
              {props.label}
            </Text>
            <Text size="xl">{props.value}</Text>
          </Grid.Col>
        </Grid> */}
      </Chip>
    </Card>
  );
}
