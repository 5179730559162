import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
    relms: any;
}

const initialState: InitialState = {
  relms: []
};

const realmRolesAppSlice = createSlice({
  name: "realms",
  initialState: { ...initialState },
 reducers: {
    getrealms: (state) => {
      const dataSource = sessionStorage.getItem("data-source");
      state.relms = dataSource;
    },
  }
});

export const {getrealms} =  realmRolesAppSlice.actions;

export default realmRolesAppSlice.reducer;

export const selectRealms = (state: any) => state.search.relms;

