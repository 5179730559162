import axios from "axios";
import CryptoJS from "crypto-js";
const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";

const login = (
  userName: string,
  password: string,
  callback: any,
  errCallback: any,
) => {
  const cryticPassword = CryptoJS.SHA256(password).toString();
  axios
    .post(
      `${BASE_URL}/keycloak/authenticateAndAuthorizeUser`,
      {
        username: userName,
        credentials: [
          {
            value: cryticPassword,
          },
        ],
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      if (response.data.accessToken && !response.data.error) {
        callback(response);
        response?.data?.realmRoles.includes("MA2-ADMIN")
          ? sessionStorage.setItem("admin", "true")
          : "";
      }
    })
    .catch((err) => {
      errCallback(err);
    });
};

export default login;
