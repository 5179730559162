import {
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectedPrimaryApp,
} from "@/features/services/overView/overViewSlice";
import {
  Box,
  Flex,
  Grid,
  Image,
  Title,
  Text,
  Card,
  Group,
  Rating,
  Stack,
  Modal,
  MediaQuery,
  Divider,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { Interweave } from "interweave";
import { UrlMatcher, HashtagMatcher } from "interweave-autolink";
import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import "./AppDescriptionCard.scss";

export default function AppDescriptionCard(props: any) {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(4);
    } else if (primaryApp !== null && comprativeAppOne !== null) {
      setColumn(12);
    } else if (primaryApp !== null && comprativeAppTwo !== null) {
      setColumn(12);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(12);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <>
      {props?.appProfileData !== undefined && props.appProfileData !== null && (
        <>
          <Modal
            opened={opened}
            onClose={close}
            title="Description"
            centered
            size="50%"
            className="ma-insight-overview"
          >
            <Text>
              <Interweave
                content={
                  props?.appProfileData?.description !== undefined
                    ? JSON?.parse(props?.appProfileData?.description)
                    : props?.appProfileData?.description?.slice(1, -1)
                }
                matchers={[
                  new UrlMatcher("url"),
                  new HashtagMatcher("hashtag"),
                ]}
              />
            </Text>
          </Modal>
          <Grid.Col md={12} lg={12} sm={12} className="ma-app-description-card">
            <Card withBorder style={{ minHeight: "300px" }}>
              <Card.Section className="card-header app-logo" withBorder>
                {primaryApp !== null &&
                comprativeAppOne !== null &&
                comprativeAppTwo !== null ? (
                  <>
                    <MediaQuery largerThan="md" styles={{ display: "none" }}>
                      <Flex
                        justify={{
                          base: "space-between",
                          md: "space-between",
                          sm: "start",
                        }}
                        align="flex-start"
                        direction="column"
                        wrap="nowrap"
                        gap="1rem"
                      >
                        <Group noWrap>
                          <Image src={props.iconUrl} width={50} height={50} />
                          <Box component="div" className="app-name">
                            <Title
                              order={5}
                              className={column === 4 ? "app-category" : ""}
                            >
                              {props.appName}
                            </Title>
                            <Text
                              fz="xs"
                              className={column === 4 ? "app-category" : ""}
                            >
                              {props.appCategory}
                            </Text>
                          </Box>
                        </Group>

                        <Box component="div" className="app-rating-area">
                          <Stack spacing={0} align="flex-start">
                            <Group spacing={5}>
                              <Text className="app-rating">
                                {Math.round(
                                  props.appProfileData?.rating?.avgRating * 10,
                                ) / 10}
                              </Text>
                              <Rating defaultValue={2} size="sm" count={1} />

                              <Text
                                fz="sm"
                                className="avg-rating-text text-right"
                              >
                                Average Rating
                              </Text>
                            </Group>

                            {props.appProfileData?.rating?.source && (
                              <Text fz="sm" className="info-text">
                                (Data Source:
                                {props.appProfileData?.rating?.source?.slice(
                                  1,
                                  -1,
                                ) === "GPS"
                                  ? " Google Play Services"
                                  : " Apple App Store"}
                                )
                              </Text>
                            )}
                          </Stack>
                        </Box>
                      </Flex>
                    </MediaQuery>
                    <MediaQuery smallerThan="md" styles={{ display: "none" }}>
                      <Flex
                        justify={{
                          base: "space-between",
                          md: "space-between",
                          sm: "start",
                        }}
                        align="flex-start"
                        direction="row"
                        wrap="nowrap"
                        gap="1rem"
                      >
                        <Group noWrap>
                          <Image src={props.iconUrl} width={50} height={50} />
                          <Box component="div" className="app-name">
                            <Title
                              order={5}
                              className={column === 4 ? "app-category" : ""}
                            >
                              {props.appName}
                            </Title>
                            <Text
                              fz="xs"
                              className={column === 4 ? "app-category" : ""}
                            >
                              {props.appCategory}
                            </Text>
                          </Box>
                        </Group>

                        <Box component="div" className="app-rating-area">
                          <Stack spacing={0} align="flex-end">
                            <Group>
                              <Text className="app-rating">
                                {Math.round(
                                  props.appProfileData?.rating?.avgRating * 10,
                                ) / 10}
                              </Text>
                              <Rating defaultValue={2} size="sm" count={1} />
                            </Group>

                            <Text
                              fz="sm"
                              className="avg-rating-text text-right"
                            >
                              Average Rating
                            </Text>

                            <Stack spacing={0}>
                              {props.appProfileData?.rating?.source && (
                                <>
                                  <Text fz="sm" className="info-text">
                                    (Data Source :
                                    {props.appProfileData?.rating?.source?.slice(
                                      1,
                                      -1,
                                    ) === "GPS"
                                      ? "Google Play Services"
                                      : "Apple App Store"}
                                    )
                                  </Text>
                                </>
                              )}
                            </Stack>
                          </Stack>
                        </Box>
                      </Flex>
                    </MediaQuery>
                  </>
                ) : (
                  <Flex
                    justify={{
                      base: "space-between",
                      md: "space-between",
                      sm: "start",
                    }}
                    align="flex-start"
                    direction="row"
                    wrap="nowrap"
                  >
                    <Group noWrap>
                      <Image src={props.iconUrl} width={50} height={50} />
                      <Box component="div" className="app-name">
                        <Title
                          order={5}
                          className={column === 4 ? "app-category" : ""}
                        >
                          {props.appName}
                        </Title>
                        <Text
                          fz="xs"
                          className={column === 4 ? "app-category" : ""}
                        >
                          {props.appCategory}
                        </Text>
                      </Box>
                    </Group>

                    <Box component="div" className="app-rating-area">
                      <Stack spacing={0} align="flex-end">
                        <Group>
                          <Text className="app-rating">
                            {Math.round(
                              props.appProfileData?.rating?.avgRating * 10,
                            ) / 10}
                          </Text>
                          <Rating defaultValue={2} size="sm" count={1} />
                        </Group>

                        <Text fz="sm" className="avg-rating-text text-right">
                          Average Rating
                        </Text>

                        <Group>
                          {props.appProfileData?.rating?.source && (
                            <Text fz="sm" className="info-text">
                              (Data Source :{" "}
                              {props.appProfileData?.rating?.source?.slice(
                                1,
                                -1,
                              ) === "GPS"
                                ? "Google Play Services"
                                : "Apple App Store"}
                              )
                            </Text>
                          )}
                        </Group>
                      </Stack>
                    </Box>
                  </Flex>
                )}
              </Card.Section>

              <Card.Section withBorder className={column === 4 ? "p-3" : "p-6"}>
                <Grid justify={"space-between"} align="center">
                  <Grid.Col md={12} lg={12} sm={12}>
                    <Divider size="xs" />
                  </Grid.Col>
                  <Grid.Col span={12} className="app-description">
                    <Stack spacing={0}>
                      <Text size="xs" className="mb-2 sub-header">
                        Description
                      </Text>
                      <Text
                        size="sm"
                        lineClamp={6}
                        className="description-text"
                      >
                        <Interweave
                          content={
                            props?.appProfileData?.description !== undefined
                              ? JSON?.parse(props?.appProfileData?.description)
                              : props?.appProfileData?.description?.slice(1, -1)
                          }
                        />
                      </Text>
                      {props?.appProfileData?.description !== undefined &&
                        JSON?.parse(props?.appProfileData?.description).length >
                          300 && (
                          <Text
                            component="button"
                            type="button"
                            align="left"
                            p={0}
                            c="blue"
                            size="xs"
                            onClick={open}
                            className="read-more-link"
                          >
                            Read More
                          </Text>
                        )}
                    </Stack>
                  </Grid.Col>
                </Grid>
              </Card.Section>
            </Card>
          </Grid.Col>
        </>
      )}
    </>
  );
}
