import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface AppProfileRequest {
  appId: number;
  appName: string;
  isDemographic: boolean;
  fromDate: string;
  toDate: string;
}

const getAppProfileData: any = gql`
  query getAppProfile($appProfileRequest: AppProfileRequest) {
    getAppProfile(appProfileRequest: $appProfileRequest) {
      appCategory
      appName
      description
      iconUrl
      rank
      rating {
        avgRating
        source
      }
      insights {
        connectionCount
        osCount
        deviceBrandCount
        os {
          name
          value
          percent
        }
        age {
          name
          value
        }
        connectionType {
          name
          value
          percent
        }
        deviceBrand {
          name
          value
          percent
        }
        activeInactiveUsers {
          name
          value
          percent
        }
        gender {
          name
          value
        }
        totalUsers {
          action
          value
        }
      }
    }
  }
`;

export const overViewApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAppProfile: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getAppProfileData,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getAppProfile;
      },
    }),
  }),
});
export const { useGetAppProfileQuery, useLazyGetAppProfileQuery } =
  overViewApiSlice;
