import {
  Box,
  Card,
  Flex,
  Grid,
  Group,
  Skeleton,
  Stack,
  Rating,
} from "@mantine/core";

export default function SkeletonLoader(props: any) {
  return (
    <>
      <Grid.Col md={12} lg={12} sm={12} className="app-basic-info-area">
        <Card withBorder style={{ minHeight: "300px" }}>
          <Card.Section className="p-4" withBorder>
            <Flex
              justify="space-between"
              align="flex-start"
              direction="row"
              wrap="wrap"
            >
              <Group>
                <Skeleton height={100} width={100} ml={10} />
                <Box component="div" className="app-name">
                  <Skeleton height={10} width={100} mt={6}></Skeleton>
                  <Skeleton height={10} width={100}></Skeleton>
                </Box>
              </Group>

              <Box component="div" className="app-rating-area">
                <Stack spacing={0}>
                  <Group>
                    <Rating defaultValue={1} size="sm" count={1} />
                  </Group>
                  <Group>
                    <Skeleton width={150} height={20} mt={20} mb={6} />
                  </Group>
                </Stack>
              </Box>
            </Flex>
          </Card.Section>
          <Card.Section withBorder className="p-6">
            <Grid justify={"flex-start"} align="center">
              <Grid.Col span={10} pt={0}>
                <Stack spacing={0}>
                  <Skeleton height={200} width="100%" />
                </Stack>
              </Grid.Col>
              <Grid.Col span={1}>
                <Skeleton width={20} height={10} radius={"xs"} my={4} />
                <Skeleton width={20} height={10} radius={"xs"} />
              </Grid.Col>
              <Grid.Col span={1} pt={0}>
                <Stack>
                  <Skeleton
                    width={100}
                    height={100}
                    style={{ borderRadius: "50%" }}
                  />
                </Stack>
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Grid.Col>
      {/* total users */}
      <Grid.Col span={4} className="app-basic-info-area">
        <Card withBorder>
          <Card.Section>
            <Skeleton width="30%" height={30} my={5} mx={10} />
          </Card.Section>
          <Card.Section withBorder className="p-4 pb-9">
            <Flex justify={"space-between"} align="center">
              <Skeleton width="30%" height={50} my={5} mx={10} />
              <Stack>
                <Skeleton
                  width={150}
                  height={150}
                  style={{ borderRadius: "50%" }}
                />
              </Stack>
            </Flex>
          </Card.Section>
        </Card>
      </Grid.Col>
      {/* age distribution */}
      <Grid.Col span={4} className="app-basic-info-area">
        <Card withBorder>
          <Card.Section>
            <Skeleton width="30%" height={30} my={5} mx={10} />
          </Card.Section>
          <Card.Section withBorder className="p-4">
            <Grid justify={"space-between"} align="center">
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Grid.Col>
      {/*Gender Distribution */}
      <Grid.Col span={4} className="app-basic-info-area">
        <Card withBorder>
          <Card.Section>
            <Skeleton width="30%" height={30} my={5} mx={10} />
          </Card.Section>
          <Card.Section withBorder className="p-4 pb-9">
            <Flex justify={"space-between"} align="center">
              <Skeleton width="30%" height={50} my={5} mx={10} />
              <Stack>
                <Skeleton
                  width={150}
                  height={150}
                  style={{ borderRadius: "50%" }}
                />
              </Stack>
            </Flex>
          </Card.Section>
        </Card>
      </Grid.Col>
      {/* debvice distribution */}
      <Grid.Col span={8} className="app-basic-info-area">
        <Card withBorder>
          <Card.Section>
            <Skeleton width="30%" height={30} my={5} mx={10} />
          </Card.Section>
          <Card.Section withBorder className="p-4">
            <Grid justify={"space-between"} align="center">
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={8}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
              <Grid.Col span={2}>
                <Skeleton height={20} my={5} mx={10} />
              </Grid.Col>
            </Grid>
          </Card.Section>
        </Card>
      </Grid.Col>
      {/* network distribution */}
      <Grid.Col span={4} className="app-basic-info-area">
        <Card withBorder>
          <Card.Section>
            <Skeleton width="30%" height={30} my={5} mx={10} />
          </Card.Section>
          <Card.Section withBorder className="p-4 pb-9">
            <Flex justify={"space-between"} align="center">
              <Skeleton width="30%" height={50} my={5} mx={10} />
              <Stack>
                <Skeleton
                  width={150}
                  height={150}
                  style={{ borderRadius: "50%" }}
                />
              </Stack>
            </Flex>
          </Card.Section>
        </Card>
      </Grid.Col>
    </>
  );
}
