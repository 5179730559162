import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  primaryApp: string | null;
  primaryAppId: any;
  comparativeAppOneId: any;
  comparativeAppTwoId: any;
  comprativeAppOne: string | null;
  comprativeAppTwo: string | null;
  primaryAppJsonModel: any;
  loading: boolean | any;
  comprativeAppJsonModel: any;
  comprativeAppJsonModelTwo: any;
  dateRange: any;
  routeflag: boolean | null;
  appDetail: any;
  dates: any;
}

export const initialState: InitialState = {
  primaryApp: null,
  primaryAppId: null,
  comparativeAppOneId: null,
  comparativeAppTwoId: null,
  comprativeAppOne: null,
  comprativeAppTwo: null,
  primaryAppJsonModel: null,
  loading: null,
  comprativeAppJsonModel: null,
  comprativeAppJsonModelTwo: null,
  routeflag: null,
  dateRange: {
    fromDate: "2023-11-14",
    toDate: "2023-11-20",
    appDetail: null,
  },
  appDetail: null,
  dates: "",
};

const overViewSlice = createSlice({
  name: "overview",
  initialState: { ...initialState },
  reducers: {
    setAppPrimaryData: (state, action) => {
      state.primaryApp = action.payload;
    },
    setComprativeOneData: (state, action) => {
      state.comprativeAppOne = action.payload;
    },
    setComprativeTwoData: (state, action) => {
      state.comprativeAppTwo = action.payload;
    },
    setLoader: (state) => {
      state.loading = true;
    },
    setPrimaryJsonModel: (state, action) => {
      state.loading = false;
      state.primaryAppJsonModel = action.payload;
    },
    setComprativeJsonModel: (state, action) => {
      state.loading = false;
      state.comprativeAppJsonModel = action.payload;
    },
    setComprativeTwoJsonModel: (state, action) => {
      state.loading = false;
      state.comprativeAppJsonModelTwo = action.payload;
    },
    setPrimaryAppId: (state, action) => {
      state.primaryAppId = action.payload;
    },
    setComparativeAppOneId: (state, action) => {
      state.comparativeAppOneId = action.payload;
    },
    setComparativeAppTwoId: (state, action) => {
      state.comparativeAppTwoId = action.payload;
    },
    setRouteFlag: (state, action) => {
      state.routeflag = action.payload;
    },
    setDateRange: (state) => {
      const dateRange = {
        fromDate: "2023-11-14",
        toDate: "2023-11-20", //moment().subtract(1, "day").format("YYYY/MM/DD"),
      };
      state.dateRange = dateRange;
    },
    setGlobalDates: (state, action) => {
      state.dates = action.payload;
    },
    setInitialApp: (state, action) => {
      state.appDetail = action.payload;
    },
  },
});

export const {
  setAppPrimaryData,
  setPrimaryAppId,
  setComparativeAppOneId,
  setComparativeAppTwoId,
  setComprativeOneData,
  setComprativeTwoData,
  setPrimaryJsonModel,
  setComprativeJsonModel,
  setComprativeTwoJsonModel,
  setLoader,
  setDateRange,
  setRouteFlag,
  setInitialApp,
  setGlobalDates,
} = overViewSlice.actions;

export default overViewSlice.reducer;

export const selectedPrimaryApp = (state: any) => state.overview.primaryApp;

export const selectPrimaryAppId = (state: any) => state.overview.primaryAppId;
export const selectComparativeAppOneId = (state: any) =>
  state.overview.comparativeAppOneId;
export const selectComparativeAppTwoId = (state: any) =>
  state.overview.comparativeAppTwoId;

export const selectedComprativeAppOne = (state: any) =>
  state.overview.comprativeAppOne;

export const selectedComprativeAppTwo = (state: any) =>
  state.overview.comprativeAppTwo;
export const selectPrimaryJsonModel = (state: any) =>
  state.overview.primaryAppJsonModel;

export const selectComprativeJsonModel = (state: any) =>
  state.overview.comprativeAppJsonModel;

export const selectComprativeTwoJsonModel = (state: any) =>
  state.overview.comprativeAppJsonModelTwo;

export const selectLoading = (state: any) => state.overview.loading;

export const selectDateRange = (state: any) => state.overview.dateRange;

export const selectDate = (state: any) => state.overview.dates;

export const selectRouteFlag = (state: any) => state.overview.routeflag;

export const selectInitialApp = (state: any) => state.overview.appDetail;
