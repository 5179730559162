import { Group, Image, SimpleGrid, Text } from "@mantine/core";
import ProgressBarComponent from "../../../../features/common/ProgressBarComponent/ProgressBarComponent";

export default function MultiProgressBar(props: any) {
  return (
    <SimpleGrid className="mt-5" style={{ gap: 0 }}>
      <Group spacing={0} mb={10}>
        <Image src={props.appIcon} height={20} width={20} />
        <Text fz={"xs"} className="pl-3 mb-0">
          {props.appName}
        </Text>
      </Group>

      <ProgressBarComponent
        value={undefined}
        color={""}
        Barsize={50}
        barType={"barWithtoolTip"}
        colorArr={[]}
        valueArr={[]}
        tooltipArr={[]}
        sections={props?.section}
        isPadding={false}
      />
    </SimpleGrid>
  );
}
