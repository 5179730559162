import React from "react";
import { Text, Flex, Image } from "@mantine/core";
import Frame from "../../assets/images/Frame.png";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  appicon: string;
  label: string;
  value: string;
}

export const SelectItem = React.forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, appicon, ...others }: ItemProps, ref) => {
    return (
      <div {...others} ref={ref} className="select-search">
        <Flex>
          {appicon !== undefined && (
            <Image
              src={appicon}
              style={{ width: "20px", height: "20px" }}
              role={"icons"}
              placeholder={
                <Image src={Frame} style={{ width: "20px", height: "20px" }} />
              }
              withPlaceholder
            />
          )}
          <div className="ml-2">
            <Text size="sm">{label}</Text>
          </div>
        </Flex>
      </div>
    );
  },
);
