import { useState, useEffect } from "react";
import ReverseOverIndexing from "@/containers/reverseOverIndexing/ReverseOverIndexing";
import {
  Accordion,
  Box,
  Grid,
  LoadingOverlay,
  Skeleton,
  Text,
  Flex,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { useLazyGetEngagementOverIndexDataQuery } from "../../../features/services/reverseOverIndexing/reverseOverIndexingApiSlice";
import { crossBrowsingData } from "../../../features/services/crossBrowsing/crossBrowsingSlice";
import {
  selectPrimaryJsonModel,
  selectComprativeJsonModel,
  selectComprativeTwoJsonModel,
  selectDate,
} from "../../../features/services/overView/overViewSlice";
import {
  selectDateRange,
  selectLabel,
} from "@/features/services/dateRange/dateRangeSlice";

export default function ByUsesOverIndex(props: any) {
  const [fetechUserEngagementOverIndex, { isLoading }] =
    useLazyGetEngagementOverIndexDataQuery();
  const primaryApp = useSelector(selectPrimaryJsonModel);
  const comprativeAppOne = useSelector(selectComprativeJsonModel);
  const comprativeAppTwo = useSelector(selectComprativeTwoJsonModel);
  const crossBrowsing = useSelector(crossBrowsingData);
  const [userReportPrimaryArray, setUserReport] = useState([]);
  const dates = useSelector(selectLabel);
  const dateRange = useSelector(selectDateRange);
  const [
    userReportArrayComprativeAppOneArray,
    setUserReportArrayComprativeAppOneArray,
  ] = useState([]);
  const [
    userReportArrayComprativeAppTwoArray,
    setUserReportArrayComprativeAppTwoArray,
  ] = useState([]);
  const [loading, setLoading] = useState(isLoading);

  useEffect(() => {
    setLoading(true);
    const payload: any = {
      appQueryParam: {
        primaryAppId: primaryApp?.appId,
        competitiveAppOneId:
          comprativeAppOne?.appId === undefined ? 0 : comprativeAppOne?.appId,
        competitiveAppTwoId:
          comprativeAppTwo?.appId === undefined ? 0 : comprativeAppTwo?.appId,
        fromDate: dateRange?.fromDate,
        toDate: dateRange?.toDate,
        isCategory: crossBrowsing?.isSelectedCategory,
        searchableAppName: crossBrowsing?.selectedAppInFilter,
        categoryName: crossBrowsing?.selectedCategoryInFilter,
        searchableAppId: props.searchableAppId,
        isUploadDataUsage: props.selectUpload === "by_upload" ? true : false,
      },
    };
    Promise.allSettled([fetechUserEngagementOverIndex(payload)])
      .then((res: any) => {
        if (res[0] !== undefined) {
          setLoading(false);
          setUserReport(
            res[0]?.value?.data?.getEngagementOverIndexData
              ?.primaryAppEngagementReportData,
          );
          setUserReportArrayComprativeAppOneArray(
            res[0]?.value?.data?.getEngagementOverIndexData
              ?.compOneAppEngagementReportData,
          );
          setUserReportArrayComprativeAppTwoArray(
            res[0]?.value?.data?.getEngagementOverIndexData
              ?.compTwoAppEngagementReportData,
          );
        }
      })
      .catch((e) => console.log(e));
  }, [
    primaryApp,
    comprativeAppOne,
    comprativeAppTwo,
    crossBrowsing,
    props.selectUpload,
    props.searchableAppId,
  ]);

  if (isLoading) {
    return (
      <Box maw={400} pos="relative">
        <LoadingOverlay
          visible={loading}
          overlayBlur={2}
          loaderProps={{ size: "lg", color: "#e20074", variant: "bars" }}
        />
      </Box>
    );
  }

  return (
    <>
      {!loading ? (
        <Accordion
          defaultValue={["customization", "flexibility", "focus-ring"]}
          multiple
        >
          <Accordion.Item
            value="customization"
            style={{
              border: "1px solid rgb(44, 122, 123)",
              borderRadius: "4px",
            }}
          >
            <Accordion.Control
              style={{
                background: "rgba(44, 122, 123, 0.05)",
                borderRadius: 0,
              }}
            >
              <Grid>
                <Grid.Col span={2}>
                  <Flex>
                    <img
                      src={primaryApp?.iconUrl?.slice(1, -1)}
                      style={{ width: 20, height: 20, marginRight: 10 }}
                    />
                    <Text fz="sm">{primaryApp?.appName}</Text>
                  </Flex>
                </Grid.Col>
                <Grid.Col span={10} className="text-center">
                  <Text fz="xs">
                    App Ranked as per Untapped Potential (Categories by Usage of
                    {dates === " lastWeek" ? " users" : " events"})
                  </Text>
                </Grid.Col>
              </Grid>
            </Accordion.Control>
            <Accordion.Panel>
              <ReverseOverIndexing
                selectUpload={props.selectUpload}
                color={"#2C7A7B"}
                userReportArray={userReportPrimaryArray}
              />
            </Accordion.Panel>
          </Accordion.Item>
          {comprativeAppOne?.appName &&
            userReportArrayComprativeAppOneArray?.length > 0 && (
              <Accordion.Item
                value="flexibility"
                className="mt-5"
                style={{
                  border: "1px solid rgb(107, 70, 193)",
                  borderRadius: "4px",
                }}
              >
                <Accordion.Control
                  style={{
                    background: "rgba(44, 122, 123, 0.05)",
                    borderRadius: 0,
                  }}
                >
                  <Grid>
                    <Grid.Col span={2}>
                      {" "}
                      <Flex>
                        <img
                          src={comprativeAppOne?.iconUrl?.slice(1, -1)}
                          style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                        <Text fz="sm">{comprativeAppOne?.appName}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={10} className="text-center">
                      <Text fz="xs">
                        App Ranked as per Over Untapped Potential (Categories by
                        Usage of {dates === " lastWeek" ? " users" : " events"})
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Accordion.Control>
                <Accordion.Panel>
                  <ReverseOverIndexing
                    selectUpload={props.selectUpload}
                    color={"#6B46C1"}
                    userReportArray={userReportArrayComprativeAppOneArray}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            )}
          {comprativeAppTwo?.appName &&
            userReportArrayComprativeAppTwoArray?.length > 0 && (
              <Accordion.Item
                value="focus-ring"
                className="mt-5"
                style={{
                  border: "1px solid rgb(192, 86, 33)",
                  borderRadius: "4px",
                }}
              >
                <Accordion.Control
                  style={{
                    background: "rgba(44, 122, 123, 0.05)",
                    borderRadius: 0,
                  }}
                >
                  <Grid>
                    <Grid.Col span={2}>
                      <Flex>
                        <img
                          src={comprativeAppTwo?.iconUrl?.slice(1, -1)}
                          style={{ width: 20, height: 20, marginRight: 10 }}
                        />
                        <Text fz="sm">{comprativeAppTwo?.appName}</Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={10} className="text-center">
                      <Text fz="xs">
                        App Ranked as per Untapped Potential (Categories by
                        Usage of {dates === " lastWeek" ? " users" : " events"})
                      </Text>
                    </Grid.Col>
                  </Grid>
                </Accordion.Control>
                <Accordion.Panel>
                  <ReverseOverIndexing
                    selectUpload={props.selectUpload}
                    color={"#C05621"}
                    userReportArray={userReportArrayComprativeAppTwoArray}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            )}
        </Accordion>
      ) : (
        <Skeleton height={250} width="100%" my={0} />
      )}
    </>
  );
}
