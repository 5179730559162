import axios from "axios";

const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";

const refresh = () => {
  axios
    .post(
      `${BASE_URL}/keycloak/refreshUserToken`,
      {
        refreshToken: sessionStorage.getItem("refreshtoken"),
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      sessionStorage.setItem("accesstoken", response.data.accessToken);
      sessionStorage.setItem("refreshtoken", response.data.refreshToken);
    })
    .catch((err) => {
      console.log(err);
    });
};

export default refresh;
