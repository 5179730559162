import React from "react";
import { Grid, Skeleton, Flex, Card } from "@mantine/core";

export default function TrendingSkeleton() {
  const appProfile = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  return (
    <>
      <Flex justify="space-between" align="center" direction="row">
        <Skeleton height={40} mt={6} width="20%" />
        <Skeleton height={50} width="20%" />
      </Flex>
      {appProfile.map((item: any) => {
        return (
          <Card withBorder className="my-3 p-1" key={item}>
            <Grid justify={"space-between"}>
              <Grid.Col span={2}>
                <Skeleton height={70} width="20%" my={0} />
              </Grid.Col>
              <Grid.Col span={9}>
                <Skeleton height={70} width="100%" my={0} />
              </Grid.Col>
              <Grid.Col span={1} style={{ position: "relative" }}>
                <Skeleton
                  height={70}
                  width="20%"
                  my={0}
                  style={{ position: "absolute", right: 20 }}
                />
              </Grid.Col>
            </Grid>
          </Card>
        );
      })}
    </>
  );
}
