import { Grid, Card, Flex, Text } from "@mantine/core";
import UsesReportCard from "../../containers/usesReportCard/UsesReportCard";

import "./ReverseOverIndexing.scss";

function titleCase(str: any) {
  let splitStr: any = str.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export default function ReverseOverIndexing(props: any) {
  return (
    <Card className="reverse-index">
      {props?.userReportArray?.length > 0 &&
        props?.userReportArray?.map((item: any, index: any) => {
          return (
            <Card.Section key={index}>
              <Grid justify="center" align="center" p={10}>
                <Grid.Col span="auto">
                  <Text fz="xs" className="text-center">
                    {titleCase(
                      item?.appDataUsageMapper?.dataUsageCategory
                        ?.replace("_", " ")
                        .toLowerCase(),
                    )}{" "}
                    Usage
                  </Text>
                </Grid.Col>
                <Grid.Col
                  span={11}
                  style={{
                    alignContent: "center",
                  }}
                >
                  <Flex className="ma-reverse-index">
                    {item?.engagementOverIndexData.map(
                      (newItem: any, newIndex: any) => {
                        return (
                          <UsesReportCard
                            key={newIndex.appName}
                            iconUrl={newItem.iconUrl}
                            appName={newItem.appName}
                            appPotential={newItem.appPxValue}
                            value={newItem.appUnTappedPercent}
                            color={props.color}
                            untappedPercentage={
                              newItem.appUnTappedPercent?.toFixed(1) + "%"
                            }
                            untappedValue={newItem.untappedValue}
                            item={item}
                            appOverLappingCount={newItem.appOverLappingCount}
                          />
                        );
                      },
                    )}
                  </Flex>
                </Grid.Col>
              </Grid>
            </Card.Section>
          );
        })}
    </Card>
  );
}
