import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface AppQueryParam {
  primaryAppId: any;
  competitiveAppOneId: number;
  competitiveAppTwoId: number;
  fromDate: string;
  toDate: string;
  isCategory: boolean;
  searchableAppName: string;
  categoryName: string;
  searchableAppId: any;
}

const getOverIndexQuery = gql`
  query getOverIndexData($appQueryParam: AppQueryParam) {
    getOverIndexData(appQueryParam: $appQueryParam) {
      overIndexPriAppsData {
        appId
        appName
        appUnTappedPercent
        appPxValue
        untappedValue
        appOverLappingCount
      }
      overIndexComOneAppsData {
        appId
        appName
        appUnTappedPercent
        appPxValue
        untappedValue
        appOverLappingCount
      }
      overIndexComTwoAppsData {
        appId
        appName
        appUnTappedPercent
        appPxValue
        untappedValue
        appOverLappingCount
      }
    }
  }
`;

const getTwinAppQuery = gql`
  query getTwinAppData($appQueryParam: AppQueryParam) {
    getTwinAppData(appQueryParam: $appQueryParam) {
      twinAppsData {
        appId
        appName
        category
        appPercent
        competitiveApp1Percent
        competitiveApp2Percent
        appOneAbsoluteValue
        competitiveApp1AbsoluteValue
        competitiveApp2AbsoluteValue
      }
    }
  }
`;
export const overIndexSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOverIndex: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getOverIndexQuery,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getOverIndexData;
      },
    }),
  }),
});

export const twinAppSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTwinApp: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getTwinAppQuery,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getTwinAppData;
      },
    }),
  }),
});
export const { useGetOverIndexQuery, useLazyGetOverIndexQuery } =
  overIndexSlice;
export const { useGetTwinAppQuery, useLazyGetTwinAppQuery } = twinAppSlice;
