import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface TrendingAppForSelectedCategory {
  appCategory: string[];
  fromDate: string;
  toDate: string;
}

const getAppAndAppIconQuery: string = gql`
  query {
    getAppAndAppIconData {
      appName
      appIcon
      appId
      micaName
      categoryList
    }
  }
`;

const getAppsForSelectedCategoryData = gql`
  query getAppsForSelectedCategory(
    $trendingAppForSelectedCategory: TrendingAppForSelectedCategory
  ) {
    getAppsForSelectedCategory(
      trendingAppForSelectedCategory: $trendingAppForSelectedCategory
    ) {
      trendingAppsForSelectedCategory {
        appId
        appName
        appCategory
        iconUrl
      }
    }
  }
`;

export const searchAppSlice = apiSlice.injectEndpoints({
  endpoints: (builder): any => ({
    getSearchedApp: builder.query<any, any>({
      query: (): any => ({
        document: getAppAndAppIconQuery,
      }),
      transformResponse: (response: any): any => {
        const appList = response?.getAppAndAppIconData
          .sort((a: any, b: any) => {
            let textA = a.appName.toUpperCase();
            let textB = b.appName.toUpperCase();
            const condition = textA > textB ? 1 : 0;
            return textA < textB ? -1 : condition;
          })
          .map((item: any) => {
            return {
              value: item.appName,
              label: item.appName,
              id: item.appId,
              appicon: item.appIcon,
              mName: item.micaName,
              categoryList: item.categoryList,
              group: "Other Apps",
            };
          });
        return appList;
      },
    }),
    getAppsForSelectedCategoryData: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getAppsForSelectedCategoryData,
        variables,
      }),
      transformResponse: (response: any): any => {
        const appList =
          response?.getAppsForSelectedCategory?.trendingAppsForSelectedCategory.map(
            (item: any) => {
              return {
                value: item.appName,
                label: item.appName,
                id: item.appId,
                appicon: item.iconUrl,
                mName: item.appName,
                categoryList: item.appCategory,
                group: "Similar Apps",
              };
            },
          );
        return appList;
      },
    }),
  }),
});

export const {
  useGetSearchedAppQuery,
  useLazyGetSearchedAppQuery,
  useGetAppsForSelectedCategoryDataQuery,
  useLazyGetAppsForSelectedCategoryDataQuery,
} = searchAppSlice;
