import {
  Group,
  Button,
  Grid,
  Drawer,
  TextInput,
  Checkbox,
  SimpleGrid,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useGetAllCategoriesQuery } from "@/features/services/trendingApps/trendingAppApiSlice";
import { setAllCategories } from "@/features/services/trendingApps/trendingAppSlice";
import { selectLightTheme } from "@/features/services/themes/themeSlice";

const SelectCategoriesForAudience = (props: any) => {
  const theme = useSelector(selectLightTheme);

  const [catValue, setCatValue] = useState<any>("");

  const [filteredCategories, setFilteredCategories] = useState<any>([]);

  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const { data: categories } = useGetAllCategoriesQuery("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (categories !== undefined) {
      dispatch(setAllCategories(categories));
      setFilteredCategories(categories);
    }
  }, [categories]);

  useEffect(() => {
    setFilteredCategories(categories);
    setSelectedCategories(props.selectedCategories);
  }, [props.filterSectionOpened]);

  const handleCategoryInputChange = (event: any) => {
    const inputValue = event.target.value;
    setCatValue(inputValue);

    const filtered: any = categories.filter((category: any) =>
      category.toLowerCase().startsWith(inputValue.toLowerCase()),
    );
    setFilteredCategories(filtered);
  };

  const handleCategoryCheckboxChange = (e: any) => {
    setSelectedCategories(e);
  };

  const applyFilters = () => {
    props.applyFilters({
      categories: selectedCategories,
      apps: [],
      dayPhase: [],
      mApps: [],
      usage: [],
      dayPhaseList: [],
      usageList: [],
    });
  };

  return (
    <Drawer
      position="right"
      opened={props.filterSectionOpened}
      onClose={props.handleFilterSectionClose}
      title="Select App categories(Max 3)"
      className="filter-drawer"
    >
      <Grid style={{ height: "70vh" }}>
        <Grid.Col lg={12} md={12} sm={12} style={{ height: "100%" }}>
          <>
            <TextInput
              placeholder="Search"
              value={catValue}
              onChange={handleCategoryInputChange}
              variant="filled"
            />
            <Checkbox.Group
              value={selectedCategories}
              onChange={(e) => handleCategoryCheckboxChange(e)}
              className={
                selectedCategories.length === 3
                  ? "filter-checkbox-group disabled"
                  : "filter-checkbox-group"
              }
              style={{ height: "95%" }}
            >
              <SimpleGrid mt="xs" className="">
                {filteredCategories?.map((category: any) => (
                  <Checkbox label={category} value={category} key={category} />
                ))}
              </SimpleGrid>
            </Checkbox.Group>
          </>
        </Grid.Col>

        <Grid.Col>
          <Group
            position="center"
            className={
              selectedCategories.length > 0 && theme === "dark"
                ? "hasSelectedFilters dark-theme-button"
                : "noFilters"
            }
          >
            <Button
              className="secondary-button btn"
              onClick={props.handleFilterSectionClose}
            >
              Cancel
            </Button>
            <Button
              className={
                selectedCategories.length > 0
                  ? "primary-button btn"
                  : "primary-button disabled btn"
              }
              disabled={selectedCategories.length > 0 ? false : true}
              onClick={() => applyFilters()}
            >
              Add
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Drawer>
  );
};

export default SelectCategoriesForAudience;
