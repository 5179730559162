import { FC, useEffect, useState } from "react";
import { Grid, Flex, Checkbox, Text, Card, Tooltip } from "@mantine/core";
import { IUserProps } from "../../../types/insightRecommendations.types";
import "./InsightsRecommendations.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPrimaryJsonModel,
  selectComprativeJsonModel,
  selectComprativeTwoJsonModel,
} from "@/features/services/overView/overViewSlice";
import { useLazyGetRecommendationsDataQuery } from "@/features/services/recommendation/recommendationApiSlice";
import InsightsRecommendationSkeleton from "@/containers/SkeletonLoader/InsightsRecommendationSkeleton";
import { setError } from "@/features/services/errorSlice";
import { generateErrorMessage } from "../../../Util/utils";
import { selectDateRange } from "@/features/services/dateRange/dateRangeSlice";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const InsightsRecommendation: FC<IUserProps> = () => {
  const dateRange = useSelector(selectDateRange);
  const dispatch = useDispatch();
  const [
    getRecommendations,
    {
      data: recommendationsData,
      isError: recomendationErr,
      error: recomendationErrMsg,
      isLoading,
    },
  ] = useLazyGetRecommendationsDataQuery();
  const [loading, setLoading] = useState(isLoading);
  const primaryAppJsonModel = useSelector(selectPrimaryJsonModel);
  const primaryAppId = primaryAppJsonModel?.appId;
  const comparativeAppJsonModel = useSelector(selectComprativeJsonModel);
  const comparativeAppTwoJsonModel = useSelector(selectComprativeTwoJsonModel);
  useEffect(() => {
    if (
      (comparativeAppJsonModel && comparativeAppJsonModel?.appId) ||
      (comparativeAppTwoJsonModel && comparativeAppTwoJsonModel?.appId)
    ) {
      setLoading(true);
      getRecommendations({
        appQueryParam: {
          primaryAppId: primaryAppId,
          competitiveAppOneId: comparativeAppJsonModel?.appId
            ? comparativeAppJsonModel?.appId
            : 0,
          competitiveAppTwoId: comparativeAppTwoJsonModel?.appId
            ? comparativeAppTwoJsonModel?.appId
            : 0,
          fromDate: dateRange?.fromDate,
          toDate: dateRange?.toDate,
          primaryAppName: primaryAppJsonModel?.appName
            ? primaryAppJsonModel?.appName
            : "",
          competitiveAppOneName: comparativeAppJsonModel?.appName
            ? comparativeAppJsonModel?.appName
            : "",
          competitiveAppTwoName: comparativeAppTwoJsonModel?.appName
            ? comparativeAppTwoJsonModel?.appName
            : "",
          isCategory: false,
          searchableAppId: 0,
        },
      }).then(() => {
        setLoading(false);
      });
    }
  }, [
    primaryAppJsonModel,
    comparativeAppJsonModel,
    comparativeAppTwoJsonModel,
    dateRange,
  ]);

  const setImpactColor = (element: any): string => {
    if (element.impact === "Positive") {
      return "positive-impact";
    } else if (element.impact === "Negative") {
      return "negative-impact";
    } else {
      return "neutral-impact";
    }
  };
  useEffect(() => {
    if (recomendationErrMsg) {
      const message = generateErrorMessage(recomendationErrMsg);
      dispatch(setError(message));
    }
  }, [recomendationErr]);
  return (
    <>
      {!loading ? (
        <Grid className="insights-recommendation mt-0">
          <Grid.Col span={12}>
            <Text
              fw={700}
              fz={"md"}
              className={"title flex"}
              style={{ justifyContent: "flex-start" }}
            >
              <span className="ml-2 mt-4">Severity Level </span>
              <Tooltip
                label="Severity levels categorize the impact of findings in competitive research"
                withArrow
              >
                <InformationCircleIcon className="h-6 w-6 ml-1 mt-4 cursor-pointer" />
              </Tooltip>
            </Text>
          </Grid.Col>
          <Grid.Col span={12}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Text fz="xl" className="mb-3">
                High Severity
              </Text>
              {recommendationsData?.getRecommendations?.high?.length ? (
                <Card.Section style={{ padding: "8px" }}>
                  {recommendationsData?.getRecommendations?.high?.map(
                    (element: {
                      id: string;
                      impact: string;
                      description: string;
                      indicator: string;
                    }) => {
                      return (
                        <Card
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                          style={{ margin: "8px" }}
                        >
                          <Card.Section
                            key={element.id}
                            style={{ padding: "4px" }}
                          >
                            <Flex align="center" gap="sm" className="px-5 py-3">
                              <Checkbox value={element.id} />

                              <Text className="description">
                                {element?.indicator}
                              </Text>
                              <Text
                                className={setImpactColor(element)}
                                style={{ position: "absolute", right: "1%" }}
                              >
                                {element.impact}
                              </Text>
                            </Flex>
                            <Text className="indication">
                              {element?.description}
                            </Text>
                          </Card.Section>
                        </Card>
                      );
                    },
                  )}
                </Card.Section>
              ) : (
                <Card.Section>
                  <Text ta="center" className="text-secondary p-5" fz="sm">
                    No High Severity issues found
                  </Text>
                </Card.Section>
              )}
            </Card>
          </Grid.Col>
          <Grid.Col span={12}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Text fz="xl" className="mb-3">
                Medium Severity
              </Text>
              {recommendationsData?.getRecommendations?.medium?.length ? (
                <Card.Section style={{ padding: "8px" }}>
                  {recommendationsData?.getRecommendations?.medium?.map(
                    (element: {
                      id: string;
                      impact: string;
                      description: string;
                      indicator: string;
                    }) => {
                      return (
                        <Card
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                          style={{ margin: "8px" }}
                        >
                          <Card.Section
                            key={element.id}
                            style={{ padding: "4px" }}
                          >
                            <Flex align="center" gap="sm" className="px-5 py-3">
                              <Checkbox value={element.id} />

                              <Text className="description">
                                {element?.indicator}
                              </Text>
                              <Text
                                className={setImpactColor(element)}
                                style={{ position: "absolute", right: "1%" }}
                              >
                                {element.impact}
                              </Text>
                            </Flex>
                            <Text className="indication">
                              {element?.description}
                            </Text>
                          </Card.Section>
                        </Card>
                      );
                    },
                  )}
                </Card.Section>
              ) : (
                <Card.Section>
                  <Text ta="center" className="text-secondary p-5" fz="sm">
                    No Medium Severity issues found
                  </Text>
                </Card.Section>
              )}
            </Card>
          </Grid.Col>

          <Grid.Col span={12}>
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Text fz="xl" className="mb-3">
                Low Severity
              </Text>
              {recommendationsData?.getRecommendations?.low?.length ? (
                <Card.Section style={{ padding: "8px" }}>
                  {recommendationsData?.getRecommendations?.low?.map(
                    (element: {
                      id: string;
                      impact: string;
                      description: string;
                      indicator: string;
                    }) => {
                      return (
                        <Card
                          shadow="sm"
                          padding="lg"
                          radius="md"
                          withBorder
                          style={{ margin: "8px" }}
                        >
                          <Card.Section
                            key={element.id}
                            style={{ padding: "4px" }}
                          >
                            <Flex align="center" gap="sm" className="px-5 py-3">
                              <Checkbox value={element.id} />
                              <Text className="description">
                                {element?.indicator}
                              </Text>
                              <Text
                                className={setImpactColor(element)}
                                style={{ position: "absolute", right: "1%" }}
                              >
                                {element.impact}
                              </Text>
                            </Flex>
                            <Text className="indication">
                              {element?.description}
                            </Text>
                          </Card.Section>
                        </Card>
                      );
                    },
                  )}
                </Card.Section>
              ) : (
                <Card.Section>
                  <Text ta="center" className="text-secondary p-5" fz="sm">
                    No Low Severity issues found
                  </Text>
                </Card.Section>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      ) : (
        <Grid className="w-full">
          <Grid.Col span={12}>
            <InsightsRecommendationSkeleton />
          </Grid.Col>
        </Grid>
      )}
    </>
  );
};

export default InsightsRecommendation;
