import { Card, Grid, Group, Text } from "@mantine/core";
import PieChartComponent from "@/features/common/PieChartComponent/PieChartComponent";
import { useSelector } from "react-redux";
import {
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectedPrimaryApp,
  selectDate,
} from "@/features/services/overView/overViewSlice";
import {
  selectDateRange,
  selectLabel,
} from "@/features/services/dateRange/dateRangeSlice";
import { useEffect, useState } from "react";

export default function GenderDistributionCard(props: any) {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);

  const [smColumnSize, setSmColumnSize] = useState(0);
  const [mdColumnSize, setMdColumnSize] = useState(0);
  const dateRange = useSelector(selectDateRange);
  const label = useSelector(selectLabel);
  const subHeader =
    label === "lastWeek" || label === "LastMonth" ? "Userwise" : "Eventwise";

  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
    } else if (
      primaryApp !== null &&
      (comprativeAppOne !== null || comprativeAppTwo !== null)
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(4);
      setSmColumnSize(4);
      setMdColumnSize(4);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <Grid.Col md={mdColumnSize} lg={column} sm={smColumnSize}>
      <Card
        withBorder
        shadow="sm"
        radius="md"
        className={
          props.appProfileData?.insights?.gender === null
            ? "chart-card disabled"
            : "chart-card"
        }
        style={{ minHeight: "240px", borderRadius: "4px" }}
      >
        <Card.Section
          inheritPadding
          py="xs"
          className="card-header text-center"
        >
          <Group position="apart">
            <Text size="sm" className="text-center text-small">
              Gender Distribution
              <span style={{ color: "#6E7B91" }}> ({subHeader}) </span>
            </Text>
          </Group>
        </Card.Section>
        <Card.Section>
          {props.appProfileData?.insights?.gender === null && (
            <Text className="no-data-text">No Data Available</Text>
          )}

          <PieChartComponent
            seriesData={props.genderDistributionSeriesData}
            chartName={""}
            showLabelLine={false}
            chartRadius={window.innerWidth < 821 ? [40, 20] : [60, 45]}
            legendOrientation={["horizontal", "center", "bottom"]}
            height={"180px"}
            showLabelinMiddle={false}
            chartAlign={["50%", "50%"]}
            hideLabel={false}
            showLegend={true}
            titleAlign={"90%"}
            colors={["#2C7A7B", "#81E6D9", "#2C7A7B", "#234E52"]}
            width={undefined}
            className={"app-profile-totalUserSeriesData"}
            itemGap={50}
            alignLegend={"horizontal"}
          />
        </Card.Section>
      </Card>
    </Grid.Col>
  );
}
