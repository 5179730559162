import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { TextInput, PasswordInput, Chip, Button, Grid } from "@mantine/core";
import Tlogo from "../../assets/images/Tlogo.svg";

import "./login.scss";
import { Link } from "react-router-dom";
import register from "./Register.generated";
import { notifications } from "@mantine/notifications";
import Captcha from "./Captcha";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

const LoginRegistration = () => {
  const [visible, { toggle }] = useDisclosure(false);
  const [visibleReEnter, { toggle: setVisibleReEnter }] = useDisclosure(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState<any>(false);
  const [recheckPasswordErr, setRecheckPasswordErr] = useState<any>(false);
  const [invalidEmail, setInvalidEmail] = useState<any>(false);
  const [value, setValue] = useState(["insights"]);
  const [validCaptcha, setValidCaptcha] = useState(false);
  const [captchaInputChecked, setCaptchaInputChecked] = useState(false);
  const [registeredUser, setRegisteredUser] = useState(false);
  const obj: any = window.location.href.includes("test");

  const registerUser = (event: any) => {
    event.preventDefault();
    if (obj === false) {
      if (validCaptcha) {
        let insightsPortalAccess = value.includes("insights");
        let audienceBuilderAccess = value.includes("audienceBuilder");
        if (!passwordErr && !recheckPasswordErr) {
          register(
            userName,
            password,
            insightsPortalAccess,
            audienceBuilderAccess,
            (res: any) => {
              setUserName("");
              setPassword("");
              setconfirmPassword("");
              setRegisteredUser(true);
              notifications.show({
                id: "login-sucess",
                withCloseButton: true,
                onClose: () => {},
                onOpen: () => {},
                autoClose: 5000,
                title: "SUCCESS",
                message: "Acccount created sucessfully. Please Sign In",
                color: "green",
                className: "my-notification-class",
                style: { backgroundColor: "white" },
                sx: { backgroundColor: "green" },
                loading: false,
              });
            },
            (err: any) => {
              const error =
                err.response.status === 409
                  ? "User already exists with same user name."
                  : err.response.data.errorMessage
                  ? err.response.data.errorMessage
                  : "Something went wrong please try again!";
              notifications.show({
                id: "login-fail",
                withCloseButton: true,
                onClose: () => {},
                onOpen: () => {},
                autoClose: 5000,
                title: "FAIL",
                message: error,
                color: "red",
                className: "my-notification-class",
                style: { backgroundColor: "white" },
                sx: { backgroundColor: "red" },
                loading: false,
              });
            }
          );
        }
      } else {
        if (!captchaInputChecked) {
          setErrorMessage("Please enter  Captcha");
        } else {
          setErrorMessage("Please enter  correct Captcha");
        }
      }
    } else {
      let insightsPortalAccess = value.includes("insight");
      let audienceBuilderAccess = value.includes("audienceBuilder");
      if (!passwordErr && !recheckPasswordErr) {
        register(
          userName,
          password,
          insightsPortalAccess,
          audienceBuilderAccess,
          (res: any) => {
            setUserName("");
            setPassword("");
            setconfirmPassword("");
            setRegisteredUser(true);
            notifications.show({
              id: "login-sucess",
              withCloseButton: true,
              onClose: () => {},
              onOpen: () => {},
              autoClose: 5000,
              title: "SUCCESS",
              message: "Acccount created sucessfully. Please Sign In",
              color: "green",
              className: "my-notification-class",
              style: { backgroundColor: "white" },
              sx: { backgroundColor: "green" },
              loading: false,
            });
          },
          (err: any) => {
            const error =
              err.response.status === 409
                ? "User already exists with same user name."
                : err.response.data.errorMessage
                ? err.response.data.errorMessage
                : "Something went wrong please try again!";
            notifications.show({
              id: "login-fail",
              withCloseButton: true,
              onClose: () => {},
              onOpen: () => {},
              autoClose: 5000,
              title: "FAIL",
              message: error,
              color: "red",
              className: "my-notification-class",
              style: { backgroundColor: "white" },
              sx: { backgroundColor: "red" },
              loading: false,
            });
          }
        );
      }
    }
  };
  const userNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegisteredUser(false);
    if (errorMessage) {
      setErrorMessage("");
    }
    setUserName(event.target.value);
    setInvalidEmail(false);
  };
  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRegisteredUser(false);
    if (errorMessage) {
      setErrorMessage("");
    }
    setPassword(event.target.value);
    setPasswordErr(false);
  };
  const ConfirmPasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (errorMessage) {
      setErrorMessage("");
    }
    setconfirmPassword(event.target.value);
    setRecheckPasswordErr(false);
    setRegisteredUser(false);
  };
  const checkError = () => {
    if (password.length < 12) {
      setPasswordErr("Password length should not be less than 12 character.");
    } else {
      let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      const val = re.test(password);
      val === false
        ? setPasswordErr(
            "Password must have one uppercase one lower case one number and one special character."
          )
        : setPasswordErr(false);
    }
  };
  const checkReenteredPassErr = () => {
    checkError();
    if (passwordErr === false && password !== confirmPassword) {
      setRecheckPasswordErr(
        "Password does not match. Please recheck both password fields"
      );
    } else {
      setRecheckPasswordErr(false);
    }
  };
  const validateEmail = () => {
    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    userName.match(validEmailRegex)
      ? setInvalidEmail(false)
      : setInvalidEmail("Please enter a valid email address.");
  };
  const ValidateCaptcha = (captchaVal: boolean) => {
    setValidCaptcha(captchaVal);
  };
  const captchaDirty = (val: boolean) => {
    setCaptchaInputChecked(val);
    setErrorMessage("");
  };

  const checkAllValidation = () => {};
  return (
    <div className="login-background">
      <img src={Tlogo}></img>
      <form className="center-content" onSubmit={registerUser}>
        <span className="signin-text">Create your account</span>
        <div className="form-container">
          <Grid>
            <Grid.Col className="error">{errorMessage}</Grid.Col>
          </Grid>
          <TextInput
            label="Work mail"
            placeholder="name@company.com"
            onChange={userNameHandler}
            value={userName}
            error={invalidEmail}
            onBlur={validateEmail}
          ></TextInput>
          <PasswordInput
            label="Create password"
            placeholder="12 characters"
            visible={visible}
            onVisibilityChange={toggle}
            value={password}
            onChange={passwordHandler}
            error={passwordErr}
            onBlur={checkError}
            visibilityToggleIcon={({ reveal }) =>
              reveal ? (
                <EyeIcon height={18} width={18} />
              ) : (
                <EyeSlashIcon height={18} width={18} />
              )
            }
          />
          <PasswordInput
            label="Confirm password"
            placeholder="Confirm password"
            visible={visibleReEnter}
            onVisibilityChange={setVisibleReEnter}
            value={confirmPassword}
            onChange={ConfirmPasswordHandler}
            error={recheckPasswordErr}
            onBlur={checkReenteredPassErr}
            visibilityToggleIcon={({ reveal }) =>
              reveal ? (
                <EyeIcon height={18} width={18} />
              ) : (
                <EyeSlashIcon height={18} width={18} />
              )
            }
          />
          {!obj && (
            <Captcha
              isCaptchaValid={ValidateCaptcha}
              isCaptchaInputChecked={captchaDirty}
              removeInputValue={registeredUser}
            />
          )}
          {!obj && (
            <Button
              type="submit"
              fullWidth
              className={
                password.trim().length > 0 &&
                userName.trim().length > 0 &&
                !passwordErr &&
                !recheckPasswordErr &&
                !invalidEmail &&
                confirmPassword.trim().length > 0 &&
                confirmPassword === password &&
                captchaInputChecked
                  ? "backgrnd-magenta sign-in-btn"
                  : "disable-signin sign-in-btn"
              }
            >
              Sign Up
            </Button>
          )}
          {obj && (
            <Button
              type="submit"
              fullWidth
              className={
                password.trim().length > 0 &&
                userName.trim().length > 0 &&
                !passwordErr &&
                !recheckPasswordErr &&
                !invalidEmail &&
                confirmPassword.trim().length > 0 &&
                confirmPassword === password
                  ? "backgrnd-magenta sign-in-btn"
                  : "disable-signin sign-in-btn"
              }
            >
              Sign Up
            </Button>
          )}
          <div className="terms-conditions">
            By signing up, you agree to the{" "}
            <Link to={"/register"} className="terms-privacy-link">
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link to={"/register"} className="terms-privacy-link">
              Privacy Policy.
            </Link>
          </div>
        </div>
        <div className="already-account-container">
          Already have an account?
          <Link to={"/login"} className="magenta">
            Sign In
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginRegistration;
