import { Alert, Flex, Button } from "@mantine/core";
import "./NetReachTotalUser.scss";

export default function NetReachTotalUser(props: any) {
  return (
    <Flex justify={"space-between"} align={"end"} className="net-reach-total">
      <Button
        className={
          props.disabled
            ? "primary-button btn"
            : "secondary-button btn disabled"
        }
        onClick={props.handleGenerateReport}
      >
        Generate Report
      </Button>
      <Alert title="Target Users No." color="cyan">
        {`${props.percentageWiseUser}% (${props.usersCount})`}
      </Alert>
    </Flex>
  );
}
