import { apiSlice } from "../api/apiSlice";
import { gql } from "graphql-request";

export interface UserActivitiesRequest {
  appId: number;
  appName: string;
  activityBy: string;
  fromDate: string;
  toDate: string;
}

const getUserActivityonHourlyBasis = gql`
  query getUserTrends($userActivitiesRequest: UserActivitiesRequest) {
    getUserTrends(userActivitiesRequest: $userActivitiesRequest) {
      appId
      appName
      trendMapper {
        time
        value
        percent
      }
    }
  }
`;

const getUserEngagement = gql`
  query getUserEngagement($userActivitiesRequest: UserActivitiesRequest) {
    getUserEngagement(userActivitiesRequest: $userActivitiesRequest) {
      hourlyResponse {
        value
        name
      }
    }
  }
`;

const getEngagementRate = gql`
  query getEngagementRate($engagementRateRequest: EngagementRateRequest) {
    getEngagementRate(engagementRateRequest: $engagementRateRequest) {
      engagementRateData {
        appId
        appName
        appPercent
        absoluteValue
      }
    }
  }
`;
export const userActivityApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserTrends: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getUserActivityonHourlyBasis,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getUserTrends;
      },
    }),
    getUserEngagement: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getUserEngagement,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getUserEngagement?.hourlyResponse;
      },
    }),
    getEngagementRateBarChart: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getEngagementRate,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getEngagementRate.engagementRateData;
      },
    }),
    getEngagementRateLineChart: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getEngagementRate,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getEngagementRate;
      },
    }),
  }),
});
export const {
  useGetUserTrendsQuery,
  useLazyGetUserTrendsQuery,
  useGetUserEngagementQuery,
  useLazyGetUserEngagementQuery,
  useGetEngagementRateBarChartQuery,
  useGetEngagementRateLineChartQuery,
  useLazyGetEngagementRateBarChartQuery,
  useLazyGetEngagementRateLineChartQuery,
} = userActivityApiSlice;
