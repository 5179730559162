import { FC } from "react";
import { Progress } from "@mantine/core";
import { IProgressBarProps } from "./types";

const ProgressBarComponent: FC<IProgressBarProps> = ({
  value,
  color,
  Barsize,
  barType,
  colorArr,
  valueArr,
  tooltipArr,
  sections,
  isPadding,
}) => {
  const data = [
    {
      value: valueArr[0],
      color: colorArr[0],
      tooltip: tooltipArr[0],
    },
    {
      value: valueArr[1],
      color: colorArr[1],
      tooltip: tooltipArr[1],
    },
  ];
  return (
    <>
      {barType === "barWithtoolTip" ? (
        <div className={isPadding ? "p-2" : ""}>
          <Progress
            radius="md"
            size={Barsize ? Barsize : 14}
            sections={sections !== undefined ? sections : data}
          />
        </div>
      ) : (
        <div className={isPadding ? "py-2" : ""}>
          <Progress
            size={Barsize || "xl"}
            sections={[{ value: value, color: color || "#ff49db" }]}
          />
        </div>
      )}
    </>
  );
};

export default ProgressBarComponent;
