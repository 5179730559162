import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  overIndexPriAppsData: any;
  overIndexComOneAppsData: any;
  overIndexComTwoAppsData: any;
  selectedAppInFilter: string;
  selectedCategoryInFilter: string;
  isSelectedCategory: any;
  overIndexLoader: any;
}

const initialState: InitialState = {
  overIndexPriAppsData: [],
  overIndexComOneAppsData: [],
  overIndexComTwoAppsData: [],
  selectedAppInFilter: "",
  selectedCategoryInFilter: "",
  isSelectedCategory: false,
  overIndexLoader: null,
};

const crossBrowsingSlice = createSlice({
  name: "crossBrowsingData",
  initialState: { ...initialState },
  reducers: {
    setCrossBrowsingData: (state, action) => {
      const {
        overIndexPriAppsData,
        overIndexComOneAppsData,
        overIndexComTwoAppsData,
      } = action?.payload;
      state.overIndexPriAppsData = overIndexPriAppsData;
      state.overIndexComOneAppsData = overIndexComOneAppsData;
      state.overIndexComTwoAppsData = overIndexComTwoAppsData;
    },
    setCrossBrowsingAppFilter: (state, action) => {
      state.selectedAppInFilter = action.payload;
    },
    setCrossBrowsingCategoryFilter: (state, action) => {
      state.selectedCategoryInFilter = action.payload;
    },
    setIsSelectedCategory: (state, action) => {
      state.isSelectedCategory = action.payload;
    },
    setOverIndexLoader: (state, action) => {
      state.overIndexLoader = action.payload;
    },
  },
});

export const {
  setCrossBrowsingData,
  setCrossBrowsingAppFilter,
  setCrossBrowsingCategoryFilter,
  setIsSelectedCategory,
  setOverIndexLoader,
} = crossBrowsingSlice.actions;

export default crossBrowsingSlice.reducer;

export const crossBrowsingData = (state: any) => state.crossBrowsingData;

export const selectOverIndexLoader = (state: any) =>
  state.crossBrowsingData.overIndexLoader;
