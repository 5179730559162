import { useState, useEffect, useRef } from "react";
import Hoc from "@/Hoc/Hoc";
import { useToggle, useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Text,
  Group,
  Button,
  Box,
  Flex,
  Skeleton,
  Spoiler,
  Stack,
  Badge,
} from "@mantine/core";
import "../AudienceBuilder.scss";
import { useLazyGetAudienceSizeQuery } from "@/features/services/audienceBuilder/audienceBuilderApiSlice";
import GenerateAudienceModal from "../../../containers/AudienceReport/GenerateAudienceModal";
import { useGenerateAudienceReportMutation } from "@/features/services/audienceBuilder/audienceBuilderApiSlice";
import CancelGenerateAudienceModal from "../../../containers/AudienceReport/CancelGenerateAudienceModal";
import AudienceInsights from "./AudienceInsights";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useSelector, useDispatch } from "react-redux";
import "./CreateAudienceStyles.scss";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import {
  selectAudienceResultData,
  setSectionOnePayload,
  setSectionTwoPayload,
  setSectionThreePayload,
  setAudienceRouteFlag,
  setSectionOneCondition,
  setSectionTwoCondition,
  setAudienceSectionOneObj,
  setAudienceSectionTwoObj,
  setAudienceSectionThreeObj,
  getSelectedDataSource,
} from "@/features/services/audienceBuilder/audienceBuilderSlice";
import AudienceResultsCriteriaDisplay from "./AudienceResultsCriteriaDisplay";

const AudienceResults = () => {
  const dispatch = useDispatch();
  const theme = useSelector(selectLightTheme);
  const location = useLocation();
  const [getAudienceSize] = useLazyGetAudienceSizeQuery<any>();
  const [audienceResultsData, setAudienceResultsData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<any>(false);
  const sectionOnePayload = useSelector(selectAudienceResultData);
  const selectedDS = useSelector(getSelectedDataSource);

  const [createAudienceResponse, setCreateAudienceResponse] = useState<any>("");

  const navigate = useNavigate();

  const [generateAudienceReport] = useGenerateAudienceReportMutation();
  const [
    cancelModalOpened,
    { open: cancelAudienceModalOpen, close: cancelAudienceModalClose },
  ] = useDisclosure(false);
  const [showMinimisedView, setShowMinimisedView] = useState<any>(true);
  const [topKeywordsMetrics, setTopKeywordsMetrics] = useState<any>([]);

  const navigateToDashboard = () => {
    dispatch(setSectionOnePayload(null));
    dispatch(setSectionTwoPayload(null));
    dispatch(setSectionThreePayload(null));
    dispatch(setAudienceSectionOneObj(null));
    dispatch(setAudienceSectionTwoObj(null));
    dispatch(setAudienceSectionThreeObj(null));
    dispatch(setAudienceRouteFlag(false));
    navigate("/audiences/audienceDashboard");
  };

  const openCancelAudienceModal = () => {
    cancelAudienceModalOpen();
  };

  const closeCancelAudienceModal = () => {
    cancelAudienceModalClose();
  };

  const [criteriaData, setCriteriaData] = useState<any>(null);

  useEffect(() => {
    setAudienceResultsData(sectionOnePayload);
    setCriteriaData(sectionOnePayload);
    calculateWeitageForKeywords(sectionOnePayload?.topKeywordsMetrics);
  }, []);

  const calculateWeitageForKeywords = (data: any) => {
    let finalData = normalizeArray(data);
    setTopKeywordsMetrics(finalData);
  };
  const normalizeArray = (inputArray: any) => {
    let sum = 0;
    for (let value of inputArray) {
      sum += value.value;
    }
    if (sum === 0) {
      return "Cannot normalize array with sum of 0";
    }
    let normalizedArray = [];
    for (let value of inputArray) {
      let normalizedValue = value.value / sum;
      normalizedArray.push({
        name: value.name,
        value: Number(Number(normalizedValue * 100).toFixed(2)),
        weightage: value.value,
      });
    }
    return normalizedArray;
  };

  const createAudience = async (payload: any) => {
    try {
      let keywordObj = topKeywordsMetrics.map(function (item: any) {
        delete item.textStyle;
        delete item.weightage;
        return item;
      });
      const data = await generateAudienceReport({
        createAudienceRequest: {
          dataSource: selectedDS,
          userName: sessionStorage.getItem("username"),
          baseReach:
            audienceResultsData?.audienceSize?.filteredSize?.toString(),
          maxAudienceSize: payload.expectedReach.toString(),
          audienceName: payload.audienceName,
          platforms: payload.platforms,
          expectedReach: payload.expectedReach,
          sectionOneCondition:
            audienceResultsData.filterSectionsDetails.sectionOneCondition,
          sectionTwoCondition:
            audienceResultsData.filterSectionsDetails.sectionTwoCondition,
          topAppsMetrics: audienceResultsData?.topAppsMetrics,
          topCategoryMetrics: audienceResultsData?.topCategoryMetrics,
          topKeywordsMetrics: keywordObj,
          sectionOneFilters: {
            keywords:
              audienceResultsData.filterSectionsDetails
                ?.sectionOneFiltersSpecificCount.keywords !== null
                ? audienceResultsData.filterSectionsDetails
                    ?.sectionOneFiltersSpecificCount.keywords
                : [],
            appCategories:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.appCategories !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionOneFiltersSpecificCount.appCategories
                : [],
            apps:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.apps !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionOneFiltersSpecificCount.apps
                : [],
            include:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.include,
            usages:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.usages !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionOneFiltersSpecificCount.usages
                : [],
            dayPhases:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.dayPhases !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionOneFiltersSpecificCount.dayPhases
                : [],
            stdApps:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.stdApps,
            appCategorySpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.appCategorySpecificCount,
            appSpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.appSpecificCount,
            keywordSpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionOneFiltersSpecificCount.keywordSpecificCount,
          },
          sectionTwoFilters: {
            keywords:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.keywords !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionTwoFiltersSpecificCount.keywords
                : [],
            appCategories:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.appCategories !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionTwoFiltersSpecificCount.appCategories
                : [],
            apps:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.apps !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionTwoFiltersSpecificCount.apps
                : [],
            include:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.include,
            usages:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.usages !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionTwoFiltersSpecificCount.usages
                : [],
            dayPhases:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.dayPhases !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionTwoFiltersSpecificCount.dayPhases
                : [],
            stdApps:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.stdApps,
            appCategorySpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.appCategorySpecificCount,
            appSpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.appSpecificCount,
            keywordSpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionTwoFiltersSpecificCount.keywordSpecificCount,
          },
          sectionThreeFilters: {
            keywords:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.keywords !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionThreeFiltersSpecificCount.keywords
                : [],
            appCategories:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.appCategories !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionThreeFiltersSpecificCount.appCategories
                : [],
            apps:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.apps !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionThreeFiltersSpecificCount.apps
                : [],
            include:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.include,
            usages:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.usages !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionThreeFiltersSpecificCount.usages
                : [],
            dayPhases:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.dayPhases !== null
                ? audienceResultsData.filterSectionsDetails
                    .sectionThreeFiltersSpecificCount.dayPhases
                : [],
            stdApps:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.stdApps,
            appCategorySpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.appCategorySpecificCount,
            appSpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.appSpecificCount,
            keywordSpecificCount:
              audienceResultsData.filterSectionsDetails
                .sectionThreeFiltersSpecificCount.keywordSpecificCount,
          },
        },
      }).unwrap();
      setCreateAudienceResponse("success");
      dispatch(setSectionOnePayload(null));
      dispatch(setSectionTwoPayload(null));
      dispatch(setSectionThreePayload(null));
      dispatch(setAudienceSectionOneObj(null));
      dispatch(setAudienceSectionTwoObj(null));
      dispatch(setAudienceSectionThreeObj(null));
      dispatch(setAudienceRouteFlag(false));
      dispatch(setSectionOneCondition("OR"));
      dispatch(setSectionTwoCondition("OR"));
    } catch (err) {
      setCreateAudienceResponse("error");
    }
  };

  const editAudienceCriteria = () => {
    navigate("/audiences/create", { state: { prevPath: location.pathname } });
  };

  const saveTopKeywords = (data: any) => {
    setTopKeywordsMetrics(data);
  };

  return (
    <>
      {sessionStorage.getItem("audienceBuilderTab") == "true" ? (
        <>
          {showLoader ? (
            <Flex justify={"center"} align={"center"}>
              <Skeleton height={800} className="my-6"></Skeleton>
            </Flex>
          ) : (
            <>
              <Grid
                className={
                  audienceResultsData?.length === 0
                    ? "create-audience-default-container"
                    : "create-audience-container"
                }
                pl={8}
              >
                <Grid.Col span={12} pl={0} pr={0} pb={16}>
                  <Group position="apart">
                    <Text size="lg" className="py-2">
                      Create New Audience
                    </Text>
                    <Group
                      className={theme === "dark" ? "dark-theme-button" : ""}
                    >
                      <Button
                        className="secondary-button btn"
                        onClick={openCancelAudienceModal}
                      >
                        Cancel
                      </Button>
                      <GenerateAudienceModal
                        createAudience={createAudience}
                        createAudienceResponse={createAudienceResponse}
                        keywords={audienceResultsData}
                      />
                    </Group>
                  </Group>
                </Grid.Col>
                {audienceResultsData?.audienceSize?.filteredSize !== 0 && (
                  <Grid.Col
                    className="highlight-bg selected-criteria-with-reach"
                    p={20}
                  >
                    <Grid>
                      <Grid.Col className="heading-text">
                        <Text>Selected criteria with specific reach:</Text>
                      </Grid.Col>
                      <Grid.Col>
                        {showMinimisedView && (
                          <Grid>
                            <Grid.Col
                              className="audience-selected-criteria"
                              md={11}
                            >
                              {criteriaData !== null && (
                                <AudienceResultsCriteriaDisplay
                                  criteriaData={criteriaData}
                                />
                              )}
                            </Grid.Col>
                            <Grid.Col span={"auto"} sx={{ textAlign: "right" }}>
                              <Button
                                className="edit-audience-criteria-btn"
                                onClick={editAudienceCriteria}
                                variant="primary"
                                leftIcon={
                                  <PencilIcon
                                    fontSize={14}
                                    height={14}
                                    width={14}
                                    color="#e20074"
                                  />
                                }
                              >
                                Edit
                              </Button>
                            </Grid.Col>
                          </Grid>
                        )}
                      </Grid.Col>
                    </Grid>
                    {/* <Stack>
                      {showMinimisedView && (
                        <Group className="audience-selected-criteria">
                          <Group>
                            <Box className="section-criteria include">
                              <Group>
                                <Text className="criteria-condition include">
                                  Include
                                </Text>
                                <Text className="criteria-type">
                                  Interests :
                                </Text>
                                <Badge className="criteria-value">
                                  Cricket
                                </Badge>
                                <Badge className="criteria-value">
                                  Football
                                </Badge>
                              </Group>
                            </Box>
                            <Text className="condition one">-OR-</Text>
                            <Box className="section-criteria exclude">
                              <Group>
                                <Text className="criteria-condition exclude">
                                  Exclude
                                </Text>
                                <Text className="criteria-type">Apps :</Text>
                                <Badge className="criteria-value">
                                  Whatsapp
                                </Badge>
                              </Group>
                            </Box>
                            <Text className="condition two">-AND-</Text>
                            <Box className="section-criteria exclude">
                              <Group>
                                <Text className="criteria-condition exclude">
                                  Exclude
                                </Text>
                                <Text className="criteria-type">
                                  Category :
                                </Text>
                                <Badge className="criteria-value">
                                  Finance
                                </Badge>
                              </Group>
                            </Box>
                          </Group>
                          <Button
                            className="edit-audience-criteria-btn"
                            onClick={editAudienceCriteria}
                            variant="primary"
                          >
                            Edit
                          </Button>
                        </Group>
                      )} */}
                    {/* {!showMinimisedView && (
                        <>
                          {sectionData.map((item: any, index: any) => {
                            return (
                              <>
                                {index === 1 && (
                                  <Button
                                    onClick={() =>
                                      sectionOneKeywordConditionToggle()
                                    }
                                    className="condition-toggle-btn"
                                    rightIcon={
                                      <ArrowsUpDownIcon
                                        fontSize={12}
                                        height={12}
                                        width={12}
                                        fill="#e20074"
                                      />
                                    }
                                  >
                                    {sectionOneKeywordCondition}
                                  </Button>
                                )}
                                {index === 2 && (
                                  <Button
                                    onClick={() =>
                                      sectionTwoKeywordConditionToggle()
                                    }
                                    className="condition-toggle-btn"
                                    rightIcon={
                                      <ArrowsUpDownIcon
                                        fontSize={12}
                                        height={12}
                                        width={12}
                                        fill="#e20074"
                                      />
                                    }
                                  >
                                    {sectionTwoKeywordCondition}
                                  </Button>
                                )}

                                <Grid.Col
                                  className="select-keyword-section"
                                  key={item.id}
                                >
                                  <AudienceSection
                                    initialkeywordsList={initialkeywordsList}
                                    keywordsList={keywordsList}
                                    sectionId={item.id}
                                    sectionCount={sectionData.length}
                                    removeSection={removeSection}
                                    updateSectionData={updateSectionData}
                                    sectionName={
                                      index === 0
                                        ? "section-one"
                                        : index === 1
                                        ? "section-two"
                                        : "section-three"
                                    }
                                  />
                                </Grid.Col>
                              </>
                            );
                          })}
                        </>
                      )} */}
                    {/* </Stack> */}
                  </Grid.Col>
                )}

                <Grid.Col p={0}>
                  {audienceResultsData?.audienceSize?.filteredSize !== 0 ? (
                    <AudienceInsights
                      audienceData={audienceResultsData}
                      saveTopKeywords={saveTopKeywords}
                    />
                  ) : (
                    <Grid
                      style={{ paddingTop: "32px" }}
                      className="venn-section"
                    >
                      <Grid.Col span={12}>
                        <Text>
                          No audience found for current users criteria.
                        </Text>
                      </Grid.Col>
                    </Grid>
                  )}
                </Grid.Col>
              </Grid>
              <CancelGenerateAudienceModal
                opened={cancelModalOpened}
                onClose={closeCancelAudienceModal}
                onCancel={navigateToDashboard}
                onContine={closeCancelAudienceModal}
              />
            </>
          )}
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

export default Hoc(AudienceResults);
