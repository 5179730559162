import { FC } from "react";
import { Navigate } from "react-router-dom";

const useAuth = (): boolean => {
  const token: any = sessionStorage.getItem("accesstoken");
  if (token) {
    return true;
  } else {
    return false;
  }
};

interface ProtectedProps {
  children: any;
}

const ProtectedRoutes: FC<ProtectedProps> = ({ children }) => {
  const token = useAuth();

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoutes;
