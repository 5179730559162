import { gql } from "graphql-request";
import { apiSlice } from "../api/apiSlice";

export interface AppQueryParam {
  primaryAppId: number;
  competitiveAppOneId: number | any;
  competitiveAppTwoId: number | any;
  fromDate: string | any;
  toDate: string | any;
  isCategory: boolean | any;
  searchableAppName: string | any;
  categoryName: string | any;
  searchableAppId: any;
}

const getEngagementOverIndexDataQuery = gql`
  query getEngagementOverIndexData($appQueryParam: AppQueryParam) {
    getEngagementOverIndexData(appQueryParam: $appQueryParam) {
      primaryAppEngagementReportData {
        appDataUsageMapper {
          appId
          iconUrl
          appName
          dataUsageCategory
        }
        engagementOverIndexData {
          appId
          appName
          iconUrl
          appUnTappedPercent
          appPxValue
          untappedValue
          appOverLappingCount
        }
      }
      compOneAppEngagementReportData {
        appDataUsageMapper {
          appId
          iconUrl
          appName
          dataUsageCategory
        }
        engagementOverIndexData {
          appId
          appName
          iconUrl
          appUnTappedPercent
          appPxValue
          untappedValue
          appOverLappingCount
        }
      }
      compTwoAppEngagementReportData {
        appDataUsageMapper {
          appId
          iconUrl
          appName
          dataUsageCategory
        }
        engagementOverIndexData {
          appId
          appName
          iconUrl
          appUnTappedPercent
          appPxValue
          untappedValue
          appOverLappingCount
        }
      }
    }
  }
`;

export const reverseOverIndexApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEngagementOverIndexData: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getEngagementOverIndexDataQuery,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
  }),
});

export const {
  useGetEngagementOverIndexDataQuery,
  useLazyGetEngagementOverIndexDataQuery,
} = reverseOverIndexApiSlice;
