import { Modal, Button, Grid, Divider, Text } from "@mantine/core";
import _ from "lodash";
import "./AudienceBlankState.scss";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useSelector } from "react-redux";

const CancelGenerateAudienceModal = (props: any) => {
  const theme = useSelector(selectLightTheme);
  return (
    <>
      <Modal.Root
        opened={props.opened}
        onClose={props.onClose}
        centered
        size="lg"
        className="ma-modal"
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Cancel Audience Creation</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Divider size="xs" className="divider-color" />
            <Grid className="modal-row" m={0}>
              <Grid.Col pl={24} pt={24} pb={40} pr={24}>
                <Text
                  fz="md"
                  style={{ color: theme === "dark" ? "#C1C2C5" : "#000" }}
                >
                  Are you sure, you want to cancel this audience creation? This
                  will delete this audience.
                </Text>
              </Grid.Col>
            </Grid>
            <Divider size="xs" className="divider-color" />
          </Modal.Body>
          <Grid>
            <Grid.Col
              span={12}
              className={
                theme === "dark"
                  ? "dark-theme-button btn-container"
                  : "btn-container"
              }
            >
              <Button
                className={"cancel-btn secondary-button btn"}
                onClick={props.onCancel}
              >
                Cancel
              </Button>
              <Button className="primary-button" onClick={props.onContine}>
                Continue
              </Button>
            </Grid.Col>
          </Grid>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default CancelGenerateAudienceModal;
