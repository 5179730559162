import { Text, Grid, Select, Flex, Button } from "@mantine/core";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import "./TargetingCard.scss";
import { useState } from "react";

export default function TargetingCard(props: any) {
  return (
    <Grid className="w-full ma-targeting-card">
      <Grid.Col span={12} lg={12} md={12} sm={12}>
        <Grid>
          <Grid.Col span={12}>
            <Flex>
              <Flex direction="column">
                <Text fz="sm" className="text-secondary">
                  {props.appLabel}
                </Text>
                <Flex align="center">
                  <Flex align={"center"} className={"ma-app-category"}>
                    <Select
                      placeholder="Pick one"
                      data={props.appList}
                      value={props.value}
                      onChange={props.onChange}
                      clearable
                      searchable
                      nothingFound="no result found..."
                      rightSection={
                        <ChevronDownIcon className="h-4 w-4 mr-4 text-secondary" />
                      }
                      allowDeselect={false}
                    />
                    <Flex
                      className="button-wrapper"
                      justify={"center"}
                      align={"center"}
                    >
                      <input
                        type="button"
                        className={
                          props.selectedInclude === "Include"
                            ? "selected primary-button p-2"
                            : " primary-button p-2"
                        }
                        onClick={props.click}
                        name={props.selectedInclude}
                        value={"Include"}
                      />
                      <input
                        type="button"
                        className={
                          props.selectedExclude === "Exclude"
                            ? "selected primary-button p-2"
                            : " primary-button p-2"
                        }
                        onClick={props.click}
                        name={props.selectedExclude}
                        value="Exclude"
                      />
                    </Flex>
                  </Flex>
                  <Button
                    className={
                      props?.value || props?.isAddButtonDisabled
                        ? "primary-button ml-4"
                        : "primary-button ml-4 disabled"
                    }
                    onClick={(e) => props.handleClick(e)}
                    disabled={!props?.value || props?.isAddButtonDisabled}
                  >
                    Add
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Grid.Col>
        </Grid>
      </Grid.Col>
    </Grid>
  );
}
