import { Grid, Text, Input } from "@mantine/core";

const PercentIcon = () => {
  return <span className="text-secondary">%</span>;
};

export default function NetReachGoal(props: any) {
  return (
    <Grid className="pt-0 pl-8" style={{ borderRight: "1px solid #e5e5e5" }}>
      <Grid.Col span={12} className="pl-0">
        <Text fz="md" className="text-secondary">
          Net Reach Goal <sup style={{ color: "#ff0000" }}>*</sup>
        </Text>
      </Grid.Col>
      <Grid align="center" className="pt-0">
        <Grid.Col span={4}>
          <Input.Wrapper
            id="input-demo"
            withAsterisk
            label=""
            description=""
            error={props.error}
          >
            <Input
              rightSection={<PercentIcon />}
              onChange={props.onChange}
              value={props.value}
              type="number"
              required
              maxLength={3}
            />
          </Input.Wrapper>
        </Grid.Col>
        <Grid.Col span={8}>
          <Text fz="sm" className="text-primary">
            of Unique Users Available
          </Text>
        </Grid.Col>
      </Grid>
    </Grid>
  );
}
