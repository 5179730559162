import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Group,
  Button,
  Grid,
  Checkbox,
  Input,
  Divider,
  Select,
} from "@mantine/core";
import { useEffect, useState, Fragment } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { useGetPlatformListQuery } from "@/features/services/audienceBuilder/audienceBuilderApiSlice";

import "./AudienceBlankState.scss";
import "./GenerateAudienceModal.scss";
import {
  ExclamationTriangleIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useSelector } from "react-redux";

const GenerateAudienceModal = (props: any) => {
  const [opened, { open, close }] = useDisclosure(false);
  const theme = useSelector(selectLightTheme);

  const [enableButton, setEnableButton] = useState(false);
  const [name, setName] = useState<any>("");
  const [expectedReach, setExpectedReach] = useState<number | "">("");
  const [expectedReachFormatter, setExpectedReachFormatter] =
    useState<any>("Million");
  const { data: platformList } = useGetPlatformListQuery<any>("");
  const [destinationData, setDestinationData] = useState<any>([]);
  const [regexErr, setRegexErr] = useState<any>(false);
  const navigate = useNavigate();
  const themeProvider = useSelector(selectLightTheme);

  useEffect(() => {
    if (platformList) {
      const checkboxData = platformList.getPlatformList.map((val: any) => {
        const obj = {
          value: val.platformName,
          checked: false,
          showInput: false,
          inputValue: "",
          id: val.platformId,
        };
        return obj;
      });
      setDestinationData(checkboxData);
    }
  }, [platformList]);
  function valueChecked(e: any, checkedValue: string) {
    const dataToShow = [...destinationData];
    for (let val of dataToShow) {
      if (val.value === checkedValue) {
        val.checked = !val.checked;
        val.showInput = !val.showInput;
        val.inputValue = "";
      }
    }
    setDestinationData(dataToShow);
  }
  function seatIdHandler(event: any, value: any) {
    const dataToShow = [...destinationData];
    for (let val of dataToShow) {
      if (val.value === value) {
        val.inputValue = event;
      }
    }
    setDestinationData(dataToShow);
  }
  useEffect(() => {
    const dataToShow = [...destinationData];
    let regex = /^[a-z0-9:\s]+$/i;
    let checkedValues = [];
    for (let val of dataToShow) {
      if (val.checked) {
        checkedValues.push(val);

        if (!val.inputValue.match(regex) && val.inputValue.length > 0) {
          setEnableButton(false);
          setRegexErr(true);
          return;
        } else {
          setRegexErr(false);
        }
      }
    }
    if (checkedValues.length === 0) {
      setEnableButton(false);
    } else {
      const valContains = _.every(
        checkedValues,
        (item: any) => !Object.values(item).some((value) => value === "")
      );
      !valContains || name === "" || !expectedReach
        ? setEnableButton(false)
        : setEnableButton(true);
    }
  }, [name, destinationData, expectedReach]);
  const enableDisable = () => {};

  function convertReachFormatterToAbsoluteNumber(text: any) {
    const numericValue = parseFloat(text);
    if (expectedReachFormatter.includes("Million")) {
      return Math.abs(numericValue) * 1000000;
    } else if (expectedReachFormatter.includes("Thousand")) {
      return Math.abs(numericValue) * 1000;
    } else {
      return Math.abs(numericValue);
    }
  }
  const genrateReport = () => {
    setEnableButton(false);
    const filteredDestinations = destinationData.filter(
      (item: any) => item.checked
    );

    let platformPayloadObj: any = [];
    filteredDestinations.forEach((item: any) =>
      platformPayloadObj.push({
        platformName: item.value,
        platformId: item.id,
        seatId: item.inputValue,
      })
    );
    props.createAudience({
      audienceName: name,
      expectedReach: convertReachFormatterToAbsoluteNumber(expectedReach),
      platforms: platformPayloadObj,
    });
  };

  function audienceNameHandler(e: any) {
    setName(e.trim());
    enableDisable();
  }

  const closeHandler = () => {
    close();
    setName("");
    setExpectedReach("");
    setEnableButton(false);
    destinationData.map((data: any) => {
      data.checked = false;
      data.showInput = false;
      data.inputValue = "";
    });
  };

  const successErrorCloseHandler = () => {
    closeHandler();
    navigate("/audiences/audienceDashboard");
  };

  const handleDenominatorChange = (e: any) => {
    setExpectedReachFormatter(e.trim());
    enableDisable();
  };
  const [error, setError] = useState(false);

  const handleExpectedReach = (e: any) => {
    if (e.target.value == "" || e.target.value.match(/^[0-9]*\.?[0-9]*$/)) {
      const inputString = e.target.value;
      const dotPosition = inputString.indexOf(".");
      const substringBeforeDot =
        dotPosition !== -1
          ? inputString.substring(0, dotPosition)
          : inputString;
      const lengthBeforeDot = substringBeforeDot.length;
      if (lengthBeforeDot < 4) {
        const validated = e.target.value.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (validated) {
          setExpectedReach(e.target.value);
          enableDisable();
          setError(false);
        }
      } else {
        let value = e.target.value.replace(/[^\d]/g, ""); // Remove non-digit characters
        value = value.replace(/(\d{1,4})/g, "$1."); // Add a dot in any position within a 4-digit group
        value = value.replace(/\.$/, ""); // Add a dot after every 4 digits
        setExpectedReach(value);
        enableDisable();
        setError(false);
      }
    } else {
      setError(true);
    }
  };
  return (
    <>
      {props?.createAudienceResponse !== "success" &&
        props?.createAudienceResponse !== "error" && (
          <Modal.Root
            opened={opened}
            onClose={closeHandler}
            centered
            size="lg"
            className="generate-audiene-modal"
          >
            <Modal.Overlay />
            <Modal.Content
              className={
                themeProvider === "light" ? "light-theme" : "dark-theme"
              }
            >
              <Modal.Header>
                <Modal.Title>Generate Audience</Modal.Title>
                <Modal.CloseButton />
              </Modal.Header>
              <Modal.Body>
                <Divider size="sm" className="divider-color" />
                <Grid className="text-padding destination-text">
                  <Grid.Col pl={0}>Choose Destination </Grid.Col>
                </Grid>
                {regexErr && (
                  <Grid
                    style={{
                      color: "#F03E3E",
                      fontSize: "14px",
                      paddingLeft: "24px",
                    }}
                  >
                    <Grid.Col>
                      {" "}
                      Seat ID and Audience Name should not contain special
                      characters.
                    </Grid.Col>
                  </Grid>
                )}
                <Grid className="content-padding destination-list">
                  {destinationData.map((data: any, index: any) => (
                    <Fragment key={`dest-${index}`}>
                      <Grid.Col span={6}>
                        <Checkbox
                          checked={data.checked}
                          onChange={(event) => valueChecked(event, data.value)}
                          label={data.value}
                        />
                      </Grid.Col>
                      <Grid.Col span={6}>
                        {data.showInput && (
                          <Input
                            size="xs"
                            placeholder="Enter Seat ID"
                            value={data.inputValue}
                            onChange={(e: any) =>
                              seatIdHandler(e.target.value.trim(), data.value)
                            }
                          />
                        )}
                      </Grid.Col>
                    </Fragment>
                  ))}
                </Grid>
                <Grid className="text-padding">
                  Expected Reach from Selected Platform
                </Grid>
                <Grid className="content-padding number-container">
                  <Grid.Col span={8}>
                    <input
                      className="number-input"
                      placeholder="Enter expected reach value"
                      onChange={handleExpectedReach}
                      value={expectedReach}
                      type="text"
                      maxLength={7}
                    />
                    {error && (
                      <span className="error">
                        Please enter the number only
                      </span>
                    )}
                  </Grid.Col>
                  <Grid.Col span={4}>
                    <Select
                      placeholder="Please select"
                      data={["Million", "Thousand"]}
                      defaultValue="Million"
                      onChange={(e: any) => handleDenominatorChange(e)}
                    ></Select>
                  </Grid.Col>
                </Grid>
                <Grid className="text-padding">Audience Name</Grid>
                <Grid className="content-padding">
                  <Grid.Col span={12}>
                    <Input
                      size="sm"
                      placeholder="Enter name"
                      onKeyUp={(e: any) => audienceNameHandler(e.target.value)}
                    />
                  </Grid.Col>
                </Grid>
                <Divider size="sm" className="divider-color" mt={16} />
              </Modal.Body>
              <Grid>
                <Grid.Col
                  span={12}
                  className={
                    theme === "dark"
                      ? "dark-theme-button btn-container"
                      : "btn-container "
                  }
                >
                  <Button className={"cancel-btn"} onClick={closeHandler}>
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    className={enableButton ? "enable" : "disable"}
                    onClick={genrateReport}
                  >
                    {" "}
                    Create Audience
                  </Button>
                </Grid.Col>
              </Grid>
            </Modal.Content>
          </Modal.Root>
        )}
      {props.createAudienceResponse === "success" && (
        <Modal.Root
          opened={opened}
          onClose={successErrorCloseHandler}
          size="lg"
          centered
          className="ma-modal"
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Body>
              <Grid m={0}>
                <Grid.Col className="center-content">
                  <CheckIcon
                    className="h-10 w-10 mt-5"
                    style={{
                      color: "white",
                      background: "#25855A",
                      borderRadius: "50%",
                      padding: "7px",
                    }}
                  />
                </Grid.Col>
                <Grid.Col className="center-content aws-text">Awesome</Grid.Col>
                <Grid.Col sx={{ textAlign: "center" }}>
                  <p className="sucess-text">
                    Your Audience is in creation. You will receive an email to{" "}
                    {sessionStorage.getItem("username")} once your audience is
                    available.
                  </p>
                </Grid.Col>
                <Grid.Col>
                  <Divider size="sm" className="divider-color" />
                </Grid.Col>
                <Grid.Col span={12} className="center-content ok-btn">
                  <Button className="enable" onClick={successErrorCloseHandler}>
                    OK
                  </Button>
                </Grid.Col>
              </Grid>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
      {props?.createAudienceResponse === "error" && (
        <Modal.Root
          opened={opened}
          onClose={successErrorCloseHandler}
          size="lg"
          centered
        >
          <Modal.Overlay />
          <Modal.Content>
            <Modal.Body>
              <Grid m={0}>
                <Grid.Col className="center-content">
                  <ExclamationTriangleIcon
                    className="h-10 w-10 mt-5"
                    color="#e20074"
                    style={{
                      padding: "7px",
                    }}
                  />
                </Grid.Col>
                <Grid.Col className="center-content aws-text">Error</Grid.Col>
                <Grid.Col sx={{ textAlign: "center" }}>
                  <p className="sucess-text">
                    Something went wrong please try again after some time.
                  </p>
                </Grid.Col>
                <Grid.Col>
                  <Divider size="sm" className="divider-color" />
                </Grid.Col>
                <Grid.Col span={12} className="center-content ok-btn">
                  <Button className="enable" onClick={successErrorCloseHandler}>
                    Close
                  </Button>
                </Grid.Col>
              </Grid>
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}

      <Group position="right" spacing={0}>
        <Button
          onClick={open}
          className={
            props?.keywords?.audienceSize?.filteredSize === 0
              ? "disable-signin generate-audience-report-btn"
              : "backgrnd-magenta primary-button generate-audience-report-btn"
          }
          disabled={props?.audienceSize?.filteredSize === 0}
          leftIcon={<UserPlusIcon height={16} width={16} />}
        >
          Generate Audience
        </Button>
      </Group>
    </>
  );
};

export default GenerateAudienceModal;
