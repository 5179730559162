import { FC } from "react";
import { Grid, Text, Select, Flex, Tooltip } from "@mantine/core";
import { OverIndexingProps } from "./OverIndexingReport.types";
import "./OverIndexingReport.scss";
import ByUsesOverIndex from "../../ByUsesOverIndex/ByUsesOverIndex";
import ByOverallOverIndex from "../../ByOverallUseOverIndex/ByOverallOverIndex";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

const OverIndexingReport: FC<OverIndexingProps> = ({
  crossBrowsingData,
  selectedPrimaryApp,
  comparativeAppOne,
  comparativeAppTwo,
  selectUpload,
  onchangeHandle,
  searchableAppId,
}) => {
  return (
    <Grid className="ma-over-index-app mt-3 p-4">
      <Grid.Col span={12}>
        <Grid>
          <Grid.Col span={7}>
            <Flex align={"center"}>
              <Text fz="sm" fw={500} className="mr-3">
                OverIndexing Report
              </Text>
              <Tooltip
                label="Relative app usage of a certain app with respect to overall user base"
                withArrow
                openDelay={500}
                closeDelay={100}
              >
                <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
              </Tooltip>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3} offset={2}>
            <Select
              label=""
              placeholder="Pick one"
              data={[
                { value: "Overall", label: "Overall" },
                { value: "by_upload", label: "By Upload" },
                { value: "by_download", label: "By Download" },
              ]}
              value={selectUpload}
              onChange={onchangeHandle}
            />
          </Grid.Col>
        </Grid>
      </Grid.Col>
      {selectUpload === "Overall" && (
        <>
          {crossBrowsingData !== undefined &&
          (crossBrowsingData?.overIndexPriAppsData.length ||
            crossBrowsingData?.overIndexComOneAppsData ||
            crossBrowsingData?.overIndexComTwoAppsData) ? (
            <>
              {crossBrowsingData?.overIndexPriAppsData && (
                <Grid.Col span={12} className="col" m={8}>
                  <ByOverallOverIndex
                    selectedApp={selectedPrimaryApp}
                    crossBrowsingData={crossBrowsingData?.overIndexPriAppsData}
                    color="#2C7A7B"
                  />
                </Grid.Col>
              )}
              {crossBrowsingData?.overIndexComOneAppsData?.length > 0 && (
                <Grid.Col span={12} className="col" m={8}>
                  <ByOverallOverIndex
                    selectedApp={comparativeAppOne}
                    crossBrowsingData={
                      crossBrowsingData?.overIndexComOneAppsData
                    }
                    color="#6B46C1"
                  />
                </Grid.Col>
              )}
              {crossBrowsingData?.overIndexComTwoAppsData?.length > 0 && (
                <Grid.Col span={12} className="col" m={8}>
                  <ByOverallOverIndex
                    selectedApp={comparativeAppTwo}
                    crossBrowsingData={
                      crossBrowsingData?.overIndexComTwoAppsData
                    }
                    color="#C05621"
                  />
                </Grid.Col>
              )}
            </>
          ) : (
            <Grid.Col span={12}>
              <Text ta="center" fz="sm">
                No data found for selected Dates
              </Text>
            </Grid.Col>
          )}
        </>
      )}
      {(selectUpload === "by_upload" || selectUpload === "by_download") && (
        <Grid.Col span={12} p={0}>
          <ByUsesOverIndex
            selectUpload={selectUpload}
            searchableAppId={searchableAppId}
          />
        </Grid.Col>
      )}
    </Grid>
  );
};

export default OverIndexingReport;
