import { FC } from "react";
import { Grid, Flex, Text } from "@mantine/core";
import "./ByOverallUseOverIndex.scss";
import AppOverIndexDetails from "./AppOverIndexDetails";

interface ByOverallOverIndexProps {
  selectedApp: any;
  crossBrowsingData: any;
  color: any;
}

const ByOverallOverIndex: FC<ByOverallOverIndexProps> = ({
  selectedApp,
  crossBrowsingData,
  color,
}) => {
  return (
    <Grid className="w-full app-row">
      <Grid.Col span={2} className="app-row-bg" p={16}>
        <Flex align={"center"}>
          <img
            src={selectedApp?.iconUrl?.slice(1, -1)}
            style={{ width: 24, height: 24, marginRight: 10 }}
          />
          <Text fz="sm" c={color}>
            {selectedApp?.appName}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={10} className="text-center app-row-bg" p={16}>
        <Text fz="xs">App Ranked as per Untapped Potential</Text>
      </Grid.Col>
      <Grid.Col
        span={12}
        style={{
          alignContent: "center",
        }}
      >
        <Flex>
          {crossBrowsingData.map((newItem: any, newIndex: any) => {
            return (
              <AppOverIndexDetails
                key={newIndex.appName}
                iconUrl={newItem.iconUrl}
                appName={newItem.appName}
                appPotential={newItem.appPxValue}
                value={newItem.appUnTappedPercent}
                color={color}
                untappedPercentage={
                  newItem.appUnTappedPercent?.toFixed(1) + "%"
                }
                item={newItem}
                selectedAppName={selectedApp?.appName}
              />
            );
          })}
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default ByOverallOverIndex;
