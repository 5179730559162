import {
  Modal,
  Button,
  Grid,
  Divider,
  Text,
  Input,
  Badge,
  Tooltip,
  Table,
  Group,
  ActionIcon,
  CloseButton,
} from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PencilIcon, PlusIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import "../AudienceBuilder.scss";

const EditKeyWordsAndWeightage = (props: any) => {
  const [initialKeywords, setInitialKeywords] = useState<any>(props.keywords);
  const [addKeyword, setAddKeyword] = useState(false);
  const [newKeywordName, setNewKeywordName] = useState("");
  const [newKeywordWeightage, setNewKeywordWeightage] = useState("");
  const theme = useSelector(selectLightTheme);
  const [editIndex, setEditIndex] = useState<{ [key: number]: any }>({});
  const [regexErr, setRegexErr] = useState<any>(false);
  const [enableAddButton, setEnableAddButton] = useState(false);
  const [errors, setErrors] = useState<{ [key: number]: string }>({});
  const [newKeywords, setNewKeywords] = useState([]);
  const [duplicateNameError, setDuplicateNameError] = useState<any>(false);

  const weightageRegEx =
    /^(?!0*(?:\.0+)?$)(0*[1-9]\d{0,1}(\.\d{1,2})?|0*\.\d{1,2})$/i;

  const handleEdit = (index: number) => {
    setEditIndex((prev) => ({
      ...prev,
      [index]: initialKeywords[index].value,
    }));
  };

  const handleDelete = (index: number) => {
    if (initialKeywords.length !== 1) {
      let normalizedObj = normalizeArray(
        initialKeywords.filter((_, i) => i !== index)
      );
      setInitialKeywords(normalizedObj);
    }
    let objToDelete = newKeywords.findIndex(
      (item) => item.name === initialKeywords[index].name
    );
    if (objToDelete !== -1) {
      setNewKeywords(newKeywords.splice(0, objToDelete));
    }
  };

  useEffect(() => {
    let regex = /^[a-z\s]+$/i;
    setDuplicateNameError(false);
    if (
      (!newKeywordName.match(regex) && newKeywordName.length > 0) ||
      newKeywordName.length > 31 ||
      (!newKeywordWeightage.match(weightageRegEx) &&
        newKeywordWeightage.length > 0)
    ) {
      setEnableAddButton(false);
      setRegexErr(true);
      return;
    } else if (
      newKeywordName.length === 0 ||
      newKeywordWeightage.length === 0
    ) {
      setEnableAddButton(false);
      setRegexErr(false);
    } else {
      setEnableAddButton(true);
      setRegexErr(false);
    }
  }, [newKeywordName, newKeywordWeightage]);

  const handleAddKeyWordClick = () => {
    setAddKeyword(true);
    setEnableAddButton(false);
  };

  const addKeywordToList = () => {
    const duplicateName = initialKeywords.some(
      (item: any) => item.name.toLowerCase() === newKeywordName.toLowerCase()
    );
    if (duplicateName) {
      setDuplicateNameError(true);
      setEnableAddButton(false);
    } else {
      const newObj = {
        name: newKeywordName,
        value: Number(newKeywordWeightage),
        weightage: "",
        textStyle: { color: "#6B46C1" },
      };
      initialKeywords.unshift(newObj);
      if (
        props.keywordCount !== "" &&
        initialKeywords.length === parseInt(props.keywordCount)
      ) {
        initialKeywords.splice(-1);
      }
      let normalizedObj = normalizeArray(initialKeywords);
      setInitialKeywords(normalizedObj);
      setEnableAddButton(false);
      newKeywords.push(newObj);
      setNewKeywords(newKeywords);
      removeNewAddedKeywordRow();
      setDuplicateNameError(false);
    }
  };

  const normalizeArray = (inputArray: any) => {
    let sum = 0;
    for (let value of inputArray) {
      sum += value.value;
    }
    if (sum === 0) {
      return "Cannot normalize array with sum of 0";
    }
    let normalizedArray = [];
    for (let value of inputArray) {
      let normalizedValue = value.value / sum;
      if (value.textStyle) {
        normalizedArray.push({
          name: value.name,
          value: Number(Number(normalizedValue * 100).toFixed(2)),
          weightage: value.value,
          textStyle: { color: "#6B46C1" },
        });
      } else {
        normalizedArray.push({
          name: value.name,
          value: Number(Number(normalizedValue * 100).toFixed(2)),
          weightage: value.value,
        });
      }
    }
    return normalizedArray;
  };

  const newKeyWordNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewKeywordName(event.target.value);
  };

  const newKeywordWeightageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewKeywordWeightage(event.target.value);
  };

  const editValueOfWeight = (val: any, index: any) => {
    if (!isNaN(val)) {
      setEditIndex((prev) => ({ ...prev, [index]: val }));
      if (!val.match(weightageRegEx)) {
        setErrors((prev) => ({
          ...prev,
          [index]: "error",
        }));
      } else {
        const newErrors = { ...errors };
        delete newErrors[index];
        setErrors(newErrors);
      }
    }
  };

  const removeNewAddedKeywordRow = () => {
    setAddKeyword(false);
    setNewKeywordWeightage("");
    setNewKeywordName("");
  };

  const updateWeightageValue = (index: any) => {
    if (editIndex[index] !== undefined) {
      let intitalValues = initialKeywords;
      let indexValue = intitalValues[index];
      indexValue.value = Number(editIndex[index]);
      intitalValues[index] = indexValue;
      let normalizedObj = normalizeArray(intitalValues);
      setInitialKeywords(normalizedObj);
      const newEditing = { ...editIndex };
      delete newEditing[index];
      setEditIndex(newEditing);
      const newErrors = { ...errors };
      delete newErrors[index];
      setErrors(newErrors);
    }
  };

  const cancelUpdateWeightage = (index: any) => {
    const newEditing = { ...editIndex };
    delete newEditing[index];
    setEditIndex(newEditing);
    const newErrors = { ...errors };
    delete newErrors[index];
    setErrors(newErrors);
  };

  const saveData = () => {
    if (props.keywordCount === "") {
      props.updateOriginalList(initialKeywords);
    } else {
      props.updateOriginalList(newKeywords);
    }
    props.saveKeywordsToCloud(initialKeywords);
  };

  return (
    <>
      <Modal.Root
        opened={props.opened}
        onClose={props.onClose}
        centered
        size="lg"
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Keywords as per criteria</Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Modal.Body>
            <Divider size="xs" className="divider-color" />
            <Grid className="modal-row" m={0}>
              <Grid.Col pl={16} pr={16}>
                <Group
                  position="apart"
                  mt="md"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Group spacing={5}>
                    <Text className="heading-text">
                      Keywords & it's Weightage
                    </Text>
                    <Tooltip
                      multiline
                      withinPortal
                      position="top"
                      width={220}
                      label={
                        <>
                          <Text color="#ffffff">
                            <span className="color-magenta">
                              Keywords:&nbsp;
                            </span>
                            <span className="element-name apps">
                              Specific terms important for your criteria
                            </span>
                          </Text>
                          <Text color="#ffffff">
                            <span className="color-magenta">
                              Weightage:&nbsp;
                            </span>
                            <span className="element-name apps">
                              The importance of each keyword, expressed as a
                              percentage. Weights are adjusted based on
                              normalization logic to ensure all keywords add up
                              to a meaningful total.
                            </span>
                          </Text>
                          <Text color="#ffffff">
                            <span className="color-magenta">
                              Normalization Logic:&nbsp;
                            </span>
                            <span className="element-name apps">
                              Adjusts the weights so they add up to 100%,
                              allowing fair comparison. Think of it like
                              converting all scores to percentages, ensuring
                              each keyword's weight reflects its true
                              importance.
                            </span>
                          </Text>
                        </>
                      }
                      withArrow
                      openDelay={500}
                      closeDelay={100}
                    >
                      <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
                    </Tooltip>
                  </Group>

                  <Button
                    variant="outline"
                    onClick={handleAddKeyWordClick}
                    className="add-new-keyword-btn"
                  >
                    <PlusIcon className="h-4 w-4" />
                    Add New Keyword
                  </Button>
                </Group>
              </Grid.Col>
              <Grid.Col pl={16} pr={16} className="keywords-list">
                <Table>
                  <thead>
                    <tr>
                      <th>Keywords</th>
                      <th>Weightage (%)</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {addKeyword && (
                      <>
                        <tr>
                          <td>
                            <Input
                              size="xs"
                              placeholder=""
                              value={newKeywordName}
                              onChange={newKeyWordNameChange}
                              className="new-keyword-input"
                              maxLength={30}
                            />
                          </td>
                          <td>
                            <Input
                              size="xs"
                              placeholder=""
                              value={newKeywordWeightage}
                              onChange={newKeywordWeightageChange}
                              className="new-keyword-input"
                              type="number"
                            />
                          </td>
                          <td>
                            <Group>
                              <Button
                                size="sm"
                                radius="md"
                                className="primary-button"
                                onClick={addKeywordToList}
                                disabled={!enableAddButton}
                              >
                                <PlusIcon className="h-4 w-4" />
                                Add
                              </Button>
                              <ActionIcon
                                color="red"
                                onClick={removeNewAddedKeywordRow}
                              >
                                <CloseButton size={18} />
                              </ActionIcon>
                            </Group>
                          </td>
                        </tr>
                        {regexErr && (
                          <tr>
                            <td colSpan={3} className="error-row">
                              <Grid
                                style={{
                                  color: "#F03E3E",
                                  fontSize: "14px",
                                }}
                              >
                                <Grid.Col>
                                  {" "}
                                  Keyword name should not contain special
                                  characters & Weightage should contain value
                                  between 0 - 100.
                                </Grid.Col>
                              </Grid>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                    {initialKeywords.map((item: any, index: any) => (
                      <tr key={index}>
                        <td width="40%">
                          {item.name}{" "}
                          {item.textStyle && (
                            <Badge className="new-added-badge">
                              Newly Added
                            </Badge>
                          )}
                        </td>
                        {editIndex[index] !== undefined ? (
                          <td>
                            <Group>
                              <Input
                                value={editIndex[index]}
                                onChange={(e: any) =>
                                  editValueOfWeight(e.target.value, index)
                                }
                                style={{ width: "100px" }}
                                type="number"
                              />
                              <Button
                                className="edit-audience-criteria-btn no-border"
                                onClick={() => updateWeightageValue(index)}
                                variant="primary"
                                disabled={errors[index] !== undefined}
                              >
                                Save
                              </Button>
                              <ActionIcon
                                color="red"
                                onClick={() => cancelUpdateWeightage(index)}
                              >
                                <CloseButton size={18} />
                              </ActionIcon>
                            </Group>
                          </td>
                        ) : (
                          <td>
                            {item.value}
                            <ActionIcon
                              style={{
                                display: "inline-block",
                                marginLeft: "12px",
                              }}
                              onClick={() => handleEdit(index)}
                            >
                              <PencilIcon
                                fontSize={14}
                                height={14}
                                width={14}
                                color="#e20074"
                              />
                            </ActionIcon>
                          </td>
                        )}
                        <td>
                          <ActionIcon
                            color="red"
                            onClick={() => handleDelete(index)}
                          >
                            <CloseButton size={18} />
                          </ActionIcon>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Grid.Col>
              <Grid.Col pl={16} pr={16}>
                <Text
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Total:-
                  {Math.round(
                    initialKeywords.reduce(
                      (total: any, obj: any) => Number(obj.value) + total,
                      0
                    )
                  )}
                </Text>
              </Grid.Col>
            </Grid>
            <Divider size="xs" className="divider-color" />
          </Modal.Body>
          <Grid>
            <Grid.Col
              span={12}
              className={
                theme === "dark"
                  ? "dark-theme-button btn-container"
                  : "btn-container "
              }
            >
              <Button className={"cancel-btn"} onClick={props.onClose}>
                {" "}
                Cancel
              </Button>
              <Button className="primary-button btn" onClick={saveData}>
                Done
              </Button>
            </Grid.Col>
          </Grid>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export default EditKeyWordsAndWeightage;
