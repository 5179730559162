import { useState, useEffect } from "react";
import Hoc from "@/Hoc/Hoc";
import { useDisclosure } from "@mantine/hooks";
import { Divider } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import { Grid, Text, Group, Button, Flex, Skeleton, Box } from "@mantine/core";
import "../AudienceBuilder.scss";
import {
  useLazyGetPersonaKeywordsQuery,
  useLazyGetAudienceSizeQuery,
} from "@/features/services/audienceBuilder/audienceBuilderApiSlice";
import { ArrowsUpDownIcon, PlusIcon } from "@heroicons/react/24/solid";
import CancelGenerateAudienceModal from "../../../containers/AudienceReport/CancelGenerateAudienceModal";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import AudienceSection from "./audienceSection";
import {
  setSectionOnePayload,
  setSectionTwoPayload,
  setSectionThreePayload,
  selectSectionOneData,
  selectSectionTwoData,
  selectSectionThreeData,
  setAudienceResultSliceData,
  selectAudienceSectionOneObj,
  selectAudienceSectionTwoObj,
  selectAudienceSectionThreeObj,
  setAudienceRouteFlag,
  selectAudienceRouteFlag,
  setAllInterestsKeywordsList,
  selectAllInterestKeywordsList,
  setSectionOneCondition,
  setSectionTwoCondition,
  getSectionOneCondition,
  getSectionTwoCondition,
  setAudienceSectionOneObj,
  setAudienceSectionTwoObj,
  setAudienceSectionThreeObj,
  getSelectedDataSource,
} from "@/features/services/audienceBuilder/audienceBuilderSlice";
import { notifications } from "@mantine/notifications";
import dataSource from "../../../assets/images/dataSource.svg";
import "./CreateAudienceStyles.scss";

const CreateAudience = (props: any) => {
  const theme = useSelector(selectLightTheme);
  const dispatch = useDispatch();
  const [fetchSuggestions] = useLazyGetPersonaKeywordsQuery<any>();
  const [getAudienceSize] = useLazyGetAudienceSizeQuery<any>();
  const [audienceResultsData, setAudienceResultsData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState<any>(false);
  const sectionOneObj = useSelector(selectAudienceSectionOneObj);
  const sectionTwoObj = useSelector(selectAudienceSectionTwoObj);
  const sectionThreeObj = useSelector(selectAudienceSectionThreeObj);
  const sectionOneFilters = useSelector(selectSectionOneData);
  const sectionTwoFilters = useSelector(selectSectionTwoData);
  const sectionThreeFilters = useSelector(selectSectionThreeData);
  const allInterestKeywordsList = useSelector(selectAllInterestKeywordsList);

  const location = useLocation();
  const [exploreBtnDisabled, setExploreBtnDisabled] = useState<any>(true);

  const [initialkeywordsList, setInitialkeywordsList] = useState<any>({});
  const [keywordsList, setKeywordsList] = useState<any>([]);

  const navigate = useNavigate();

  const [
    cancelModalOpened,
    { open: cancelAudienceModalOpen, close: cancelAudienceModalClose },
  ] = useDisclosure(false);

  const [sectionData, setSectionData] = useState<any>([]);
  const maxSections = 3;
  const routeBackFlag = useSelector(selectAudienceRouteFlag);
  const sectionOneCondition = useSelector(getSectionOneCondition);
  const sectionTwoCondition = useSelector(getSectionTwoCondition);
  const selectedDS = useSelector(getSelectedDataSource);

  useEffect(() => {
    dispatch(setSectionOnePayload(sectionOneObj));
    dispatch(setSectionTwoPayload(sectionTwoObj));
    dispatch(setSectionThreePayload(sectionThreeObj));
    if (sectionData.length === 0) {
      setExploreBtnDisabled(true);
    } else {
      if (
        sectionData[0] &&
        sectionData[0]?.id !== undefined &&
        (sectionOneObj === null || Object.keys(sectionOneObj).length === 1)
      ) {
        setExploreBtnDisabled(true);
      } else if (
        sectionData[1] &&
        sectionData[1]?.id !== undefined &&
        (sectionTwoObj === null || Object.keys(sectionTwoObj).length === 1)
      ) {
        setExploreBtnDisabled(true);
      } else if (
        sectionData[2] &&
        sectionData[2].id !== undefined &&
        (sectionThreeObj === null || Object.keys(sectionThreeObj).length === 1)
      ) {
        setExploreBtnDisabled(true);
      } else {
        setExploreBtnDisabled(false);
      }
    }
  }, [dispatch, sectionData, sectionOneObj, sectionTwoObj, sectionThreeObj]);

  useEffect(() => {
    setShowLoader(true);
    fetchSuggestions("").then((response: any) => {
      if (response !== undefined && response?.status === "fulfilled") {
        let resObj = response?.data?.getPersonaKeywords.map((item: any) => ({
          category: item.category,
          keywords: [...new Set(item.keywords)],
        }));
        const keywordsList = resObj.map((item: any) => ({
          name: item.category,
          tabContent: item.keywords.map((keyword: any) => ({
            name: keyword,
            isChecked: false,
          })),
        }));
        setInitialkeywordsList(keywordsList);
        setKeywordsList(keywordsList);
        dispatch(setAllInterestsKeywordsList(keywordsList));
        if (routeBackFlag === false) {
          setSectionData([...sectionData, { id: Date.now() }]);
        }
        if (location.state === null) {
          dispatch(setSectionOnePayload(null));
          dispatch(setSectionTwoPayload(null));
          dispatch(setSectionThreePayload(null));
          dispatch(setAudienceSectionOneObj(null));
          dispatch(setAudienceSectionTwoObj(null));
          dispatch(setAudienceSectionThreeObj(null));
          dispatch(setAudienceRouteFlag(false));
          setSectionData([{ id: Date.now() }]);
        }
      }
      setShowLoader(false);
    });
  }, []);

  useEffect(() => {
    if (routeBackFlag == true) {
      setInitialkeywordsList(allInterestKeywordsList);
      let objData = [];
      if (sectionOneObj !== null) {
        objData.push({ id: Date.now() + 1 });
      }
      if (sectionTwoObj !== null) {
        objData.push({ id: Date.now() + 2 });
      }
      if (sectionThreeObj !== null) {
        objData.push({ id: Date.now() + 3 });
      }
      setSectionData(objData);
    }
  }, [routeBackFlag]);

  const addNewSection = () => {
    if (sectionData.length < maxSections) {
      setSectionData([...sectionData, { id: Date.now() }]);
    }
    setExploreBtnDisabled(true);
  };

  const removeSection = (index: any) => {
    const itemIndexToRemove = sectionData.findIndex(
      (item: any) => item.id === index
    );
    if (itemIndexToRemove === 0) {
      dispatch(setAudienceSectionOneObj(sectionTwoObj));
      if (sectionThreeObj !== null) {
        dispatch(setAudienceSectionTwoObj(sectionThreeObj));
        dispatch(setAudienceSectionThreeObj(null));
      } else {
        dispatch(setAudienceSectionTwoObj(null));
      }
    }
    if (itemIndexToRemove === 1) {
      if (sectionThreeObj !== null) {
        dispatch(setAudienceSectionTwoObj(sectionThreeObj));
        dispatch(setAudienceSectionThreeObj(null));
      } else {
        dispatch(setAudienceSectionTwoObj(null));
      }
    }
    if (itemIndexToRemove === 2) {
      dispatch(setAudienceSectionThreeObj(null));
    }

    const updatedDivData = sectionData.filter((data: any) => data.id !== index);
    setSectionData(updatedDivData);
  };

  const navigateToDashboard = () => {
    dispatch(setSectionOnePayload(null));
    dispatch(setSectionTwoPayload(null));
    dispatch(setSectionThreePayload(null));
    dispatch(setAudienceSectionOneObj(null));
    dispatch(setAudienceSectionTwoObj(null));
    dispatch(setAudienceSectionThreeObj(null));
    dispatch(setAudienceRouteFlag(false));
    navigate("/audiences/audienceDashboard");
  };

  const openCancelAudienceModal = () => {
    cancelAudienceModalOpen();
  };

  const closeCancelAudienceModal = () => {
    cancelAudienceModalClose();
  };

  const updateSectionData = (sectionName: any, data: any) => {};

  const retrieveAudienceSize = () => {
    setShowLoader(true);
    getAudienceSize({
      audienceBuilderRequest: {
        sectionOneCondition:
          sectionTwoFilters !== null ? sectionOneCondition : "",
        sectionTwoCondition:
          sectionThreeFilters !== null ? sectionTwoCondition : "",
        sectionOneFilters: sectionOneFilters,
        sectionTwoFilters: sectionTwoFilters === null ? {} : sectionTwoFilters,
        sectionThreeFilters:
          sectionThreeFilters === null ? {} : sectionThreeFilters,
      },
    })
      .then((response: any) => {
        setShowLoader(false);
        if (response !== undefined) {
          dispatch(setAudienceRouteFlag(true));
          setShowLoader(false);
          dispatch(setSectionOnePayload(null));
          dispatch(setSectionTwoPayload(null));
          dispatch(setSectionThreePayload(null));
          navigate("/audiences/results");
          dispatch(setAudienceResultSliceData(response?.data));
        }

        setAudienceResultsData(response?.data);
      })
      .catch((e: any) => {
        setShowLoader(false);
        notifications.show({
          id: "error-audience",
          message: "",
          withCloseButton: true,
          onClose: () => {},
          onOpen: () => {},
          autoClose: 5000,
          title: "Something went wrong",
          color: "red",
          style: { backgroundColor: "red" },
          sx: { backgroundColor: "red" },
          loading: false,
        });
      });
  };

  const toggleSectionOneCondition = () => {
    if (sectionOneCondition === "OR") {
      dispatch(setSectionOneCondition("AND"));
    } else {
      dispatch(setSectionOneCondition("OR"));
    }
  };

  const toggleSectionTwoCondition = () => {
    if (sectionTwoCondition === "OR") {
      dispatch(setSectionTwoCondition("AND"));
    } else {
      dispatch(setSectionTwoCondition("OR"));
    }
  };

  return (
    <>
      {sessionStorage.getItem("audienceBuilderTab") == "true" ? (
        <>
          {showLoader ? (
            <Flex justify={"center"} align={"center"}>
              <Skeleton height={800} className="my-6"></Skeleton>
            </Flex>
          ) : (
            <>
              <Grid
                className={
                  audienceResultsData?.length === 0
                    ? "create-audience-default-container"
                    : "create-audience-container"
                }
                pl={8}
              >
                <Grid.Col span={12} pl={0} pr={0} pb={16}>
                  <Group position="apart">
                    <Group spacing={10}>
                      <Text size="lg">Create New Audience</Text>
                      <Divider size="sm" orientation="vertical" />
                      <Box>
                        <img
                          style={{
                            display: "inline-block",
                          }}
                          src={dataSource}
                        ></img>
                        <span
                          style={{
                            color: "#6e7b91",
                            fontSize: "14px",
                            paddingLeft: "12px",
                            textTransform: "capitalize",
                          }}
                        >
                          Data Source : {selectedDS}
                        </span>
                      </Box>
                    </Group>

                    <Group
                      className={theme === "dark" ? "dark-theme-button" : ""}
                    >
                      <Button
                        className="secondary-button btn"
                        onClick={openCancelAudienceModal}
                      >
                        Cancel
                      </Button>
                    </Group>
                  </Group>
                </Grid.Col>
                <Grid.Col className="highlight-bg" p={24}>
                  <Grid>
                    {sectionData.map((item: any, index: any) => {
                      return (
                        <>
                          {index === 1 && (
                            <Grid.Col
                              className="toggle-area"
                              key={`section-cond-${item.id}`}
                            >
                              <Button
                                onClick={() => toggleSectionOneCondition()}
                                className="condition-toggle-btn"
                                rightIcon={
                                  <ArrowsUpDownIcon
                                    fontSize={12}
                                    height={12}
                                    width={12}
                                    fill="#e20074"
                                  />
                                }
                              >
                                {sectionOneCondition}
                              </Button>
                            </Grid.Col>
                          )}
                          {index === 2 && (
                            <Grid.Col
                              className="toggle-area"
                              key={`section-cond-${item.id}`}
                            >
                              <Button
                                onClick={() => toggleSectionTwoCondition()}
                                className="condition-toggle-btn"
                                rightIcon={
                                  <ArrowsUpDownIcon
                                    fontSize={12}
                                    height={12}
                                    width={12}
                                    fill="#e20074"
                                  />
                                }
                              >
                                {sectionTwoCondition}
                              </Button>
                            </Grid.Col>
                          )}

                          <Grid.Col
                            className="select-keyword-section"
                            key={item.id}
                          >
                            <AudienceSection
                              initialkeywordsList={initialkeywordsList}
                              keywordsList={keywordsList}
                              sectionId={item.id}
                              sectionCount={sectionData.length}
                              removeSection={removeSection}
                              handleSelectedSection={
                                index === 0
                                  ? "section-one"
                                  : index === 1
                                  ? "section-two"
                                  : "section-three"
                              }
                              updateSectionData={(e: any) =>
                                updateSectionData(item, e)
                              }
                              sectionName={
                                index === 0
                                  ? "section-one"
                                  : index === 1
                                  ? "section-two"
                                  : "section-three"
                              }
                              existingSectionData={
                                index === 0
                                  ? sectionOneObj !== null
                                    ? sectionOneObj
                                    : {}
                                  : index === 1
                                  ? sectionTwoObj !== null
                                    ? sectionTwoObj
                                    : {}
                                  : sectionThreeObj !== null
                                  ? sectionThreeObj
                                  : {}
                              }
                            />
                          </Grid.Col>
                        </>
                      );
                    })}

                    {sectionData.length < 3 ? (
                      <Grid.Col sx={{ textAlign: "center" }} p={16} pt={32}>
                        <Button
                          className="add-section-btn"
                          onClick={addNewSection}
                          leftIcon={
                            <PlusIcon
                              fontSize={14}
                              height={14}
                              width={14}
                              color="#e20074"
                            />
                          }
                        >
                          Add Section
                        </Button>
                      </Grid.Col>
                    ) : (
                      <Grid.Col className="max-section-limit">
                        <Text>Max 3 Section Limit Reached</Text>
                      </Grid.Col>
                    )}
                  </Grid>
                </Grid.Col>
                <Grid.Col sx={{ textAlign: "center" }} p={16}>
                  <Button
                    className={"primary-button btn"}
                    disabled={exploreBtnDisabled}
                    onClick={() => retrieveAudienceSize()}
                  >
                    Explore this Audience
                  </Button>
                </Grid.Col>
              </Grid>
              <CancelGenerateAudienceModal
                opened={cancelModalOpened}
                onClose={closeCancelAudienceModal}
                onCancel={navigateToDashboard}
                onContine={closeCancelAudienceModal}
              />
            </>
          )}
        </>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

export default Hoc(CreateAudience);
