import "./login.scss";
import { useState } from "react";
import Tlogo from "../../assets/images/Tlogo.svg";
import { Button, PasswordInput } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import ResetLoginPassword from "./ResetPassword.generated";
import { useLocation, useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export const ForgotPasswordForm = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [visible, { toggle }] = useDisclosure(false);
  const [visibleReEnter, { toggle: setVisibleReEnter }] = useDisclosure(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [recheckPasswordErr, setRecheckPasswordErr] = useState(false);
  //const [enableResendLink, setEnableResrendLink] = useState(false);

  const resetPassword = (event: any) => {
    event.preventDefault();
    ResetLoginPassword(
      // location .state.userName,
      password,
      props.otpentered.join(""),
      (res: any) => {
        res.status === 204 ? navigate("/resetemailsuccess") : "";
      },
      (err: any) => {
        console.log({ err });
        //  setEnableResrendLink(true);
        props.enableDisable();
        notifications.show({
          id: "fail",
          withCloseButton: true,
          onClose: () => {},
          onOpen: () => {},
          autoClose: 5000,
          title: "FAIL",
          message: err?.response?.data,
          color: "red",
          className: "my-notification-class",
          style: { backgroundColor: "white" },
          sx: { backgroundColor: "red" },
          loading: false,
        });
      }
    );
  };
  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setPasswordErr(false);
  };

  const checkError = () => {
    if (password.length < 12) {
      setPasswordErr("Password length should not be less than 12 character.");
    }
    if (password !== confirmPassword) {
      setPasswordErr(
        "Password does not match. Please recheck both password fields"
      );
    } else {
      let re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      const val = re.test(password);
      val === false
        ? setPasswordErr(
            "Password must have one uppercase one lower case one number and one special charater."
          )
        : setPasswordErr(false);
    }
  };
  const ConfirmPasswordHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setconfirmPassword(event.target.value);
    setRecheckPasswordErr(false);
    setPasswordErr(false);
  };
  const checkReenteredPassErr = () => {
    checkError();
    if (passwordErr === false && password !== confirmPassword) {
      setRecheckPasswordErr(
        "Password does not match. Please recheck both password fields"
      );
    } else {
      setRecheckPasswordErr(false);
    }
  };
  return (
    <div>
      <form onSubmit={resetPassword}>
        <div className="form-container">
          <PasswordInput
            label="Create a new password"
            placeholder="12 characters"
            visible={visible}
            onVisibilityChange={toggle}
            value={password}
            onChange={passwordHandler}
            error={passwordErr}
            onBlur={checkError}
            visibilityToggleIcon={({ reveal }) =>
              reveal ? (
                <EyeIcon height={18} width={18} />
              ) : (
                <EyeSlashIcon height={18} width={18} />
              )
            }
          />
          <PasswordInput
            label="Re-enter your password"
            placeholder="12 characters"
            visible={visibleReEnter}
            onVisibilityChange={setVisibleReEnter}
            value={confirmPassword}
            onChange={ConfirmPasswordHandler}
            error={recheckPasswordErr}
            onBlur={checkReenteredPassErr}
            visibilityToggleIcon={({ reveal }) =>
              reveal ? (
                <EyeIcon height={18} width={18} />
              ) : (
                <EyeSlashIcon height={18} width={18} />
              )
            }
          />

          <Button
            type="submit"
            fullWidth
            className={
              password.trim().length > 0 &&
              !passwordErr &&
              !recheckPasswordErr &&
              confirmPassword.trim().length > 0 &&
              confirmPassword === password &&
              /^\d{6}$/.test(props.otpentered.join(""))
                ? "backgrnd-magenta sign-in-btn"
                : "disable-signin sign-in-btn"
            }
          >
            Update Password
          </Button>
        </div>
      </form>
    </div>
  );
};
