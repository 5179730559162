import axios from "axios";
import CryptoJS from "crypto-js";

const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";
const register = (
  email: string,
  password: string,
  insightsPortalAccess: boolean,
  audienceBuilderAccess: boolean,
  callback: any,
  errCallback: any,
) => {
  const cryticPassword = CryptoJS.SHA256(password).toString();
  axios
    .post(
      `${BASE_URL}/keycloak/registerNewUser`,
      {
        email: email,
        enabled: true,
        username: email,
        credentials: [
          {
            type: "password",
            value: cryticPassword,
          },
        ],
        realmRoles: ["INSIGHTS"]
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      errCallback(err);
    });
};

export default register;
