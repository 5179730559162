import { forwardRef } from "react";
import { Grid, Text, Image, Select, Flex } from "@mantine/core";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Frame from "@/assets/images/Frame.png";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  appicon: string;
  label: string;
  value: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ value, label, appicon, ...others }: ItemProps, ref) => {
    return (
      <div {...others} ref={ref} className="select-search">
        <Flex>
          {appicon !== undefined && (
            <Image
              src={appicon}
              style={{ width: "20px", height: "20px" }}
              role={"icons"}
              placeholder={
                <Image
                  src={"../../../assets/images/Frame.png"}
                  style={{ width: "20px", height: "20px" }}
                />
              }
              withPlaceholder
            />
          )}
          <div className="ml-2">
            <Text size="sm">{label}</Text>
          </div>
        </Flex>
      </div>
    );
  },
);

export default function PrimaryAppSelector(props: any) {
  return (
    <Grid className="pt-0 pr-8" style={{ borderRight: "1px solid #e5e5e5" }}>
      <Grid.Col span={12}>
        <Text fz="md" className="text-secondary">
          Primary App <sup style={{ color: "#ff0000" }}>*</sup>
        </Text>
      </Grid.Col>
      <Grid.Col span={12} className="pt-0">
        <Select
          withAsterisk
          placeholder="Enter app name"
          limit={100}
          itemComponent={SelectItem}
          clearable
          value={props.value}
          onChange={props.onChange}
          searchable
          nothingFound="no result found..."
          data={props.data}
          icon={
            <Image
              width={20}
              height={20}
              src={props.value === null || undefined || "" ? Frame : props.src}
              alt={"frame"}
            />
          }
          rightSection={
            <ChevronDownIcon className="h-4 w-4 mr-4 text-secondary" />
          }
        />
      </Grid.Col>
    </Grid>
  );
}
