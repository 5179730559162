import { FC } from "react";
import { Grid, Flex, Text, Tooltip } from "@mantine/core";
import { useSelector } from "react-redux";
import "./ByOverallUseOverIndex.scss";
import { selectInitialAppList } from "../../../features/services/search/searchAppSlice";
import DefaultAppImage from "../../../assets/images/default-app.svg";
import ProgressBarComponent from "../../../features/common/ProgressBarComponent/ProgressBarComponent";

interface AppOverIndexDetailsProps {
  iconUrl: any;
  appName: string;
  appPotential: any;
  color: any;
  untappedPercentage: any;
  value: any;
  item: any;
  selectedAppName: any;
}
const renderToolTip: any = (selectedItem: any, item: any) => {
  return (
    <Flex className="mb-2" direction={"row"}>
      <span>
        <p className="mb-2">
          {selectedItem} users use {item?.appName}&nbsp;
          {item?.appPxValue?.toFixed(2)}x more than average among all users.
        </p>
        <p>
          For {selectedItem} there is {Math.round(item?.appUnTappedPercent)}% (
          {item?.untappedValue}) untapped potential with respect to &nbsp;
          {item?.appName} users.
        </p>
      </span>
    </Flex>
  );
};

const AppOverIndexDetails: FC<AppOverIndexDetailsProps> = ({
  iconUrl,
  appName,
  appPotential,
  color,
  untappedPercentage,
  value,
  item,
  selectedAppName,
}) => {
  const allAppLists = useSelector(selectInitialAppList);
  return (
    <Grid className="w-full ma-user-card">
      <Grid.Col span={12}>
        <div className="user-card">
          <Grid justify={"space-between"} px={10}>
            <Grid.Col span={8}>
              <Flex align={"center"}>
                {iconUrl !== null && (
                  <img
                    src={
                      allAppLists?.filter(
                        (data: any) => data?.id === item.appId,
                      )[0]
                        ? allAppLists?.filter(
                            (data: any) => data?.id === item.appId,
                          )[0].appicon
                        : DefaultAppImage
                    }
                    alt=""
                    style={{ height: 20, width: 20 }}
                    loading="lazy"
                  />
                )}
                <Text fz="md" ml={10}>
                  {appName}
                </Text>
              </Flex>
            </Grid.Col>

            <Grid.Col span={4} className="text-right">
              <Flex direction={"row"} justify="flex-end">
                <Text fz="md">{appPotential?.toFixed(2)}x</Text>
              </Flex>
            </Grid.Col>
          </Grid>
          <Grid px={10}>
            <Grid.Col span={12}>
              <Text fz="xs" className="mb-2">
                Untapped Potential
              </Text>
              <Grid justify="space-between;" align="center">
                <Grid.Col span={10} pl={0}>
                  <Tooltip.Floating
                    label={renderToolTip(selectedAppName, item)}
                    color={""}
                    offset={10}
                    key={appName}
                    multiline
                    width={300}
                  >
                    <div>
                      <ProgressBarComponent
                        Barsize="lg"
                        color={color}
                        value={100 - Math.round(value)}
                        barType={undefined}
                        colorArr={[]}
                        valueArr={[]}
                        tooltipArr={[]}
                        sections={undefined}
                        isPadding={true}
                      />
                    </div>
                  </Tooltip.Floating>
                </Grid.Col>
                <Grid.Col span={2}>
                  <Text fz="xs" className="text-right">
                    {untappedPercentage}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default AppOverIndexDetails;
