import { useEffect, useState } from "react";
import {
  Card,
  Group,
  Text,
  Menu,
  ActionIcon,
  Flex,
  Image,
  Divider,
  Stack,
  Grid,
  Button,
  Tooltip,
  Modal,
  Input,
  Chip,
  Popover,
} from "@mantine/core";
import { useDispatch } from "react-redux";
import {
  InformationCircleIcon,
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { numberFormatter } from "../../Util/utils";
import TargetingIcon from "@/assets/icons/targeting-icon.svg";
import CalculatingIcon from "@/assets/icons/calculating-icon.svg";
import { useDisclosure } from "@mantine/hooks";
import "./MaCard.scss";
import DeleteAudienceModal from "../../containers/AudienceReport/DeleteAudienceModal";
import {
  useDeleteAudienceMutation,
  useUpdateAudienceMutation,
} from "@/features/services/audienceBuilder/audienceBuilderApiSlice";
import { setError, successMessage } from "@/features/services/errorSlice";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useSelector } from "react-redux";
import { notifications } from "@mantine/notifications";
import dataSource from "@/assets/images/dataSource.svg";

const LabledButton = (data: any) => {
  const response = data.data;
  const [opened, setOpened] = useState(false);
  return (
    <Flex align="center" wrap="wrap" gap="xs">
      {response?.sectionOneFilters !== null && (
        <Chip
          className={
            response?.sectionOneFilters?.include
              ? "selected--keywords-section include"
              : "selected--keywords-section exclude"
          }
          defaultChecked={false}
          checked={false}
          size="sm"
          style={{
            width: "auto",
            fontSize: "10px",
          }}
        >
          <Flex>
            <Text
              className={
                response?.sectionOneFilters?.include
                  ? "include-exclude-section include"
                  : "include-exclude-section exclude"
              }
            >
              {response?.sectionOneFilters?.include ? "Include" : "Exclude"}
            </Text>
            <Text className="names">
              <>
                {response?.sectionOneFilters?.appCategories?.length > 0 && (
                  <>
                    <span className="text-secondary">App Categories:-</span>
                    <Tooltip
                      multiline
                      width={220}
                      transitionProps={{ duration: 200 }}
                      position="top-start"
                      withinPortal
                      label={response?.sectionOneFilters?.appCategories?.map(
                        (element: any, index: any) => {
                          return (
                            <span className="element-name">
                              &nbsp;{element}
                              {response?.sectionOneFilters?.appCategories
                                ?.length -
                                1 !==
                              index
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                      )}
                    >
                      <span>
                        {response?.sectionOneFilters?.appCategories?.map(
                          (element: any, index: any) => {
                            return (
                              <span className="element-name">
                                &nbsp;{element}
                                {response?.sectionOneFilters?.appCategories
                                  ?.length -
                                  1 !==
                                index
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      </span>
                    </Tooltip>
                  </>
                )}
                {response?.sectionOneFilters?.apps?.length > 0 && (
                  <>
                    <div>
                      <span className="text-secondary">Apps:-</span>
                      <Tooltip
                        multiline
                        width={220}
                        transitionProps={{ duration: 200 }}
                        position="top-start"
                        withinPortal
                        label={response?.sectionOneFilters?.apps?.map(
                          (element: any, index: any) => {
                            return (
                              <span className="element-name">
                                &nbsp;{element}
                                {response?.sectionOneFilters?.apps?.length -
                                  1 !==
                                index
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      >
                        <span>
                          {response?.sectionOneFilters?.apps?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name apps">
                                  &nbsp;{element}
                                  {response?.sectionOneFilters?.apps?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        </span>
                      </Tooltip>
                      <Tooltip
                        multiline
                        width={220}
                        transitionProps={{ duration: 200 }}
                        position="top-start"
                        withinPortal
                        color="dark"
                        opened={opened}
                        label={
                          <>
                            <Text color="#ffffff">
                              <span className="color-magenta">Apps:-</span>
                              {response?.sectionOneFilters?.apps?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionOneFilters?.apps
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </Text>
                            <Text color="#ffffff">
                              <span className="color-magenta">Usage:-</span>
                              {response?.sectionOneFilters?.usages?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionOneFilters?.usages
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </Text>
                            <Text color="#ffffff">
                              <span className="color-magenta">Day Phase:-</span>
                              {response?.sectionOneFilters?.dayPhases?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionOneFilters?.dayPhases
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </Text>
                          </>
                        }
                        withArrow
                      >
                        <span
                          className="show-more"
                          onClick={() => setOpened((o) => !o)}
                        >
                          More
                        </span>
                      </Tooltip>
                    </div>
                  </>
                )}
                {response?.sectionOneFilters?.keywords?.length > 0 && (
                  <>
                    <span className="text-secondary">Interests:-</span>
                    <Tooltip
                      multiline
                      width={220}
                      transitionProps={{ duration: 200 }}
                      position="top-start"
                      withinPortal
                      label={response?.sectionOneFilters?.keywords?.map(
                        (element: any, index: any) => {
                          return (
                            <span className="element-name">
                              &nbsp;{element}
                              {response?.sectionOneFilters?.keywords?.length -
                                1 !==
                              index
                                ? ", "
                                : ""}
                            </span>
                          );
                        }
                      )}
                    >
                      <span>
                        {response?.sectionOneFilters?.keywords?.map(
                          (element: any, index: any) => {
                            return (
                              <span className="element-name">
                                &nbsp;{element}
                                {response?.sectionOneFilters?.keywords?.length -
                                  1 !==
                                index
                                  ? ", "
                                  : ""}
                              </span>
                            );
                          }
                        )}
                      </span>
                    </Tooltip>
                  </>
                )}
              </>
            </Text>
          </Flex>
        </Chip>
      )}
      {response?.sectionOneCondition !== "" && (
        <Text className="color-magenta">-{response?.sectionOneCondition}-</Text>
      )}
      {response?.sectionOneCondition !== "" && (
        <>
          {response?.sectionTwoFilters !== null && (
            <Chip
              className={
                response?.sectionTwoFilters?.include
                  ? "selected--keywords-section include"
                  : "selected--keywords-section exclude"
              }
              defaultChecked={false}
              checked={false}
              size="sm"
              style={{
                width: "auto",
                fontSize: "10px",
              }}
            >
              <Flex>
                <Text
                  className={
                    response?.sectionTwoFilters?.include
                      ? "include-exclude-section include"
                      : "include-exclude-section exclude"
                  }
                >
                  {/* {data?.data?.sectionOneKeywords[0]?.toUpperCase()} */}
                  {response?.sectionTwoFilters?.include ? "Include" : "Exclude"}
                </Text>
                <Text className="names">
                  <>
                    {response?.sectionTwoFilters?.appCategories?.length > 0 && (
                      <>
                        <span className="text-secondary">App Categories:-</span>
                        <Tooltip
                          multiline
                          width={220}
                          transitionProps={{ duration: 200 }}
                          position="top-start"
                          withinPortal
                          label={response?.sectionTwoFilters?.appCategories?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name">
                                  &nbsp;{element}
                                  {response?.sectionTwoFilters?.appCategories
                                    ?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        >
                          <span>
                            {response?.sectionTwoFilters?.appCategories?.map(
                              (element: any, index: any) => {
                                return (
                                  <span className="element-name">
                                    &nbsp;{element}
                                    {response?.sectionTwoFilters?.appCategories
                                      ?.length -
                                      1 !==
                                    index
                                      ? ", "
                                      : ""}
                                  </span>
                                );
                              }
                            )}
                          </span>
                        </Tooltip>
                      </>
                    )}
                    {response?.sectionTwoFilters?.apps?.length > 0 && (
                      <>
                        <span className="text-secondary">Apps:-</span>
                        <Tooltip
                          multiline
                          width={220}
                          transitionProps={{ duration: 200 }}
                          position="top-start"
                          withinPortal
                          label={response?.sectionTwoFilters?.apps?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name">
                                  &nbsp;{element}
                                  {response?.sectionTwoFilters?.apps?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        >
                          <span>
                            <div className="app-block">
                              {response?.sectionTwoFilters?.apps?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionTwoFilters?.apps
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            </div>
                          </span>
                        </Tooltip>
                        <Tooltip
                          multiline
                          width={220}
                          transitionProps={{ duration: 200 }}
                          position="top-start"
                          withinPortal
                          color="dark"
                          opened={opened}
                          label={
                            <>
                              <Text color="#ffffff">
                                <span className="color-magenta">Apps:-</span>
                                {response?.sectionTwoFilters?.apps?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionTwoFilters?.apps
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </Text>
                              <Text color="#ffffff">
                                <span className="color-magenta">Usage:-</span>
                                {response?.sectionTwoFilters?.usages?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionTwoFilters?.usages
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </Text>
                              <Text color="#ffffff">
                                <span className="color-magenta">
                                  Day Phase:-
                                </span>
                                {response?.sectionTwoFilters?.dayPhases?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionTwoFilters?.dayPhases
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </Text>
                            </>
                          }
                          withArrow
                        >
                          <span
                            className="show-more"
                            onClick={() => setOpened((o) => !o)}
                          >
                            More
                          </span>
                        </Tooltip>
                      </>
                    )}

                    {response?.sectionTwoFilters?.keywords?.length > 0 && (
                      <>
                        <span className="text-secondary">Interests:-</span>
                        <Tooltip
                          multiline
                          width={220}
                          transitionProps={{ duration: 200 }}
                          position="top-start"
                          withinPortal
                          label={response?.sectionTwoFilters?.keywords?.map(
                            (element: any, index: any) => {
                              return (
                                <span className="element-name">
                                  &nbsp;{element}
                                  {response?.sectionTwoFilters?.keywords
                                    ?.length -
                                    1 !==
                                  index
                                    ? ", "
                                    : ""}
                                </span>
                              );
                            }
                          )}
                        >
                          <span>
                            {response?.sectionTwoFilters?.keywords?.map(
                              (element: any, index: any) => {
                                return (
                                  <span className="element-name">
                                    &nbsp;{element}
                                    {response?.sectionTwoFilters?.keywords
                                      ?.length -
                                      1 !==
                                    index
                                      ? ", "
                                      : ""}
                                  </span>
                                );
                              }
                            )}
                          </span>
                        </Tooltip>
                      </>
                    )}
                  </>
                </Text>
              </Flex>
            </Chip>
          )}
          {response?.sectionTwoCondition !== "" && (
            <Text className="color-magenta">
              -{response?.sectionTwoCondition}-
            </Text>
          )}
          {response?.sectionTwoCondition !== "" && (
            <>
              {response?.sectionThreeFilters !== null && (
                <Chip
                  className={
                    response?.sectionThreeFilters?.include
                      ? "selected--keywords-section include"
                      : "selected--keywords-section exclude"
                  }
                  defaultChecked={false}
                  checked={false}
                  size="sm"
                  style={{
                    width: "auto",
                    fontSize: "10px",
                  }}
                >
                  <Flex>
                    <Text
                      className={
                        response?.sectionThreeFilters?.include
                          ? "include-exclude-section include"
                          : "include-exclude-section exclude"
                      }
                    >
                      {response?.sectionThreeFilters?.include
                        ? "Include"
                        : "Exclude"}
                    </Text>
                    <Text className="names">
                      <>
                        {response?.sectionThreeFilters?.appCategories?.length >
                          0 && (
                          <>
                            <span className="text-secondary">
                              App Categories:-
                            </span>
                            <Tooltip
                              multiline
                              width={220}
                              transitionProps={{ duration: 200 }}
                              position="top-start"
                              withinPortal
                              label={response?.sectionThreeFilters?.appCategories?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name">
                                      &nbsp;{element}
                                      {response?.sectionThreeFilters
                                        ?.appCategories?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            >
                              <span>
                                {response?.sectionThreeFilters?.appCategories?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name">
                                        &nbsp;{element}
                                        {response?.sectionThreeFilters
                                          ?.appCategories?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {response?.sectionThreeFilters?.apps?.length > 0 && (
                          <>
                            <span className="text-secondary">Apps:-</span>
                            <Tooltip
                              multiline
                              width={220}
                              transitionProps={{ duration: 200 }}
                              position="top-start"
                              withinPortal
                              label={response?.sectionThreeFilters?.apps?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name apps">
                                      &nbsp;{element}
                                      {response?.sectionThreeFilters?.apps
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            >
                              <span>
                                {response?.sectionThreeFilters?.apps?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name apps">
                                        &nbsp;{element}
                                        {response?.sectionThreeFilters?.apps
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </Tooltip>
                            <Tooltip
                              multiline
                              width={220}
                              transitionProps={{ duration: 200 }}
                              position="top-start"
                              withinPortal
                              color="dark"
                              opened={opened}
                              label={
                                <>
                                  <Text color="#ffffff">
                                    <span className="color-magenta">
                                      Apps:-
                                    </span>
                                    {response?.sectionThreeFilters?.apps?.map(
                                      (element: any, index: any) => {
                                        return (
                                          <span className="element-name apps">
                                            &nbsp;{element}
                                            {response?.sectionThreeFilters?.apps
                                              ?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      }
                                    )}
                                  </Text>
                                  <Text color="#ffffff">
                                    <span className="color-magenta">
                                      Usage:-
                                    </span>
                                    {response?.sectionThreeFilters?.usages?.map(
                                      (element: any, index: any) => {
                                        return (
                                          <span className="element-name apps">
                                            &nbsp;{element}
                                            {response?.sectionThreeFilters
                                              ?.usages?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      }
                                    )}
                                  </Text>
                                  <Text color="#ffffff">
                                    <span className="color-magenta">
                                      Day Phase:-
                                    </span>
                                    {response?.sectionThreeFilters?.dayPhases?.map(
                                      (element: any, index: any) => {
                                        return (
                                          <span className="element-name apps">
                                            &nbsp;{element}
                                            {response?.sectionThreeFilters
                                              ?.dayPhases?.length -
                                              1 !==
                                            index
                                              ? ", "
                                              : ""}
                                          </span>
                                        );
                                      }
                                    )}{" "}
                                  </Text>
                                </>
                              }
                              withArrow
                            >
                              <span
                                className="show-more"
                                onClick={() => setOpened((o) => !o)}
                              >
                                More
                              </span>
                            </Tooltip>
                          </>
                        )}
                        {response?.sectionThreeFilters?.keywords?.length >
                          0 && (
                          <>
                            <span className="text-secondary">Interests:-</span>
                            <Tooltip
                              multiline
                              width={220}
                              transitionProps={{ duration: 200 }}
                              position="top-start"
                              withinPortal
                              label={response?.sectionThreeFilters?.keywords?.map(
                                (element: any, index: any) => {
                                  return (
                                    <span className="element-name">
                                      &nbsp;{element}
                                      {response?.sectionThreeFilters?.keywords
                                        ?.length -
                                        1 !==
                                      index
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                }
                              )}
                            >
                              <span>
                                {response?.sectionThreeFilters?.keywords?.map(
                                  (element: any, index: any) => {
                                    return (
                                      <span className="element-name">
                                        &nbsp;{element}
                                        {response?.sectionThreeFilters?.keywords
                                          ?.length -
                                          1 !==
                                        index
                                          ? ", "
                                          : ""}
                                      </span>
                                    );
                                  }
                                )}
                              </span>
                            </Tooltip>
                          </>
                        )}
                      </>
                    </Text>
                  </Flex>
                </Chip>
              )}
            </>
          )}
        </>
      )}
    </Flex>
  );
};

export default function MaCard(props: any) {
  const theme = useSelector(selectLightTheme);
  const [opened, { open, close }] = useDisclosure(false);
  const dispatch = useDispatch();
  const [
    deleteModalOpened,
    { open: deleteModalOpen, close: deleteModalClose },
  ] = useDisclosure(false);
  const [deleteAudience] = useDeleteAudienceMutation();
  const [updateAudience] = useUpdateAudienceMutation();
  const { element } = props;
  const [errorAudiences, setErrorAudiences] = useState<any>([]);
  const [regexErr, setRegexErr] = useState<any>(false);
  const [enable, setEnable] = useState<any>(false);

  const openDeleteModal = () => {
    deleteModalOpen();
  };

  const closeDeleteModal = () => {
    deleteModalClose();
    setSeatIdValue({});
  };

  const deleteAudienceClick = async () => {
    try {
      const data = await deleteAudience({
        audienceId: element?.audienceId,
      }).unwrap();
      if (data.deleteAudience) {
        closeDeleteModal();
        //dispatch(successMessage("Successfully deleted audience."));
        notifications.show({
          message: "Successfully deleted audience.",
          color: "green",
        });
        props.reloadAudience();
      } else {
        // dispatch(
        //   setError(
        //     "Audience delete was not successful. Please try after some time."
        //   )
        // );

        notifications.show({
          message:
            "Audience delete was not successful. Please try after some time.",
        });
      }
    } catch (err) {
      //dispatch(setError(err.message));
      notifications.show({
        message:
          "Audience delete was not successful. Please try after some time.",
      });
    }
  };
  const updateAudienceClick = async () => {
    try {
      let platformList: any = [...errorAudiences];
      platformList.forEach((object: any) => {
        delete object["reachForPlatform"];
        delete object["error"];
        object.platformId = parseInt(object.platformId);
      });
      const data = await updateAudience({
        updateAudienceRequest: {
          audienceId: parseInt(element?.audienceId),
          audienceName: element?.audienceName,
          platforms: platformList,
          expectedReach: null,
        },
      }).unwrap();
      if (data.updateAudience) {
        close();
        dispatch(successMessage("Successfully updated audience."));

        props.reloadAudience();
      } else {
        dispatch(
          setError(
            "Audience update was not successful. Please try after some time."
          )
        );
      }
    } catch (err: any) {
      dispatch(setError("Not able to update audience request for now."));
    }
  };
  const [count, setCount] = useState<any>(0);
  useEffect(() => {
    element?.platforms?.forEach((item: any) => {
      if (item?.reachForPlatform === null) {
        setCount(count + 1);
      }
    });
    let errorObj = element?.platforms?.filter(
      (item: any) => item.error !== null
    );
    setErrorAudiences(errorObj);
  }, [element?.platforms]);

  const seatIdHandler = (event: any, value: any) => {
    const dataToShow = JSON.parse(JSON.stringify(errorAudiences));
    const modifiedEmployees = dataToShow.map((obj: any) => {
      if (obj.platformId === value) {
        return { ...obj, seatId: event };
      }
      return obj;
    });
    setErrorAudiences(modifiedEmployees);
  };
  const [seatIdValues, setSeatIdValue] = useState<any>({});
  const handleChange = (e: any, i: any) => {
    const { id, value } = e.target;
    let regex = /^[a-z0-9\s]+$/i;

    if (e.target.value === "" || e.target.value.match(regex)) {
      setSeatIdValue((prev: any) => ({ ...prev, [id]: value }));
      seatIdHandler(value, i);
      setRegexErr(false);
      setEnable(true);
    } else {
      setSeatIdValue((prev: any) => ({ ...prev, [id]: value }));
      seatIdHandler(value, i);
      setRegexErr(true);
      setEnable(false);
    }
    if (e.target.value === "") {
      setEnable(false);
    }
  };

  const cancelErrorModal = () => {
    close();
    setSeatIdValue({});
    setRegexErr(false);
  };
  return (
    <>
      <Card
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        style={{ height: "calc(99% - calc(1rem / 2))" }}
        className={
          errorAudiences?.length > 0
            ? "my-2 ma-card error-border"
            : "my-2 ma-card"
        }
      >
        <Card.Section className="p-4">
          <Group position="apart">
            <Text>{element?.audienceName}</Text>
            <Menu withinPortal position="bottom-end" shadow="sm">
              <Menu.Target>
                <ActionIcon>
                  <EllipsisHorizontalIcon />
                </ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item disabled>Rename</Menu.Item>
                <Menu.Item onClick={() => openDeleteModal()}>Delete</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
          {sessionStorage?.admin && (
            <Text>
              <span className="text-secondary text-xs">Created by: </span>
              <span className="text-xs">{element?.userName}</span>
            </Text>
          )}
          <Flex align="center" className="mt-4">
            <Text className="pr-4 status-block calculating">
              <Flex align={"center"}>
                {element?.status === "Calculating" ? (
                  <Image width={20} src={CalculatingIcon} className="mr-1" />
                ) : (
                  <Image width={20} src={TargetingIcon} className="mr-1" />
                )}

                {element?.status}
              </Flex>
            </Text>

            <Divider orientation="vertical" />
            <Text className="pl-4 creation-date pr-4">
              {element?.creationDate}
            </Text>
            <Divider orientation="vertical" />
            <Text className="pl-4 pr-4">
              <img
                style={{
                  display: "inline-block",
                  marginTop: "-4px",
                }}
                src={dataSource}
                className="pr-1"
              ></img>
              {element?.dataSource}
            </Text>
          </Flex>

          <Grid className="base-reach-block">
            <Grid.Col>
              <LabledButton data={props?.element} />
            </Grid.Col>
            <Grid.Col>
              <Flex direction={"row"} justify={"space-between"}>
                <Text className="pr-1 text-secondary flex">
                  Base Reach
                  <Tooltip
                    label="User base used for Model-Training"
                    multiline
                    w={150}
                    withArrow
                  >
                    <InformationCircleIcon className="h-6 w-6 ml-2 cursor-pointer" />
                  </Tooltip>
                </Text>
                <Text className="pl-4">
                  {element?.baseReach !== null &&
                    numberFormatter(element?.baseReach)}
                </Text>
              </Flex>
            </Grid.Col>
            {props.element.topKeywordsMetrics && (
              <Grid.Col className="keyword-section" style={{}}>
                <Flex style={{ justifyContent: "space-between" }}>
                  <Group spacing={5}>
                    <Text className="heading-text">Keywords</Text>
                    <Tooltip
                      label="Keywords and weightage"
                      position="top"
                      zIndex={1000}
                    >
                      <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
                    </Tooltip>
                  </Group>
                  <Text>{props.element.topKeywordsMetrics.length}</Text>
                </Flex>
              </Grid.Col>
            )}
          </Grid>
          <Flex justify={"space-between"}>
            <Flex>
              <Text className="pb-3 pr-1 platform-list-heading">
                Final Reach per Platform ({element?.platforms?.length})
              </Text>
              <Tooltip
                offset={0}
                multiline
                w={220}
                withArrow
                label="Addressable Users in the calculated Segment that can be used for targeting"
              >
                <InformationCircleIcon className="h-6 w-6 cursor-pointer" />
              </Tooltip>
            </Flex>
            <Text fz="sm">
              {errorAudiences?.length > 0 && (
                <>
                  <span className="magenta-color ma-card-error">
                    {errorAudiences?.length} Errors -
                  </span>
                  <span
                    className="show-more cursor-pointer ma-card-error"
                    onClick={open}
                  >
                    View Error
                  </span>
                </>
              )}
            </Text>
          </Flex>
          <Stack className="platform-list">{props.children}</Stack>
        </Card.Section>
        <Card.Section>
          <Flex justify={"center"}>
            <Text
              className="show-more cursor-pointer mb-5"
              onClick={props.handleClick}
            >
              View Details
            </Text>
          </Flex>
        </Card.Section>
      </Card>
      <Modal.Root
        opened={opened}
        onClose={() => {
          cancelErrorModal();
        }}
        className="error-modal"
        size={600}
        centered
      >
        <Modal.Overlay />
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>
              <Flex align={"center"}>
                <ExclamationTriangleIcon
                  className="h-6 w-6 mx-2"
                  color="#e20074"
                />
                <Text>Error - {element?.audienceName}</Text>
              </Flex>
            </Modal.Title>
            <Modal.CloseButton />
          </Modal.Header>
          <Divider></Divider>
          {regexErr && (
            <Grid
              style={{
                color: "#F03E3E",
                fontSize: "14px",
                paddingLeft: "24px",
                paddingTop: "12px",
              }}
            >
              <Grid.Col>
                Seat ID should not contain special characters.
              </Grid.Col>
            </Grid>
          )}
          <Modal.Body>
            <Text fz="sm" className="mb-2">
              Invalid Seat ID for “
              {Array.prototype.map
                .call(errorAudiences, function (item) {
                  return item.platformName;
                })
                .join(",")}
              ” Platform. Please enter correct ID.
            </Text>
            {errorAudiences?.length > 0 &&
              errorAudiences.map((errorItem: any, i: any) => {
                return (
                  <Flex
                    justify={"space-between"}
                    className="mb-6"
                    key={errorItem.platformId}
                  >
                    <Text>{errorItem.platformName}</Text>
                    <Input
                      size="xs"
                      placeholder="Enter Seat ID"
                      onChange={(e: any) => {
                        handleChange(e, errorItem.platformId);
                      }}
                      value={seatIdValues[i]}
                      name={seatIdValues[i]}
                      id={i}
                    />
                  </Flex>
                );
              })}
            {/* <Flex justify={"space-between"} className="mb-6">
              <Text>Trade-desk</Text>
              <Input size="xs" placeholder="Enter Seat ID" />
            </Flex>
            <Flex justify={"space-between"} className="mb-6">
              <Text>DV360</Text>
              <Input size="xs" placeholder="Enter Seat ID" />
            </Flex> */}
            <Divider></Divider>
          </Modal.Body>
          <Flex
            justify={"flex-end"}
            className={
              theme === "dark" ? "dark-theme-button pr-4 pb-4" : "pr-4 pb-4"
            }
          >
            <Button
              className="secondary-button mr-5"
              onClick={cancelErrorModal}
            >
              Cancel
            </Button>
            <Button
              className={regexErr || !enable ? "disable" : "primary-button"}
              onClick={updateAudienceClick}
            >
              Update Seat Id
            </Button>
          </Flex>
        </Modal.Content>
      </Modal.Root>
      <DeleteAudienceModal
        opened={deleteModalOpened}
        onClose={closeDeleteModal}
        title={element?.audienceName}
        onDelete={deleteAudienceClick}
      />
    </>
  );
}
