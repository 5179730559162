import React, { useState } from "react";
import { Input, Button, Grid } from "@mantine/core";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import "./login.scss";

type CaptchaProps = {
  isCaptchaValid: (val: boolean) => any;
  isCaptchaInputChecked: (val: boolean) => any;
  removeInputValue?: boolean;
};

const Captcha: React.FC<CaptchaProps> = ({
  isCaptchaValid,
  isCaptchaInputChecked,
  removeInputValue,
}) => {
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [userInput, setUserInput] = useState("");

  function generateCaptcha() {
    const possibleChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let captcha = "";
    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * possibleChars.length);
      captcha += possibleChars.charAt(randomIndex);
    }
    return captcha;
  }
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setUserInput(event.target.value);
    isCaptchaInputChecked(true);
    if (event.target.value.length > 1) {
      isCaptchaInputChecked(true);
    }
    if (captcha === event.target.value) {
      isCaptchaValid(true);
    }
    if (captcha !== event.target.value) {
      isCaptchaValid(false);
    }
  }
  const handleRefresh = () => {
    setUserInput("");
    setCaptcha(generateCaptcha());
    isCaptchaValid(false);
  };
  const handleCopy = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();
  };
  removeInputValue && userInput.length > 0 ? handleRefresh() : "";

  return (
    <Grid style={{ paddingTop: "22px" }}>
      <Grid.Col span={5}>
        <Input
          value={captcha}
          onCopy={handleCopy}
          readOnly
          className="captchContainer"
          styles={{
            input: {
              userSelect: "none",
            },
          }}
          rightSection={
            <Button
              sx={{ paddingRight: "15px" }}
              className="refresh-button"
              variant="link"
              size="xs"
              color="#fff"
              onClick={handleRefresh}
              rightIcon={
                <ArrowPathIcon
                  fontSize={16}
                  height={16}
                  width={16}
                  color="#fff"
                />
              }
            />
          }
        ></Input>
      </Grid.Col>
      <Grid.Col span={7}>
        <Input
          placeholder="Enter text from side"
          onChange={handleInputChange}
          value={userInput}
        ></Input>
      </Grid.Col>
    </Grid>
  );
};

export default Captcha;
