import { ComponentType } from "react";
import { Breadcrumbs, Anchor, Text, useMantineTheme } from "@mantine/core";
import AppHeader from "@/containers/header/AppHeader";
import AppFooter from "@/containers/footer/AppFooter";
import { useMatches } from "react-router-dom";

import "./Hoc.scss";

export function capitalizeFirstLetter(string: string) {
  return (
    string?.charAt(0)?.toUpperCase() +
    string
      ?.slice(1)
      ?.replace(/([A-Z])/g, " $1")
      ?.trim()
  );
}

export const getMenuHref = (item: any) => {
  if (item === "appProfile") {
    return "trending";
  } else if (item === "insights") {
    return "insights/competitiveResearch";
  } else if (item === "accessmanagment") {
    return "home/accessmanagment";
  } else if (item === "home") {
    return "trending";
  }
  return item === "audiences" ? "audiences/audienceDashboard" : item;
};

export const getMenuItemText = (item: any) => {
  if (item === "appProfile") {
    return capitalizeFirstLetter("home");
  }
  if (item === "create") {
    return capitalizeFirstLetter("Create New Audience");
  }
  if (item === "accessmanagment") {
    return capitalizeFirstLetter("User Access Management");
  }

  return item === "audiences"
    ? capitalizeFirstLetter("My Audiences")
    : capitalizeFirstLetter(item);
};

const Hoc =
  <P extends object>(WrappedComponent: ComponentType<P>) =>
  (props: P) => {
    const match = useMatches();
    let arr: string = "";
    const theme = useMantineTheme();
    for (const element of match) {
      arr += element.pathname;
    }
    const items: any = arr
      .split("/")
      .slice(1)
      .map((item: any) => {
        return (
          <Anchor key={item} href={"/" + getMenuHref(item)}>
            <Text size="xs">{getMenuItemText(item)}</Text>
          </Anchor>
        );
      });
    return (
      <>
        <AppHeader
          colors={theme.colorScheme === "dark" ? "gray" : "dark"}
          lightBg={theme.colors.yellow[4]}
          darkBg={"#e20074"}
        />
        <div className="wrapper-component">
          {items.length > 1 && (
            <Breadcrumbs className="pb-5" separator={">"} role="breadcrumbs">
              {items}
            </Breadcrumbs>
          )}
          <WrappedComponent {...props} />
        </div>
        <AppFooter />
      </>
    );
  };

export default Hoc;
