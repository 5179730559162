import noResults from "src/assets/images/noResults.svg";
import { Text, Stack } from "@mantine/core";

const NoApp = () => {
  return (
    <Stack align="center">
      <img
        src={noResults}
        style={{
          maxWidth: "156px",
          maxHeight: "126px",
          marginTop: "50px",
        }}
      />
      <Text>No App Found</Text>
      <Text>Please check app name or enter different app</Text>
    </Stack>
  );
};

export default NoApp;
