import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Text,
  Group,
  Card,
  Flex,
  SimpleGrid,
  Select,
  Stack,
  Box,
  Image,
  Tooltip,
  Button,
} from "@mantine/core";
import ProgressBarComponent from "../../../features/common/ProgressBarComponent/ProgressBarComponent";
import PieChartComponent from "../../../features/common/PieChartComponent/PieChartComponent";
import SessionCard from "../../Insights/InsightsUserActivity/SessionCard/SessionCard";
import "../AudienceBuilder.scss";
import { numberFormatter } from "@/Util/utils";
import { selectAudienceAppList } from "../../../features/services/search/searchAppSlice";
import { UsersIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import WordCloud from "../WordCloud";
import { useLazyGetSearchedAppQuery } from "@/features/services/search/serachAppApiSlice";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { PencilIcon } from "@heroicons/react/24/solid";
import { useDisclosure } from "@mantine/hooks";
import EditKeyWordsAndWeightage from "./EditKeyWordsAndWeightage";
import { Chip } from "@mantine/core";

const AudienceInsights = (props: any) => {
  const [fetchAppList] = useLazyGetSearchedAppQuery<any>();
  const theme = useSelector(selectLightTheme);
  const userEngagementColor = [
    "#FDD835",
    "#FFF59D",
    "#F5F7FA",
    "#FEB2B2",
    "#C53030",
  ];

  const selectInitialApp = useSelector(selectAudienceAppList);

  const downloadAndUpload = [
    { label: "By Downloads", value: "download_data_usage" },
    { label: "By Uploads", value: "upload_data_usage" },
  ];

  const [downloadupload, setDownloadUploads] = useState<string | null>(null);
  const [insightsData, setAudienceInsightsData] = useState<any>({});
  const [openpopUp, { open: setOpenPopUp, close: closeOpenPopUp }] =
    useDisclosure(false);

  const [originalTopKeywords, setOriginalTopKeywords] = useState<any>({});
  const [topKeywords, setTopKeywords] = useState<any>({});
  const [keywordCount, setKeywordCount] = useState<any>("");

  const closeAudiencePopUp = () => {
    closeOpenPopUp();
  };

  const handleEdit = () => {
    setOpenPopUp();
  };
  useEffect(() => {
    setDownloadUploads(downloadAndUpload[0].value);
  }, []);

  useEffect(() => {
    if (props?.audienceData.length !== 0) {
      let audienceInsightsData = JSON?.parse(
        JSON?.stringify(props?.audienceData)
      );
      let {
        audienceSize: { filteredSize },
      } = audienceInsightsData;

      audienceInsightsData?.deviceBrandDistributionMetrics?.forEach(
        (item: any) => {
          item.percent = ((item.value / filteredSize) * 100).toFixed(2);
        }
      );
      audienceInsightsData?.networkDistributionMetrics?.forEach((item: any) => {
        item.percent = ((item.value / filteredSize) * 100).toFixed(2);
      });
      audienceInsightsData?.downloadUsageMetrics?.forEach(
        (item: any, index: any) => {
          item.value = parseFloat(
            ((item.value / filteredSize) * 100).toFixed(2)
          );

          item.color = userEngagementColor[index];
          item.label = item.value + "%";
          item.tooltip = item.name;
        }
      );
      audienceInsightsData?.uploadUsageMetrics.forEach(
        (item: any, index: any) => {
          item.value = parseFloat(
            ((item.value / filteredSize) * 100).toFixed(2)
          );
          item.color = userEngagementColor[index];
          item.label = item.value + "%";
          item.tooltip = item.name;
        }
      );

      setAudienceInsightsData(audienceInsightsData);

      calculateWeitageForKeywords(audienceInsightsData?.topKeywordsMetrics);
    }
  }, [props.audienceData]);

  const calculateWeitageForKeywords = (data: any) => {
    let finalData = normalizeArray(data);
    setOriginalTopKeywords(finalData);
    setTopKeywords(finalData);
  };
  const normalizeArray = (inputArray: any) => {
    let sum = 0;
    for (let value of inputArray) {
      sum += value.value;
    }
    if (sum === 0) {
      return "Cannot normalize array with sum of 0";
    }
    let normalizedArray = [];
    for (let value of inputArray) {
      let normalizedValue = value.value / sum;
      if (value.textStyle) {
        normalizedArray.push({
          name: value.name,
          value: Number(Number(normalizedValue * 100).toFixed(2)),
          weightage: value.value,
          textStyle: { color: "#6B46C1" },
        });
      } else {
        normalizedArray.push({
          name: value.name,
          value: Number(Number(normalizedValue * 100).toFixed(2)),
          weightage: value.value,
        });
      }
    }
    return normalizedArray;
  };

  const handleDownloadsAndUploads = (e: any) => {
    setDownloadUploads(e);
  };
  const [topAppsMetrics, setIcon] = useState([]);
  useEffect(() => {
    if (
      selectInitialApp !== null &&
      (insightsData !== null || insightsData !== undefined)
    ) {
      if (
        insightsData?.topAppsMetrics !== undefined &&
        insightsData?.topAppsMetrics.length > 0
      ) {
        let topAppData = insightsData?.topAppsMetrics.map((tItem: any) => {
          let appItem = selectInitialApp?.filter(
            (aItem: any) =>
              tItem.name.toLowerCase() == aItem.value.toLowerCase() ||
              tItem.name.toLowerCase() == aItem.mName.toLowerCase()
          )[0];

          if (appItem !== undefined) {
            return {
              value: appItem.value,
              appicon: appItem.appicon,
              label: tItem.value,
              mName: appItem.mName,
            };
          }
        });
        setIcon(
          topAppData.filter(function (element: any) {
            return element !== undefined;
          })
        );
      }
    } else {
      fetchAppList("").then((res: any) => {
        if (res !== undefined) {
          let topAppData = insightsData?.topAppsMetrics.map((tItem: any) => {
            let appItem = res?.data?.filter(
              (aItem: any) =>
                tItem.name.toLowerCase() == aItem.value.toLowerCase()
            )[0];
            return {
              value: appItem.value,
              appicon: appItem.appicon,
              label: tItem.value,
              mName: appItem.mName,
            };
          });
          setIcon(topAppData);
        }
      });
    }
  }, [selectInitialApp, fetchAppList, insightsData]);

  const selectKeywordFilterCount = (count: any) => {
    setKeywordCount(count);
    let finalData = normalizeArray(topKeywords.slice(0, parseInt(count)));
    setOriginalTopKeywords(finalData);
    props.saveTopKeywords(finalData);
  };

  const saveKeywords = (data: any) => {
    setOriginalTopKeywords(data.sort((a: any, b: any) => b.value - a.value));
    closeAudiencePopUp();
    props.saveTopKeywords(data.sort((a: any, b: any) => b.value - a.value));
  };

  const updateOriginalList = (data: any) => {
    if (keywordCount !== "") {
      let sortedData = topKeywords
        .concat(data)
        .sort((a: any, b: any) => b.value - a.value);
      setTopKeywords(sortedData);
    } else {
      setTopKeywords(data.sort((a: any, b: any) => b.value - a.value));
    }
  };

  return (
    <>
      <Grid
        style={{ paddingTop: "32px" }}
        m={0}
        className="venn-section audience-insights-section"
      >
        <Grid.Col span={12} className="base-reach-section" p={0}>
          <Grid
            gutter={1}
            sx={{
              border:
                theme === "dark"
                  ? "0.0625rem solid #373a40"
                  : "0.0625rem  solid #E8EEFA",
              borderRadius: "4px",
            }}
          >
            <Grid.Col span={6}>
              <Card padding="lg" className="base-reach-card">
                <Card.Section inheritPadding mt={0}>
                  <Group spacing={12}>
                    <UsersIcon height={20} width={20} color="#C6D0E0" />
                    <Text className="content-header">
                      Clustering Base Reach
                    </Text>
                    <Tooltip
                      label="Base Reach refers to the total number of unique users identified based on audience criteria (based on cluster Size)"
                      withArrow
                      openDelay={500}
                      closeDelay={100}
                      width={600}
                      multiline
                    >
                      <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
                    </Tooltip>
                  </Group>
                </Card.Section>
                <Card.Section inheritPadding className="with-border">
                  <Group align="stretch" spacing={0}>
                    <Text className="base-reach-value">
                      {numberFormatter(
                        insightsData?.audienceSize?.filteredSize
                      )}
                    </Text>
                    <Text className="total-reach-value">
                      {" "}
                      / {numberFormatter(insightsData?.audienceSize?.totalSize)}
                    </Text>
                    <Stack
                      spacing={0}
                      sx={{
                        borderLeft: "1px solid #dde3ee;",
                        paddingLeft: "20px",
                        alignSelf: "center",
                      }}
                    >
                      <Text>
                        {Math.round(
                          (insightsData?.audienceSize?.filteredSize /
                            insightsData?.audienceSize?.totalSize) *
                            100
                        ) <= 25
                          ? "Your audience reach is marginal."
                          : Math.round(
                              (insightsData?.audienceSize?.filteredSize /
                                insightsData?.audienceSize?.totalSize) *
                                100
                            ) > 25 &&
                            Math.round(
                              (insightsData?.audienceSize?.filteredSize /
                                insightsData?.audienceSize?.totalSize) *
                                100
                            ) <= 50
                          ? "Your audience reach is adequate."
                          : Math.round(
                              (insightsData?.audienceSize?.filteredSize /
                                insightsData?.audienceSize?.totalSize) *
                                100
                            ) > 50 &&
                            Math.round(
                              (insightsData?.audienceSize?.filteredSize /
                                insightsData?.audienceSize?.totalSize) *
                                100
                            ) <= 75
                          ? "Your audience reach is moderate."
                          : "Your audience reach is fairly broad."}
                      </Text>
                      <Tooltip.Floating
                        label={`${Math.round(
                          (insightsData?.audienceSize?.filteredSize /
                            insightsData?.audienceSize?.totalSize) *
                            100
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} %`}
                        color={"#25855A"}
                        offset={10}
                      >
                        <div>
                          <ProgressBarComponent
                            Barsize="lg"
                            color="#25855A"
                            value={Math.round(
                              (insightsData?.audienceSize?.filteredSize /
                                insightsData?.audienceSize?.totalSize) *
                                100
                            )}
                            barType={undefined}
                            colorArr={[]}
                            valueArr={[]}
                            tooltipArr={[]}
                            sections={undefined}
                            isPadding={true}
                          />
                        </div>
                      </Tooltip.Floating>
                    </Stack>
                  </Group>
                </Card.Section>
                <Card.Section inheritPadding>
                  <Stack spacing={0}>
                    <Text className="top-hader-text">Top 5 Apps Used</Text>
                    <Box>
                      <Group spacing={1}>
                        {topAppsMetrics !== null &&
                          topAppsMetrics !== undefined &&
                          topAppsMetrics?.length > 0 &&
                          topAppsMetrics?.map((item: any) => {
                            return (
                              <Chip
                                mb={8}
                                checked={false}
                                key={`top-app-${item.value}`}
                              >
                                <Image
                                  radius="md"
                                  src={item.appicon}
                                  height={24}
                                  width={24}
                                />
                                <Text fz="sm" style={{ color: "#000" }}>
                                  {item.value}
                                </Text>
                              </Chip>
                            );
                          })}
                      </Group>
                    </Box>
                  </Stack>
                </Card.Section>
                <Card.Section inheritPadding mb={0}>
                  <Stack spacing={0}>
                    <Text className="top-hader-text">Top 5 category used</Text>
                    <Box>
                      <Group spacing={1}>
                        {insightsData?.topCategoryMetrics?.length > 0 &&
                          insightsData?.topCategoryMetrics?.map(
                            (item: any, index: any) => {
                              return (
                                <Chip
                                  mb={8}
                                  checked={false}
                                  key={`top-category-${index}`}
                                >
                                  {item.name}
                                </Chip>
                              );
                            }
                          )}
                      </Group>
                    </Box>
                  </Stack>
                </Card.Section>
              </Card>
            </Grid.Col>

            <Grid.Col span={6}>
              <Card padding="lg" className="base-reach-card">
                <Card.Section inheritPadding mt={0}>
                  <Group style={{ justifyContent: "space-between" }}>
                    <Group spacing={10}>
                      <Text className="content-header">Keyword Analysis</Text>
                      <Tooltip
                        label="Keyword Analysis displays the most relevant words associated with the audience criteria."
                        withArrow
                        openDelay={500}
                        closeDelay={100}
                      >
                        <InformationCircleIcon className="h-5 w-5 cursor-pointer" />
                      </Tooltip>
                    </Group>
                    <Button
                      className="edit-audience-criteria-btn"
                      onClick={handleEdit}
                      leftIcon={
                        <PencilIcon
                          fontSize={14}
                          height={14}
                          width={14}
                          color="#e20074"
                        />
                      }
                    >
                      Edit
                    </Button>
                  </Group>
                  <Group mt={10}>
                    <Chip.Group
                      multiple={false}
                      value={keywordCount}
                      onChange={(value) => selectKeywordFilterCount(value)}
                    >
                      <Group>
                        <Chip value="50" className="keyword-chip">
                          Max 50
                        </Chip>
                        <Chip value="30" className="keyword-chip">
                          Max 30
                        </Chip>
                        <Chip value="20" className="keyword-chip">
                          Max 20
                        </Chip>
                      </Group>
                    </Chip.Group>
                  </Group>
                </Card.Section>
                <Card.Section inheritPadding mb={0}>
                  {insightsData?.topKeywordsMetrics !== undefined && (
                    <WordCloud
                      data={originalTopKeywords}
                      displayCount={keywordCount}
                      showKeywords={true}
                    ></WordCloud>
                  )}
                </Card.Section>
              </Card>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={12} className="audience-insights-section">
          <Card shadow="sm" padding="lg">
            <Grid>
              <Grid.Col span={12}>
                <Card withBorder>
                  <Card.Section>
                    <Grid className="mt-5 mb-2 mx-4">
                      <Grid.Col span={6}>
                        <Flex className="title-text">
                          <Text fw={700} fz={"md"} className={"title-text"}>
                            User Engagement
                          </Text>
                          <Tooltip
                            zIndex={1}
                            label={
                              "User data usage on an app, split into upload and download, measured in megabytes. It categorizes app users by activity levels (low, very low, medium, high, very high) through daily/weekly/monthly sessions selected in a dropdown, helping identify top apps for advertisers."
                            }
                            openDelay={500}
                            closeDelay={100}
                            multiline
                            width={800}
                            withArrow
                            transitionProps={{
                              duration: 100,
                              transition: "scale-y",
                            }}
                          >
                            <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                          </Tooltip>
                        </Flex>
                      </Grid.Col>
                      <Grid.Col span={2} offset={4}>
                        <SimpleGrid style={{ marginLeft: "10px" }}>
                          <Select
                            value={downloadupload}
                            data={downloadAndUpload}
                            onChange={(e: any) => handleDownloadsAndUploads(e)}
                          />
                        </SimpleGrid>
                      </Grid.Col>
                    </Grid>
                  </Card.Section>
                  <Card.Section className="mx-2">
                    <ProgressBarComponent
                      value={undefined}
                      color={""}
                      Barsize={50}
                      barType={"barWithtoolTip"}
                      colorArr={[]}
                      valueArr={[]}
                      tooltipArr={[]}
                      sections={
                        downloadupload === "download_data_usage"
                          ? insightsData?.downloadUsageMetrics
                          : insightsData?.uploadUsageMetrics
                      }
                      isPadding={false}
                    />
                  </Card.Section>

                  <Flex
                    justify={"space-between"}
                    align={"center"}
                    className="ma-session-card"
                  >
                    {insightsData?.downloadUsageMetrics?.map(
                      (item: any, index: any) => {
                        return (
                          <SessionCard
                            appColor={item.color}
                            appUses={item.tooltip}
                            appCount={item.value}
                            key={`session-card-${item.value}-${index}`}
                          />
                        );
                      }
                    )}
                  </Flex>
                </Card>
              </Grid.Col>
            </Grid>
          </Card>
        </Grid.Col>
      </Grid>
      {openpopUp && (
        <>
          <EditKeyWordsAndWeightage
            opened={openpopUp}
            onClose={closeAudiencePopUp}
            onCancel={closeAudiencePopUp}
            lockScroll={false}
            keywords={originalTopKeywords}
            xOffset={20}
            keywordCount={keywordCount}
            saveKeywordsToCloud={saveKeywords}
            updateOriginalList={updateOriginalList}
          />
        </>
      )}
    </>
  );
};

export default AudienceInsights;
