import { FC } from "react";
import { Grid, Flex, Text, Tooltip, Progress } from "@mantine/core";
import "./UsesReportCard.scss";
import { numberFormatter } from "@/Util/utils";

interface UsesReportsProps {
  iconUrl: any;
  appName: string;
  appPotential: any;
  color: any;
  untappedPercentage: any;
  value: any;
  item: any;
  appOverLappingCount: any;
  untappedValue: any;
}
const renderToolTip: any = (
  appName: any,
  untappedPercentage: any,
  untappedValue: any,
  item: any,
) => {
  return (
    <Flex className="mb-2" direction={"row"}>
      <span>
        For{" "}
        {item?.appDataUsageMapper?.dataUsageCategory
          ?.toLowerCase()
          ?.replace(/_/g, " ")}{" "}
        usage, users of &nbsp;
        {item?.appDataUsageMapper?.appName} there is &nbsp;
        {untappedPercentage} ({numberFormatter(untappedValue)}) untapped
        potential with respect to {appName} users.
      </span>
    </Flex>
  );
};

const UsesReportCard: FC<UsesReportsProps> = ({
  iconUrl,
  appName,
  appPotential,
  color,
  untappedPercentage,
  value,
  item,
  appOverLappingCount,
  untappedValue,
}) => {
  return (
    <Grid className="w-full ma-user-card">
      <Grid.Col span={12}>
        <div className="user-card">
          <Grid justify={"space-between"} px={10}>
            <Grid.Col span={8}>
              <Flex>
                {iconUrl !== null && (
                  <img
                    src={iconUrl}
                    alt=""
                    style={{ height: 20, width: 20 }}
                    loading="lazy"
                  />
                )}
                <Text fz="xs" ml={10}>
                  {appName}
                </Text>
              </Flex>
            </Grid.Col>

            <Grid.Col span={4} className="text-right">
              <Flex direction={"row"} justify="flex-end">
                <Text fz="xs">{appPotential?.toFixed(2)}x</Text>
              </Flex>
            </Grid.Col>
          </Grid>
          <Grid px={10}>
            <Grid.Col span={12}>
              <Text fz="xs" className="mb-2">
                Untapped Potential
              </Text>
              <Grid justify="center" align="center">
                <Grid.Col span={9}>
                  <Tooltip.Floating
                    label={renderToolTip(
                      appName,
                      untappedPercentage,
                      untappedValue,
                      item,
                    )}
                    color={""}
                    offset={10}
                    key={appName}
                    multiline
                    width={300}
                  >
                    <Progress value={value} color={color} />
                  </Tooltip.Floating>
                </Grid.Col>
                <Grid.Col span={3} className="p-0">
                  <Text fz="xs" className="text-right">
                    {untappedPercentage}
                  </Text>
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </div>
      </Grid.Col>
    </Grid>
  );
};

export default UsesReportCard;
