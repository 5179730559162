import axios from "axios";
const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";

const GrantAcccess = (accessData: any, success: any, error: any) => {
  const data = accessData;
  axios
    .post(
      `${BASE_URL}/keycloakAdmin/assignRolesToUser`,

      data,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accesstoken")}`,
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      success(response);
    })
    .catch((err) => {
      error(err);
    });
};

export default GrantAcccess;
