import { Modal, Button, Grid, Divider, Text } from "@mantine/core";
import { useSelector } from "react-redux";
import { selectLightTheme } from "@/features/services/themes/themeSlice";

import "./AudienceBlankState.scss";

const DeleteAudienceModal = (props: any) => {
  const theme = useSelector(selectLightTheme);
  return (
    <Modal.Root
      opened={props.opened}
      onClose={props.onClose}
      centered
      size="md"
      className="ma-modal delete-audience-modal"
    >
      <Modal.Overlay />
      <Modal.Content
        className={theme === "light" ? "light-theme" : "dark-theme"}
      >
        <Modal.Header>
          <Modal.Title>Delete Audience</Modal.Title>
          <Modal.CloseButton />
        </Modal.Header>
        <Modal.Body>
          <Divider size="xs" className="divider-color" />
          <Grid className="modal-row" m={0}>
            <Grid.Col pl={24} pt={24} pb={40} pr={24}>
              <Text fz="sm">
                Are you sure, you want to delete&nbsp;
                <span className="audience-name">"{props.title}"</span>
                &nbsp;audience?
              </Text>
            </Grid.Col>
          </Grid>
          <Divider size="xs" className="divider-color" />
        </Modal.Body>
        <Grid>
          <Grid.Col
            span={12}
            className={
              theme === "dark"
                ? "dark-theme-button  btn-container"
                : "btn-container"
            }
          >
            <Button className={"cancel-btn"} onClick={props.onClose}>
              Cancel
            </Button>
            <Button className="primary-button" onClick={props.onDelete}>
              {" "}
              Delete
            </Button>
          </Grid.Col>
        </Grid>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DeleteAudienceModal;
