import { Grid, Container, Text, Flex } from "@mantine/core";
import overViewImage from "../../assets/images/overView.png";
import "./InsightBlankState.scss";

export default function InsightBlankState() {
  return (
    <Grid className="ma-insight-blankstate full-width">
      <Container fluid>
        <Grid justify={"center"} align="center" className="h-full">
          <Grid.Col span={12} className="ma-placeholder-image text-center">
            <Flex justify={"center"} direction="column" align={"center"}>
              <img src={overViewImage} alt="" />
              <Text size="lg" className="text-secondary mt-4">
                Generate Detailed Insights!
              </Text>
              <Text size="sm" className="text-secondary mt-2">
                To analyse your app data, we need you to start with selecting an
                app !
              </Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </Container>
    </Grid>
  );
}
