import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  userName: string | null;
  accesstoken: string | null;
}

const initialState: InitialState = {
  userName: null,
  accesstoken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: { ...initialState },
  reducers: {
    setLogin: (state, action) => {
      const { userName } = action.payload;
      state.userName = userName;
      state.accesstoken = sessionStorage.getItem("accesstoken");
    },
    logOut: (state) => {
      state.userName = null;
      state.accesstoken = null;
    },
  },
});

export const { setLogin, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: any) => state.auth.userName;

export const selectCurrentToken = (state: any) => state.auth.accesstoken;
