import { createSlice } from "@reduxjs/toolkit";

interface userActivityInitialState {
  usePrimaryChartData: any;
  useComprativeChartDataOne: any;
  useComprativeChartDataTwo: any;
  userEngagementData: any;
  userEngagementOneData: any;
  userEngagementTwoData: any;
  loading: any;
  userEngagementLoader: any;
  audienceUserCreated: any;
  sectionOneData: any;
  sectionTwoData: any;
  sectionThreeData: any;
  audienceResultData: any;
  sectionOneObj: any;
  sectionTwoObj: any;
  sectionThreeObj: any;
  audienceRouteFlag: any;
  allInterestKeywordsList: any;
  sectionOneCondition: any;
  sectionTwoCondition: any;
  selectedDataValue: any;
}

const initialState: userActivityInitialState = {
  usePrimaryChartData: [],
  useComprativeChartDataOne: [],
  useComprativeChartDataTwo: [],
  userEngagementData: [],
  userEngagementOneData: [],
  userEngagementTwoData: [],
  loading: null,
  userEngagementLoader: null,
  audienceUserCreated: null,
  sectionOneData: null,
  sectionTwoData: null,
  sectionThreeData: null,
  audienceResultData: {},
  sectionOneObj: null,
  sectionTwoObj: null,
  sectionThreeObj: null,
  audienceRouteFlag: false,
  allInterestKeywordsList: [],
  sectionOneCondition: "OR",
  sectionTwoCondition: "OR",
  selectedDataValue: null,
};

const audienceBuilderSlice = createSlice({
  name: "useractivity",
  initialState: { ...initialState },
  reducers: {
    setSectionOnePayload: (state, action) => {
      if (action.payload !== null) {
        const sectionOneData = action.payload;
        const obj = {
          keywords: sectionOneData?.keywords,
          appCategories: sectionOneData?.appCategories,
          apps: sectionOneData?.mApps,
          stdApps: sectionOneData?.apps,
          dayPhases: sectionOneData?.dayPhases,
          usages: sectionOneData?.usages,
          include: sectionOneData?.include,
        };
        state.sectionOneData = { ...obj };
      } else {
        state.sectionOneData = null;
      }
    },
    setSectionTwoPayload: (state, action) => {
      if (action.payload !== null) {
        const sectionTwoData = action.payload;
        const obj = {
          keywords: sectionTwoData?.keywords,
          appCategories: sectionTwoData?.appCategories,
          apps: sectionTwoData?.mApps,
          stdApps: sectionTwoData?.apps,
          dayPhases: sectionTwoData?.dayPhases,
          usages: sectionTwoData?.usages,
          include: sectionTwoData?.include,
        };
        state.sectionTwoData = { ...obj };
      } else {
        state.sectionTwoData = null;
      }
    },
    setSectionThreePayload: (state, action) => {
      if (action.payload !== null) {
        const sectionThreeData = action.payload;
        const obj = {
          keywords: sectionThreeData?.keywords,
          appCategories: sectionThreeData?.appCategories,
          apps: sectionThreeData?.mApps,
          stdApps: sectionThreeData?.apps,
          dayPhases: sectionThreeData?.dayPhases,
          usages: sectionThreeData?.usages,
          include: sectionThreeData?.include,
        };
        state.sectionThreeData = { ...obj };
      } else {
        state.sectionThreeData = null;
      }
    },
    setAudienceResultSliceData: (state, action) => {
      state.audienceResultData = action.payload;
    },
    setAudienceSectionOneObj: (state, action) => {
      const sectionOneObj = action.payload;
      state.sectionOneObj = sectionOneObj;
    },
    setAudienceSectionTwoObj: (state, action) => {
      const sectionTwoObj = action.payload;
      state.sectionTwoObj = sectionTwoObj;
    },
    setAudienceSectionThreeObj: (state, action) => {
      const sectionThreeObj = action.payload;
      state.sectionThreeObj = sectionThreeObj;
    },
    setAudienceRouteFlag: (state, action) => {
      state.audienceRouteFlag = action.payload;
    },
    setAllInterestsKeywordsList: (state, action) => {
      const sectionOneObj = action.payload;
      state.allInterestKeywordsList = sectionOneObj;
    },
    setSectionOneCondition: (state, action) => {
      state.sectionOneCondition = action.payload;
    },
    setSectionTwoCondition: (state, action) => {
      state.sectionTwoCondition = action.payload;
    },
    setSelectedDataSource: (state, action) => {
      state.selectedDataValue = action.payload;
    },
  },
});

export const {
  setSectionOnePayload,
  setSectionTwoPayload,
  setSectionThreePayload,
  setAudienceResultSliceData,
  setAudienceSectionOneObj,
  setAudienceSectionTwoObj,
  setAudienceSectionThreeObj,
  setAudienceRouteFlag,
  setAllInterestsKeywordsList,
  setSectionOneCondition,
  setSectionTwoCondition,
  setSelectedDataSource,
} = audienceBuilderSlice.actions;

export default audienceBuilderSlice.reducer;

export const selectSectionOneData = (state: any) =>
  state.audienceBuilder.sectionOneData;

export const selectSectionTwoData = (state: any) =>
  state.audienceBuilder.sectionTwoData;

export const selectSectionThreeData = (state: any) =>
  state.audienceBuilder.sectionThreeData;

export const selectAudienceResultData = (state: any) =>
  state.audienceBuilder.audienceResultData;

export const selectAudienceSectionOneObj = (state: any) =>
  state.audienceBuilder.sectionOneObj;

export const selectAudienceSectionTwoObj = (state: any) =>
  state.audienceBuilder.sectionTwoObj;

export const selectAudienceSectionThreeObj = (state: any) =>
  state.audienceBuilder.sectionThreeObj;

export const selectAudienceRouteFlag = (state: any) =>
  state.audienceBuilder.audienceRouteFlag;

export const selectAllInterestKeywordsList = (state: any) =>
  state.audienceBuilder.allInterestKeywordsList;

export const getSectionOneCondition = (state: any) =>
  state.audienceBuilder.sectionOneCondition;

export const getSectionTwoCondition = (state: any) =>
  state.audienceBuilder.sectionTwoCondition;

export const getSelectedDataSource = (state: any) =>
  state.audienceBuilder.selectedDataValue;
