import { Card, Flex, Skeleton } from "@mantine/core";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  selectedPrimaryApp,
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
} from "../../features/services/overView/overViewSlice";

export default function UserEngagementSkeleton() {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);
  useEffect(() => {
    if (
      primaryApp !== null &&
      comprativeAppOne !== null &&
      comprativeAppTwo !== null
    ) {
      setColumn(4);
    } else if (primaryApp !== null && comprativeAppOne !== null) {
      setColumn(6);
    } else if (primaryApp !== null && comprativeAppTwo !== null) {
      setColumn(6);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(12);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <>
      {column === 4 && (
        <>
          <Card withBorder>
            <Card.Section className="p-4">
              <Skeleton height={50} my={10} />
            </Card.Section>
          </Card>
          <Flex justify={"center"} align={"space-between"}>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                width: "20%",
                padding: 10,
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
          </Flex>
          <Card withBorder>
            <Card.Section className="p-4">
              <Skeleton height={50} my={10} />
            </Card.Section>
          </Card>
          <Flex justify={"center"} align={"space-between"}>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                width: "20%",
                padding: 10,
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
          </Flex>
          <Card withBorder>
            <Card.Section className="p-4">
              <Skeleton height={50} my={10} />
            </Card.Section>
          </Card>
          <Flex justify={"center"} align={"space-between"}>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                width: "20%",
                padding: 10,
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
          </Flex>
        </>
      )}
      {column === 6 && (
        <>
          <Card withBorder>
            <Card.Section className="p-4">
              <Skeleton height={50} my={10} />
            </Card.Section>
          </Card>
          <Flex justify={"center"} align={"space-between"}>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                width: "20%",
                padding: 10,
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
          </Flex>
          <Card withBorder>
            <Card.Section className="p-4">
              <Skeleton height={50} my={10} />
            </Card.Section>
          </Card>
          <Flex justify={"center"} align={"space-between"}>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                width: "20%",
                padding: 10,
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
          </Flex>
        </>
      )}
      {column === 12 && (
        <>
          <Card withBorder>
            <Card.Section className="p-4">
              <Skeleton height={50} my={10} />
            </Card.Section>
          </Card>
          <Flex justify={"center"} align={"space-between"}>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                width: "20%",
                padding: 10,
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
            <Card
              style={{
                height: "53px",
                padding: 10,
                width: "20%",
                marginRight: 10,
              }}
            >
              <Card.Section>
                <Skeleton height={20} width={100}></Skeleton>
                <Skeleton height={20} width={"100%"} my={5}></Skeleton>
              </Card.Section>
            </Card>
          </Flex>
        </>
      )}
    </>
  );
}
