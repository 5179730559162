import PieChartComponent from "@/features/common/PieChartComponent/PieChartComponent";
import {
  selectedComprativeAppOne,
  selectedComprativeAppTwo,
  selectedPrimaryApp,
} from "@/features/services/overView/overViewSlice";
import { selectLabel } from "@/features/services/dateRange/dateRangeSlice";
import { Card, Grid, Group, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function DeviceDistribution(props: any) {
  const primaryApp = useSelector(selectedPrimaryApp);
  const comprativeAppOne = useSelector(selectedComprativeAppOne);
  const comprativeAppTwo = useSelector(selectedComprativeAppTwo);
  const [column, setColumn] = useState(0);
  const [smColumnSize, setSmColumnSize] = useState(0);
  const [mdColumnSize, setMdColumnSize] = useState(0);
  const label = useSelector(selectLabel);
  const subHeader =
    label === "lastWeek" || label === "LastMonth" ? "Userwise" : "Eventwise";

  useEffect(() => {
    if (
      (primaryApp !== null &&
        comprativeAppOne !== null &&
        comprativeAppTwo !== null) ||
      (primaryApp !== null &&
        (comprativeAppOne !== null || comprativeAppTwo !== null))
    ) {
      setColumn(12);
      setSmColumnSize(12);
      setMdColumnSize(12);
    } else if (
      primaryApp !== null &&
      comprativeAppOne === null &&
      comprativeAppTwo === null
    ) {
      setColumn(4);
      setSmColumnSize(4);
      setMdColumnSize(4);
    }
  }, [primaryApp, comprativeAppOne, comprativeAppTwo, setColumn]);
  return (
    <>
      {props?.deviceDistributionSeriesData !== undefined && (
        <Grid.Col lg={column} sm={smColumnSize} md={mdColumnSize}>
          <Card
            withBorder
            shadow="sm"
            radius="md"
            className="chart-card"
            style={{ minHeight: "280px", borderRadius: "4px" }}
          >
            <Card.Section inheritPadding py="xs" className="card-header">
              <Group position="apart">
                <Text size="sm" style={{ fontSize: "14px", fontWeight: "500" }}>
                  Device Distribution{" "}
                  <span style={{ color: "#6E7B91" }}> ({subHeader}) </span>
                </Text>
              </Group>
            </Card.Section>
            <Card.Section className="mb-3">
              <PieChartComponent
                seriesData={props.deviceDistributionSeriesData}
                chartName={`${props.deviceDistributionSeriesChartName} \n\n ${
                  label === "Yesterday" ? "Events" : "Users"
                }`}
                showLabelLine={true}
                chartRadius={["60%", "70%"]}
                legendOrientation={["horizontal", "center", "bottom"]}
                height={220}
                showLabelinMiddle={true}
                chartAlign={["50%", "50%"]}
                hideLabel={false}
                showLegend={true}
                titleAlign={"50%"}
                colors={props.color}
                width={column === 12 || column === 4 ? "100%" : 200}
                className={"insight-overview-networkDistributionSeriesData"}
                itemGap={50}
                alignLegend={"horizontal"}
              />
            </Card.Section>
          </Card>
        </Grid.Col>
      )}
    </>
  );
}
