import { FC } from "react";
import ReactEcharts from "echarts-for-react";
import { IPieChartProps } from "./types";
import { useSelector } from "react-redux";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
const PieChartComponent: FC<IPieChartProps> = ({
  seriesData,
  chartName,
  showLabelLine,
  chartRadius,
  legendOrientation,
  height,
  showLabelinMiddle,
  chartAlign,
  hideLabel,
  showLegend,
  titleAlign,
  colors,
  width,
  className,
  itemGap,
  alignLegend,
}) => {
  const themeProvider = useSelector(selectLightTheme);

  let legendsData = seriesData?.map((item: any) => item.name);
  let options = {
    grid: {
      containLabel: true,
      x: 0,
      y: 0,
      x2: 0,
      y2: 0,
    },
    autoResize: true,
    color: colors,
    tooltip: {
      trigger: "item",
      z: 9999,
      position: "inside",
      extraCssText: "text-transform: capitalize",
    },
    legend: {
      show: showLegend,
      orient: (legendOrientation && legendOrientation[0]) || "horizontal",
      left: (legendOrientation && legendOrientation[1]) || "0",
      top: (legendOrientation && legendOrientation[2]) || "80%",
      data: legendsData,
      padding: 0,
      align: "auto",
      itemGap: itemGap || 10,
      textStyle: {
        fontSize: "10px",
        color: themeProvider === "light" ? "#00022d" : "#ffffff",
      },
      formatter: function (params: any) {
        for (let data of seriesData) {
          if (params === data.name) {
            return `${params.toUpperCase()}${
              alignLegend === "horizontal" ? "\n" : ":"
            }${data.percent !== undefined ? data.percent + "%" : ""}`;
          }
        }
      },
    },
    title: {
      show: showLabelinMiddle,
      text: showLabelinMiddle && chartName,
      left: titleAlign,
      top: "center",
      textAlign: "center",
      textStyle: {
        fontSize: "small",
        color: themeProvider === "light" ? "#00022d" : "#ffffff",
      },
    },

    series: [
      {
        name: chartName,
        type: "pie",
        radius: chartRadius,
        avoidLabelOverlap: true,
        center: chartAlign,
        labelLine: {
          show: showLabelLine,
        },
        label: {
          show: hideLabel,
          formatter: "{b|{b}}\n {per|{d}%}  ",
          rich: {
            b: {
              color: themeProvider === "light" ? "#00022d" : "#ffffff",
              fontSize: 12,
              padding: [3, 4],
              align: "center",
            },
            per: {
              color: themeProvider === "light" ? "#00022d" : "#ffffff",
              align: "right",
              fontSize: 12,
              padding: [3, 4],
            },
          },
        },
        data: seriesData,
      },
    ],
  };
  return (
    <div className="px-2">
      {seriesData !== null && seriesData !== undefined && (
        <ReactEcharts
          option={options}
          className={className}
          opts={{ renderer: "svg" }}
          style={{
            height: height,
            // background: "#fff ",
            width: width ? width : "auto",
          }}
        ></ReactEcharts>
      )}
    </div>
  );
};

export default PieChartComponent;
