import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  twinAppData: any;
  twinAppLoader: any;
}

const initialState: InitialState = {
  twinAppData: [],
  twinAppLoader: null,
};

const twinAppSlice = createSlice({
  name: "twinAppData",
  initialState: { ...initialState },
  reducers: {
    setTwinAppData: (state, action) => {
      const { twinAppsData } = action?.payload;
      state.twinAppData = twinAppsData;
    },
    setTwinApploader: (state, action) => {
      state.twinAppLoader = action.payload;
    },
  },
});

export const { setTwinAppData, setTwinApploader } = twinAppSlice.actions;

export default twinAppSlice.reducer;

export const twinAppData = (state: any) => state.twinAppData;

export const selectTwinAppLoader = (state: any) =>
  state.twinAppData.twinAppLoader;
