import React, { useState } from "react";
import "./MaCustomSelect.scss";
import {
  CalendarIcon,
  ChevronDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { Button, Flex, Popover, Text, Tooltip } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useSelector, useDispatch } from "react-redux";
import {
  selectMinMaxDate,
  setDateRange,
  setDateLabel,
} from "../../features/services/dateRange/dateRangeSlice";
import moment from "moment";
import { useDisclosure } from "@mantine/hooks";

export default function MaCustomSelect(props: any) {
  const dispatch = useDispatch();
  const [cLass, setCLass] = React.useState<any>("");
  const { startAndEndDateInEs } = useSelector(selectMinMaxDate);
  const [minDate, setMinDate] = React.useState(
    new Date(startAndEndDateInEs?.startDateInEs)
  );
  const [maxDate, setMaxDate] = React.useState(
    new Date(startAndEndDateInEs?.endDateInEs)
  );
  const [listItem, setListItem] = React.useState(props?.listItems);
  const year = moment(startAndEndDateInEs?.endDateInEs).year();
  const month = moment(startAndEndDateInEs?.endDateInEs).month();
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const [label, setLabel] = React.useState("");

  const [value, setValue] = React.useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [opened, { close, open }] = useDisclosure(false);

  const [selectedOption, setSelectedOption] =
    React.useState("Select an option");

  React.useEffect(() => {
    if (listItem !== undefined) {
      setSelectedOption(listItem[0].label);
      listItem?.forEach((item: any) => {
        if (item.value === "lastWeek") {
          setCLass(0);
          dispatch(setDateLabel("lastWeek"));
          const dateRegex = /\((\d{2}-\d{2}-\d{4}) - (\d{2}-\d{2}-\d{4})\)/;
          const match = dateRegex.exec(item?.label);
          if (match) {
            const startDate = match[1]; // "14-11-2023"
            const endDate = match[2]; // "20-11-2023"
            setMinDate(moment(startDate, "DD-MM-YYYY").toDate());
            setMaxDate(moment(endDate, "DD-MM-YYYY").toDate());
            setValue([
              moment(startDate, "DD-MM-YYYY").toDate(),
              moment(endDate, "DD-MM-YYYY").toDate(),
            ]);
            dispatch(setDateLabel("lastWeek"));
            const dateRange = {
              dateLabel: "lastWeek",
              fromDate: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              toDate: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
              calFromDates: moment(startDate, "DD-MM-YYYY").format(
                "YYYY-MM-DD"
              ),
              calToDates: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            };
            dispatch(setDateRange(dateRange));
          }
        }
      });
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const selectOption = async (option: any, i: any) => {
    if (option.value === "lastWeek") {
      dispatch(setDateLabel("lastWeek"));
      setLabel("lastWeek");
    } else if (option.value === "LastMonth") {
      dispatch(setDateLabel("LastMonth"));
      setLabel("LastMonth");
    } else {
      dispatch(setDateLabel("Yesterday"));
      setLabel("Yesterday");
    }
    setCLass(i);
    const selectedValue = option?.label;
    setSelectedOption(selectedValue);
    setDropdownOpen(false);
    const dateRegex = /\((\d{2}-\d{2}-\d{4}) - (\d{2}-\d{2}-\d{4})\)/;
    const match = dateRegex.exec(option.label);
    if (match) {
      const startDate = match[1]; // "14-11-2023"
      const endDate = match[2]; // "20-11-2023"
      setValue([
        moment(startDate, "DD-MM-YYYY").toDate(),
        moment(endDate, "DD-MM-YYYY").toDate(),
      ]);
      const dateRange: any = {
        fromDate: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        toDate: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        calFromDates: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
        calToDates: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
      await props.handelApiCall(dateRange);
    } else {
      const dateRegex = /\((\d{2}-\d{2}-\d{4})\)/;
      const match: any = dateRegex.exec(option.label);

      const dateRange: any = {
        fromDate: moment(match[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
        toDate: moment(match[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
        calFromDates: moment(match[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
        calToDates: moment(match[1], "DD-MM-YYYY").format("YYYY-MM-DD"),
      };
      await props.handelApiCall(dateRange);
    }
  };

  const handlePopoverClick = (e: any, item: any) => {
    e.preventDefault();
    setDropdownOpen(true);
    open();
    if (opened === true) {
      toggleDropdown();
      close();
    }
  };

  const modifyDate = () => {
    if (
      (value[0] !== null && value[1] !== null) ||
      (value[0] !== null && value[1] === null)
    ) {
      const list: any = [];
      const obj: any = {};

      listItem.forEach(async (item: any) => {
        if (item.value === "lastWeek") {
          setCLass(0);
          const startDate = moment(value[0]).format("DD-MM-YYYY");
          const endDate =
            value[1] !== null
              ? moment(value[1]).format("DD-MM-YYYY")
              : moment(value[0]).format("DD-MM-YYYY");
          obj.label = `Last Week (${startDate} - ${endDate})`;
          obj.value = "lastWeek";
          list.push(obj);
          setSelectedOption(obj.label);
          dispatch(setDateLabel("lastWeek"));
          const dateRange = {
            dateLabel: "lastWeek",
            fromDate: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            toDate: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            calFromDates: moment(startDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
            calToDates: moment(endDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          };
          setValue([value[0], value[1]]);
          dispatch(setDateRange(dateRange));
          await props.handelApiCall(dateRange);
        } else {
          list.push(item);
        }
      });
      setListItem(list);
      toggleDropdown();
    }
  };
  return (
    <div className="ma-custom-dropdown">
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <Flex align={"center"}>
          <CalendarIcon className="h-5 w-5 mr-2 nutral" />
          <span>{selectedOption}</span>
        </Flex>
        <ChevronDownIcon className="h-4 w-4 mr-3 text-secondary" />
      </button>
      {isDropdownOpen && (
        <div
          className="abs-position"
          onClick={() => setDropdownOpen(false)}
          role="overlay"
        ></div>
      )}
      {isDropdownOpen && (
        <ul className="dropdown-list">
          {listItem.map((item: any, index: any) => {
            return (
              <li key={item.value}>
                <div
                  className={
                    index === cLass ? "active x-lg-padder" : "x-lg-padder"
                  }
                  onClick={() => selectOption(item, index)}
                  style={{
                    display: "flex",
                    width:
                      item?.value !== "lastWeek" || "yesterday"
                        ? "100%"
                        : "70%",
                    // color: item?.value === "lastWeek" ? "#e20074" : "",
                  }}
                >
                  <span>{item.label}</span>
                  {item?.value === "LastMonth" && (
                    <Tooltip
                      multiline
                      width={220}
                      label="We are considering 4 sliding weeks as a month and showcasing the aggregated results"
                      withArrow
                    >
                      <InformationCircleIcon className="h-5 w-5 ml-3 cursor-pointer" />
                    </Tooltip>
                  )}
                  {item?.value === "lastWeek" && (
                    <Tooltip
                      multiline
                      width={220}
                      label="We are considering 1 sliding week and showcasing the aggregated results"
                      withArrow
                    >
                      <InformationCircleIcon className="h-5 w-5 ml-3 cursor-pointer" />
                    </Tooltip>
                  )}
                  {item?.value === "Yesterday" && (
                    <Tooltip
                      multiline
                      width={220}
                      label="We are considering 1 day and showcasing the aggregated results"
                      withArrow
                    >
                      <InformationCircleIcon className="h-5 w-5 ml-3 cursor-pointer" />
                    </Tooltip>
                  )}
                </div>
                {item?.value !== "lastWeek" ||
                  ("yesterday" && (
                    <Popover
                      width={"auto"}
                      position="bottom"
                      withArrow
                      shadow="md"
                      opened={opened}
                      arrowSize={15}
                      closeOnClickOutside={true}
                    >
                      <Popover.Target>
                        <div
                          className="date-container"
                          onClick={(e) => handlePopoverClick(e, item)}
                        >
                          <span>
                            <CalendarIcon className="h-5 w-5 icons" />
                          </span>
                          <Text fz="xs" className="label">
                            Change Date
                          </Text>
                        </div>
                      </Popover.Target>
                      <Popover.Dropdown>
                        <DatePicker
                          type="range"
                          value={value}
                          onChange={setValue}
                          defaultDate={new Date(year, month)}
                          minDate={minDate}
                          maxDate={maxDate}
                          allowSingleDateInRange
                        />
                        <Flex justify={"flex-end"} className="mt-3">
                          <Button
                            size="sm"
                            radius="md"
                            className="ml-4 primary-button"
                            onClick={() => {
                              modifyDate();
                              close();
                            }}
                          >
                            Apply
                          </Button>
                        </Flex>
                      </Popover.Dropdown>
                    </Popover>
                  ))}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
