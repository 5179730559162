import axios from "axios";
const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";
const generateOtp = (userName: any, callback: any, errCallback: any) => {
  sessionStorage.setItem("userName", userName);
  axios
    .post(
      `${BASE_URL}/keycloak/requestOtp`,

      {
        username: userName,
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      errCallback(err);
    });
};

export default generateOtp;
