import { Grid, RingProgress, Title, Text } from "@mantine/core";

export default function NetReachProgress(props: any) {
  return (
    <>
      {props.uniqueUserCountPercentage !== undefined && (
        <Grid justify="center" align="center">
          <Grid.Col span={12}>
            <RingProgress
              size={120}
              thickness={15}
              className="mx-auto"
              label={
                <Text
                  size="xs"
                  align="center"
                  px="xs"
                  sx={{ pointerEvents: "none" }}
                >
                  {props.uniqueUserCountPercentage + "%"}
                </Text>
              }
              sections={[
                {
                  value: props.uniqueUserCountPercentage,
                  color: "#2c7a7b",
                  tooltip: `Achieved Users No. ${props.sumOfUniqueUsers}`,
                },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={12} className="pl-0 text-center">
            <Title order={3} style={{ marginTop: -20 }}>{`
              ${
                props.sumOfUniqueUsers === undefined
                  ? ""
                  : props.sumOfUniqueUsers
              }`}</Title>
            <Text fz="xs" className="text-secondary mb-4">
              Percentage of target users achieved
            </Text>
          </Grid.Col>
        </Grid>
      )}
    </>
  );
}
