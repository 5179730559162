import { useState, useEffect } from "react";
import { Card, List, Text, Flex } from "@mantine/core";
import { numberFormatter } from "@/Util/utils";
import { selectLabel } from "@/features/services/dateRange/dateRangeSlice";
import "./AppCard.scss";
import { useSelector } from "react-redux";

export default function AppCard(props: any) {
  const [getMaxValueInDay, setMaxValueInDay] = useState<any>("");
  const [getMaxValueInPercentage, setMaxValueInPercentage] = useState<any>("");
  const [getMaxValue, setMaxValue] = useState<any>("");
  const label = useSelector(selectLabel);

  useEffect(() => {
    if (props.trendMapper !== undefined) {
      let max = Math.max(...props?.trendMapper?.value);
      const arr = props?.trendMapper?.value.map((item: any, index: any) => {
        return {
          value: item,
          time: props?.trendMapper?.time[index] + "/" + "Week-" + (index + 1),
          percent: props?.trendMapper?.percent[index],
        };
      });
      for (let i = 0; i < arr?.length; i++) {
        if (max === arr[i]?.value) {
          if (label === "LastMonth") {
            setMaxValueInDay(
              arr[i]?.time?.substring(arr[i]?.time?.lastIndexOf("/") + 1),
            );
            setMaxValueInPercentage(arr[i]?.percent?.toFixed(2));
            setMaxValue(numberFormatter(arr[i]?.value));
            break;
          } else {
            setMaxValueInDay(
              arr[i]?.time?.substring(0, arr[i]?.time.lastIndexOf("/")),
            );
            setMaxValueInPercentage(arr[i]?.percent?.toFixed(2));
            setMaxValue(numberFormatter(arr[i]?.value));
            break;
          }
        }
      }
    }
  }, [props.trendMapper]);
  return (
    <>
      {props.appName && (
        <Card
          withBorder
          className={`ma-app-card mb-12`}
          style={{
            background: props.cardColor,
            border: `1px solid ${props.borderColor}`,
          }}
        >
          <Card.Section>
            <Flex className="p-4" direction={"row"}>
              <img
                src={props?.iconUrl}
                style={{ height: 20, width: 20, marginRight: 10 }}
              />

              <Text fz="md" style={{ color: props.textColor }}>
                {props.appName}
              </Text>
            </Flex>
            <List style={{ color: props.textColor }}>
              <List.Item>
                <Text style={{ color: props.textColor }} fz="xs">
                  <b>{getMaxValueInDay}</b> witnesses a peak of{" "}
                  <b>{getMaxValue}</b>{" "}
                  {props.value === "num_sessions"
                    ? "active sessions "
                    : "active events "}
                  constituting&nbsp;
                  <b>{getMaxValueInPercentage + "%"}</b> of the total{" "}
                  {props.value === "num_sessions" ? "sessions " : "events "}.
                </Text>
              </List.Item>
            </List>
          </Card.Section>
        </Card>
      )}
    </>
  );
}
