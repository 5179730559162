export const chartOptions = (chartData: any, chartLabel: any): any => {
  return {
    grid: {
      top: 10,
      left: "3%",
      bottom: "3%",
      right: "7%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.5],
    },
    yAxis: {
      type: "category",
      data: [...chartLabel],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    series: [
      {
        data: [...chartData],
        type: "bar",
        showBackground: true,
        barWidth: 15,
        color: ["#2C7A7B"],
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0)",
        },
        itemStyle: {
          emphasis: {
            barBorderRadius: [5, 5],
          },
          normal: {
            barBorderRadius: [0, 5, 5, 0],
          },
        },
      },
    ],
  };
};

export const ChartOptions: any = {
  grid: {
    top: 10,
    left: 0,
    bottom: 10,
    right: 0,
    containLabel: true,
  },
  xAxis: {
    type: "category",
    data: ["Trade-desk", "DV360", "Platform 2", "Platform 2"],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      data: [120, 200, 150, 80],
      type: "bar",
      showBackground: true,
      barWidth: 15,
      color: ["#2C7A7B"],
      backgroundStyle: {
        color: "rgba(180, 180, 180, 0)",
      },
      itemStyle: {
        emphasis: {
          barBorderRadius: [5, 5],
        },
        normal: {
          barBorderRadius: [5, 5, 0, 0],
        },
      },
    },
  ],
};
