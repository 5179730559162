export const numberFormatter = (num: any) => {
  num = num?.toString()?.replace(/[^0-9.]/g, "");
  if (num < 1000) {
    return num;
  }
  let si = [
    { v: 1e3, s: "K" },
    { v: 1e6, s: "M" },
    { v: 1e9, s: "B" },
    { v: 1e12, s: "T" },
    { v: 1e15, s: "P" },
    { v: 1e18, s: "E" },
  ];
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].v) {
      break;
    }
  }
  return (
    (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
    si[index].s
  );
};

export const filterOnEdit = (
  list: any,
  comprativeAppOne: any,
  comprativeAppTwo: any,
) => {
  const newList = list
    .filter((item: any) => {
      if (comprativeAppOne && comprativeAppTwo) {
        return (
          item.value !== comprativeAppOne && item.value !== comprativeAppTwo
        );
      } else if (
        (comprativeAppOne !== null || comprativeAppOne !== "") &&
        (comprativeAppTwo === null || comprativeAppTwo === "")
      ) {
        return item.value !== comprativeAppOne;
      } else if (
        (comprativeAppOne === null || comprativeAppOne === "") &&
        (comprativeAppTwo !== null || comprativeAppTwo !== "")
      ) {
        return item.value !== comprativeAppTwo;
      } else if (
        (comprativeAppOne === null && comprativeAppTwo === null) ||
        (comprativeAppOne === "" && comprativeAppTwo === "")
      ) {
        return item;
      }
    })
    .map((item: any) => item);

  return newList;
};

export const filterAppandReturnList = (
  list: any,
  appName1: any,
  appName2: any,
  appName3: any,
) => {
  if (appName1 !== null && !appName2 && !appName3) {
    const newList = list
      .filter((item: any) => item.value !== appName1)
      .map((item: any) => item);

    return newList;
  }
  if (appName1 && !appName2 && appName3) {
    const newList = list
      .filter((item: any) => item.value !== appName1 && item.value !== appName3)
      .map((item: any) => item);

    return newList;
  }
  if (appName1 && appName2 && !appName3) {
    const newList = list
      .filter((item: any) => item.value !== appName1 && item.value !== appName2)
      .map((item: any) => item);

    return newList;
  }
};

export const filterAppandReturnListWithCategory1 = (
  list: any,
  appName1: any,
  appName2: any,
  appName3: any,
) => {
  if (appName1 !== null && appName2 === null && appName3 === null) {
    const newList = list?.filter(
      (item: any) => item?.value?.toLowerCase() !== appName1?.toLowerCase(),
    );
    return newList;
  } else if (appName1 !== null && appName2 !== null && appName3 === null) {
    const newList = list?.filter(
      (item: any) =>
        item?.value?.toLowerCase() !== appName1?.toLowerCase() &&
        item?.value?.toLowerCase() !== appName2?.toLowerCase(),
    );

    return newList;
  } else if (appName1 !== null && appName2 !== null && appName3 !== null) {
    const newList = list?.filter(
      (item: any) =>
        item?.value?.toLowerCase() !== appName1?.toLowerCase() &&
        item?.value?.toLowerCase() !== appName2?.toLowerCase() &&
        item?.value?.toLowerCase() !== appName3?.toLowerCase(),
    );

    return newList;
  } else if (appName1 !== null && appName2 == null && appName3 !== null) {
    const newList = list?.filter(
      (item: any) =>
        item?.value?.toLowerCase() !== appName1?.toLowerCase() &&
        item?.value?.toLowerCase() !== appName3?.toLowerCase(),
    );
    return newList;
  }
};

export const generateErrorMessage = (errMesaage: any) => {
  const index = errMesaage.message.indexOf(":");
  const str = errMesaage.message.substring(0, index);
  return str;
};
