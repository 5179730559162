import { Text, Group, Badge, Box, Divider } from "@mantine/core";
import { numberFormatter } from "@/Util/utils";

const AudienceResultsCriteriaDisplay = (props: any) => {
  const {
    filterSectionsDetails: {
      sectionOneFiltersSpecificCount,
      sectionOneCondition,
      sectionTwoFiltersSpecificCount,
      sectionTwoCondition,
      sectionThreeFiltersSpecificCount,
    },
  } = props?.criteriaData;
  return (
    <Group>
      {sectionOneFiltersSpecificCount !== null && (
        <Box
          className={
            sectionOneFiltersSpecificCount.include === true
              ? "section-criteria include"
              : "section-criteria exclude"
          }
        >
          <Group className="selected-criteria-data-group">
            <Text
              className={
                sectionOneFiltersSpecificCount.include === true
                  ? "criteria-condition include"
                  : "criteria-condition exclude"
              }
            >
              {sectionOneFiltersSpecificCount.include === true
                ? "Include"
                : "Exclude"}
            </Text>
            {sectionOneFiltersSpecificCount.keywords !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Interests :</Text>
                {sectionOneFiltersSpecificCount.keywords.map(
                  (keywordItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-one-keyword-${index}`}
                      >
                        {keywordItem}&nbsp;
                        {sectionOneFiltersSpecificCount.keywordSpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              keywordItem?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionOneFiltersSpecificCount.keywordSpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    keywordItem?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Group>
            )}
            {sectionOneFiltersSpecificCount.stdApps !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Apps :</Text>
                {sectionOneFiltersSpecificCount.stdApps.map(
                  (appItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-one-app-${index}`}
                      >
                        {appItem}&nbsp;
                        {sectionOneFiltersSpecificCount.appSpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              sectionOneFiltersSpecificCount?.apps[
                                index
                              ]?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionOneFiltersSpecificCount.appSpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    sectionOneFiltersSpecificCount.apps[
                                      index
                                    ]?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
                {(sectionOneFiltersSpecificCount.usages !== null &&
                  sectionOneFiltersSpecificCount.usages?.length > 0) ||
                  (sectionOneFiltersSpecificCount.dayPhases !== null &&
                    sectionOneFiltersSpecificCount.dayPhases?.length > 0 && (
                      <Divider orientation="vertical" />
                    ))}
                {sectionOneFiltersSpecificCount.dayPhases !== null &&
                  sectionOneFiltersSpecificCount.dayPhases?.length > 0 && (
                    <>
                      <Text className="criteria-type">DayPhase :</Text>
                      {sectionOneFiltersSpecificCount.dayPhases.map(
                        (dayPhaseItem: any, index: any) => {
                          return (
                            <Badge
                              className="criteria-value"
                              key={`section-one-dayphase-${index}`}
                            >
                              {dayPhaseItem}
                            </Badge>
                          );
                        }
                      )}
                    </>
                  )}
                {sectionOneFiltersSpecificCount.usages !== null &&
                  sectionOneFiltersSpecificCount.usages?.length > 0 && (
                    <>
                      <Text className="criteria-type">Usage :</Text>
                      {sectionOneFiltersSpecificCount.usages.map(
                        (useItem: any, index: any) => {
                          return (
                            <Badge
                              className="criteria-value"
                              key={`section-one-usage-${index}`}
                            >
                              {useItem}
                            </Badge>
                          );
                        }
                      )}
                    </>
                  )}
              </Group>
            )}

            {sectionOneFiltersSpecificCount.appCategories !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Cateory :</Text>
                {sectionOneFiltersSpecificCount.appCategories.map(
                  (categoryItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-one-category-${index}`}
                      >
                        {categoryItem}&nbsp;
                        {sectionOneFiltersSpecificCount.appCategorySpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              categoryItem?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionOneFiltersSpecificCount.appCategorySpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    categoryItem?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Group>
            )}
          </Group>
        </Box>
      )}
      {sectionOneCondition !== "" && (
        <Text className="condition one">-{sectionOneCondition}-</Text>
      )}
      {(sectionTwoFiltersSpecificCount.stdApps !== null ||
        sectionTwoFiltersSpecificCount.appCategories !== null ||
        sectionTwoFiltersSpecificCount.keywords !== null) && (
        <Box
          className={
            sectionTwoFiltersSpecificCount.include === true
              ? "section-criteria include"
              : "section-criteria exclude"
          }
        >
          <Group className="selected-criteria-data-group">
            <Text
              className={
                sectionTwoFiltersSpecificCount.include === true
                  ? "criteria-condition include"
                  : "criteria-condition exclude"
              }
            >
              {sectionTwoFiltersSpecificCount.include === true
                ? "Include"
                : "Exclude"}
            </Text>
            {sectionTwoFiltersSpecificCount.keywords !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Interests :</Text>
                {sectionTwoFiltersSpecificCount.keywords.map(
                  (keywordItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-two-keyword-${index}`}
                      >
                        {keywordItem}&nbsp;
                        {sectionTwoFiltersSpecificCount.keywordSpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              keywordItem?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionTwoFiltersSpecificCount.keywordSpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    keywordItem?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Group>
            )}
            {sectionTwoFiltersSpecificCount.stdApps !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Apps :</Text>
                {sectionTwoFiltersSpecificCount.stdApps.map(
                  (appItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-two-app-${index}`}
                      >
                        {appItem}&nbsp;
                        {sectionTwoFiltersSpecificCount.appSpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              sectionTwoFiltersSpecificCount.apps[
                                index
                              ]?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionTwoFiltersSpecificCount.appSpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    sectionTwoFiltersSpecificCount.apps[
                                      index
                                    ]?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
                {(sectionTwoFiltersSpecificCount.usages !== null &&
                  sectionTwoFiltersSpecificCount.usages?.length > 0) ||
                  (sectionTwoFiltersSpecificCount.dayPhases !== null &&
                    sectionTwoFiltersSpecificCount.dayPhases?.length > 0 && (
                      <Divider orientation="vertical" />
                    ))}
                {sectionTwoFiltersSpecificCount.dayPhases !== null &&
                  sectionTwoFiltersSpecificCount.dayPhases?.length > 0 && (
                    <>
                      <Text className="criteria-type">DayPhase :</Text>
                      {sectionTwoFiltersSpecificCount.dayPhases.map(
                        (dayPhaseItem: any, index: any) => {
                          return (
                            <Badge
                              className="criteria-value"
                              key={`section-two-dayphase-${index}`}
                            >
                              {dayPhaseItem}
                            </Badge>
                          );
                        }
                      )}
                    </>
                  )}
                {sectionTwoFiltersSpecificCount.usages !== null &&
                  sectionTwoFiltersSpecificCount.usages?.length > 0 && (
                    <>
                      <Text className="criteria-type">Usage :</Text>
                      {sectionTwoFiltersSpecificCount.usages.map(
                        (useItem: any, index: any) => {
                          return (
                            <Badge
                              className="criteria-value"
                              key={`section-two-usage-${index}`}
                            >
                              {useItem}
                            </Badge>
                          );
                        }
                      )}
                    </>
                  )}
              </Group>
            )}

            {sectionTwoFiltersSpecificCount.appCategories !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Cateory :</Text>
                {sectionTwoFiltersSpecificCount.appCategories.map(
                  (categoryItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-two-category-${index}`}
                      >
                        {categoryItem}&nbsp;
                        {sectionTwoFiltersSpecificCount.appCategorySpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              categoryItem?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionTwoFiltersSpecificCount.appCategorySpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    categoryItem?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Group>
            )}
          </Group>
        </Box>
      )}
      {sectionTwoCondition !== "" && (
        <Text className="condition two">-{sectionTwoCondition}-</Text>
      )}
      {(sectionThreeFiltersSpecificCount.stdApps !== null ||
        sectionThreeFiltersSpecificCount.appCategories !== null ||
        sectionThreeFiltersSpecificCount.keywords !== null) && (
        <Box
          className={
            sectionThreeFiltersSpecificCount.include === true
              ? "section-criteria include"
              : "section-criteria exclude"
          }
        >
          <Group className="selected-criteria-data-group">
            <Text
              className={
                sectionThreeFiltersSpecificCount.include === true
                  ? "criteria-condition include"
                  : "criteria-condition exclude"
              }
            >
              {sectionThreeFiltersSpecificCount.include === true
                ? "Include"
                : "Exclude"}
            </Text>
            {sectionThreeFiltersSpecificCount.keywords !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Interests :</Text>
                {sectionThreeFiltersSpecificCount.keywords.map(
                  (keywordItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-three-keyword-${index}`}
                      >
                        {keywordItem}&nbsp;
                        {sectionThreeFiltersSpecificCount.keywordSpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              keywordItem?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionThreeFiltersSpecificCount.keywordSpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    keywordItem?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Group>
            )}
            {sectionThreeFiltersSpecificCount.stdApps !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Apps :</Text>
                {sectionThreeFiltersSpecificCount.stdApps.map(
                  (appItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-three-app-${index}`}
                      >
                        {appItem}&nbsp;
                        {sectionThreeFiltersSpecificCount.appSpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              sectionThreeFiltersSpecificCount.apps[
                                index
                              ]?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionThreeFiltersSpecificCount.appSpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    sectionThreeFiltersSpecificCount.apps[
                                      index
                                    ]?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
                {(sectionThreeFiltersSpecificCount.usages !== null &&
                  sectionThreeFiltersSpecificCount.usage?.length > 0) ||
                  (sectionThreeFiltersSpecificCount.dayPhases !== null &&
                    sectionThreeFiltersSpecificCount.dayPhases?.length > 0 && (
                      <Divider orientation="vertical" />
                    ))}
                {sectionThreeFiltersSpecificCount.dayPhases !== null &&
                  sectionThreeFiltersSpecificCount.dayPhases?.length > 0 && (
                    <>
                      <Text className="criteria-type">DayPhase :</Text>
                      {sectionThreeFiltersSpecificCount.dayPhases.map(
                        (dayPhaseItem: any, index: any) => {
                          return (
                            <Badge
                              className="criteria-value"
                              key={`section-three-dayphase-${index}`}
                            >
                              {dayPhaseItem}
                            </Badge>
                          );
                        }
                      )}
                    </>
                  )}
                {sectionThreeFiltersSpecificCount.usages !== null &&
                  sectionThreeFiltersSpecificCount.usages?.length > 0 && (
                    <>
                      <Text className="criteria-type">Usage :</Text>
                      {sectionThreeFiltersSpecificCount.usages.map(
                        (useItem: any, index: any) => {
                          return (
                            <Badge
                              className="criteria-value"
                              key={`section-three-usage-${index}`}
                            >
                              {useItem}
                            </Badge>
                          );
                        }
                      )}
                    </>
                  )}
              </Group>
            )}

            {sectionThreeFiltersSpecificCount.appCategories !== null && (
              <Group className="selected-criteria-data-item-group">
                <Text className="criteria-type">Cateory :</Text>
                {sectionThreeFiltersSpecificCount.appCategories.map(
                  (categoryItem: any, index: any) => {
                    return (
                      <Badge
                        className="criteria-value"
                        key={`section-three-category-${index}`}
                      >
                        {categoryItem}&nbsp;
                        {sectionThreeFiltersSpecificCount.appCategorySpecificCount?.filter(
                          (item: any) => {
                            return (
                              item.name.toLowerCase() ===
                              categoryItem?.toLowerCase()
                            );
                          }
                        )[0]?.value !== undefined && (
                          <span className="count-value">
                            {`(${numberFormatter(
                              sectionThreeFiltersSpecificCount.appCategorySpecificCount?.filter(
                                (item: any) => {
                                  return (
                                    item.name.toLowerCase() ===
                                    categoryItem?.toLowerCase()
                                  );
                                }
                              )[0]?.value
                            )})`}
                          </span>
                        )}
                      </Badge>
                    );
                  }
                )}
              </Group>
            )}
          </Group>
        </Box>
      )}
    </Group>
  );
};

export default AudienceResultsCriteriaDisplay;
