import { createSlice } from "@reduxjs/toolkit";

interface InitialState {
  errorMessage: string | null | undefined;
  successMessage: string | null | undefined;
}

const initialState: InitialState = {
  errorMessage: null,
  successMessage: null,
};

const errorSlice = createSlice({
  name: "notification",
  initialState: { ...initialState },
  reducers: {
    setError: (state, action) => {
      const errorMessage = action.payload;
      state.errorMessage = errorMessage;
    },
    successMessage: (state, action) => {
      const successMessage = action.payload;
      state.successMessage = successMessage;
    },
  },
});

export const { setError, successMessage } = errorSlice.actions;

export default errorSlice.reducer;

export const selectMessage = (state: any) => {
  return state.notification;
};
