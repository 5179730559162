import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { MantineProvider } from "@mantine/core";
import { Provider, useSelector } from "react-redux";
import { store } from "./features/store/store";
import { Notifications } from "@mantine/notifications";
import { App } from "@/App";
import "./index.css";
import { selectLightTheme } from "./features/services/themes/themeSlice";
import { Helmet, HelmetProvider } from "react-helmet-async";

export const MainApp = () => {
  const themeProvider = useSelector(selectLightTheme);
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: themeProvider,
        colors: {
          brand: [
            "#F0BBDD",
            "#ED9BCF",
            "#EC7CC3",
            "#ED5DB8",
            "#F13EAF",
            "#F71FA7",
            "#FF00A1",
            "#E00890",
            "#C50E82",
            "#AD1374",
          ],
        },
        primaryColor: "brand",
      }}
    >
      <Notifications position="top-right" />
      <App className={themeProvider} />
    </MantineProvider>
  );
};
const helmetContext: any = {};

const root: any = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <Helmet>
          <meta http-equiv="X-Frame-Options" content="SAMEORIGIN" />
        </Helmet>
        <MainApp />
      </Provider>
    </HelmetProvider>
  </StrictMode>
);
