import {
  Group,
  Button,
  Grid,
  Input,
  Divider,
  Container,
  Tabs,
  Drawer,
  Chip,
} from "@mantine/core";
import { useEffect, useState } from "react";
import _ from "lodash";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import { useSelector } from "react-redux";

const SelectSectionOneKeywordDrawer = (props: any) => {
  const theme = useSelector(selectLightTheme);
  const [
    keywordSearchFieldCalledSectionOne,
    setKeywordSearchFieldCalledSectionOne,
  ] = useState<any>(false);

  const [activeTabSectionOne, setActiveTabSectionOne] = useState<string | any>(
    props.keywordsList[0]?.name,
  );

  const [selectedOneKeywords, setSelectedOneKeywords] = useState<any>(
    props.selectedOneKeywords,
  );
  const [sectionOneKeywordsList, setSectionOneKeywordsList] = useState<any>(
    props.keywordsList,
  );

  const [initialkeywordsList, setInitialkeywordsList] = useState<any>({});

  const searchKeyWordInputChangeSectionOne = (event: any) => {
    setKeywordSearchFieldCalledSectionOne(true);
    let objData = initialkeywordsList
      .map((item: any) => {
        const filteredTabContent = item.tabContent.filter((content: any) =>
          content.name.toLowerCase().includes(event.target.value.toLowerCase()),
        );

        return {
          name: item.name,
          tabContent: filteredTabContent,
        };
      })
      .filter((item: any) => item.tabContent.length > 0);
    const mergedData = updateKeywordList(
      initialkeywordsList,
      sectionOneKeywordsList,
    );
    setInitialkeywordsList(mergedData);
    setSectionOneKeywordsList(objData);
    if (event.target.value === "") {
      setKeywordSearchFieldCalledSectionOne(false);
      setActiveTabSectionOne(objData[0]?.name);
    }
  };

  const updateKeywordList = (data: any, newData: any) => {
    const mergedData = data.map((dataItem: any) => {
      const matchingNewDataItem = newData.find(
        (newDataItem: any) => newDataItem.name === dataItem.name,
      );
      if (matchingNewDataItem) {
        const tabContent = dataItem.tabContent.map((tabItem: any) => {
          const matchingNewTabItem = matchingNewDataItem.tabContent.find(
            (newTabItem: any) => newTabItem.name === tabItem.name,
          );
          if (matchingNewTabItem) {
            return {
              ...tabItem,
              isChecked: matchingNewTabItem.isChecked,
            };
          }
          return tabItem;
        });
        return {
          ...dataItem,
          tabContent,
        };
      }
      return dataItem;
    });

    return mergedData;
  };

  useEffect(() => {
    setSectionOneKeywordsList(props.keywordsList);
    setSelectedOneKeywords(props.selectedOneKeywords);
    setActiveTabSectionOne(props.keywordsList[0]?.name);
    setInitialkeywordsList(props.initialkeywordsList);
  }, [props.sectionOneOpened]);

  useEffect(() => {
    if (
      sectionOneKeywordsList.length > 0 &&
      keywordSearchFieldCalledSectionOne
    ) {
      setActiveTabSectionOne(sectionOneKeywordsList[0]?.name);
    }
  }, [sectionOneKeywordsList]);

  const updateSectionOneKeywordsList = () => {
    props.setKeywordsList(sectionOneKeywordsList, selectedOneKeywords);
  };

  const selectSectionOneKeyword = (
    keyword: any,
    itemChecked: boolean,
    keywordCat: any,
  ) => {
    let keywordsArray;
    if (itemChecked) {
      keywordsArray = [...selectedOneKeywords, keyword];
    } else {
      keywordsArray = selectedOneKeywords.filter(function (item: any) {
        return item !== keyword;
      });
    }
    setSelectedOneKeywords(keywordsArray);
    let newObj = JSON.parse(JSON.stringify(sectionOneKeywordsList));
    newObj.map((item: any) => {
      if (item.name === keywordCat) {
        item.tabContent.map((tItem: any) => {
          if (tItem.name === keyword) {
            tItem.isChecked = itemChecked;
          }
        });
      }
    });
    setSectionOneKeywordsList(newObj);
  };

  return (
    <>
      <Drawer
        size="lg"
        position="right"
        opened={props.sectionOneOpened}
        onClose={props.handleSectionOneClose}
        title="Select Interests / Keywords (Max 3)"
        className="section-drawer"
        keepMounted={false}
      >
        <Container pl={0} pr={0}>
          <Grid>
            <Grid.Col>
              <Divider my="sm" />
            </Grid.Col>
            <Grid.Col>
              <Input
                icon={
                  <MagnifyingGlassIcon height={20} width={20} color="#6E7B91" />
                }
                placeholder="Search for your interests & keywords"
                onChange={searchKeyWordInputChangeSectionOne}
              />
            </Grid.Col>
            <Grid.Col h="70vh">
              <Tabs
                color="red"
                variant="pills"
                radius="xl"
                value={activeTabSectionOne}
                onTabChange={setActiveTabSectionOne}
                className={
                  selectedOneKeywords.length === 3 ? "tabs-disabled" : ""
                }
              >
                <Tabs.List>
                  {sectionOneKeywordsList.map((keyword: any) => {
                    return (
                      <Tabs.Tab
                        value={keyword.name}
                        className="active-categories"
                        key={keyword.name}
                      >
                        {keyword.name}
                      </Tabs.Tab>
                    );
                  })}
                </Tabs.List>
                {sectionOneKeywordsList.map((keyword: any) => {
                  return (
                    <Tabs.Panel
                      value={keyword.name}
                      pt="xs"
                      key={keyword.name}
                      style={{
                        border: "1px solid #e5e5e5",
                        padding: "15px",
                        borderRadius: "8px",
                      }}
                    >
                      {keyword.tabContent?.map((tabsItem: any) => {
                        return (
                          <Chip
                            onChange={(checked) =>
                              selectSectionOneKeyword(
                                tabsItem.name,
                                checked,
                                keyword.name,
                              )
                            }
                            className="keyword-chip"
                            defaultChecked={tabsItem.isChecked}
                            checked={tabsItem.isChecked}
                            key={tabsItem.name}
                          >
                            {tabsItem.name}
                          </Chip>
                        );
                      })}
                    </Tabs.Panel>
                  );
                })}
              </Tabs>
            </Grid.Col>

            <Grid.Col>
              <Group position="apart">
                <Group
                  position="center"
                  className={theme === "dark" ? "dark-theme-button" : ""}
                >
                  <Button
                    className="secondary-button btn"
                    onClick={props.handleSectionOneClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    className={
                      selectedOneKeywords.length > 0
                        ? "primary-button btn"
                        : "primary-button disabled btn"
                    }
                    disabled={selectedOneKeywords.length > 0 ? false : true}
                    onClick={updateSectionOneKeywordsList}
                  >
                    Add
                  </Button>
                </Group>
              </Group>
            </Grid.Col>
          </Grid>
        </Container>
      </Drawer>
    </>
  );
};

export default SelectSectionOneKeywordDrawer;
