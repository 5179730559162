import { Text } from "@mantine/core";
import "./SessionCard.scss";

export default function SessionCard(props: any) {
  return (
    <>
      <span style={{ background: props.appColor }} className="span"></span>
      <Text fw={700} fz={"xs"} className="session-card">
        {props?.appUses?.split(":")[0]}
      </Text>
    </>
  );
}
