import axios from "axios";
const BASE_URL: string = window.location.href.includes("test")
  ? "https://gateway.test.ma2.telekom.com/insights-service"
  : window.location.href.includes("demo")
  ? "https://gateway.demo.ma2.telekom.com/insights-service"
  : "https://gateway.dev.ma2.telekom.com/insights-service";
const logout = (callback: any, errCallback: any) => {
  axios
    .post(
      `${BASE_URL}/keycloak/logoutUser`,

      {
        refreshToken: sessionStorage.getItem("refreshtoken"),
      },
      {
        headers: {
          "X-Frame-Options": "SAMEORIGIN",
        },
      },
    )
    .then((response) => {
      callback(response);
    })
    .catch((err) => {
      errCallback(err);
    });
};

export default logout;
