import Frame from "@/assets/images/Frame.png";
import { numberFormatter } from "@/Util/utils";

type State = {
  value: string | any;
  src: string | any;
  data: [] | any;
  appCategory: [] | any;
  appCategoryValue: any;
  netReachTextValue: any | number;
  appListBasedOnCategory: any;
  showLoader: any;
  appListValueBasedOnCategory: any;
  totalUserCount: any;
  showSkeleton: any;
  uniqueUsersProgress: any;
  dates: any;
  showFiltersFlag: any;
};

type Action =
  | { type: "set-primary-app"; payload: any }
  | { type: "set-image"; payload: any }
  | { type: "set-list"; payload: any }
  | { type: "set-app-category"; payload: any }
  | { type: "set-app-category-value"; payload: any }
  | { type: "set-app-text-value"; payload: any }
  | { type: "set-app-list-based-on-category"; payload: any }
  | { type: "set-loader"; payload: any }
  | { type: "set-app-list-value-based-on-category"; payload: any }
  | { type: "set-total-user"; payload: any }
  | { type: "set-skeleton"; payload: any }
  | { type: "set-unique-users-progress"; payload: any }
  | { type: "set-date-range-value"; payload: any }
  | { type: "set-filters-flag"; payload: any };

function splitArrayWithExpectedReach(inputArray: any, expectedReach: any) {
  let arr1 = [];
  let arr2 = [];
  let currentSum = 0;
  let zeroElement = 0;

  for (const element of inputArray) {
    if (currentSum + element <= expectedReach) {
      arr1.push(element);
      arr2.push(zeroElement);
      currentSum += element;
    } else {
      const remaining = currentSum + element - expectedReach;
      arr1.push(element - remaining);
      arr2.push(remaining);
      currentSum += element - remaining;
    }
  }

  // Remove trailing zeros from arr1
  while (arr1.length > 0 && arr1[arr1.length - 1] === 0) {
    arr1.pop();
  }

  // Trim arr2 to be the same size as arr1
  while (arr2.length > arr1.length) {
    arr2.pop();
  }

  return [arr1, arr2];
}

export const initialState: State = {
  value: "",
  src: Frame,
  data: [],
  appCategory: [],
  appCategoryValue: "",
  netReachTextValue: "",
  appListBasedOnCategory: [],
  showLoader: false,
  appListValueBasedOnCategory: "",
  totalUserCount: 0,
  showSkeleton: false,
  uniqueUsersProgress: null,
  dates: "",
  showFiltersFlag: false,
};

export const netRichReportReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "set-primary-app": {
      return { ...state, value: action.payload };
    }
    case "set-image": {
      return { ...state, src: action.payload };
    }
    case "set-list": {
      return { ...state, data: action.payload };
    }
    case "set-app-category": {
      return { ...state, appCategory: action.payload };
    }
    case "set-app-category-value": {
      return { ...state, appCategoryValue: action.payload };
    }
    case "set-app-text-value": {
      return { ...state, netReachTextValue: action.payload };
    }
    case "set-app-list-based-on-category": {
      return { ...state, appListBasedOnCategory: action.payload };
    }
    case "set-loader": {
      return { ...state, showLoader: action.payload };
    }
    case "set-app-list-value-based-on-category": {
      return { ...state, appListValueBasedOnCategory: action.payload };
    }
    case "set-total-user": {
      return { ...state, totalUserCount: action.payload };
    }
    case "set-skeleton": {
      return { ...state, showSkeleton: action.payload };
    }
    case "set-unique-users-progress": {
      return { ...state, uniqueUsersProgress: action.payload };
    }
    case "set-date-range-value": {
      return { ...state, dates: action.payload };
    }
    case "set-filters-flag": {
      return { ...state, showFiltersFlag: action.payload };
    }
    default:
      return initialState;
  }
};

/* defining action creators */

export const setList: any = (payload: any) => {
  return { type: "set-list", payload: payload };
};

export const updateValue: any = (payload: any) => {
  return { type: "set-primary-app", payload: payload };
};

export const setImage: any = (payload: any) => {
  return { type: "set-image", payload };
};

export const setAppCategory: any = (payload: any) => {
  return {
    type: "set-app-category",
    payload: payload,
  };
};

export const setAppCategoryValue: any = (payload: any) => {
  return {
    type: "set-app-category-value",
    payload: payload,
  };
};

export const setAppTextValue: any = (payload: any) => {
  return {
    type: "set-app-text-value",
    payload,
  };
};

export const setAppListBasedOnCategory: any = (payload: any) => {
  return {
    type: "set-app-list-based-on-category",
    payload,
  };
};

export const setLoader: any = (payload: any) => {
  return {
    type: "set-loader",
    payload,
  };
};

export const setAppListValueBasedOnCategory: any = (payload: any) => {
  return {
    type: "set-app-list-value-based-on-category",
    payload,
  };
};

export const setTotalUserCount: any = (payload: any) => {
  return {
    type: "set-total-user",
    payload,
  };
};

export const setSkeletonLoader: any = (payload: any) => {
  return {
    type: "set-skeleton",
    payload,
  };
};

export const setUniqueUserProgress: any = (payload: any) => {
  return {
    type: "set-unique-users-progress",
    payload,
  };
};

export const setDateValue: any = (payload: any) => {
  return {
    type: "set-date-range-value",
    payload,
  };
};

export const setFiltersFlag: any = (payload: any) => {
  return {
    type: "set-filters-flag",
    payload,
  };
};

export const chartData = (
  data: any,
  value: string,
  totalUserCount: number,
  netReachTextValue: any,
) => {
  const xAxisData: any = [];
  const yAxisData: any = [];
  const imageObject = {
    [data[0]?.value]: data[0]?.appicon,
    [data[1]?.value]: data[1]?.appicon,
    [data[2]?.value]: data[2]?.appicon,
    [data[3]?.value]: data[3]?.appicon,
    [data[4]?.value]: data[4]?.appicon,
    [data[5]?.value]: data[5]?.appicon,
    [data[6]?.value]: data[6]?.appicon,
    [data[7]?.value]: data[7]?.appicon,
    [data[8]?.value]: data[8]?.appicon,
    [data[9]?.value]: data[9]?.appicon,
  };
  for (let key in data) {
    xAxisData.push(data[key].value);
    yAxisData.push(data[key].uniqueUserCount);
  }

  const sumOfUniqueUsers = yAxisData.reduce((partialSum: any, a: any) => {
    return partialSum + a;
  }, 0);
  const uniqueUserCountPercentage =
    (sumOfUniqueUsers / (totalUserCount * (netReachTextValue * 0.01))) * 100;

  const userCountCalculated = Math.round(
    totalUserCount * (netReachTextValue * 0.01),
  );

  const [stackedChart1, stackedChart2] = splitArrayWithExpectedReach(
    yAxisData,
    userCountCalculated,
  );
  let xAxisLabelData: any = [];
  if (data.length > stackedChart1.length) {
    xAxisLabelData = data.splice(0, stackedChart1.length);
  } else {
    xAxisLabelData = data;
  }
  const option: any = {
    legend: {
      show: false,
      title: value,
      position: "left",
    },
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        return `${params?.name}: ${numberFormatter(params?.data)}`;
      },
    },
    grid: {
      top: 60,
      left: 0,
      bottom: 10,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: [...xAxisLabelData],
      axisLabel: {
        show: true,
        interval: 0,
        formatter: function (value: any) {
          return (
            "{" +
            value?.replace(/ +/g, "") +
            "| }{value|" +
            value?.replace(/ +/g, "") +
            "}"
          );
        },
        rich: {
          value: {
            lineHeight: 30,
            padding: 10,
            align: "center",
          },
          [xAxisLabelData[0]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[0]?.value],
            },
          },
          [xAxisLabelData[1]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[1]?.value],
            },
          },
          [xAxisLabelData[2]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[2]?.value],
            },
          },
          [xAxisLabelData[3]?.value]: {
            height: 15,
            width: 15,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[3]?.value],
            },
          },
          [xAxisLabelData[4]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[4]?.value],
            },
          },
          [xAxisLabelData[5]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[5]?.value],
            },
          },
          [xAxisLabelData[6]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[6]?.value],
            },
          },
          [xAxisLabelData[7]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[7]?.value],
            },
          },
          [xAxisLabelData[8]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[8]?.value],
            },
          },
          [xAxisLabelData[9]?.value]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: imageObject[xAxisLabelData[9]?.value],
            },
          },
        },
      },
    },
    yAxis: {
      axisLine: {
        show: true,
      },
      type: "value",
      axisLabel: {
        formatter: function (value: any) {
          return numberFormatter(value);
        },
      },
    },
    series: [
      {
        name: value,
        data: [...stackedChart1],
        type: "bar",
        stack: "x",
        color: ["#2C7A7B"],
        barWidth: 15,
        emphasis: {
          focus: "series",
        },
      },
      {
        name: value,
        data:
          sumOfUniqueUsers > totalUserCount * (netReachTextValue * 0.01)
            ? stackedChart2
            : [],
        type: "bar",
        stack: "x",
        color: ["#C1ECED"],
        barWidth: 15,
        emphasis: {
          focus: "series",
        },
        label: {
          show: false,
          position: "top",
          color: "#99999",
          formatter: () => {
            return `In ${numberFormatter(
              sumOfUniqueUsers,
            )} users the targeted users are ${numberFormatter(
              totalUserCount * (netReachTextValue * 0.01),
            )}`;
          },
        },
      },
    ],
  };
  return {
    option: option,
    uniqueUserCountPercentage:
      sumOfUniqueUsers > totalUserCount * (netReachTextValue * 0.01)
        ? 100
        : uniqueUserCountPercentage.toFixed(2),
    sumOfUniqueUsers:
      sumOfUniqueUsers > totalUserCount * (netReachTextValue * 0.01)
        ? numberFormatter(
            Math.round(totalUserCount * (netReachTextValue * 0.01)),
          )
        : numberFormatter(Math.round(sumOfUniqueUsers)),
  };
};
