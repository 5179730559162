import { Divider, Grid } from "@mantine/core";
import AppDescriptionCard from "./AppDescriptionCard/AppDescriptionCard";
import TotalUserCard from "./TotalUserCard/TotalUserCard";
import AgeDistributionCard from "./AgeDistributionCard/AgeDistributionCard";
import GenderDistributionCard from "./GenderDistributionCard/GenderDistributionCard";
import DeviceBrandDistribution from "./DeviceBrandDistribution/DeviceBrandDistribution";
import NetworkDistributionCard from "./NetworkDistributionCard/NetworkDistributionCard";
import { numberFormatter } from "../../../Util/utils";
import DeviceDistribution from "./DeviceDistribution/DeviceDistribution";

import "./InsightsOverview.scss";

export default function InsightsOverView(props: any) {
  const deviceDistributionSeriesData =
    props.appProfileData?.insights?.os === null
      ? [
          { value: 40, name: "IOS" },
          { value: 60, name: "Android" },
        ]
      : props.appProfileData?.insights?.os;
  const totalUserSeriesData =
    props.appProfileData?.insights?.activeInactiveUsers === null
      ? [
          { value: 30, name: "Active Users" },
          { value: 70, name: "Inactive Users" },
        ]
      : props.appProfileData?.insights?.activeInactiveUsers;
  const genderDistributionSeriesData =
    props.appProfileData?.insights?.gender === null
      ? [
          { value: 0, name: "Male" },
          { value: 0, name: "Female" },
        ]
      : props.appProfileData?.insights?.gender;

  const networkDistributionSeriesData =
    props.appProfileData?.insights?.connectionType === null
      ? [
          { value: 40, name: "3G" },
          { value: 20, name: "4G" },
          { value: 10, name: "2G" },
          { value: 30, name: "5G" },
        ]
      : props.appProfileData?.insights?.connectionType;
  const deviceBrandDistributionData =
    props.appProfileData?.insights?.deviceBrand.length === 0
      ? [
          { percent: "0", name: "Samsung" },
          { percent: "0", name: "Iphone" },
          { percent: "0", name: "Nokia" },
          { percent: "0", name: "Xiaomi" },
          { percent: "0", name: "huawei" },
        ]
      : props.appProfileData?.insights?.deviceBrand;
  const ageDistributionData =
    props.appProfileData?.insights?.age === null
      ? [
          { percent: "0", name: "<25" },
          { percent: "0", name: "25-34" },
          { percent: "0", name: "35-54" },
          { percent: "0", name: "55+" },
        ]
      : props.appProfileData?.insights?.age;
  return (
    <>
      {props?.appProfileData && (
        <>
          <AppDescriptionCard
            appCount={1}
            colors={props.colors}
            deviceDistributionSeriesChartName={
              numberFormatter(props.appProfileData?.insights?.osCount) || ""
            }
            appName={props.appName}
            iconUrl={props.iconUrl}
            appCategory={props.appCategory}
            appProfileData={props.appProfileData}
          />
          {props.isCOl == 1 && (
            <Grid.Col md={12} lg={12} sm={12}>
              <Divider label="User Insights" size="sm" labelPosition="center" />
            </Grid.Col>
          )}
          <TotalUserCard
            appName={props.appName}
            color={props.colors}
            totalUserSeriesData={totalUserSeriesData}
            totalUserSeriesChartName={
              props.appProfileData?.insights?.totalUsers?.total
                ? numberFormatter(
                    props.appProfileData?.insights?.totalUsers?.total,
                  )
                : ""
            }
            appProfileData={props.appProfileData}
          />
          <AgeDistributionCard
            ageDistributionData={ageDistributionData}
            color={props.colors[0]}
            appProfileData={props.appProfileData}
          />
          <GenderDistributionCard
            genderDistributionSeriesData={genderDistributionSeriesData}
            color={props.colors[0]}
            appProfileData={props.appProfileData}
            colors={props.colors}
          />
          {props.isCOl == 1 && (
            <Grid.Col md={12} lg={12} sm={12}>
              <Divider
                label="Device Device & Network Insights"
                size="sm"
                labelPosition="center"
              />
            </Grid.Col>
          )}
          <DeviceDistribution
            deviceDistributionSeriesData={deviceDistributionSeriesData}
            deviceDistributionSeriesChartName={
              numberFormatter(props?.appProfileData?.insights?.osCount) || ""
            }
            color={props?.colors}
          />
          <DeviceBrandDistribution
            deviceBrandDistributionData={deviceBrandDistributionData}
            color={props.colors[0]}
          />
          <NetworkDistributionCard
            networkDistributionSeriesData={networkDistributionSeriesData}
            networkDistributionSeriesChartName={
              numberFormatter(
                props.appProfileData?.insights?.connectionCount,
              ) || ""
            }
            color={props.piChartColors}
          />
        </>
      )}
    </>
  );
}
