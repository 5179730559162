import { useEffect, useReducer, useState } from "react";
import {
  Grid,
  Title,
  Text,
  Card,
  Flex,
  SimpleGrid,
  Skeleton,
  Tooltip,
  Group,
  Button,
  Divider,
  Select,
  Drawer,
} from "@mantine/core";
import PrimaryAppSelector from "../../containers/netReactReportFrom/primaryAppSelector/PrimaryAppSelector";
import Hoc from "@/Hoc/Hoc";
import { selectInitialAppList } from "@/features/services/search/searchAppSlice";
import { useGetSearchedAppQuery } from "@/features/services/search/serachAppApiSlice";
import ReactEcharts from "echarts-for-react";
import {
  netRichReportReducer,
  initialState,
  updateValue,
  setImage,
  setList,
  setAppCategory,
  setAppCategoryValue,
  setAppTextValue,
  setAppListBasedOnCategory,
  setLoader,
  chartData,
  setAppListValueBasedOnCategory,
  setTotalUserCount,
  setSkeletonLoader,
  setUniqueUserProgress,
  setDateValue,
  setFiltersFlag,
} from "./NetReachReducer";
import NetReachGoal from "@/containers/netReactReportFrom/netReachGoal/NetReachGoal";
import DatePicker from "@/containers/netReactReportFrom/datePicker/DatePicker";
import TargetingCard from "@/containers/netReactReportFrom/TargetingCard/TargetingCard";
import NetReachTotalUser from "@/containers/netReactReportFrom/netReactTotalUser/NetReachTotalUser";
import NetReachProgress from "@/containers/netReactReportFrom/netReachProgress/NetReachProgress";
import {
  useLazyGetAllCategoriesQuery,
  useLazyGetMinMaxDateQuery,
} from "@/features/services/trendingApps/trendingAppApiSlice";
import { selectAllCategories } from "@/features/services/trendingApps/trendingAppSlice";
import {
  useLazyGetTotalUserCountForAppQuery,
  useLazyGetNetReachReportQuery,
} from "@/features/services/netReach/netReachApiSlice";
import useDebounce from "./useDebounce";
import { useSelector } from "react-redux";
import { generateErrorMessage, numberFormatter } from "@/Util/utils";
import {
  InformationCircleIcon,
  XMarkIcon,
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import "./NetReachReport.scss";
import { useDisclosure } from "@mantine/hooks";
import { selectLightTheme } from "@/features/services/themes/themeSlice";
import UnauthorizedPage from "@/features/common/UnauthorizedPage";
import moment from "moment";

const NetReachReport = () => {
  const themeProvider = useSelector(selectLightTheme);
  const initialAppList = useSelector(selectInitialAppList);
  const { data: appList } = useGetSearchedAppQuery("");
  const [state, dispatch] = useReducer(netRichReportReducer, initialState);
  const getAllCategories = useSelector(selectAllCategories);
  const [fetchCategory] = useLazyGetAllCategoriesQuery<any>();
  const [fetchTotalCount] = useLazyGetTotalUserCountForAppQuery<any>();
  const [fetchNetReachRequest] = useLazyGetNetReachReportQuery<any>();
  const [netReactGoal, setNetReachGoal] = useDebounce("", 1000);
  const [options, setOption] = useState<any>({});
  const [selectCategoryInclude, setSelectInclude] = useState("Include");
  const [selectCategoryExclude, setSelectedExclude] = useState("");
  const [selectAppListInclude, setAppListInclude] = useState("Include");
  const [selecteAppListExclude, setAppListExclude] = useState("");
  const [error, setError] = useState("");
  const [opened, { open, close }] = useDisclosure(false);
  const [filterByValue, setFilterByValue] = useState<any>("appCategory");
  const [addedFiltersArray, setAddedFiltersArray] = useState<any>([]);
  const [showAddedFilters, setShowAddedFilters] = useState<any>([]);
  const [appIncludeList, setAppIncludeList] = useState<any>([]);
  const [appExcludeList, setAppExcludeList] = useState<any>([]);
  const [categoryIncludeList, setCategoryIncludeList] = useState<any>([]);
  const [categoryExcludeList, setCategoryExcludeList] = useState<any>([]);
  const [isAddButtonDisabled, setIsAddButtonDisabled] = useState<any>(false);
  const [showError, setShowError] = useState<any>("");
  const [fetchMinMaxDate, { data: startAndEndDateInEs }] =
    useLazyGetMinMaxDateQuery<any>();
  const [minMaxDate, setMinMaxDate] = useState<any>(null);
  const [dateRange, setDateRange] = useState<any>([]);
  const [customDates, setDate] = useState<any>(null);

  useEffect(() => {
    const getMinMaxDate = async () => {
      await fetchMinMaxDate("");
      if (startAndEndDateInEs?.startAndEndDateInEs) {
        setMinMaxDate(startAndEndDateInEs?.startAndEndDateInEs);

        const { startDateInEs, endDateInEs } =
          startAndEndDateInEs?.startAndEndDateInEs;
        if (minMaxDate === null || minMaxDate === undefined) {
          if (startDateInEs !== undefined && endDateInEs !== undefined) {
            const startWeekDate = moment(endDateInEs)
              .subtract(6, "days")
              .format("DD-MM-YYYY");
            const endDateWeekDate = moment(endDateInEs).format("DD-MM-YYYY");
            const arr: any = [
              {
                label: `Last Week (${startWeekDate} - ${endDateWeekDate})`,
                value: "lastWeek",
              },
              {
                label: `Yesterday (${endDateWeekDate})`,
                value: `Yesterday`,
              },
            ];
            setDateRange(arr);
          }
        }
      }
    };
    getMinMaxDate();
  }, [minMaxDate, startAndEndDateInEs]);

  useEffect(() => {
    if (
      state?.value &&
      !isNaN(parseFloat(state?.netReachTextValue)) &&
      state?.dates &&
      netReactGoal
    ) {
      getTotalCount();
    }
  }, [state?.value, state?.netReachTextValue, state?.dates]);

  useEffect(() => {
    if (state?.data.length === 0 && appList !== undefined) {
      dispatch(setList(appList));
    }
  }, [state?.data, appList]);

  useEffect(() => {
    if (initialAppList !== null) {
      dispatch(setList(initialAppList));
    }
  }, [initialAppList]);

  useEffect(() => {
    if (getAllCategories !== null) {
      dispatch(setAppCategory(getAllCategories));
    }
  }, [getAllCategories]);

  useEffect(() => {
    const appCount = appIncludeList.length + appExcludeList.length;
    const categoryCount =
      categoryIncludeList.length + categoryExcludeList.length;
    if (appCount >= 3 && filterByValue === "app") {
      setShowError("You can only select upto 3 apps *");
      setIsAddButtonDisabled(true);
    } else if (categoryCount >= 3 && filterByValue === "appCategory") {
      setShowError("You can only select upto 3 categories *");
      setIsAddButtonDisabled(true);
    } else {
      setShowError("");
      setIsAddButtonDisabled(false);
    }
  }, [
    filterByValue,
    state?.appListValueBasedOnCategory,
    state?.appCategoryValue,
    appIncludeList,
    appExcludeList,
  ]);

  const handleAddClick = (e: any) => {
    const filters = [...addedFiltersArray];
    const appName = state?.appListBasedOnCategory?.find(
      (element: any) => element.value === state?.appListValueBasedOnCategory
    );
    if (filterByValue === "app") {
      const elementAlreadyPresent = filters.filter(
        (element) => element.filterBy === appName?.label
      );
      if (elementAlreadyPresent.length === 0) {
        const filter = {
          filterByValue: filterByValue,
          filterBy: appName?.label,
          include: selectAppListInclude,
          exclude: selecteAppListExclude,
        };

        filters.push(filter);
      } else {
        const index = filters.findIndex(
          (element) => element.filterBy === elementAlreadyPresent[0].filterBy
        );
        filters[index].include = selectAppListInclude;
        filters[index].exclude = selecteAppListExclude;
      }
    } else {
      const elementAlreadyPresent = filters.filter(
        (element) => element.filterBy === state?.appCategoryValue
      );
      if (elementAlreadyPresent.length === 0) {
        const filter = {
          filterByValue: filterByValue,
          filterBy: state?.appCategoryValue,
          include: selectCategoryInclude,
          exclude: selectCategoryExclude,
        };
        filters.push(filter);
      } else {
        const index = filters.findIndex(
          (element) => element.filterBy === elementAlreadyPresent[0].filterBy
        );
        filters[index].include = selectCategoryInclude;
        filters[index].exclude = selectCategoryExclude;
      }
    }
    setAddedFiltersArray(filters);
  };
  const handleChange = async (e: any) => {
    dispatch(updateValue(e));
    const app: any = state?.data.find((item: any) => {
      if (item.value === e) {
        return item;
      }
    });
    dispatch(setImage(app?.appicon));
  };

  const getTotalCount: any = async () => {
    const id = state?.data.filter((item: any) => {
      if (item.value === state?.value) {
        dispatch(setImage(item.appicon));
        return item;
      }
    })[0].id;
    const payload = {
      netReachRequest: {
        primaryAppId: id,
        categoryIncludeList: null,
        categoryExcludeList: null,
        appExcludeList: null,
        appIncludeList: null,
        targetUniqueUserPercentage: parseFloat(state?.netReachTextValue),
        fromDate: customDates?.startDate,
        toDate: customDates?.endDate,
      },
    };
    const response: any = await fetchTotalCount(payload);
    dispatch(setTotalUserCount(response?.data?.getTotalUserCountForApp));
  };

  const handleDateChange = (e: any, i: any) => {
    if (e === "lastWeek") {
      const dateRegex = /\((\d{2}-\d{2}-\d{4}) - (\d{2}-\d{2}-\d{4})\)/;
      const match: any = dateRegex.exec(dateRange[0].label);
      const startDate = moment(match[1], "DD-MM-YYYY").format("YYYY-MM-DD");
      const endDate = moment(match[2], "DD-MM-YYYY").format("YYYY-MM-DD");
      const dateRangeData = {
        startDate,
        endDate,
      };
      setDate(dateRangeData);
    } else {
      const dateRegex = /\((\d{2}-\d{2}-\d{4})\)/;
      const match: any = dateRegex.exec(dateRange[1].label);
      const startDate = moment(match[1], "DD-MM-YYYY").format("YYYY-MM-DD");
      const dateRangeData = {
        startDate: startDate,
        endDate: startDate,
      };
      setDate(dateRangeData);
    }
    dispatch(setDateValue(e));
  };

  const handleCategoryChange = async (e: any) => {
    dispatch(setAppCategoryValue(e));
  };

  useEffect(() => {
    if (state?.value !== "") {
      const data1: any = state?.data
        ?.filter((item: any) => item.value !== state?.value)
        .map((newItem: any) => {
          return { value: newItem.id.toString(), label: newItem.label };
        });
      dispatch(setAppListBasedOnCategory(data1));
    }
  }, [state?.value]);

  useEffect(() => {
    const fetchApi = async () => {
      const response = await fetchCategory("");
      const category = response?.data;
      dispatch(setAppCategory(category));
    };
    fetchApi();
  }, []);

  useEffect(() => {
    if ((state?.value, state?.netReachTextValue, state?.dates)) {
      handleGenerateReport();
    }
  }, [showAddedFilters]);

  useEffect(() => {
    const includeListApp = addedFiltersArray.filter(
      (element: any) =>
        element.include === "Include" && element.filterByValue === "app"
    );
    const excludeListApp = addedFiltersArray.filter(
      (element: any) =>
        element.exclude === "Exclude" && element.filterByValue === "app"
    );
    const localAppList: any = appList;
    const appIdInclude = includeListApp.map((element: any) => element.filterBy);
    const findAppIdInclude = appIdInclude.map((element: any) => {
      const a = localAppList?.find((e: any) => e.value === element);
      return a.id.toString();
    });
    const appIdExclude = excludeListApp.map((element: any) => element.filterBy);
    const findAppIdExclude = appIdExclude.map((element: any) => {
      const a = localAppList?.find((e: any) => e.value === element);
      return a.id.toString();
    });
    setAppIncludeList(findAppIdInclude);
    setAppExcludeList(findAppIdExclude);

    const includeListCategory = addedFiltersArray.filter(
      (element: any) =>
        element.include === "Include" && element.filterByValue === "appCategory"
    );
    const findCategoryInclude = includeListCategory.map(
      (element: any) => element.filterBy
    );
    const excludeListCategory = addedFiltersArray.filter(
      (element: any) =>
        element.exclude === "Exclude" && element.filterByValue === "appCategory"
    );
    const findCategoryExclude = excludeListCategory.map(
      (element: any) => element.filterBy
    );
    setCategoryIncludeList(findCategoryInclude);
    setCategoryExcludeList(findCategoryExclude);
  }, [addedFiltersArray]);
  const genereteNetReachReport: any = async (payload: any) => {
    dispatch(setFiltersFlag(true));
    dispatch(setLoader(true));
    dispatch(setSkeletonLoader(true));
    const response: any = await fetchNetReachRequest(payload);
    const data: any = [];
    state?.data.filter(
      (item: any) =>
        response?.data?.getNetReachReport?.netReachData.some((newItem: any) => {
          if (newItem.appId === item.id) {
            data.push({
              appicon: item.appicon,
              value: item.value,
              label: item.label,
              id: item.id.toString(),
              uniqueUserCount: newItem.uniqueUserCount,
            });
          }
        })
    );
    const sortedData: any = data.sort((a: any, b: any) => {
      return b.uniqueUserCount - a.uniqueUserCount;
    });
    const { option, sumOfUniqueUsers, uniqueUserCountPercentage } = chartData(
      sortedData,
      state?.value,
      state?.totalUserCount,
      state?.netReachTextValue
    );
    setOption(option);
    dispatch(
      setUniqueUserProgress({ sumOfUniqueUsers, uniqueUserCountPercentage })
    );

    dispatch(setSkeletonLoader(false));
  };

  const handleNetReactValue = (e: any) => {
    if (e.target.value.length < 4) {
      if (e.target.value > 100) {
        dispatch(setAppTextValue(100));
        setNetReachGoal(100);
      } else if (e.target.value < 0) {
        dispatch(setAppTextValue(0));
        setNetReachGoal(0);
      } else {
        dispatch(setAppTextValue(e.target.value));
        setNetReachGoal(e.target.value);
      }
    } else {
      setNetReachGoal(100);
    }
  };
  const handleGenerateReport: any = async () => {
    const id = state?.data?.filter(
      (item: any) => item.value === state?.value
    )[0]?.id;
    const payload: any = {
      netReachRequest: {
        primaryAppId: id,
        categoryIncludeList: categoryIncludeList,
        categoryExcludeList: categoryExcludeList,
        appIncludeList: appIncludeList,
        appExcludeList: appExcludeList,
        targetUniqueUserPercentage: parseFloat(state?.netReachTextValue),
        fromDate: customDates?.startDate,
        toDate: customDates?.endDate,
      },
    };
    await genereteNetReachReport(payload);
  };

  const handleAppListChange = (e: any) => {
    const id: any = state?.data?.filter(
      (item: any) => item.value === state?.value
    )[0].id;
  };

  const handleCloseClickDrawer = (e: any) => {
    const newArray = [...addedFiltersArray];
    const removedElementsArray = newArray.filter(
      (element: any) =>
        element.filterBy.toLowerCase() !== e.filterBy.toLowerCase()
    );
    setAddedFiltersArray(removedElementsArray);
    const localAppList: any = appList;

    if (e.filterByValue === "app") {
      if (e.include.length) {
        const removedElement = localAppList?.find((element: any) => {
          return element.value === e.filterBy;
        });
        const newIncludeList = appIncludeList.filter(
          (e: any) => e !== removedElement.id
        );
        setAppIncludeList(newIncludeList);
      } else {
        const removedElement = localAppList?.find((element: any) => {
          return element.value === e.filterBy;
        });
        const newExcludeList = appExcludeList.filter(
          (element: any) => element !== removedElement.id.toString()
        );
        setAppExcludeList(newExcludeList);
      }
    } else {
      if (e.include.length) {
        const newIncludeList = categoryIncludeList.filter(
          (element: any) => element.toLowerCase() !== e.filterBy.toLowerCase()
        );
        setCategoryIncludeList(newIncludeList);
      } else {
        const newExcludeList = categoryExcludeList.filter(
          (element: any) => element.toLowerCase() !== e.filterBy.toLowerCase()
        );
        setCategoryExcludeList(newExcludeList);
      }
    }
  };
  const handleCloseClick = (e: any) => {
    const newArray = [...addedFiltersArray];
    const removedElementsArray = newArray.filter(
      (element: any) =>
        element.filterBy.toLowerCase() !== e.filterBy.toLowerCase()
    );
    setAddedFiltersArray(removedElementsArray);
    setShowAddedFilters(removedElementsArray);
    const localAppList: any = appList;

    if (e.filterByValue === "app") {
      if (e.include.length) {
        const removedElement = localAppList?.find((element: any) => {
          return element.value === e.filterBy;
        });
        const newIncludeList = appIncludeList.filter(
          (e: any) => e !== removedElement.id
        );
        setAppIncludeList(newIncludeList);
      } else {
        const removedElement = localAppList?.find((element: any) => {
          return element.value === e.filterBy;
        });
        const newExcludeList = appExcludeList.filter(
          (element: any) => element !== removedElement.id
        );
        setAppExcludeList(newExcludeList);
      }
    } else {
      if (e.include.length) {
        const newIncludeList = categoryIncludeList.filter(
          (element: any) => element.toLowerCase() !== e.filterBy.toLowerCase()
        );
        setCategoryIncludeList(newIncludeList);
      } else {
        const newExcludeList = categoryExcludeList.filter(
          (element: any) => element.toLowerCase() !== e.filterBy.toLowerCase()
        );
        setCategoryExcludeList(newExcludeList);
      }
    }
  };
  const handleClearAll = () => {
    setAddedFiltersArray([]);
    setShowAddedFilters([]);
    setAppIncludeList([]);
    setAppExcludeList([]);
    setCategoryExcludeList([]);
    setCategoryIncludeList([]);
  };

  return (
    <>
      {sessionStorage.getItem("insightTab") == "true" ? (
        <div className="net-reach-report">
          <Grid>
            <Grid.Col span={12}>
              <Flex align={"center"}>
                <Title order={3}>Net-Reach Report</Title>
                <Tooltip
                  label="Identify apps to reach your target users"
                  withArrow
                  openDelay={500}
                  closeDelay={100}
                >
                  <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                </Tooltip>
              </Flex>
            </Grid.Col>
            <Grid.Col span={3} md={6} sm={12} lg={3}>
              {state?.data !== undefined && (
                <PrimaryAppSelector
                  data={state?.data}
                  value={state?.value}
                  onChange={(e: any) => handleChange(e)}
                  src={state?.src}
                />
              )}
            </Grid.Col>
            <Grid.Col span={3} md={6} sm={12} lg={3}>
              <NetReachGoal
                value={state?.netReachTextValue}
                onChange={(e: any) => {
                  handleNetReactValue(e);
                  if (e.target.value === "") {
                    setError("Please enter the number");
                  } else {
                    setError("");
                  }
                }}
                error={error}
              />
            </Grid.Col>
            <Grid.Col span={3} md={6} sm={12} lg={3}>
              <DatePicker
                dateRange={dateRange}
                handleDateChange={(e: any, i: any) => handleDateChange(e, i)}
                dates={state?.dates}
                defaultValue={state?.dates}
              />
            </Grid.Col>
            {state?.value && state?.netReachTextValue && state?.dates && (
              <Grid.Col span={3} md={5} sm={11} lg={3}>
                <NetReachTotalUser
                  percentageWiseUser={state?.netReachTextValue}
                  usersCount={numberFormatter(
                    Math.round(
                      state?.totalUserCount * (state?.netReachTextValue * 0.01)
                    )
                  )}
                  handleGenerateReport={handleGenerateReport}
                  disabled={
                    state?.value && state?.netReachTextValue && state?.dates
                  }
                />
              </Grid.Col>
            )}
          </Grid>

          <Grid className="mt-5">
            <Grid.Col span={12} className="">
              {state?.showFiltersFlag === true && (
                <Card withBorder>
                  <Card.Section className="px-8">
                    <Grid align="center">
                      <Grid.Col span={12} className="pb-4">
                        <Flex align={"center"}>
                          <Title order={5}>Targeting</Title>
                          <Tooltip
                            label="Filters on primary app users by which you can achieve your target"
                            withArrow
                            openDelay={500}
                            closeDelay={100}
                          >
                            <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                          </Tooltip>
                        </Flex>
                      </Grid.Col>

                      <Grid.Col lg={9} md={9} sm={12}>
                        {state?.showSkeleton ? (
                          <Flex justify={"center"} align={"center"}>
                            <Skeleton height={100} className="my-6"></Skeleton>
                          </Flex>
                        ) : (
                          <Grid>
                            <Grid.Col lg={2}>
                              <div>
                                <Drawer
                                  opened={opened}
                                  onClose={close}
                                  title="Manage Filters"
                                  position="right"
                                  className={
                                    themeProvider === "dark"
                                      ? "filter-drawer dark-theme-drawer"
                                      : "filter-drawer light-theme-drawer"
                                  }
                                  size="xl"
                                >
                                  <Divider></Divider>
                                  <Flex className="content-modal mt-4">
                                    <Flex direction={"column"} className="mr-4">
                                      <Text fz="sm" className="text-secondary">
                                        Filter By
                                      </Text>
                                      <Select
                                        allowDeselect={false}
                                        placeholder="Pick one"
                                        data={[
                                          { value: "app", label: "App" },
                                          {
                                            value: "appCategory",
                                            label: "App Category",
                                          },
                                        ]}
                                        value={filterByValue}
                                        onChange={setFilterByValue}
                                        clearable
                                        searchable
                                        nothingFound="no result found..."
                                        rightSection={
                                          <ChevronDownIcon className="h-4 w-4 mr-4 text-secondary" />
                                        }
                                        className="filter-select"
                                      />
                                    </Flex>
                                    <div>
                                      {filterByValue === "app" ? (
                                        <TargetingCard
                                          isAddButtonDisabled={
                                            isAddButtonDisabled
                                          }
                                          handleClick={(e: any) =>
                                            handleAddClick(e)
                                          }
                                          addedFiltersArray={addedFiltersArray}
                                          appLabel={"App"}
                                          appList={
                                            state?.appListBasedOnCategory
                                          }
                                          value={
                                            state?.appListValueBasedOnCategory
                                          }
                                          onChange={(e: any) => {
                                            dispatch(
                                              setAppListValueBasedOnCategory(e)
                                            );
                                            handleAppListChange(e);
                                          }}
                                          selectedInclude={selectAppListInclude}
                                          selectedExclude={
                                            selecteAppListExclude
                                          }
                                          click={(e: any) => {
                                            if (e.target.value === "Include") {
                                              setAppListInclude("Include");
                                              setAppListExclude("");
                                            } else if (
                                              e.target.value === "Exclude"
                                            ) {
                                              setAppListInclude("");
                                              setAppListExclude("Exclude");
                                            }
                                          }}
                                        />
                                      ) : (
                                        <TargetingCard
                                          isAddButtonDisabled={
                                            isAddButtonDisabled
                                          }
                                          handleClick={(e: any) =>
                                            handleAddClick(e)
                                          }
                                          addedFiltersArray={addedFiltersArray}
                                          value={state?.appCategoryValue}
                                          appLabel={"App Category"}
                                          appList={state?.appCategory}
                                          onChange={(e: any) =>
                                            handleCategoryChange(e)
                                          }
                                          selectedInclude={
                                            selectCategoryInclude
                                          }
                                          selectedExclude={
                                            selectCategoryExclude
                                          }
                                          click={(e: any) => {
                                            if (e.target.value === "Include") {
                                              setSelectInclude("Include");
                                              setSelectedExclude("");
                                            } else if (
                                              e.target.value === "Exclude"
                                            ) {
                                              setSelectedExclude("Exclude");
                                              setSelectInclude("");
                                            }
                                          }}
                                        />
                                      )}
                                    </div>
                                  </Flex>
                                  <Text className="error mb-8 text-xs">
                                    {showError}
                                  </Text>
                                  <Divider></Divider>
                                  <div className="mt-4">
                                    <Text>Added Filters</Text>
                                    <div className="filter-chip-block">
                                      {addedFiltersArray?.map((filter: any) => {
                                        return (
                                          <div className="filter-chip">
                                            <Flex align="center">
                                              <Text className="category-label">
                                                {filter.filterByValue === "app"
                                                  ? "App :"
                                                  : "App Category :"}
                                              </Text>
                                              <Text className="category-name">
                                                {filter.filterBy}
                                              </Text>
                                              <Text className="include-exclude-label">
                                                {filter.include ||
                                                  filter.exclude}
                                              </Text>
                                              <XMarkIcon
                                                className="h-4 w-4 close-mark cursor-pointer"
                                                onClick={() =>
                                                  handleCloseClickDrawer(filter)
                                                }
                                              />
                                            </Flex>
                                          </div>
                                        );
                                      })}
                                    </div>

                                    <Flex
                                      className="mt-60"
                                      align={"center"}
                                      justify={"center"}
                                    >
                                      <Button
                                        className="secondary-button mr-5"
                                        onClick={() => {
                                          dispatch(setAppCategoryValue(""));
                                          dispatch(
                                            setAppListValueBasedOnCategory("")
                                          );
                                          setAddedFiltersArray([]);
                                          close();
                                        }}
                                      >
                                        Cancel
                                      </Button>

                                      <Button
                                        className="primary-button"
                                        onClick={() => {
                                          handleGenerateReport();
                                          setShowAddedFilters(
                                            addedFiltersArray
                                          );
                                          close();
                                        }}
                                        disabled={
                                          addedFiltersArray.length === 0
                                        }
                                      >
                                        Apply Filters
                                      </Button>
                                    </Flex>
                                  </div>
                                </Drawer>
                                <Group position="center">
                                  <Button
                                    size="sm"
                                    radius="sm"
                                    variant="outline"
                                    className="filter-button"
                                    onClick={open}
                                  >
                                    <AdjustmentsHorizontalIcon className="h-5 w-5 mr-2 filter-icon" />
                                    Filters
                                  </Button>
                                </Group>
                              </div>
                            </Grid.Col>
                            <Grid.Col lg={8}>
                              <div>
                                {showAddedFilters?.map((filter: any) => {
                                  return (
                                    <div className="filter-chip">
                                      <Flex align="center">
                                        <Text className="category-label">
                                          {filter.filterByValue === "app"
                                            ? "App :"
                                            : "App Category :"}
                                        </Text>
                                        <Text className="category-name">
                                          {filter.filterBy}
                                        </Text>
                                        <Text className="include-exclude-label">
                                          {filter.include || filter.exclude}
                                        </Text>
                                        <XMarkIcon
                                          className="h-4 w-4 close-mark cursor-pointer"
                                          onClick={() =>
                                            handleCloseClick(filter)
                                          }
                                        />
                                      </Flex>
                                    </div>
                                  );
                                })}
                              </div>
                            </Grid.Col>
                            <Grid.Col lg={1}>
                              {showAddedFilters.length > 0 && (
                                <Text
                                  td="underline"
                                  className="text-secondary cursor-pointer "
                                  onClick={() => handleClearAll()}
                                >
                                  Clear All
                                </Text>
                              )}
                            </Grid.Col>
                            {showAddedFilters.length ? (
                              <Divider orientation="vertical"></Divider>
                            ) : (
                              <></>
                            )}
                          </Grid>
                        )}
                      </Grid.Col>

                      <Grid.Col lg={3} md={3} sm={12}>
                        <NetReachProgress
                          uniqueUserCountPercentage={
                            state?.uniqueUsersProgress
                              ?.uniqueUserCountPercentage
                          }
                          sumOfUniqueUsers={
                            state?.uniqueUsersProgress?.sumOfUniqueUsers
                          }
                        />
                      </Grid.Col>
                    </Grid>
                    <Grid.Col lg={12} md={12} sm={12} className="px-4">
                      {state?.showLoader ? (
                        <>
                          {state?.showSkeleton ? (
                            <Flex justify={"center"} align={"center"}>
                              <Skeleton
                                height={460}
                                className="my-6"
                              ></Skeleton>
                            </Flex>
                          ) : (
                            <Card.Section withBorder>
                              <SimpleGrid className="mt-10" style={{ gap: 0 }}>
                                <Flex justify={"space-between"}>
                                  <SimpleGrid>
                                    <Title
                                      order={5}
                                      className="pb-0 mb-1"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      Target Apps
                                      <Tooltip label="Suggested apps to achieve your net reach goal">
                                        <InformationCircleIcon className="w-6 h-6 ml-2 cursor-pointer" />
                                      </Tooltip>
                                    </Title>
                                  </SimpleGrid>
                                  <SimpleGrid>
                                    <Flex
                                      direction={"row"}
                                      justify={"space-around"}
                                    >
                                      <SimpleGrid className="flex mr-2">
                                        <span className="user_included"></span>
                                        <Text fz="xs">Targeted users</Text>
                                      </SimpleGrid>
                                      <SimpleGrid className="flex">
                                        <span className="user_excluded"></span>
                                        <Text fz="xs">Remaining users</Text>
                                      </SimpleGrid>
                                    </Flex>
                                  </SimpleGrid>
                                </Flex>
                                <ReactEcharts
                                  option={options}
                                  opts={{ renderer: "svg" }}
                                  style={{
                                    height: "490px",
                                    width: "auto",
                                  }}
                                ></ReactEcharts>
                              </SimpleGrid>
                            </Card.Section>
                          )}
                        </>
                      ) : (
                        <>
                          <Flex
                            justify={"center"}
                            align="center"
                            style={{ height: "50vh" }}
                            direction={"column"}
                          >
                            <Text fz="lg" className="text-secondary mb-4">
                              Get Target Apps & Unique Users
                            </Text>
                            <Text fz="xs" className="text-secondary">
                              Please select app category & App filter to get the
                              list of apps & unique users
                            </Text>
                          </Flex>
                        </>
                      )}
                    </Grid.Col>
                  </Card.Section>
                </Card>
              )}
            </Grid.Col>
          </Grid>
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </>
  );
};

export default Hoc(NetReachReport);
