import { personaApiSlice } from "../api/personaApiSlice";
import { gql } from "graphql-request";

export interface AudienceBuilderRequest {
  keyword: string;
  categories: any;
  apps: any;
  dayPhases: any;
  urls: any;
}

export interface CreateAudienceRequest {
  dataSource: string;
  userName: string;
  baseReach: any;
  maxAudienceSize: any;
  sectionOneCondition: any;
  sectionTwoCondition: any;
  sectionOneFilters: {
    keywords: any;
    appCategories: any;
    apps: any;
    include: any;
    usages: any;
    dayPhases: any;
  };
  sectionTwoFilters: {
    keywords: any;
    appCategories: any;
    apps: any;
    include: any;
    usages: any;
    dayPhases: any;
  };
  sectionThreeFilters: {
    keywords: any;
    appCategories: any;
    apps: any;
    include: any;
    usages: any;
    dayPhases: any;
  };
  expectedReach: any;
  audienceName: string;
  platforms: any;
}

export interface SavedAudiencesRequest {
  userName: string;
}

export interface UpdateAudienceRequest {
  audienceId: string;
  audienceName: any;
  platforms: any;
}

const getAudienceBuilderSize = gql`
  query getAudienceSearchResults(
    $audienceBuilderRequest: AudienceBuilderRequest
  ) {
    getAudienceSearchResults(audienceBuilderRequest: $audienceBuilderRequest) {
      audienceSize {
        filteredSize
        totalSize
      }
      networkDistributionMetrics {
        name
        value
      }
      deviceBrandDistributionMetrics {
        name
        value
      }
      topAppsMetrics {
        name
        value
      }
      topCategoryMetrics {
        name
        value
      }
      uploadUsageMetrics {
        name
        value
      }
      downloadUsageMetrics {
        name
        value
      }
      topKeywordsMetrics {
        name
        value
      }
      filterSectionsDetails {
        sectionOneCondition
        sectionTwoCondition
        sectionOneFiltersSpecificCount {
          stdApps
          apps
          dayPhases
          usages
          keywords
          appCategories
          include
          appSpecificCount {
            name
            value
          }
          keywordSpecificCount {
            name
            value
          }
          appCategorySpecificCount {
            name
            value
          }
        }
        sectionTwoFiltersSpecificCount {
          stdApps
          apps
          dayPhases
          usages
          keywords
          appCategories
          include
          appSpecificCount {
            name
            value
          }
          keywordSpecificCount {
            name
            value
          }
          appCategorySpecificCount {
            name
            value
          }
        }
        sectionThreeFiltersSpecificCount {
          apps
          dayPhases
          usages
          stdApps
          keywords
          appCategories
          include
          appSpecificCount {
            name
            value
          }
          keywordSpecificCount {
            name
            value
          }
          appCategorySpecificCount {
            name
            value
          }
        }
      }
    }
  }
`;

const getPersonaKeywords = gql`
  query {
    getPersonaKeywords {
      keywords
      category
    }
  }
`;
const getPlatformList = gql`
  query {
    getPlatformList {
      platformName
      platformId
    }
  }
`;

const getSavedAudiences = gql`
  query getAudiencesForUser($userName: String) {
    getAudiencesForUser(userName: $userName) {
      dataSource
      userName
      baseReach
      audienceName
      audienceId
      status
      expectedReach
      maxAudienceSize
      creationDate
      topAppsMetrics {
        value
        name
      }
      topCategoryMetrics {
        value
        name
      }
      topKeywordsMetrics {
        value
        name
      }
      sectionOneFilters {
        keywords
        keywordSpecificCount {
          value
          name
        }
        appCategories
        appCategorySpecificCount {
          value
          name
        }
        usages
        dayPhases
        apps
        appSpecificCount {
          value
          name
        }
        stdApps
        include
      }
      sectionTwoFilters {
        keywords
        keywordSpecificCount {
          value
          name
        }
        appCategories
        appCategorySpecificCount {
          value
          name
        }
        usages
        dayPhases
        apps
        appSpecificCount {
          value
          name
        }
        stdApps
        include
      }
      sectionThreeFilters {
        keywords
        keywordSpecificCount {
          value
          name
        }
        appCategories
        appCategorySpecificCount {
          value
          name
        }
        usages
        dayPhases
        apps
        appSpecificCount {
          value
          name
        }
        stdApps
        include
      }
      platforms {
        platformName
        platformId
        seatId
        reachForPlatform
        error
      }
      sectionOneCondition
      sectionTwoCondition
    }
  }
`;

const updateAudience = gql`
  mutation updateAudience($updateAudienceRequest: UpdateAudienceRequest) {
    updateAudience(updateAudienceRequest: $updateAudienceRequest)
  }
`;

const generateAudience = gql`
  mutation createAudience($createAudienceRequest: CreateAudienceRequest) {
    createAudience(createAudienceRequest: $createAudienceRequest)
  }
`;

export const audienceBuilderApiSlice = personaApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAudienceSize: builder.query<string, any>({
      query: (variables: any): any => ({
        document: getAudienceBuilderSize,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getAudienceSearchResults;
      },
    }),
    getPersonaKeywords: builder.query<any, any>({
      query: (): any => ({
        document: getPersonaKeywords,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getPlatformList: builder.query<any, any>({
      query: (): any => ({
        document: getPlatformList,
      }),
      transformResponse: (response: any): any => {
        return response;
      },
    }),
    getSavedAudiences: builder.query<any, any>({
      query: (variables: any): any => ({
        document: getSavedAudiences,
        variables,
      }),
      transformResponse: (response: any): any => {
        return response?.getAudiencesForUser;
      },
    }),
    generateAudienceReport: builder.mutation<any, any>({
      query: (variables: any): any => ({
        document: generateAudience,
        variables,
      }),
    }),
    deleteAudience: builder.mutation<any, any>({
      query: (variables: any): any => ({
        document: gql`
        mutation {
          deleteAudience(audienceId: ${variables.audienceId})
        }
      `,
      }),
    }),
    updateAudience: builder.mutation<any, any>({
      query: (variables: any): any => ({
        document: updateAudience,
        variables,
      }),
    }),
  }),
});
export const {
  useGetAudienceSizeQuery,
  useLazyGetAudienceSizeQuery,
  useGetPersonaKeywordsQuery,
  useLazyGetPersonaKeywordsQuery,
  useGetPlatformListQuery,
  useLazyGetPlatformListQuery,
  useGetSavedAudiencesQuery,
  useLazyGetSavedAudiencesQuery,
  useGenerateAudienceReportMutation,
  useDeleteAudienceMutation,
  useUpdateAudienceMutation,
} = audienceBuilderApiSlice;
