import { FC, useEffect, useState } from "react";
import { Grid, Text, Card, Flex, Tooltip } from "@mantine/core";
import { useSelector } from "react-redux";
import ReactEcharts from "echarts-for-react";
import { EngagementRateProps } from "../../types/twinAppReport.types";
import { selectInitialAppList } from "@/features/services/search/searchAppSlice";
import "./EngagementRateReport.scss";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { numberFormatter } from "@/Util/utils";

const EngagementRateReport: FC<EngagementRateProps> = ({
  engagementRateData,
}) => {
  const initialAppList = useSelector(selectInitialAppList);
  const [engagementRate, setEngagementRate] = useState<any>([]);
  const [xAxisLabel, setLabel] = useState<string[] | undefined>();
  const reportData = (): any => {
    const engagementRate: any[] = [];
    const xAxisLabels: string[] = [];
    const colors = ["#50D1C5", "#B593F2", "#FDAD65"];
    let i = 0;
    for (let x in engagementRateData) {
      if (engagementRateData[x]?.appId) {
        let xAxisLabel = engagementRateData[x]?.appName;
        let primaryApp = engagementRateData[x]?.appPercent;
        let absoluteValue = engagementRateData[x]?.absoluteValue;
        xAxisLabels.push(xAxisLabel);
        engagementRate.push({
          value: primaryApp,
          itemStyle: {
            color: colors[i],
          },
          absoluteValue: absoluteValue,
        });
        i++;
      }
    }
    setLabel(xAxisLabels);
    return engagementRate;
  };
  useEffect(() => {
    setEngagementRate(reportData());
  }, [setEngagementRate, engagementRateData]);
  let appData: any[] = [];
  xAxisLabel?.map((item) => {
    appData.push({
      name: item,
    });
  });
  const options = {
    legend: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: (params: any) => {
        return `
        <div style="display: flex; justify-content: space-between; align-items:center">
          <div style="margin-right : 15px">
            <span style="display: inline-block; width: 10px; margin-right : 3px; height: 10px; border-radius: 50%; background:${params?.color}"></span>
            <span style="display:inline-block; margin-right : 30px">${params?.name}</span>
            <span style="display:inline-block">${
              numberFormatter(
                params?.data?.absoluteValue
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
              ) +
              " (" +
              params?.value?.toFixed(2) +
              "%)"
            }</span>
          </div>
          </div>
       `;
      },
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      top: 20,
      left: 0,
      bottom: 10,
      right: 10,
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: xAxisLabel !== undefined ? [...xAxisLabel] : [],
      axisLabel: {
        interval: 0,
        formatter: function (value: any) {
          return (
            "{" +
            value?.replace(/ +/g, "") +
            "| }{value|" +
            value?.replace(/ +/g, "") +
            "}"
          );
        },
        rich: {
          value: {
            lineHeight: 30,
            padding: 10,
            align: "center",
          },
          [appData[0]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[0]?.name;
              })?.appicon,
            },
          },
          [appData[1]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[1]?.name;
              })?.appicon,
            },
          },
          [appData[2]?.name]: {
            height: 20,
            width: 20,
            align: "center",
            backgroundColor: {
              image: initialAppList.find(function (item: any) {
                return item.value === appData[2]?.name;
              })?.appicon,
            },
          },
        },
        fontSize: "small",
      },
    },
    yAxis: {
      type: "value",
      min: 0,
      max: 100,
      axisLabel: {
        formatter: (num: any) => {
          return num + "%";
        },
      },
    },
    series: [
      {
        type: "bar",
        color: ["#50D1C5"],
        data: [...engagementRate],
        barWidth: 45,
        label: {
          show: true,
          position: "top",
          formatter: (params: any) => {
            return params?.value.toFixed(2) + "%";
          },
        },
      },
    ],
  };
  return (
    <Grid className="engagement-rate-report py-4">
      <Grid.Col span={12}>
        <Card
          shadow="sm"
          padding="lg"
          radius="md"
          withBorder
          style={{ zIndex: 0, overflow: "unset" }}
        >
          <Card.Section>
            <div className="title-text p-6">
              <Flex align={"center"}>
                <Text fw={700} fz={"md"} className={"title-text"}>
                  Retention Rate
                </Text>
                <Tooltip
                  label="Number of users retained over a week"
                  withArrow
                  openDelay={500}
                  closeDelay={100}
                >
                  <InformationCircleIcon className="h-6 w-6 ml-1 cursor-pointer" />
                </Tooltip>
                {/* <Flex className="icon-block">
                <div
                  className={showBarChart ? "bar-icon active" : "bar-icon"}
                  onClick={() => handleClick("barChart")}
                >
                  <Image src={BarChartIcon} width={20} height={20} />
                </div>
                <div
                  className={showBarChart ? "line-icon" : "line-icon active"}
                  onClick={() => handleClick("lineChart")}
                >
                  <Image src={LineChartIcon} width={20} height={20} />
                </div>
              </Flex> */}
              </Flex>
            </div>
            {engagementRateData !== undefined &&
            engagementRateData.length > 0 ? (
              <ReactEcharts
                option={options}
                opts={{ renderer: "svg" }}
                style={{
                  width: "auto",
                  height: "300px",
                  paddingTop: "20px",
                  margin: "0 16px 16px 16px",
                }}
              ></ReactEcharts>
            ) : (
              <Text ta="center" fz="sm">
                No data found for selected Dates
              </Text>
            )}
          </Card.Section>
        </Card>
      </Grid.Col>
    </Grid>
  );
};

export default EngagementRateReport;
