import {
  Group,
  Button,
  Grid,
  Drawer,
  Text,
  TextInput,
  Checkbox,
  SimpleGrid,
  Divider,
  Chip,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useLazyGetSearchedAppQuery } from "@/features/services/search/serachAppApiSlice";
import {
  selectAudienceAppList,
  getAudienceAppList,
} from "@/features/services/search/searchAppSlice";
import { selectLightTheme } from "@/features/services/themes/themeSlice";

const SelectAppsForAudience = (props: any) => {
  const theme = useSelector(selectLightTheme);
  const initialDayPhaseList: any = [
    { id: 41, name: "Early Morning", isChecked: false },
    { id: 42, name: "Morning", isChecked: false },
    { id: 43, name: "Afternoon", isChecked: false },
    { id: 44, name: "Evening", isChecked: false },
    { id: 45, name: "Night", isChecked: false },
  ];
  const initialUsageList: any = [
    { id: 51, name: "Very High", isChecked: false },
    { id: 52, name: "High", isChecked: false },
    { id: 53, name: "Medium", isChecked: false },
    { id: 54, name: "Low", isChecked: false },
    { id: 55, name: "Very Low", isChecked: false },
  ];
  const [appValue, setAppValue] = useState<any>("");
  const [dayPhaseList, setDayPhaseList] = useState(initialDayPhaseList);
  const [usageList, setUsageList] = useState(initialUsageList);

  const [filteredApps, setFilteredApps] = useState<any>([]);
  const [filteredDayPhase, setFilteredDayPhase] = useState<any>([]);
  const [filteredUsage, setFilteredUsage] = useState<any>([]);

  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [selectedDayPhase, setSelectedDayPhase] = useState<string[]>([]);
  const [selectedUsage, setSelectedUsage] = useState<string[]>([]);
  const [selectedMicaNameApps, setSelectedMicaNameApps] = useState<string[]>(
    []
  );

  const [fetchAppList] = useLazyGetSearchedAppQuery<any>();
  const appLists = useSelector(selectAudienceAppList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (appLists !== null || appLists !== undefined) {
      setFilteredApps(appLists);
    }
  }, [appLists]);

  useEffect(() => {
    if (appLists === null || appLists === undefined) {
      fetchAppList("").then((res: any) => {
        if (res !== undefined) {
          dispatch(getAudienceAppList(res.data));
          setFilteredApps(res.data);
        }
      });
    }
  }, [fetchAppList, appLists, getAudienceAppList]);

  useEffect(() => {
    setFilteredApps(appLists);
    setFilteredDayPhase(
      props.selectedDayPhaseList.length > 0
        ? props.selectedDayPhaseList
        : dayPhaseList
    );
    setFilteredUsage(
      props.selectedUsageList.length > 0 ? props.selectedUsageList : usageList
    );
    setSelectedApps(props.selectedApps);
    setSelectedMicaNameApps(props.selectedmApps);
    setSelectedDayPhase(props.selectedDayPhase);
    setSelectedUsage(props.selectedUsage);
  }, [props.filterSectionOpened]);

  const handleAppInputChange = (event: any) => {
    const inputValue = event.target.value;
    setAppValue(inputValue);

    const filtered: any = appLists?.filter((app: any) =>
      app.value.toLowerCase().startsWith(inputValue.toLowerCase())
    );
    setFilteredApps(filtered);
  };

  const handleAppCheckboxChange = (e: any) => {
    const filterObj = appLists
      ?.filter((obj: any) => e.includes(obj.value))
      .map((obj: any) => obj.mName);
    setSelectedApps(e);

    setTimeout(() => {
      setSelectedMicaNameApps(filterObj);
    }, 100);
  };

  const handleDayPhaseCheckboxChange = (keyword: any, itemChecked: boolean) => {
    let keywordsArray;
    if (itemChecked) {
      keywordsArray = [...selectedDayPhase, keyword];
    } else {
      keywordsArray = selectedDayPhase.filter(function (item: any) {
        return item !== keyword;
      });
    }
    setSelectedDayPhase(keywordsArray);
    let newObj = JSON.parse(JSON.stringify(dayPhaseList));
    newObj.map((item: any) => {
      if (item.name === keyword) {
        item.isChecked = itemChecked;
      }
    });
    setDayPhaseList(newObj);
    setFilteredDayPhase(newObj);
  };

  const handleUsageCheckboxChange = (keyword: any, itemChecked: boolean) => {
    let keywordsArray;
    if (itemChecked) {
      keywordsArray = [...selectedUsage, keyword];
    } else {
      keywordsArray = selectedUsage.filter(function (item: any) {
        return item !== keyword;
      });
    }
    setSelectedUsage(keywordsArray);
    let newObj = JSON.parse(JSON.stringify(usageList));
    newObj.map((item: any) => {
      if (item.name === keyword) {
        item.isChecked = itemChecked;
      }
    });

    setUsageList(newObj);
    setFilteredUsage(newObj);
  };

  const applyFilters = () => {
    props.applyFilters({
      apps: selectedApps,
      dayPhase: selectedDayPhase,
      mApps: selectedMicaNameApps,
      usage: selectedUsage,
      dayPhaseList: filteredDayPhase,
      usageList: filteredUsage,
      categories: [],
      keywords: [],
    });
  };

  return (
    <Drawer
      position="right"
      opened={props.filterSectionOpened}
      onClose={props.handleFilterSectionClose}
      title="Select Apps (Max 3)"
      className="section-drawer"
    >
      <Grid style={{ height: "70vh" }}>
        <Grid.Col>
          <Divider my="sm" />
        </Grid.Col>
        <Grid.Col>
          <TextInput
            placeholder="Search"
            value={appValue}
            onChange={handleAppInputChange}
            variant="filled"
          />
        </Grid.Col>
        <Grid.Col>
          <Checkbox.Group
            value={selectedApps}
            onChange={(e) => handleAppCheckboxChange(e)}
            className={
              selectedApps.length === 3
                ? "filter-checkbox-group disabled"
                : "filter-checkbox-group"
            }
          >
            <SimpleGrid mt="xs">
              {filteredApps?.map((app: any) => (
                <Checkbox label={app.label} value={app.value} key={app.id} />
              ))}
            </SimpleGrid>
          </Checkbox.Group>
        </Grid.Col>
        <Grid.Col>
          <Divider my="sm" />
        </Grid.Col>
        <Grid.Col>
          <Text>Day Phase</Text>
        </Grid.Col>
        <Grid.Col>
          <Group spacing={5}>
            {filteredDayPhase.map((data: any) => (
              <Chip
                onChange={(checked) =>
                  handleDayPhaseCheckboxChange(data.name, checked)
                }
                className="keyword-chip"
                defaultChecked={data.isChecked}
                checked={data.isChecked}
                key={data.id}
              >
                {data.name}
              </Chip>
            ))}
          </Group>
        </Grid.Col>
        <Grid.Col>
          <Divider my="sm" />
        </Grid.Col>
        <Grid.Col>
          <Text>Usage</Text>
        </Grid.Col>
        <Grid.Col>
          <Group spacing={5}>
            {filteredUsage.map((data: any) => (
              <Chip
                onChange={(checked) =>
                  handleUsageCheckboxChange(data.name, checked)
                }
                className="keyword-chip"
                defaultChecked={data.isChecked}
                checked={data.isChecked}
                key={data.id}
              >
                {data.name}
              </Chip>
            ))}
          </Group>
        </Grid.Col>
        <Grid.Col>
          <Group
            position="center"
            className={
              (selectedApps.length > 0 ||
                selectedDayPhase.length > 0 ||
                selectedUsage.length > 0) &&
              theme === "dark"
                ? "hasSelectedFilters dark-theme-button"
                : "noFilters"
            }
          >
            <Button
              className="secondary-button btn"
              onClick={props.handleFilterSectionClose}
            >
              Cancel
            </Button>
            <Button
              className={
                selectedApps.length > 0 &&
                (selectedDayPhase.length >= 0 || selectedUsage.length >= 0)
                  ? "primary-button btn"
                  : "primary-button disabled btn"
              }
              disabled={
                selectedApps.length > 0 &&
                (selectedDayPhase.length >= 0 || selectedUsage.length >= 0)
                  ? false
                  : true
              }
              onClick={() => applyFilters()}
            >
              Add
            </Button>
          </Group>
        </Grid.Col>
      </Grid>
    </Drawer>
  );
};

export default SelectAppsForAudience;
