import { useState } from "react";
import Tlogo from "../../assets/images/Tlogo.svg";
import { Button, TextInput } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import "./login.scss";
import generateOtp from "./Otp.generated";
import { setError } from "../../features/services/errorSlice";
import { useDispatch } from "react-redux";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [invalidEmail, setInvalidEmail] = useState<any>(false);
  const dispatch = useDispatch();

  const resetPasword = (event: any) => {
    event.preventDefault();
    generateOtp(
      userName,
      (res: any) => {
        navigate("/otpverifcationpage");
      },
      (err: any) => {
        dispatch(setError(err.response.data));
      }
    );
  };
  const userNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
    setInvalidEmail(false);
  };
  const validateEmail = () => {
    const validEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    userName.match(validEmailRegex)
      ? setInvalidEmail(false)
      : setInvalidEmail("Please enter a valid email address.");
  };

  return (
    <div className="login-background">
      <img src={Tlogo}></img>
      <form className="center-content" onSubmit={resetPasword}>
        <span className="signin-text">Forget your password</span>
        <div className="form-container">
          <TextInput
            label="Email address"
            placeholder="name@company.com"
            onChange={userNameHandler}
            value={userName}
            error={invalidEmail}
            onBlur={validateEmail}
            data-testid="email-input"
          ></TextInput>
          <Button
            type="submit"
            fullWidth
            className={
              userName.trim().length > 0 && !invalidEmail
                ? "backgrnd-magenta sign-in-btn"
                : "disable-signin sign-in-btn"
            }
            data-testid="send-otp-button"
          >
            Send OTP
          </Button>
          <div className="sign-in card">
            <p>
              Back to{" "}
              <Link to={"/login"} className="forgot-password">
                Sign In
              </Link>{" "}
              Page
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};
