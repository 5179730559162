// OtpVerificationPage.tsx
import React, { useState, useRef, useEffect } from "react";
import { Input, Button } from "@mantine/core";
import "./login.scss";
import Tlogo from "../../assets/images/Tlogo.svg";
import generateOtp from "./Otp.generated";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../features/services/errorSlice";
import { notifications } from "@mantine/notifications";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
interface OtpVerificationPageProps {
  // Add any necessary props
}

const OtpVerificationPage: React.FC<OtpVerificationPageProps> = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState<number[]>([NaN, NaN, NaN, NaN, NaN, NaN]);
  const otpInputsRefs = useRef<Array<HTMLInputElement | null>>([
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [timer, setTimer] = useState<number>(600); // 10 minutes in seconds 600
  const [resendActive, setResendActive] = useState<any>(false);
  const handleOtpChange = (index: number, value: string) => {
    // Handle backspace
    const newOtp = [...otp];
    if (value === "") {
      newOtp[index] = NaN;
      setOtp(newOtp);

      // Focus on the previous input (if available) when backspace is pressed
      if (otpInputsRefs.current[index - 1]) {
        otpInputsRefs.current[index - 1]?.focus();
      }

      return;
    }

    // Ensure that the entered value is a number
    const digit = parseInt(value, 10);

    // Update the state only if the entered value is a valid number
    if (!isNaN(digit)) {
      const newOtp = [...otp];
      newOtp[index] = digit;
      setOtp(newOtp);

      // Focus on the next input (if available) when a digit is entered
      if (otpInputsRefs.current[index + 1]) {
        otpInputsRefs.current[index + 1]?.focus();
      }
    }
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number,
  ) => {
    // Prevent non-numeric input
    if (isNaN(parseInt(event.key, 10))) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    // Focus on the first input when the component mounts
    if (otpInputsRefs.current[0]) {
      otpInputsRefs.current[0]?.focus();
    }
  }, []);
  useEffect(() => {
    // Countdown timer logic
    const timerId = setTimeout(() => {
      if (timer === 0) {
        setResendActive(true);
        notifications.show({
          message: "OTP expired. Please click on resend link below.",
          color: "green",
        });
      }
      if (timer > 0) {
        setTimer(timer - 1);
      }
    }, 1000);

    return () => clearTimeout(timerId);
  }, [timer]);

  const formatTimer = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const resendOtp = () => {
    const user = sessionStorage.getItem("userName");
    generateOtp(
      user,
      (res: any) => {
        setOtp([NaN, NaN, NaN, NaN, NaN, NaN]);
        notifications.show({
          message: "OTP resent sucessfully. Please check your mail.",
          color: "green",
        });
        setTimer(600);
        setResendActive(false);
      },
      (err: any) => {
        dispatch(setError(err.message));
      },
    );
  };

  const enableResent = () => {
    setResendActive(true);
  };

  return (
    <div className="login-background">
      <img src={Tlogo}></img>
      <div className="center-content">
        <h1>Reset your password</h1>
        <h4
          style={{
            color: "#FFFFFF80",
            fontSize: "14px",
            paddingBottom: "12px",
          }}
        >
          Please check your email for OTP
        </h4>
        <div style={{ display: "flex" }}>
          {otp.map((digit, index) => (
            <Input
              placeholder="-"
              key={index}
              style={{ width: "2.5em", marginRight: "0.5em" }}
              value={isNaN(digit) ? "" : digit.toString()}
              maxLength={1}
              onChange={(event) =>
                handleOtpChange(index, event.currentTarget.value)
              }
              onKeyPress={(event) => handleKeyPress(event, index)}
              type="text"
              ref={(ref) => (otpInputsRefs.current[index] = ref)}
            />
          ))}
        </div>
        <p style={{ paddingTop: "12px" }}>
          {" "}
          <Link
            onClick={resendOtp}
            className={
              resendActive ? "activate-resend-link" : "inactive-resend-link"
            }
          >
            Resend{" "}
          </Link>{" "}
          <span>
            {" "}
            OTP {!resendActive && <span>in {formatTimer(timer)}</span>}
          </span>
        </p>
        <ForgotPasswordForm otpentered={otp} enableDisable={enableResent} />
      </div>
    </div>
  );
};

export default OtpVerificationPage;
