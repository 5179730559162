import React from "react";
import { useNavigate } from "react-router-dom";
import Tlogo from "../../assets/images/Tlogo.svg";
import lockClosed from "../../assets/icons/lock-closed.svg";
import { Grid } from "@mantine/core";

interface ErrorPageProps {}

const UnauthorizedPage: React.FC<ErrorPageProps> = ({}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  return (
    <Grid style={{ padding: "25px" }}>
      <img src={Tlogo}></img>
      <Grid.Col
        span={12}
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "100px",
        }}
      >
        <img src={lockClosed}></img>
      </Grid.Col>
      <Grid.Col span={12} style={{ display: "flex", justifyContent: "center" }}>
        <h1>
          {" "}
          Sorry,You do not have the access to this page. Please contact admin.
        </h1>
      </Grid.Col>
      <Grid.Col span={12} style={{ display: "flex", justifyContent: "center" }}>
        <button onClick={handleClick}>Back</button>
      </Grid.Col>
    </Grid>
  );
};

export default UnauthorizedPage;
